import React, {Component} from 'react';
import { connect } from 'react-redux';

import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


import { addStoreToFavlist, usersearchShops } from 'actions';

import ShopItem from "components/elements/user/ShopItem";


class ShopSearch extends Component {

    constructor (props) {
        super (props);
        this.state = { limit: 5, hasMoreItems: true };
    }

    componentWillMount(){
        this.fetchMoreItems();
    }

    fetchMoreItems = () => {
        if (this.state.limit >= this.props.shops.length) {
            this.setState({ hasMoreItems: false });
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 5
            });
        }, 3000);


    }

    fetchSearchResults = ( updatedPageNo = '', query ) => {
        this.props.usersearchShops(query);
    };

    handleOnInputChange = ( event ) => {
        const query = event.target.value;
        if ( ! query ) {
            this.setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
        } else {
            this.setState( { query, loading: true, message: '' }, () => {
                this.fetchSearchResults( 1, query );
            } );
        }
    };

    render (){
        const {isLoading, query, searchproductList, shops, addStoreToFavlist} = this.props;
        //const {count, products} = productList;
        console.log(this.props.colSize)
        return (
            <div>
                <div className="container">
                    <section className="search-block">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    <form className="form-header">
                                        <div className="input-group">
                                            <input type="text"
                                                   name="query"
                                                   value={ query }
                                                   placeholder="Search shops..."
                                                   onChange={this.handleOnInputChange}
                                                   onSearch={this.filterProducts}
                                                   className="form-control"
                                                   aria-label="Amount (to the nearest dollar)"
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-solid"><i
                                                    className="fa fa-search"></i>Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {shops.length > 0 ?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls"></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <div className="row">
                                    { shops.slice(0, this.state.limit).map((shop, index) =>
                                        <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+this.props.colSize}`} key={index}>
                                            <ShopItem shop={shop}
                                                      onAddToFavlistClicked={() => addStoreToFavlist(shop)}/> )
                                        </div>)
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                    <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                                    <p>Please check if you have misspelt something or try searching with other words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    shops: state.userstoreList.searchshops,
    //products: getVisibleproducts(state.userproducts, state.filters),
    symbol: state.userproducts.symbol,
})

export default connect(
    mapStateToProps, {addStoreToFavlist, usersearchShops}
)(ShopSearch)
