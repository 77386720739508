// @flow
import { combineReducers } from "redux";

// Import All Reducers to be combined at the app Level- Root Reducer

import coreReducer from "../reducers-common/core/core";

// Import custom components
import sellerorderReducer from '../reducers-common/seller/seller.orders';
import sellerproductReducer from '../reducers-common/seller/seller.products';
import sellerstoreReducer from '../reducers-common/seller/seller.store';
import sellersubcriptionReducer from '../reducers-common/seller/seller.subscription';
import sellerRegisterStoreReducer from '../reducers-common/seller/seller.registerstore';

import adminOrderReducer from '../reducers-common/admin/admin.orders';
import adminProductReducer from '../reducers-common/admin/admin.products';
import adminShopsReducer from '../reducers-common/admin/admin.shops';
import adminSubcriptionReducer from '../reducers-common/admin/admin.subscription';

import userBaseReducer from '../reducers-common/user/user-base';
import userproductReducer from '../reducers-common/user/user-products';
import userorderReducer from '../reducers-common/user/user-orders';
import usercartwishReducer from '../reducers-common/user/user-cart-wish';
import userstoresfavReducer from '../reducers-common/user/user-stores';

import filtersReducer from '../reducers-common/extra/filters';
import wishlistReducer from '../reducers-common/extra/wishlist';
import compareReducer from '../reducers-common/extra/compare';

import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'



export default combineReducers({
  bootupInfo: coreReducer,

  adminOrders: adminOrderReducer,
  adminProducts: adminProductReducer,
  adminShops: adminShopsReducer,
  adminSubscriptions: adminSubcriptionReducer,

  subscriptions: sellersubcriptionReducer,
  store: sellerstoreReducer,
  products: sellerproductReducer,
  orders: sellerorderReducer,
  registerStore: sellerRegisterStoreReducer,

  userbootupInfo: userBaseReducer,
  userproducts: userproductReducer,
  userorders: userorderReducer,
  cartList: usercartwishReducer,
  userstoreList: userstoresfavReducer,

  filters: filtersReducer,
  wishlist: wishlistReducer,
  compare: compareReducer,
  Intl
});
