// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  categoryList: {
    count: 0,
    categories: [],
  },
  categoryImageList: {
    image: [],
  },
  productinfo: {
    _id: "",
    name: "",
    images: []
  },
  productList: {
    meta: {
      count: 0,
    },
    records: [],
  },
  productImageList: {
    images: [],
  },

  productsByCategory: {
    meta: {
      count: 0,
    },
    records: [],
  },

  storeProductCategoryList: {
    count: 0,
    categories: [],
  },
  storeProducts: {
    meta: {
      count: 0,
    },
    records: [],
  },
  searchproductList: {
    count: 0,
    products: [],
  },
  searchkeywords: {
    keywords: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_START:
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_START:

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_START:
    case ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_START:
    case ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START:

    case ActionTypes.ADMIN_GET_COMMON_PRODUCTS_START:
    case ActionTypes.ADMIN_GET_COMMON_CATEGORIES_START:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_START:
    case ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_START:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_START:
    case ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_START:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_START:
    case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE:

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE:
    case ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE:

    case ActionTypes.ADMIN_GET_COMMON_PRODUCTS_FAILURE:
    case ActionTypes.ADMIN_GET_COMMON_CATEGORIES_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_FAILURE:
    case ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE:
    case ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_FAILURE:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE:
    case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.ADMIN_SEARCH_PRODUCTS_SUCCESS: {
      const { product_count, products } = action.payload;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        productList: {
          meta: {
            count: product_count,
          },
          records: products,
        },
      };
    }
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS: {
      const { keywords } = action.payload;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        searchkeywords: {
          keywords : keywords,
        },
      };
    }

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeProducts: {
            meta: {
              count: product_count,
            },
            records: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeProducts: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeProductCategoryList: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeProductCategoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_GET_COMMON_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productsByCategory: {
            meta: {
              count: product_count,
            },
            records: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productsByCategory: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_COMMON_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          categoryList: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          categoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS:
    case ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productImageList: {
            images: action.payload.response.images,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productImageList: {
            images: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS:
    case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          categoryImageList: {
            image: action.payload.response.image,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          categoryImageList: {
            image: [],
          },
        };
      }
    }

    default:
      return state;
  }
}
