import * as React from "react";
import { AppContext } from "../../../app-context";
// import ThemeTogglerButton from './theme-toggler-button';

class Page extends React.PureComponent {
  updatePageHeader;
  constructor(props) {
    super(props);

    this.updatePageHeader = this.updatePageHeader.bind(this);
  }

  updatePageHeader() {
    const { title, breadCrumb } = this.props;
    this.setPageHeader({ title, breadCrumb });
  }

  componentDidMount() {
    this.updatePageHeader();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.updatePageHeader();
    }
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ pageHeader, setPageHeader }) => {
          if (!this.setPageHeader) {
            this.setPageHeader = setPageHeader;
          }
          return (
            <section
              className={`page ${this.props.className || ""}`}
              data-test="page"
            >
              {this.props.children}
            </section>
          );
        }}
      </AppContext.Consumer>
    );
  }
}
export default Page;
