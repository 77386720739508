// eslint-disable-next-line import/prefer-default-export
export const SellerSiteSideBarConfig = [
  {
    id: "homesellerweb",
    name: "Home",
    icon: "icon-home",
    header: "",
    route: "",
    routestart: "/",
    routeend: "",
  },
  {
    id: "sellerrorders",
    name: "Orders",
    icon: "icon-website",
    header: "Orders",
    route: "/orders",
    routestart: "/",
    routeend: "orders",
    subMenu: [
      {
        id: "inprogress",
        name: "Orders -in progress",
        header: "Orders",
        route: "",
        routestart: "/",
        routeend: "orders",
      },
    ],
  },
  {
    id: "sellerproducts",
    name: `${process.env.REACT_APP_PRODUCT_TITLE}`,
    icon: "icon-product",
    header: `${process.env.REACT_APP_PRODUCT_TITLE}`,
    route: "/product",
    routestart: "/",
    routeend: "product",
    subMenu: [
      {
        id: "my_products",
        name: "My products",
        header: "product",
        route: "",
        routestart: "/",
        routeend: "product",

      },
    ],
  },
  {
    id: "account",
    name: "Account",
    header: "Profile",
    icon: "icon-home",
    route: "/me",
    routestart: "/",
    routeend: "me",
  },
];
