/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Box, Card, IconButton, Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./ShopItemBox.scss";
import * as Actions from "actions";

const ShopItemBox = (props) => {
  const [state, setState] = useState({
    open: false,
    stock: "InStock",
    quantity: 1,
    image: "",
  });
  const {
    shop, onAddToFavlistClicked, shopProducts, adminGetStorePrivateProducts, adminGetShopInfoById,
    adminGetStorePrivateCategories, shopCategories, shopOrders, adminGetStoreOrders, shopDetail, history,
  } = props;

  // const onClickHandle = (img) => {
  //   setState({ image: img });
  // };
  const onOpenModal = () => {
    setState({ open: true });
    adminGetStorePrivateProducts(shop.id, false, false);
    adminGetStoreOrders(shop.id);
    adminGetStorePrivateCategories(shop.id, false, false);
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

  const handleMoreButtonClick = (element) => {
    history.push(`/approve/${element.id}`);
  };

  // const minusQty = () => {
  //   if (state.quantity > 1) {
  //     setState({ stock: "InStock" });
  //     setState({ quantity: state.quantity - 1 });
  //   }
  // };

  // const plusQty = () => {
  //   if (props.product.stock >= state.quantity) {
  //     setState({ quantity: state.quantity + 1 });
  //   } else {
  //     setState({ stock: "Out of Stock !" });
  //   }
  // };
  // const changeQty = (e) => {
  //   setState({ quantity: parseInt(e.target.value, 10) });
  // };
  const RatingStars = [];
  for (let i = 0; i < shop.ratings; i += 1) {
    RatingStars.push(<i className="fa fa-star" key={i} />);
  }

  const { records } = shopOrders;

  return (
    <Card className="product-card">
      <div className="image-container">
        <Box className="front">
          <a
            data-toggle="modal"
            data-target="#quick-view"
            title="Quick View"
            onClick={onOpenModal}
          >
            {shop.images && (
              <img
                src={`${shop.images[0] ? shop.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`}
                className="product-image"
                alt=""
              />
            )}
          </a>
        </Box>
        <Box className="actions" position="absolute" top={0} right={0} display="none" flexDirection="column">
          <IconButton color="secondary" size="small" onClick={onAddToFavlistClicked}>
            <ImageSearchIcon />
          </IconButton>
        </Box>
      </div>
      <div className="product-detail">
        <div>
          <div className="rating">
            {RatingStars}
          </div>
          <Link className="styled-link" to={`${process.env.PUBLIC_URL}/user/shop/${shop.id}`}>
            {shop.title}
          </Link>
        </div>
        <IconButton color="secondary" size="small" onClick={() => handleMoreButtonClick(shop)}>
          <MoreHorizIcon />
        </IconButton>
      </div>
      <Modal open={state.open} onClose={onCloseModal} center>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6  col-xs-12">
                  <div className="quick-view-img">
                    <img src={`${shop.images && shop.images[0] ? shop.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <h2>
                      {shop.title}
                    </h2>
                    <h3>{shop.areaname}</h3>
                    <div className="border-product">
                      <h6 className="product-title">About us</h6>
                      <p>{shop.shortDetails}</p>
                    </div>

                    <div className="border-product">
                      <table className="table cart-table table-responsive-xs">
                        <thead>
                        <tr className="table-head">
                          <th scope="col">Categories</th>
                        </tr>
                        </thead>
                        {shopCategories.categories.map((shopcategory, index) => (
                            <tbody key={`${index + 1}`}>
                            <tr>
                              <td>
                                  {shopcategory.name}
                              </td>
                              <td>
                                <h6>
                                  {shopcategory.description}
                                </h6>
                              </td>
                            </tr>
                            </tbody>
                        ))}
                      </table>
                    </div>

                    <div className="border-product">
                      <table className="table cart-table table-responsive-xs">
                        <thead>
                          <tr className="table-head">
                            <th scope="col">Products</th>
                          </tr>
                        </thead>
                        {shopProducts.records.map((shopprice, index) => (
                          <tbody key={`${index + 1}`}>
                            <tr>
                              <td>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/user/shop/${shopprice._id}`}
                                >
                                  {shopprice.name}
                                </Link>
                              </td>
                              <td>
                                <h6>
                                  {shopprice.price}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>

                    <div className="border-product">
                      <table className="table cart-table table-responsive-xs">
                        <thead>
                        <tr className="table-head">
                          <th scope="col">Latest orders</th>
                        </tr>
                        </thead>
                        {records.map((shoporder, index) => (
                            <tbody key={`${index + 1}`}>
                            <tr>
                              <td>
                                  {shoporder.order_id}
                              </td>
                              <td>
                                <h6>
                                  {shoporder.status}
                                </h6>
                              </td>
                            </tr>
                            </tbody>
                        ))}
                      </table>
                    </div>

                    <div className="product-buttons">
                      <Button onClick={() => history.push(`/user/shop/${shop.id}`)} className="btn btn-solid">Visit Shop</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

ShopItemBox.propTypes = {
  shop: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  onAddToFavlistClicked: PropTypes.func.isRequired,
  adminGetStorePrivateProducts: PropTypes.func.isRequired,
  adminGetStorePrivateCategories: PropTypes.func.isRequired,
  adminGetShopInfoById: PropTypes.func.isRequired,
  adminGetStoreOrders: PropTypes.func.isRequired,
  shopProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  shopCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  shopOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ShopItemBoxComponent = connect(
  ({ adminShops, adminProducts, adminOrders, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
    shopDetail: adminShops.shopDetail,
    shopOrders: adminOrders.storeOrders,
    shopProducts: adminProducts.storeProducts,
    shopCategories: adminProducts.storeProductCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopItemBox);

export default ShopItemBoxComponent;
