//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
} from "components/Atoms";

import * as ActionTypes   from 'constants/ActionTypes';
import * as Actions       from "actions/seller/seller.subscription";


import AvailablePlan      from "components/elements/seller/AvailablePlan";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class SubscriptionStore extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      itemsInCart: false,
    };
    props.getAllSubscriptions();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
      history,
    } = this.props;

    if (reqStatus === prevProps.reqStatus) return;

    switch (reqStatus) {
      case ActionTypes.CHECKOUT_SUBSCRIPTION_SUCCESS:
        history.push(`/subscription/checkout`);
        break;

      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  addToCart = (plan) => () => {
    this.props.addSubscriptionToCart(plan);
  };

  removeFromCart = (plan) => () => {
    this.props.removeSubscriptionFromCart(plan);
  };

  checkoutSubscriptionPlan = () => {
    const { availablePlans, checkoutSubscriptionPlan } = this.props;
    const plansToCheckOut = availablePlans.filter((plan) => plan.addedToCart);
    checkoutSubscriptionPlan(plansToCheckOut);
  };

  render() {
    const { isLoading, availablePlans } = this.props;
    const itemsInCart = availablePlans.filter((plan) => plan.addedToCart)
      .length;
    const checkOutReady = itemsInCart > 0;
    return (
      <Page
        className="subscriptions-home"
        /*breadCrumb={
          <Link to="/subscriptions">
            <Button type="secondary">View Your Subscriptions</Button>
          </Link>
        }*/
      >
        <h4 className="page-title">Available Subscriptions</h4>
        <Row gutter={[24, 24]}>
          {availablePlans.map((availablePlan, index) => {
            return (
              <Col xs={24} md={12} lg={8} key={index}>
                <AvailablePlan
                  data={availablePlan}
                  addToCart={this.addToCart(availablePlan)}
                  removeFromCart={this.removeFromCart(availablePlan)}
                />
              </Col>
            );
          })}
        </Row>
        {checkOutReady && (
          <Button
            type="primary"
            size="large"
            className="m-fit"
            onClick={this.checkoutSubscriptionPlan}
          >
            {`Checkout ${itemsInCart} Plan(s)`}
          </Button>
        )}
      </Page>
    );
  }
}
// Mapping Props and State.
const _SubscriptionStore = connect(
  ({ subscriptions, appBase }) => ({
    appBase: appBase,
    reqStatus: subscriptions.reqStatus,
    isLoading: subscriptions.isLoading,
    serviceError: subscriptions.serviceError,
    availablePlans: subscriptions.availablePlans,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SubscriptionStore);

export { _SubscriptionStore as default };
