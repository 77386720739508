import React from 'react';
import { styled } from '@mui/material/styles';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {IconButton} from "@mui/material";


const Input = styled('input')({
    display: 'none',
});

export default props =>
    <div className='buttons fadein'>
        <div className='button'>
            <label htmlFor="icon-button-file">
                <Input accept="image/*" id="icon-button-file" type="file" onChange={props.onChange} />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
            </label>
        </div>
    </div>
