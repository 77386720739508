// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminGetLocationShopList = (req) => ({
  type: ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_START,
  payload: req,
});

export const adminGetLocationShopListSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_SUCCESS,
    payload: data,
  };
};
export const adminGetShopLocationListFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_FAILURE,
  payload: error,
});

export const adminSearchShop = (name) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_START,
  name: name,
});

export const adminSearchShopSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SHOPS_SUCCESS,
    payload: data,
  };
};
export const adminSearchShopFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_FAILURE,
  payload: error,
});

export const adminSearchShopKeywords = (req) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_START,
  payload: req,
});

export const adminSearchShopKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS,
    payload: data,
  };
};
export const adminSearchShopKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE,
  payload: error,
});


export const adminGetShopInfoById = (reqData) => ({
  type: ActionTypes.ADMIN_GET_SHOP_INFO_BYID_START,
  payload: reqData,
});

export const adminGetShopInfoByIdSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_SHOP_INFO_BYID_SUCCESS,
    payload: data,
  };
};

export const adminGetShopInfoByIdFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_SHOP_INFO_BYID_FAILURE,
  payload: error,
});

export const adminUpdateShopStatus = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_STATUS_START,
  payload: req,
});

export const adminUpdateShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_STATUS_FAILURE,
  payload: error,
});

