// @flow
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppBar, Toolbar, Menu } from "components/Atoms";
import { withTranslate } from "react-redux-multilingual";

import { BreakPoints } from "config/constants";
import { SellerSideBarConfig } from "config/SellerNavigation";
import { UserSideBarConfig } from "config/UserNavigation";
import { SellerWebSideBarConfig } from "config/SellerWebNavigation";
import { SellerSiteSideBarConfig } from "config/SellerSiteNavigation";
import { AdminSideBarConfig } from "config/AdminNavigation";

import CartContainer from "../../../../containers/CartContainer";

import TopBarUser from "components/elements/main/headers/common/topbar-user";
import Sidebar from "components/elements/main/Sidebar";

import {
  ShoppingCartOutlined,
} from "@ant-design/icons";
import "./style.scss";

import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Topbar from "../Topbar";

const { SubMenu } = Menu;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  page: {
    background: "#f9f9f9",
    width: "100%",
    padding: theme.spacing(3),
  },
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appbartitle: {
    background: "#f4f4f4",
    color: theme.palette.primary[900],
  },
  title: {
    padding: theme.spacing(2),
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  date: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  avatar: {
    marginLeft: theme.spacing(2),
  },
}));

const PageHeader = (props) => {

  const classes = useStyles();

  const [state, setState] = useState({
    collapsedWidth: 0,
    breakPoint: "lg",
    isHeaderVisible: false,
  });

  const [visible, setVisible] = useState(false);


  const manageLayoutChange = () => {
    const docElement = document.documentElement;
    const currentWindowWidth = window.innerWidth || (docElement && docElement.offsetWidth);

    if (currentWindowWidth < BreakPoints.md) {
      setState({ isHeaderVisible: false });
      return setState({ collapsedWidth: 0 });
    }
    setState({ isHeaderVisible: false });
    return setState({ collapsedWidth: 80 });
  };

  useEffect(() => {
    window.addEventListener("resize", manageLayoutChange);
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const constructHeaderMenu = (navList) => {
    const { enabledFeatures, collapseSider } = props;

    return navList.map((navItem) => {
      const {
        id, name, icon, route, routestart, routeend, subMenu,
      } = navItem;

      let routelink;

      if (navItem.subMenu) {
        if (props.routeType === "SELLERWEB") {
          routelink = subMenu[0].routestart + this.props.subdomain_shopId + subMenu[0].routeend;
        } else if (props.routeType === "SELLERSITE") {
          routelink = subMenu[0].routestart + subMenu[0].routeend;
        } else {
          routelink = subMenu[0].route;
        }

        return (
          <SubMenu
            key={routelink}
            icon={subMenu[0] && subMenu[0].icon}
            className="sub-menu"
            title={name}
          >
            {constructHeaderMenu(subMenu)}
          </SubMenu>
        );
      }
      if (props.routeType === "SELLERWEB") {
        routelink = routestart + props.subdomain_shopId + routeend;
      } else if (props.routeType === "SELLERSITE") {
        routelink = routestart + routeend;
      } else {
        routelink = route;
      }

      return (
        <Menu.Item onClick={onClose} className="menu-item">
          <Link className="item-link" to={routelink}>
            <span className="title">{name}</span>
          </Link>
        </Menu.Item>
      );
    });
  };

  const constructSidebarMenuItems = (navList) => navList.map((navItem) => {
    const {
      id, name, icon, route, routestart, routeend, subMenu,
    } = navItem;

    let routelink;

    if (props.routeType === "SELLERWEB") {
      routelink = routestart + props.subdomain_shopId + routeend;
    } else if (props.routeType === "SELLERSITE") {
      routelink = routestart + routeend;
    } else {
      routelink = route;
    }

    return ({ routelink, name, icon });
  });

  const constructHeaderTitle = (navList, pageRoute, shopinfo, routeType, subdomain_shopId) => {
    const title = navList.reduce((result, navItem) => {
      if (result.length > 0) return result;

      const {
        id, name, header, icon, route, routestart, routeend, subMenu,
      } = navItem;

      let routelink;

      if (subMenu) {
        const title1 = subMenu.reduce((result1, subnavItem) => {
          if (result1.length > 0) return result1;

          const {
            id, name, header, icon, route, routestart, routeend, subMenu,
          } = subnavItem;

          let routelink1;

          if (routeType === "SELLERWEB") {
            routelink1 = subnavItem.routestart + subdomain_shopId + subnavItem.routeend;
          } else if (routeType === "SELLERSITE") {
            routelink1 = subnavItem.routestart + subnavItem.routeend;
          } else {
            routelink1 = subnavItem.route;
          }
          if (routelink1 === pageRoute) result1.push(subnavItem.header);

          return result1;
        }, []);

        if (title1.length > 0) {
          result.push(title1);
          return result;
        }
      }

      if (routeType === "SELLERWEB") {
        routelink = routestart + subdomain_shopId + routeend;
      } else if (routeType === "SELLERSITE") {
        routelink = routestart + routeend;
      } else {
        routelink = route;
      }

      if (routelink === pageRoute) {
        result.push(navItem.header);
      }
      return result;
    }, []);

    if (title.length > 0 && title[0] !== "") return title[0];

    // catch all
    if (props.routeType === "SELLERWEB") {
      return shopinfo.title;
    } if (props.routeType === "SELLERSITE") {
      return shopinfo.title;
    }
    return `${process.env.REACT_APP_NAME}`;
  };

  const {
    titleContent = {},
    routeType,
    subdomain_shopId,
    shopinfo,
    pageRoute,
  } = props;

  let SideBarConfig;
  let cart; let headerCart; let cartlink; let checkoutlink; let
    topBar;
  let titleTag;

  if (routeType === "SELLER") {
    titleTag = `${process.env.REACT_APP_NAME}`;
    cart = "";
    cartlink = "";
    checkoutlink = "";
    SideBarConfig = SellerSideBarConfig;

    topBar = <TopBarUser />;
  } else if (routeType === "ADMIN") {
    titleTag = `${process.env.REACT_APP_NAME}`;
    cart = "";
    cartlink = "";
    checkoutlink = "";
    SideBarConfig = AdminSideBarConfig;

    topBar = <TopBarUser />;
  } else if (routeType === "USER") {
    SideBarConfig = UserSideBarConfig;
    titleTag = `${process.env.REACT_APP_NAME}`;

    cartlink = "/cart";
    checkoutlink = "/checkout";
    headerCart = (
      <CartContainer
        cartlink={cartlink}
        checkoutlink={checkoutlink}
      />
    );

    topBar = <TopBarUser />;

    cart = (
      <span className="item cart">
        <Link to={cartlink}>
          <ShoppingCartOutlined className="icon" />
        </Link>
      </span>
    );
  } else if (routeType === "SELLERWEB") {
    SideBarConfig = SellerWebSideBarConfig;
    titleTag = shopinfo.title;

    cartlink = `/shop/${subdomain_shopId}/cart`;
    checkoutlink = `/shop/${subdomain_shopId}/checkout`;
    headerCart = (
      <CartContainer
        cartlink={cartlink}
        checkoutlink={checkoutlink}
      />
    );

    topBar = <TopBarUser />;

    cart = (
      <span className="item cart">
        <Link to={cartlink}>
          <ShoppingCartOutlined className="icon" />
        </Link>
      </span>
    );
  } else if (routeType === "SELLERSITE") {
    SideBarConfig = SellerSiteSideBarConfig;
    titleTag = shopinfo.title;

    cartlink = "/cart";
    checkoutlink = "/checkout";
    headerCart = (
      <CartContainer
        cartlink={cartlink}
        checkoutlink={checkoutlink}
      />
    );

    topBar = <TopBarUser />;

    cart = (
      <span className="item cart">
        <Link to={cartlink}>
          <ShoppingCartOutlined className="icon" />
        </Link>
      </span>
    );
  } else {
    SideBarConfig = UserSideBarConfig;
    titleTag = `${process.env.REACT_APP_NAME}`;

    cartlink = "/cart";
    checkoutlink = "/checkout";
    headerCart = (
      <CartContainer
        cartlink={cartlink}
        checkoutlink={checkoutlink}
      />
    );

    topBar = <TopBarUser />;

    cart = (
      <span className="item cart">
        <Link to={cartlink}>
          <ShoppingCartOutlined className="icon" />
        </Link>
      </span>
    );
  }

  const drawerMenuItems = constructSidebarMenuItems(SideBarConfig);

  const { translate } = props;

  return (
    <div>
      <AppBar
        position="fixed"
        elevation={0}
        className={ classes.appbartitle }
      >
        <Toolbar>
          <IconButton color="inherit" onClick={showDrawer} size="large">
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" color="inherit">
            {titleTag}
          </Typography>

          <Topbar
            menuItems={drawerMenuItems}
          />

          {headerCart}

        </Toolbar>
      </AppBar>
      <Sidebar
        open={visible}
        onOpen={showDrawer}
        onClose={onClose}
        menuItems={drawerMenuItems}
      />
    </div>
  );
};

export default withTranslate(PageHeader);
