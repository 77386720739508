/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import {
  Container, FormControlLabel, Switch, Button,
} from "@mui/material";
import TextField from "components/TextField";
import ButtonComponent from "components/Button";

const AdminShopList = (props) => {
  const [state, setState] = useState({
    name: "",
    description: "",
    sku: "",
    isFoodItem: false,
    isVegetarian: false,
    category: "",
  });
  const [isCategory, setIsCategory] = useState(false);

  const handleChange = (field, event) => {
    setState({
      ...state,
      [field]: event.target.value,
    });
  };

  const handleSwitches = (field) => {
    setState({
      ...state,
      [field]: !(state[field]),
    });
  };
  const handleSubmit = () => {
    console.log(state);
  };

  return (
    <div>
      <Container maxWidth="md">
        <TextField
          onChange={(e) => handleChange("name", e)}
          fullWidth
          required
          style={{ width: "100%" }}
          label="Name"
          value={state.name}
        />
        <br />
        <br />
        <TextField
          onChange={(e) => handleChange("description", e)}
          required
          fullWidth
          style={{ width: "100%" }}
          label="Description"
          value={state.description}
        />
        <br />
        <br />
        <TextField
          onChange={(e) => handleChange("sku", e)}
          required
          style={{ width: "100%" }}
          fullWidth
          label="SKU"
          value={state.description}
        />
        <br />
        <br />
        <div>
          <FormControlLabel
            value={state.isFoodItem}
            control={<Switch color="primary" />}
            label="Food Item"
            onChange={() => handleSwitches("isFoodItem")}
            labelPlacement="start"
          />
        </div>
        <div>
          <FormControlLabel
            value={state.isVegetarian}
            disabled={!state.isFoodItem}
            control={<Switch color="primary" />}
            label="Vegetarian"
            onChange={() => handleSwitches("isVegetarian")}
            labelPlacement="start"
          />
        </div>
        <br />
        <br />
        <Button onClick={() => setIsCategory(true)} color="secondary" fullWidth className="cart-button-mobile">
          +  Category
        </Button>
        {isCategory && (
        <TextField
          onChange={(e) => handleChange("category", e)}
          required
          style={{ width: "100%" }}
          fullWidth
          label="Category"
          value={state.category}
        />
        )}
        <br />
        <br />
        <ButtonComponent
          name="Add"
          onClick={handleSubmit}
        />
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
};

const adminShopList = connect(
  ({ adminShops, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
    storeList: adminShops.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopList);

export { adminShopList as default };
