/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-multi-carousel";
import * as Actions from "actions";
import { Typography, Divider, Container } from "@mui/material";
import { CustomLeftArrow, CustomRightArrow } from "components/Button/SliderButtons";
// import Custom Components
import ShopItemBox from "components/elements/user/ShopItemBox";
import responsive from "./SliderProps";

const UserShopsSlider = (props) => {
  useEffect(() => {
    props.usergetStoreList();
  }, []);

  const { allshops, addStoreToFavlist } = props;
  const { shops } = allshops;
  return (
    <Container>
      <div className="theme-card position-relative">
        <Typography variant="h6">Popular Shops</Typography>
        <Divider />
        <Slider
          containerClass="overflow-clear"
          renderButtonGroupOutside
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          responsive={responsive}
        >
          { shops.map((shop, index) => (
            <div key={`${index + 1}`}>
              <ShopItemBox
                shop={shop}
                onAddToFavlistClicked={() => addStoreToFavlist(shop)}
                key={`${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
};

UserShopsSlider.propTypes = {
  allshops: PropTypes.arrayOf(PropTypes.object).isRequired,
  addStoreToFavlist: PropTypes.func.isRequired,
  usergetStoreList: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userShopsSlider = connect(
  ({ userstoreList, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userstoreList.reqStatus,
    isLoading: userstoreList.isLoading,
    serviceError: userstoreList.serviceError,
    allshops: userstoreList.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserShopsSlider);

export { userShopsSlider as default };
