import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useState} from "react";

const CustomSnackBar = (props: Props) => {

  //const [open, setOpen] = React.useState(true);
  const { errorMessage = "", status } = props;

    const [open, setOpen] = useState(status);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
      <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
  );

  return (
      <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          message={errorMessage}
      />
  );
}

export default CustomSnackBar;
