// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  storeProfile : {
    title: "",
    logo: [],
  },
  storeService: {
    shippingoption: "",
    orderminimum: "",
    shipping: "",
  },
  storeAddress: {
    _id: "",
    cityname: "",
    areaname: "",
    addressline: "",
    pincode: "",
  },
  storeImages : {
    images: []
  },
  storeDomain: {
    value: {
      domain: "",
      subdomain: "",
      title: "",
      _id: "",
    },
    status: "NOT_LOADED",
    isLoading: false,
  },
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */

    case ActionTypes.UPDATE_DOMAIN_START:
    case ActionTypes.GET_STORE_PROFILE_START:
    case ActionTypes.UPDATE_STORE_PROFILE_START:
    case ActionTypes.GET_STORE_SERVICE_START:
    case ActionTypes.UPDATE_STORE_SERVICE_START:
    case ActionTypes.GET_STORE_ADDRESS_START:
    case ActionTypes.UPDATE_STORE_ADDRESS_START:
    case ActionTypes.GET_STORE_IMAGES_START:
    case ActionTypes.ADD_STORE_IMAGE_START:
    case ActionTypes.REMOVE_STORE_IMAGE_START:
    case ActionTypes.UPDATE_STORE_LOGO_START:{
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }
    case ActionTypes.GET_DOMAIN_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
        storeDomain: {
          ...state.storeDomain,
          isLoading: true,
          status: "READ_DOMAIN",
        },
      };
    }
    case ActionTypes.CHECK_DOMAIN_AVAILABILITY_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
        storeDomain: {
          ...state.storeDomain,
          isLoading: true,
          status: "CHECK_AVAILABILITY",
        },
      };
    }
    /* Common Failures */
    case ActionTypes.GET_DOMAIN_FAILURE:
    case ActionTypes.UPDATE_DOMAIN_FAILURE:
    case ActionTypes.GET_STORE_PROFILE_FAILURE:
    case ActionTypes.UPDATE_STORE_PROFILE_FAILURE:
    case ActionTypes.GET_STORE_SERVICE_FAILURE:
    case ActionTypes.UPDATE_STORE_SERVICE_FAILURE:
    case ActionTypes.GET_STORE_ADDRESS_FAILURE:
    case ActionTypes.UPDATE_STORE_ADDRESS_FAILURE:
    case ActionTypes.GET_STORE_IMAGES_FAILURE:
    case ActionTypes.ADD_STORE_IMAGE_FAILURE:
    case ActionTypes.REMOVE_STORE_IMAGE_FAILURE:
    case ActionTypes.UPDATE_STORE_LOGO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.GET_DOMAIN_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeDomain: {
            value: action.payload.response,
            isLoading: false,
            status: "DOMAIN_LOADED",
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeDomain: {
            value: {
              domain: "",
              subdomain: "",
              title: "",
              _id: "",
            },
            isLoading: false,
            status: "DOMAIN_LOADED",
          },
        };
      }
    }
    case ActionTypes.CHECK_DOMAIN_AVAILABILITY_SUCCESS: {
      const { status } = action.payload;
      const statusVal =
        status === "success" ? "DOMAIN_AVAILABLE" : "DOMAIN_UNAVAILABLE";
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        storeDomain: {
          value: action.payload.response,
          isLoading: false,
          status: statusVal,
        },
      };
    }
    case ActionTypes.UPDATE_DOMAIN_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeDomain: {
            value: action.payload.response,
            isLoading: false,
            status: "LOADED",
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeDomain: {
            value: {
              domain: "",
              subdomain: "",
              title: "",
              _id: "",
            },
            isLoading: false,
            status: "LOADED",
          },
        };
      }
    }

    case ActionTypes.UPDATE_STORE_PROFILE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeProfile: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeProfile: {
            title: "",
          },
        };
      }
    }

    case ActionTypes.GET_STORE_PROFILE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeProfile: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeProfile: {
            title: ""
          },
        };
      };
    }

    case ActionTypes.UPDATE_STORE_SERVICE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeService: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeService: {
            shippingoption: "",
            orderminimum: "",
            shipping: "",
          },
        };
      }
    }

    case ActionTypes.GET_STORE_SERVICE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeService: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeService: {
            title: "",
          },
        };
      };
    }
    case ActionTypes.UPDATE_STORE_ADDRESS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeAddress: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeAddress: {
            _id: "",
            cityname: "",
            areaname: "",
            addressline: "",
            pincode: "",
          },
        };
      }
    }

    case ActionTypes.GET_STORE_ADDRESS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeAddress: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeAddress: {
            _id: "",
            cityname: "",
            areaname: "",
            addressline: "",
            pincode: "",
          },
        };
      };
    }

    case ActionTypes.GET_STORE_IMAGES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeImages: {
            images: action.payload.response.images
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeImages: {
            images: []
          },
        };
      };
    }

    case ActionTypes.REMOVE_STORE_IMAGE_SUCCESS:
    case ActionTypes.ADD_STORE_IMAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        storeImages: {
          images: action.payload.response.images,
        },
      };
    }

    case ActionTypes.UPDATE_STORE_LOGO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeProfile: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          storeProfile: {
            title: "",
            logo: []
          },
        };
      };
    }

    default:
      return state;
  }
}
