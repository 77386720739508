//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
} from "components/Atoms";


import * as Actions       from "actions/seller/seller.subscription";


import ActiveSubscription from "components/elements/seller/ActiveSubscription";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class Home extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    props.getUserSubscriptions();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  render() {
    const { isLoading, activePlans } = this.props;
    return (
      <Page className="subscriptions-home">
        <h4 className="page-title">Active Subscriptions</h4>
        <Row gutter={[24, 24]}>
          {activePlans.map((activePlan, index) => {
            return (
              <Col xs={24} md={12} lg={8} key={activePlan._id}>
                <ActiveSubscription data={activePlan} />
              </Col>
            );
          })}
        </Row>
      </Page>
    );
  }
}
// Mapping Props and State.
const _Home = connect(
  ({ subscriptions, appBase }) => ({
    appBase: appBase,
    reqStatus: subscriptions.reqStatus,
    isLoading: subscriptions.isLoading,
    serviceError: subscriptions.serviceError,
    activePlans: subscriptions.activePlans,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(Home);

export { _Home as default };
