//  @flow
import * as React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
    Input,
  Notification,
  DatePicker,
  Roller,
} from "components/Atoms";


import * as Actions         from "actions/seller/seller.products";
import * as ActionTypes     from 'constants/ActionTypes';

import Product              from "components/elements/seller/Product";
import ProductEntryForm     from "components/elements/seller/ProductEntryForm";
import CategoryBrowser      from "components/elements/seller/CategoryBrowser";
import SearchModal          from "components/elements/seller/SearchModal";

import "./styles.scss";
import {scanBarCode, stopScanning} from "utils/cordova";
// Component Imports

type Props = {};

type State = {};

class SearchProductList extends React.PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      bShowProductEntryDialog: false,
      bShowCategoryBrowser: false,
      bShowSearchModal: false,
      selectedProduct: null,
      selectedCategory: null,
      searchquery: "",
      isUPCbased: false
    };
    props.getActiveCategories();
  }

  componentDidMount() {
    const cordova = window.cordova;
    if (cordova) {
      this.startScan();
      this.state.isUPCbased = true;
    } else {
      this.state.isUPCbased = false;
    }
  }

  componentWillUnmount() {
    stopScanning();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (reqStatus === prevProps.reqStatus) return;

    switch (reqStatus) {
      case ActionTypes.ADD_PRODUCT_SUCCESS:
      case ActionTypes.UPDATE_PRODUCT_SUCCESS:
        // optimize later - updated product only
        this.props.searchProducts(this.state.searchquery, this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
        break;
      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }

        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  saveProduct = (product, prodId, store_entry) => {

    if (!store_entry) {
      product.id = prodId;
      this.props.addProduct(product);
    } else {
      product.id = store_entry._id;
      this.props.updateProduct(product);
    }
    this.hideProductEntryDialog();

  };

  filterProducts = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };
  /* Header actions*/
  showProductEntryDialog = (product) => () => {
    this.setState({ selectedProduct: product, bShowProductEntryDialog: true });
  };
  hideProductEntryDialog = () => {
    this.setState({ selectedProduct: null, bShowProductEntryDialog: false });
  };

  showCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: true });
  };

  hideCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: false });
  };

  showSearchModal = () => {
    this.setState({ bShowSearchModal: true });
  };

  hideSearchModal = () => {
    this.setState({ bShowSearchModal: false });
  };

  setKeyword = (keyword) => {
    this.state.searchquery = keyword;
    this.hideSearchModal();
    this.props.searchProducts(keyword, this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
  };

  resetCategory = () => {
    this.state.selectedCategory = null;
    this.props.searchProducts(this.state.searchquery, this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
  };

  setCategory = (category) => {
    this.state.selectedCategory = category;
    this.hideCategoryBrowser();
    this.props.searchProducts(this.state.searchquery, this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
  };

  fetchSearchResults = ( updatedPageNo = '', query ) => {
    this.state.searchquery = query;
    this.props.searchProducts(query, this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
  };

  handleOnInputChange = ( value ) => {
    const query = value;
    if ( ! query ) {
      this.setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
    } else {
      this.setState( { query, loading: true, message: '' }, () => {
        this.fetchSearchResults( 1, query );
      } );
    }
  };

  resolve = (value) => {
    this.state.upc = value;
    this.props.searchProducts("", this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
  };

  reject = (value) => {};

  startScan = () => {
    this.setState(
        {
          isScanning: true,
          scanError: false,
        },
        () => {
          scanBarCode(this.resolve, this.reject);
        }
    );
  };

  stopScan = () => {
    this.setState(
        {
          isScanning: false,
          scanError: false,
        },
        () => {
          stopScanning();
        }
    );
  };

  render() {
    const { isLoading, query, searchproductList, categoryList } = this.props;
    const { count, products } = searchproductList;

    const {
      bShowProductEntryDialog,
      bShowCategoryBrowser,
      bShowSearchModal,
      selectedProduct,
      selectedCategory,
      searchquery,
      upc,
      isUPCbased
    } = this.state;

    return (
        <Page className="products-catalog">
        <h4 className="page-title">Search Products</h4>
        <div className="content">
          <div className="header">
            <Input.Search
                type="text"
                name="query"
                value={ query }
                placeholder="Enter Product Name"
                onSearch={this.handleOnInputChange}
            />
            <div className="actions">
              <Button
                  icon={<GroupOutlined />}
                  onClick={this.showCategoryBrowser}
              />
            </div>
          </div>
          {selectedCategory ? "Search in category: " + selectedCategory.name : ""}
          <Button
            icon={<DeleteOutlined/>}
            onClick={this.resetCategory}
          />
          <div className="product-list">
            <Row gutter={[24, 24]}>
              {products.map((product) => {
                return (
                    <Col span={6} key={`pr-item-${product.id}`}>
                      <Product
                          className="product-item"
                          key={product.id}
                          data={product}
                          editactions={
                            <>
                              <Button
                                  type="primary"
                                  onClick={this.showProductEntryDialog(product)}
                              >
                                Edit
                              </Button>
                            </>
                          }
                          addactions={
                            <>
                              <Button
                                  type="primary"
                                  onClick={this.showProductEntryDialog(product)}
                              >
                                Add to Store
                              </Button>
                            </>
                          }
                      />
                    </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <ProductEntryForm
            visible={bShowProductEntryDialog}
            data={selectedProduct}
            onOk={this.saveProduct}
            onCancel={this.hideProductEntryDialog}
        />
          <CategoryBrowser
              title="All Product Categories"
              visible={bShowCategoryBrowser}
              categoryList={categoryList}
              onCategorySelect={this.setCategory}
              onOk={this.hideCategoryBrowser}
              onCancel={this.hideCategoryBrowser}
          />
          <SearchModal
              title="Search"
              visible={bShowSearchModal}
              onKeywordSelect={this.setKeyword}
              onOk={this.hideSearchModal}
              onCancel={this.hideSearchModal}
          />
      </Page>
    );
  }
}
// Mapping Props and State.
const _SearchProductList = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    searchproductList: products.searchproductList,
    categoryList: products.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SearchProductList);

export { _SearchProductList as default };
