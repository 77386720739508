//  @flow
import * as React from "react";
import {
  Page,
} from "components/Atoms";

import PrivateCategoryMain    from "components/pages/seller/products/PrivateCategoryMain";

import "./styles.scss";

class PrivateCategories extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {

    return (
        <Page className="products-home">
          <PrivateCategoryMain></PrivateCategoryMain>
        </Page>
    );
  }
}

export default (PrivateCategories)
