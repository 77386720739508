/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemText,
} from "components/Atoms";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    color: "white",
    paddingTop: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
      color: "#555",
      border: "1px solid #ece7e7",
    },
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
      cursor: "pointer",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      display: "none",
      fontSize: "18px",
    },
  },
  text: {
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
    rightToolbar: {
      marginLeft: "auto",
      marginRight: -12,
    },
  },
}));

const Topbar = (props) => {
  const { menuItems } = props;
  const classes = useStyles();

  return (
    <section style={{ marginLeft: "auto", marginRight: "12px" }}>
      <List className={classes.text}>
        {menuItems.map((navItem) => (
          <ListItem button component={Link} to={navItem.routelink} key={navItem.name}>
            <ListItemText primary={navItem.name} />
          </ListItem>

        ))}
      </List>
    </section>
  );
};

Topbar.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Topbar;
