/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container, Box, Typography, FormControlLabel, Radio, Card,
} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import {
  schema, getTextfieldData, getRadioButtonData, getButtonData,
} from "./helper";

const ServiceEditor = ({
  setState, service, updateServiceCall, cancelEdit,
}) => {
  const { shippingoption, orderminimum, shipping } = service;

  const [blur, setblur] = React.useState({
    shippingoption: false,
    orderminimum: false,
    shipping: false,
  });

  const onFinish = () => {
    updateServiceCall({
      shippingoption, orderminimum, shipping,
    });
  };

  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleRadioButtonChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(service);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.shippingoption || blur.orderminimum || blur.shipping);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, service);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  const options = [
    { label: "Free Shipping", value: "freeshipping" },
    { label: "Minimum Order", value: "shippingcost" },
  ];

  const inputOptions = options.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });

  return (
    <Container>
      <Box>
        <Card>
        {getRadioButtonData(shippingoption).map((element) => (
            <Box className="textfield" m={2}>
              <RadioButtonFieldComponent
                  required
                  options={inputOptions}
                  label={element.label}
                  value={element.value}
                  onChange={(event) => handleRadioButtonChange(event, element.onChangeField)}
              />
            </Box>
        ))}
        {getTextfieldData(orderminimum, shipping).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
            />
          </Box>
        ))}
        </Card>
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

ServiceEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  updateService: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ServiceEditor;
