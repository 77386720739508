//  @flow
import * as React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
    Input,
  Notification,
  DatePicker,
  Roller,
    Radio,
  Spin
} from "components/Atoms";
import * as _ from "lodash";

import * as Actions     from "actions/seller/seller.orders";
import * as ActionTypes from 'constants/ActionTypes';


import "./styles.scss";

import {scanBarCode, stopScanning}    from "utils/cordova";
import SellerOrder                    from "components/elements/seller/SellerOrder";
import SellerOrderEditor              from "components/elements/seller/SellerOrderEditor";
// Component Imports

type Props = {};

type State = {};

class SearchOrderList extends React.PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      orderMode: "PREVIEW",
      searchquery: "",
      searchType: "orderid"
    };
    this.filterCategories = _.debounce(this.filterOrders, 1000);
    props.getStoreOrders(this.props.ordertype);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    stopScanning();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (reqStatus === prevProps.reqStatus) return;

    switch (reqStatus) {
      case ActionTypes.UPDATE_ORDER_SUCCESS:
        // optimize later - updated order only
        this.props.searchOrders(this.state.searchquery, this.state.searchType);
        break;
      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }

        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  filterOrders = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };

  setKeyword = (keyword) => {
    this.state.searchquery = keyword;
    this.hideSearchModal();
    this.props.searchOrders(keyword, this.state.searchType);
  };

  editOrder = (order) => {
    this.props.updateOrder(order);
    this.setState({ orderMode: "PREVIEW" });
  };

  handleOrderEdit = (mode, order, reset) => () => {

    this.setState({ orderMode: mode });
    if (reset) {
      this.setState({ selectedOrder: { order_id: "", _id: "", status: "", order_type: ""}});
    } else {
      this.setState({ selectedOrder: { order_id: order.order_id, _id: order._id, status: order.status, order_type: order.order_type}});
    }
  };

  fetchSearchResults = ( updatedPageNo = '', query ) => {
    this.state.searchquery = query;
    this.props.searchOrders(query, this.state.searchType);
  };

  handleOnInputChange = ( value ) => {
    const query = value;
    if ( ! query ) {
      this.setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
    } else {
      this.setState( { query, loading: true, message: '' }, () => {
        this.fetchSearchResults( 1, query );
      } );
    }
  };

  onChange = e => {
    this.state.searchType = e.target.value;
  };

  render() {
    const { isLoading, query, searchorderList } = this.props;
    const { orderMode, selectedOrder = {}, searchType } = this.state;
    const { records } = searchorderList;


    const { Search } = Input;

    return (
        <section className="order-page">
          <div className="action-box">
        <Page className="orders-catalog">
        <div className="content">
          <div className="header">
            <Input.Search
                type="text"
                name="query"
                value={ query }
                placeholder="Enter Order Name"
                onSearch={this.handleOnInputChange}
                enterButton
            />
            <Radio.Group defaultValue="orderid" onChange={this.onChange}>
              <Radio value="orderid">Order Id</Radio>
              <Radio value="name">Name</Radio>
              <Radio value="phone">Phone</Radio>
              <Radio value="email">Email</Radio>
            </Radio.Group>
          </div>
          {isLoading && <Spin />}
          {orderMode === "PREVIEW" ? (
              <div className="order-list">
                {
                  records.length > 0 ?
                      <Row gutter={[24, 24]}>
                        {records.map((order) => {
                          return (
                              <Col span={24} key={`pr-item-${order.id}`}>
                                <SellerOrder
                                    className="product-item"
                                    key={order._id}
                                    data={order}
                                    actions={
                                      <>
                                        <Button
                                            type="primary"
                                            onClick={this.handleOrderEdit("EDIT", order, false)}
                                        >
                                          Edit
                                        </Button>
                                      </>
                                    }
                                />
                              </Col>
                          );
                        })
                        }
                      </Row> :
                      <div className="row">
                        <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                          <h4>No orders!!</h4>
                        </div>
                      </div>
                }
              </div>
          ) : (
              <SellerOrderEditor
                  data={selectedOrder}
                  updateOrderClicked={this.editOrder}
                  onCancel={() => this.setState({ orderMode: "PREVIEW" })}
              />
          )
          }
        </div>
      </Page>
          </div>
        </section>
    );
  }
}
// Mapping Props and State.
const _SearchOrderList = connect(
  ({ orders, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: orders.reqStatus,
    isLoading: orders.isLoading,
    serviceError: orders.serviceError,
    searchorderList: orders.searchorderList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SearchOrderList);

export { _SearchOrderList as default };
