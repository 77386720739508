import * as React from "react";
import { Button } from "components/Atoms";
import {
    Card, IconButton, Box, Typography,
} from "@mui/material";

import { H6, SpanInline } from 'components/CustomTypography';
import CustomImageBox from 'components/CustomImageBox';

import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import "./SellerProduct.scss";


const SellerProduct = (props: Props) => {
  const { product = {}, editShopImages, editProductInfo, editProductSettings, className = "" } = props;
  const { id, name, categories, images, price, is_popular, is_price_visible, is_active, store_entry } = product;
  const galleryInput = images.map((image) => {
    const { id, cdn } = image;
    return {
      id,
      original: cdn.secure_url,
      thumbnail: cdn.secure_url,
    };
  });

  return (
      <Card className="seller-product-card">
          <CustomImageBox mx="auto" borderRadius="8px" onClick={editShopImages}>
          <div className="image-container" >
              <Box className="actions" position="absolute" top={0} right={0} display="none" flexDirection="column">
                  <IconButton color="primary" size="small">
                      <ImageIcon />
                  </IconButton>
              </Box>
              <Box className="front">
                      {product.images && (

                          <img
                              src={`${product.images[0] ? product.images[0].cdn.secure_url : "/assets/images/fashion/product/1.jpg"}`}
                              className="product-image"
                              alt=""
                          />

                      )}
              </Box>
          </div>
          </CustomImageBox>
          <SpanInline fontWeight="600" fontSize="14px" title={product.name} ellipsis>
              <H6  className="text-truncate" fontWeight="600" width="70%" textAlign="left">
                  {name}
              </H6>
              <IconButton  className="setting-button" color="primary" size="small" onClick={editProductInfo}>
                  <MoreHorizIcon />
              </IconButton>
          </SpanInline>
          <SpanInline fontWeight="600" fontSize="14px" title={product.name} ellipsis>
              <H6 fontWeight="600" width="70%" textAlign="left">
                  ${parseFloat(product.price).toFixed(2)}
                  {product.is_active === "true" ? "Active" : ""}
                  {product.is_popular === "true" ? "Popular" : ""}
              </H6>
              <IconButton  className="setting-button" color="primary" size="small" onClick={editProductSettings}>
                  <MoreHorizIcon />
              </IconButton>
          </SpanInline>
      </Card>
  );
};

export default SellerProduct;
