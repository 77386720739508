/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";

const CheckboxComponent = ({
  required = false, label, value, onChange, ...rest
}) => (
  <FormControlLabel
    control={<Checkbox checked={value} onChange={onChange} color="secondary" />}
    label={label}
    {...rest}
  />
);

CheckboxComponent.defaultProps = {
  type: "text",
};

CheckboxComponent.propTypes = {
  type: PropTypes.string,
};

export default CheckboxComponent;
