import * as yup from "yup";

export const schema = yup.object().shape({
  addressline: yup.string().required("Address is required field"),
  areaname: yup.string().required("Area is required field"),
  cityname: yup.string().required("City is required field"),
  pincode: yup.string().required("Pin/Zipcode is required field"),
});

export const getTextfieldData = (addressline, areaname, cityname, pincode) => [
  {
    label: "Address",
    value: addressline,
    onChangeField: "addressline",
  },
  {
    label: "Area",
    value: areaname,
    onChangeField: "areaname",
  },
  {
    label: "City",
    value: cityname,
    onChangeField: "cityname",
  },
  {
    label: "Pin/Zipcode",
    value: pincode,
    onChangeField: "pincode",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    type: "primary",
    variant: "contained",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    type: "primary",
    variant: "outlined",
    disabled: false,
  },
];
