/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";

const ShopProfileEditor = ({ profile, updateShopProfileCall, cancelEdit }) => {
  const [values, setValues] = useState({ title: "" });
  const [blur, setBlur] = useState(false);

  const { title } = profile;

  const onFinish = () => {
      updateShopProfileCall(values);
  };

  useEffect(() => {
    setValues({ title });
  }, [title]);

  const onChange = (event) => {
    setValues({ title: event.target.value });
      setBlur(event.target.value);
  };
  const onBlur = (event) => {
    setBlur(event.target.value);
  };

  return (
    <div className="profile editor">
      <TextFieldComponent
        value={values.title}
        label="Shop Name"
        required
        helperText={blur && !values.title && "This field is required"}
        onChange={onChange}
        error={blur && !values.title}
      />
      <br />
      <br />
      <Box>
        <ButtonComponent
            sx={{
                m: 2,
            }}
          variant="contained"
          type="primary"
          onClick={onFinish}
          name="Update Profile"
          disabled={!blur || (blur && !values.title)}
        />
        <ButtonComponent
            variant="outlined"
            type="primary"
          onClick={cancelEdit}
          name="Cancel"
        />
      </Box>
    </div>
  );
};

ShopProfileEditor.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
    updateShopProfileCall: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ShopProfileEditor;
