import * as CommonSagas from "../sagas-common";

const sagas = {
  ...CommonSagas,
};

// Run Sagas
export function registerWithMiddleware(middleware) {
  for (let name in sagas) {
    middleware.run(sagas[name]);
  }
}
