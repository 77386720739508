// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";

function* userlogin(action: Object) {
  try {
    const response = yield API.get(`/users/account/get-profile`);
    yield put(Actions.userloginSuccess(response.response.user[0]));
  } catch (error) {
    yield put(Actions.userloginFailure(error));
  }
}

function* userSociallogin(action: Object) {
  try {
    const response = yield API.get(`/users/account/get-profile`, {
      withCredentials: true
    });
    yield put(Actions.userSocialloginSuccess(response.response.user));
  } catch (error) {
    yield put(Actions.userSocialloginFailure(error));
  }
}

function* userlogout(action: Object) {
  try {
    yield put(Actions.userlogoutSuccess());
  } catch (error) {
    yield put(Actions.userlogoutFailure(error));
  }
}

function* userresetPassword(action: Object) {
  try {
    const response = yield API.post(
      `/users/account/change-password`,
      action.payload
    );

    yield put(Actions.userresetPasswordSuccess(response));
  } catch (error) {
    yield put(Actions.userresetPasswordFailure(error));
  }
}

function* userregister(action: Object) {
  try {
    const response = yield API.post(`/users/register`, action.payload);
    yield put(Actions.userregisterSuccess(response));
  } catch (error) {
    yield put(Actions.userregisterFailure(error));
  }
}

function* usergetUserProfile(action: Object) {
  try {
    const response = yield API.get(
      `/users/account/get-profile`,
        { withCredentials: true }
    );

    yield put(Actions.usergetUserProfileSuccess(response.response.user[0]));
  } catch (error) {
    yield put(Actions.usergetUserProfileFailure(error));
  }
}

function* userupdateUserProfile(action: Object) {
  try {
    const response = yield API.post(
      `/users/account/get-profile`,
        { withCredentials: true }
    );

    yield put(Actions.userupdateUserProfileSuccess(response));
  } catch (error) {
    yield put(Actions.userupdateUserProfileFailure(error));
  }
}

function* usergetBillingAddress(action: Object) {
  try {
    const response = yield API.get(
      `/users/account/get-billing-address`,
        { withCredentials: true }
    );

    yield put(Actions.usergetBillingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.usergetBillingAddressFailure(error));
  }
}
function* userupdateBillingAddress(action: Object) {
  try {
    const response = yield API.put(
      `/users/account/update-billing-address`,
        { withCredentials: true }
    );

    yield put(Actions.userupdateBillingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.userupdateBillingAddressFailure(error));
  }
}

function* usergetUserLocationAddress(action: Object) {
  try {
    const usergetUserGeoLocation = () =>
        new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
              (location) => resolve(location),
              (error) => reject(error)
          );
        });

    const response = yield call(usergetUserGeoLocation);
    const { latitude, longitude } = response.coords;

    const query = {
      lat: latitude,
      lng: longitude,
    };

    const addresses = yield API.get(`/address`, { params: query });

    yield put(Actions.usergetUserLocationAddressSuccess(response.addresses[0]));
  } catch (error) {
    yield put(Actions.usergetUserLocationAddressFailure(error));
  }
}

export default function* AppBaseSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_LOGIN_START, userlogin);
  yield takeLatest(ActionTypes.USER_SOCIAL_LOGIN_START, userSociallogin);
  yield takeLatest(ActionTypes.USER_LOGOUT_START, userlogout);
  yield takeLatest(ActionTypes.USER_REGISTER_START, userregister);
  yield takeLatest(ActionTypes.USER_RESET_PASSWORD_START, userresetPassword);
  yield takeLatest(ActionTypes.USER_GET_USER_PROFILE_START, usergetUserProfile);
  yield takeLatest(ActionTypes.USER_UPDATE_USER_PROFILE_START, userupdateUserProfile);
  yield takeLatest(ActionTypes.USER_GET_BILLING_ADDRESS_START, usergetBillingAddress);
  yield takeLatest(
    ActionTypes.USER_UPDATE_BILLING_ADDRESS_START,
      userupdateBillingAddress
  );
  yield takeLatest(ActionTypes.USER_GET_USER_LOCATION_START, usergetUserLocationAddress);
}
