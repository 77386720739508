/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ShopProductSearch from "components/widgets/user/ShopProductSearch";
import ShopProductSlider from "components/elements/user/ShopProductSlider";

import * as Actions from "actions";

const CollectionLeftSidebarShop = (props) => {
  useEffect(() => {
    props.userResetSearchProducts();
  }, []);

  return (
    <div>
      <ShopProductSearch {...props} />

      <ShopProductSlider />

    </div>
  );
};

CollectionLeftSidebarShop.propTypes = {
  userResetSearchProducts: PropTypes.func.isRequired,
};

const collectionLeftSidebarShop = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(CollectionLeftSidebarShop);

export default collectionLeftSidebarShop;
