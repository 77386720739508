import * as React from "react";
import {
  Box, Container, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import CheckboxComponent from "components/Checkbox";
import PropTypes from "prop-types";
import {
  schema, getButtonData, getCheckboxData,
} from "./helper";

const SellerCategorySettingEditor = ({
  setState, category, updateCategorySettingClicked, cancelEdit,
}) => {
  const {
    name, id, is_popular, is_active,
  } = category || {};

  const [blur, setblur] = React.useState({
    is_popular: false,
    is_active: false,
  });

  const onFinish = () => {
    updateCategorySettingClicked({
      is_popular, is_active,
    }, id);
  };

  const handleCheckBoxChange = (event, field) => {
    setState(event.target.checked, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(category);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.is_popular || blur.is_active);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, category);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getCheckboxData(is_popular, is_active).map((element) => (
          <Box className="textfield" m={2}>
            <CheckboxComponent
              required
              label={element.label}
              value={element.value}
              onBlur={() => handleBlur(element.onChangeField)}
              onChange={(event) => handleCheckBoxChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            type={element.type}
            variant={element.variant}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

SellerCategorySettingEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  updateCategorySettingClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default SellerCategorySettingEditor;
