// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  categoryList: {
    count: 0,
    categories: [],
  },
  categoryImageList: {
    image: [],
  },
  productinfo: {
    _id: "",
    name: "",
    images: []
  },
  productList: {
    meta: {
      count: 0,
    },
    records: [],
  },
  productImageList: {
    images: [],
  },

  productsByCategory: {
    meta: {
      count: 0,
    },
    records: [],
  },

  storeProductCategoryList: {
    count: 0,
    categories: [],
  },
  storeProducts: {
    meta: {
      count: 0,
    },
    records: [],
  },
  searchproductList: {
    count: 0,
    products: [],
  },
  searchkeywords: {
    keywords: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.GET_ACTIVE_CATEGORIES_START:
    case ActionTypes.GET_COMMON_PRODUCTS_START:
    case ActionTypes.GET_STORE_PRODUCT_CATEGORIES_START:
    case ActionTypes.GET_STORE_PRODUCTS_START:
    case ActionTypes.ADD_PRODUCT_START:
    case ActionTypes.UPDATE_PRODUCT_START:
    case ActionTypes.REMOVE_PRODUCT_START:
    case ActionTypes.ADD_PRODUCT_UPC_START:
    case ActionTypes.SEARCH_PRODUCTS_START:
    case ActionTypes.SEARCH_KEYWORDS_START:
    case ActionTypes.CHECK_PRODUCT_START:
    case ActionTypes.ADD_PRIVATE_PRODUCT_START:
    case ActionTypes.UPDATE_PRIVATE_PRODUCT_START:
    case ActionTypes.REMOVE_PRIVATE_PRODUCT_START:
    case ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_START:
    case ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_START:
    case ActionTypes.ADD_PRIVATE_CATEGORY_START:
    case ActionTypes.UPDATE_PRIVATE_CATEGORY_START:
    case ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_START:
    case ActionTypes.REMOVE_PRIVATE_CATEGORY_START:
    case ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_START:
    case ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.GET_ACTIVE_CATEGORIES_FAILURE:
    case ActionTypes.GET_COMMON_PRODUCTS_FAILURE:
    case ActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILURE:
    case ActionTypes.GET_STORE_PRODUCTS_FAILURE:

    case ActionTypes.ADD_PRODUCT_FAILURE:
    case ActionTypes.UPDATE_PRODUCT_FAILURE:
    case ActionTypes.REMOVE_PRODUCT_FAILURE:
    case ActionTypes.ADD_PRODUCT_UPC_FAILURE:
    case ActionTypes.SEARCH_PRODUCTS_FAILURE:
    case ActionTypes.SEARCH_KEYWORDS_FAILURE:
    case ActionTypes.CHECK_PRODUCT_FAILURE:
    case ActionTypes.ADD_PRIVATE_PRODUCT_FAILURE:
    case ActionTypes.UPDATE_PRIVATE_PRODUCT_FAILURE:
    case ActionTypes.REMOVE_PRIVATE_PRODUCT_FAILURE:
    case ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_FAILURE:
    case ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_FAILURE:
    case ActionTypes.ADD_PRIVATE_CATEGORY_FAILURE:
    case ActionTypes.UPDATE_PRIVATE_CATEGORY_FAILURE:
    case ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_FAILURE:
    case ActionTypes.REMOVE_PRIVATE_CATEGORY_FAILURE:
    case ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_FAILURE:
    case ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

          // update keeps loading to true to avoid gap
    case ActionTypes.UPDATE_PRODUCT_SUCCESS:{
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.REMOVE_PRODUCT_SUCCESS:
    case ActionTypes.ADD_PRODUCT_SUCCESS:
    case ActionTypes.CHECK_PRODUCT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADD_PRODUCT_UPC_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productinfo: action.payload.response[0],
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productinfo: {
            _id: "",
            name: "",
            images: []
          },
        };
      }
    }

    case ActionTypes.SEARCH_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          productList: {
            count: product_count,
            products: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productList: {
            count: 0,
            products: [],
          },
        };
      }
    }

    /*
    case ActionTypes.SEARCH_PRODUCTS_SUCCESS: {
      const { product_count, products } = action.payload;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        productList: {
          meta: {
            count: product_count,
          },
          records: products,
        },
      };
    }

     */

    case ActionTypes.SEARCH_KEYWORDS_SUCCESS: {
        if (action.payload.statusCode === 200) {
          const {keywords} = action.payload;
          return {
            ...state,
            isLoading: false,
            reqStatus: action.type,
            serviceError: "",
            serviceCode: action.payload.statusCode,
            searchkeywords: {
              keywords: keywords,
            },
          };
        } else {
          return {
            ...state,
            isLoading: false,
            reqStatus: action.type,
            serviceError: action.payload.statusMessage,
            serviceCode: action.payload.statusCode,
            searchkeywords: {
              keywords: [],
            },
          };
        }
      }

    case ActionTypes.GET_ACTIVE_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          categoryList: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          categoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.GET_COMMON_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productsByCategory: {
            meta: {
              count: product_count,
            },
            records: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productsByCategory: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {count, categories} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeProductCategoryList: {
            count: count,
            categories: categories
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeProductCategoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.GET_STORE_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeProducts: {
            meta: {
              count: product_count,
            },
            records: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeProducts: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }


    // update product keeps loading active to avoid gap
    case ActionTypes.UPDATE_PRIVATE_PRODUCT_SUCCESS:{
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: "",
        };
      } else {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.REMOVE_PRIVATE_PRODUCT_SUCCESS:
    case ActionTypes.ADD_PRIVATE_PRODUCT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
        };
      } else {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }
    case ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS:
    case ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productImageList: {
            images: action.payload.response.images,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productImageList: {
            images: [],
          },
        };
      }
    }

    // update category and setting to set loading to avoid gap
    case ActionTypes.UPDATE_PRIVATE_CATEGORY_SUCCESS:
    case ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS:{
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.REMOVE_PRIVATE_CATEGORY_SUCCESS:
    case ActionTypes.ADD_PRIVATE_CATEGORY_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }
    case ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS:
    case ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          categoryImageList: {
            image: action.payload.response.image,
          },
        };
      } else {
        return {
          ...state,
          isLoading: true,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          categoryImageList: {
            image: [],
          },
        };
      }
    }

    default:
      return state;
  }
}
