/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Divider, Container } from "@mui/material";
import { CustomLeftArrow, CustomRightArrow } from "components/Button/SliderButtons";
import * as Actions from "actions";
import ShopProductItem from "components/elements/user/ShopProductItem";
import responsive from "./SliderProps";

const ShopProductSlider = (props) => {
  const {
    products, symbol, addProductToCart, addProductToWishlist, addToCompare,
    usergetPopularProducts, bootupInfo,
  } = props;
  useEffect(() => {
    usergetPopularProducts(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite);
  }, []);

  return (
    <Container>
      <div className="theme-card position-relative">
        <Typography variant="h6">Popular products</Typography>
        <Divider />
        <Slider
          containerClass="overflow-clear"
          renderButtonGroupOutside
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          responsive={responsive}
        >
          { products.map((product, index) => (
            <div key={`${index + 1}`}>
              <ShopProductItem
                product={product}
                symbol={symbol}
                onAddToCompareClicked={() => addToCompare(product)}
                onAddToWishlistClicked={() => addProductToWishlist(product)}
                onAddToCartClicked={() => addProductToCart(product,
                  bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
};

ShopProductSlider.defaultProps = {
  symbol: "$",
};

ShopProductSlider.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  symbol: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usergetPopularProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ShopProductSlide = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.popularproducts,
    symbol: userproducts.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductSlider);

export { ShopProductSlide as default };
