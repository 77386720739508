// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  categoryList: {
    count: 0,
    categories: [],
  },
  orderinfo: {
    _id: "",
    name: "",
    images: []
  },
  orderList: {
    meta: {
      count: 0,
    },
    records: [],
  },
  ordersByCategory: {
    meta: {
      count: 0,
    },
    records: [],
  },

  storeOrderCategoryList: {
    count: 0,
    categories: [],
  },
  storeOrders: {
    records: [],
  },
  searchorderList: {
    count: 0,
    records: [],
  },
  searchkeywords: {
    keywords: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_GET_COMMON_ORDERS_START:
    case ActionTypes.ADMIN_GET_STORE_ORDERS_START:
    case ActionTypes.ADMIN_SEARCH_ORDERS_START:
    case ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_GET_COMMON_ORDERS_FAILURE:
    case ActionTypes.ADMIN_GET_STORE_ORDERS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_ORDERS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.ADMIN_GET_COMMON_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {order_count, orders} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          ordersByCategory: {
            meta: {
              count: order_count,
            },
            records: orders,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          ordersByCategory: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_STORE_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {orders} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeOrders: {
            records: orders,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeOrders: {
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {orders} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchorderList: {
            records: orders,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchorderList: {
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {keywords} = action.payload;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: keywords,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: [],
          },
        };
      }
    }

    default:
      return state;
  }
}
