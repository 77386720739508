/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { schema, getTextfieldData, getButtonData } from "./helper";

const ShippingAddressEditor = ({
  setState, shippingAddressEditing, addUpdateShippingAddress, cancelEdit,
}) => {
  const {
    _id, name, city, area, address, pincode, phone,
  } = shippingAddressEditing;
  const [blur, setblur] = React.useState({
    name: false,
    city: false,
    area: false,
    address: false,
    pincode: false,
    phone: false,
  });

  const onFinish = () => {
    addUpdateShippingAddress({
      _id, name, city, area, address, pincode, phone,
    });
  };

  const handleChange = (event, feild) => {
    setState(event, feild);
  };

  const hasError = () => {
    try {
      return !schema.validateSync(shippingAddressEditing);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.name || blur.area || blur.address || blur.pincode || blur.phone);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, shippingAddressEditing);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(name, city, address, area, phone, pincode).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onBlur={() => handleBlur(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

ShippingAddressEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  shippingAddressEditing: PropTypes.object.isRequired,
  addUpdateShippingAddress: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ShippingAddressEditor;
