//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";

import * as Actions       from "actions/seller/seller.store";

import * as ActionTypes     from 'constants/ActionTypes';
import { CircularProgress, Container, Typography } from "@mui/material";
import "./styles.scss";

import ProfileLogoEditor   from "components/widgets/seller/ProfileLogoEditor";
// Component Imports

type Props = {};

type State = {};

class StoreProfileLogo extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pictureMode: "PREVIEW"
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;
    if (reqStatus == prevProps.reqStatus) return;
    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
  }

  handleImagesEdit = (mode) => (e) => {
    this.setState({ pictureMode: mode });
  };

  handleImagesEditCancel = (mode) => (e) => {
    this.setState({ pictureMode: mode });
    this.props.history.push({
      pathname: '/shop'
    });
  }

  render() {
    const {
      isLoading,
      reqStatus,
      storeProfile,
      updateStoreLogo,
    } = this.props;
      const {logo} = storeProfile;

    const { pictureMode } = this.state;

    return (
      <>
        {isLoading && <CircularProgress />}
        <Container>
          <Typography className="mt-2" variant="h4">Logo</Typography>
          {pictureMode === "PREVIEW" ? (
              <ProfileLogoEditor
                  image={logo}
                  updateStoreLogo={updateStoreLogo}
                  cancelEdit={this.handleImagesEditCancel("EDIT")}
              />
          ) : (
              <ProfileLogoEditor
                  image={logo}
                  cancelEdit={this.handleImagesEditCancel("PREVIEW")}
              />
          )}

        </Container>
      </>
    );
  }
}
// Mapping Props and State.
const _StoreProfileLogo = connect(
  ({ store }) => ({
    store,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StoreProfileLogo);

export { _StoreProfileLogo as default };
