//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Row,
  Col,
  Input,
  Button,
  Notification,
  DatePicker,
  Roller,
} from "components/Atoms";

import { RightOutlined } from "@ant-design/icons";

import * as Actions       from "actions/seller/seller.subscription";

import AvailablePlanCheckout  from "components/elements/seller/AvailablePlanCheckout";

import "./styles.scss";

type Props = {};

type State = {};

class SubscriptionCheckout extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    props.checkoutSubscriptionPlan({ plan_id: "57e91523d360cc7622640d67" });
    /*
    this.state = {
      categoryName: props.match.params.category,
    };

     */
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  render() {
    const { isLoading, subscriptionCheckout } = this.props;
    //const { categoryName } = this.state;
    return (
      <Page className="page-checkout">
        <h4 className="page-title">Order Summary</h4>
        <div className="content">
          <div className="panel--white">
            <AvailablePlanCheckout
              plan={
                subscriptionCheckout.cart > 0
                  ? subscriptionCheckout.cart[0].plan_id
                  : ""
              }
            />
            <div className="split-row">
              <label>Subscription price</label>
              <p className="value">
                {subscriptionCheckout.total_original_price}
              </p>
            </div>
            <div className="split-row">
              <label>Delivery/Shipping</label>
              <p className="value">{subscriptionCheckout.total_shipping}</p>
            </div>
            <div className="split-row">
              <label>Discount</label>
              <p className="value">{subscriptionCheckout.promo_discount}</p>
            </div>
            <div className="split-row">
              <label className="text large">Total (after discount)</label>
              <p className="value text large bold">
                {subscriptionCheckout.total_after_discount}
              </p>
            </div>
            <hr />
            <div className="split-row savings">
              <label className="text large bold">Total Savings</label>
              <p className="value">$40</p>
            </div>
          </div>
          <div className="coupon-code">
            <Input
              size="large"
              className="input-code"
              placeholder="Enter Coupon Code"
            ></Input>
            <Button size="large" className="value">
              Apply
            </Button>
          </div>
          <h4> Payment Information</h4>
          <div className="panel--white payment">
            <div className="content">
              <h5>Payment Method</h5>
              <p className="value">Paytm</p>
            </div>
            <Button
              type="secondary"
              shape="circle"
              icon={<RightOutlined />}
              size="large"
            />
          </div>
          <Button type="primary" size="large" className="m-fit">
            Complete Payment
          </Button>
        </div>
      </Page>
    );
  }
}
// Mapping Props and State.
const _SubscriptionCheckout = connect(
  ({ subscriptions, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: subscriptions.reqStatus,
    isLoading: subscriptions.isLoading,
    serviceError: subscriptions.serviceError,
    subscriptionCheckout: subscriptions.subscriptionCheckout,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SubscriptionCheckout);

export { _SubscriptionCheckout as default };
