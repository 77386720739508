// @flow
import * as ActionTypes from "constants/ActionTypes";

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  bootupCompleted: false,
  userDetails: {
    photo: "https://randomuser.me/api/portraits/men/11.jpg",
    name: "",
    phone: "",
    email: "",
  },
  billingAddress: null,
  usersAddress: {},
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.USER_LOGIN_START:
    case ActionTypes.USER_REGISTER_START:
    case ActionTypes.USER_RESET_PASSWORD_START:
    case ActionTypes.USER_GET_USER_PROFILE_START:
    case ActionTypes.USER_UPDATE_USER_PROFILE_START:
    case ActionTypes.USER_GET_BILLING_ADDRESS_START:
    case ActionTypes.USER_UPDATE_BILLING_ADDRESS_START:
    case ActionTypes.USER_GET_USER_LOCATION_START:{
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.USER_LOGIN_FAILURE:
    case ActionTypes.USER_REGISTER_FAILURE:
    case ActionTypes.USER_RESET_PASSWORD_FAILURE:
    case ActionTypes.USER_GET_USER_PROFILE_FAILURE:
    case ActionTypes.USER_UPDATE_USER_PROFILE_FAILURE:
    case ActionTypes.USER_GET_BILLING_ADDRESS_FAILURE:
    case ActionTypes.USER_UPDATE_BILLING_ADDRESS_FAILURE:
    case ActionTypes.USER_GET_USER_LOCATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        bootupCompleted: false,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    }

    case ActionTypes.USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: false,
        userDetails: { ...initialState.userDetails },
      };
    }

    case ActionTypes.USER_REGISTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: action.payload,
      };
    }

    case ActionTypes.USER_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: action.payload,
      };
    }

    case ActionTypes.USER_GET_USER_PROFILE_SUCCESS:
    case ActionTypes.USER_UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    }

    case ActionTypes.USER_GET_BILLING_ADDRESS_SUCCESS:
    case ActionTypes.USER_UPDATE_BILLING_ADDRESS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        billingAddress: action.payload,
      };
    }

    case ActionTypes.USER_GET_USER_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        userAddress: action.payload,
      };
    }

    default:
      return state;
  }
}
