import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Resizer from "react-image-file-resizer";

import {
  Page,
  Button,
  Notification,
} from "components/Atoms";

import * as Actions         from "actions/seller/seller.products";

import Images               from "components/elements/seller/Images";
import Buttons              from "components/elements/seller/Buttons";
import ButtonComponent from "components/Button";
import {Box, Container, Typography} from "@mui/material";
import { H4, H5 } from "components/CustomTypography";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1200,
            1200,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
      splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

class PrivateCategoryImageMain extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    this.props.category.image = this.props.categoryImageList.image;

 //       this.setState({ images: this.props.productImageList })
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

   onChange = (e) => {
    const errs = []
    const files = Array.from(e.target.files)

    if (files.length > 3) {
      const msg = 'Only 3 images can be uploaded at a time'
      return this.sendNotification("error", {title: msg, description: msg});
    }

    const formData = new FormData()
    const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp']

    files.forEach(async (file, i) => {

      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`)
      }

      if (file.size > 15000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`)
      }

      if (errs.length) {
        return errs.forEach(err => this.sendNotification("error", {title: err, description: err}))
      }
//      formData.append(i, file)

      const image = await resizeFile(file);
      const newFile = dataURIToBlob(image);
      formData.append("image", newFile);
      this.props.addPrivateCategoryImage(this.state.categ_id, formData);
    })

    //this.setState({ uploading: true })
  }

  filter = id => {
    return this.image.filter(image => image.cdn.public_id !== id)
  }

  removeImage = id => {
    //this.setState({ images: this.filter(id) });
    this.props.category.image = this.props.category.image.filter(image => image.cdn.public_id !== id);
    this.props.removePrivateProductImage(this.state.prod_id, id);
  }

  onError = id => {
    this.setState({ image: this.filter(id) })
  }

  render() {

    const {isLoading, category, cancelEdit} = this.props;
    const {id, name, image} = category;
    this.setState({ categ_id: id });

    const content = () => {
      switch(true) {
        case image.length > 0:
          return <div><Buttons onChange={this.onChange} />
          <Images
              images={image}
              removeImage={this.removeImage}
              onError={this.onError}
          />
              </div>
        default:
          return <div><Buttons onChange={this.onChange} /></div>
      }
    }

    return (

        <Container>
            <H4>Upload Photos </H4>
            <Box >
                <Typography align="left">
                    <H5>{name}</H5>
                </Typography>
                <Typography align="right">
                    <ButtonComponent
                        sx={{
                            m: 2,
                            color: 'text.lightprimary',
                        }}
                        type="primary"
                        variant="contained"
                        onClick={cancelEdit}
                        name="Done"
                    />
                </Typography>
                <div className="product-list">
                    {content()}
                </div>
            </Box>
        </Container>
    );



  }
};

// Mapping Props and State.
const _PrivateCategoryImageMain = connect(
    ({ products, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: products.reqStatus,
      isLoading: products.isLoading,
      serviceError: products.serviceError,
      categoryImageList: products.categoryImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(PrivateCategoryImageMain);

export { _PrivateCategoryImageMain as default };
