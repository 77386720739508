// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.products";

const querystring = require('querystring');

function* adminGetCommonProducts(action: Object) {
  try {
    const response = yield API.get(
      `/admin/products/search-products-common` + (action.category ? `?category=` + action.category : ""),
      action.payload
    );
    yield put(Actions.adminGetCommonProductsSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonProductsFailure(error));
  }
}

function* adminGetCommonProductCategories(action: Object) {
  try {
    const response = yield API.get(
        `/admin/categories/get-active-categories`,
        action.payload
    );
    yield put(Actions.adminGetCommonProductCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonProductCategoriesFailure(error));
  }
}

function* adminGetStorePrivateProducts(action: Object) {
  try {

    const searchQuery = querystring.stringify({ id: action.shopId, private: action.private === true ? "1" : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/products/seller-products-common` + query,
        action.payload
    );
    yield put(Actions.adminGetStorePrivateProductsSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetStorePrivateProductsFailure(error));
  }
}

function* adminGetStorePrivateCategories(action: Object) {
  try {
    const searchQuery = querystring.stringify({ id: action.shopId, private: action.private === true ? "1" : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/categories/seller-categories` + query
    );
    yield put(Actions.adminGetStorePrivateCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetStorePrivateCategoriesFailure(error));
  }
}

function* adminUpdateStatusPrivateProduct(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/update-status/` + action.payload.prod_id,
        action.payload
    );
    yield put(Actions.adminUpdateStatusPrivateProductSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateStatusPrivateProductFailure(error));
  }
}

function* adminSearchProducts(action: Object) {
  try {
    const searchQuery = ((action.isUPCBased) ? (`upc=` + action.upc) : (`name=` + action.payload +  (action.category ? `&category=` + action.category : "")));
    const response = yield API.get(
      `/admin/products/search-products-common?` + searchQuery,
      action.payload
    );
    yield put(Actions.adminSearchProductsSuccess(response.response));
  } catch (error) {
    yield put(Actions.adminSearchProductsFailure(error));
  }
}
function* adminSearchProductKeywords(action: Object) {
  try {
    //const searchQuery = ((action.isUPCBased) ? (`upc=` + action.upc) : (`name=` + action.payload +  (action.category ? `&category=` + action.category.id : "")));
    const response = yield API.get(
        `/admin/products/products-keywords?name=` + action.payload,
        action.payload
    );
    yield put(Actions.adminSearchProductKeywordsSuccess(response.response));
  } catch (error) {
    yield put(Actions.adminSearchProductKeywordsFailure(error));
  }
}

function* adminAddCommonProduct(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/add-common-private`,
        action.payload
    );
    yield put(Actions.adminAddCommonProductSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonProductFailure(error));
  }
}

function* adminUpdateCommonProduct(action: Object) {
  try {
    const response = yield API.post(
        `sellers/products/update-product-private/` + action.payload.prod_id,
        action.payload
    );
    yield put(Actions.adminUpdateCommonProductSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateCommonProductFailure(error));
  }
}

function* adminAddCommonProductImage(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/add-images/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminAddCommonProductImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonProductImageFailure(error));
  }
}

function* adminRemoveCommonProductImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/products/remove-image`,
        action.payload
    );
    yield put(Actions.adminRemoveCommonProductImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminRemoveCommonProductImageFailure(error));
  }
}

function* adminAddCommonCategory(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/add-category`,
        action.payload
    );
    yield put(Actions.adminAddCommonCategorySuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonCategoryFailure(error));
  }
}

function* adminUpdateCommonCategory(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/update-category/` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateCommonCategorySuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateCommonCategoryFailure(error));
  }
}

function* adminAddCommonCategoryImage(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/update-category-image/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminAddCommonCategoryImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonCategoryImageFailure(error));
  }
}

function* adminRemoveCommonCategoryImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/categories/remove-image`,
        action.payload
    );
    yield put(Actions.adminRemoveCommonCategoryImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminRemoveCommonCategoryImageFailure(error));
  }
}

export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_SEARCH_PRODUCTS_START, adminSearchProducts);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_START, adminSearchProductKeywords);

  yield takeLatest(ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_START, adminGetStorePrivateProducts);
  yield takeLatest(ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_START, adminGetStorePrivateCategories);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START, adminUpdateStatusPrivateProduct);

  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_PRODUCTS_START, adminGetCommonProducts);
  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_CATEGORIES_START, adminGetCommonProductCategories);

  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_PRODUCT_START, adminAddCommonProduct);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_START, adminUpdateCommonProduct);
  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_START, adminAddCommonProductImage);
  yield takeLatest(ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START, adminRemoveCommonProductImage);
  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_CATEGORY_START, adminAddCommonCategory);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START, adminUpdateCommonCategory);
  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_START, adminAddCommonCategoryImage);
  yield takeLatest(ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START, adminRemoveCommonCategoryImage);

}
