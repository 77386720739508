/* eslint-disable import/prefer-default-export */
export const SearchOptions = [
  {
    category: "Shops",
    placeholder: "search business",
  },
  {
    category: "Orders",
    placeholder: "search orders",
  },
];
