//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
} from "components/Atoms";
import * as _ from "lodash";

import * as Actions         from "actions/seller/seller.registerstore";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class ClaimStore extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  render() {
    const { isLoading } = this.props;
    return (
      <Page className="claim-store">
        <h2 className="page-title">Claim Store</h2>
      </Page>
    );
  }
}
// Mapping Props and State.
const _ClaimStore = connect(
  ({ store, appBase }) => ({
    appBase: appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    overview: store.overview,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ClaimStore);

export { _ClaimStore as default };
