//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";

import * as Actions         from "actions/core/core.actions";
import * as ActionTypes     from 'constants/ActionTypes';
import { CircularProgress, Container, Typography } from "@mui/material";
import "./styles.scss";

import ProfileImageEditor   from "components/widgets/core/ProfileImageEditor";
// Component Imports

type Props = {};

type State = {};

class ProfileImage extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pictureMode: "PREVIEW"
    };
    //props.getStoreImages();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;
    if (reqStatus == prevProps.reqStatus) return;
    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
  }

  handleImagesEdit = (mode) => (e) => {
    this.setState({ pictureMode: mode });
  };

  handleImagesEditCancel = (mode) => (e) => {
    this.setState({ pictureMode: mode });
    this.props.history.push({
      pathname: '/me'
    });
  }

  render() {
    const {
      isLoading,
      reqStatus,
        userDetails,
        updateUserPic,
    } = this.props;
      const {image} = userDetails;

    const { pictureMode } = this.state;

    return (
      <>
        {isLoading && <CircularProgress />}
        <Container>
          <Typography className="mt-2" variant="h4">Profile</Typography>
          {pictureMode === "PREVIEW" ? (
              <ProfileImageEditor
                  image={image}
                  updateUserPic={updateUserPic}
                  cancelEdit={this.handleImagesEditCancel("EDIT")}
              />
          ) : (
              <ProfileImageEditor
                  image={image}
                  cancelEdit={this.handleImagesEditCancel("PREVIEW")}
              />
          )}
        </Container>
      </>
    );
  }
}
// Mapping Props and State.
const _ProfileImage = connect(
  ({ store, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProfileImage);

export { _ProfileImage as default };
