/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import ShopProductSearch from "components/widgets/user/ShopProductSearch";
import "components/elements/main/index.scss";
import { Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SpanInline } from "components/CustomTypography";

const ShopSearch = (props) => {
  const [state] = useState({
    layoutColumns: 3,
  });

  useEffect(() => {
    props.userResetSearchProducts();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

  return (
      <ShopProductSearch {...props} colSize={state.layoutColumns} />
  );
};

ShopSearch.propTypes = {
  userResetSearchProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ShopSearchComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopSearch);

export { ShopSearchComponent as default };
