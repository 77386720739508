/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Card,
  IconButton,
  Container,
  Divider,
  MenuItem,
  Snackbar,
} from "@mui/material";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import CustomSnackBar from "components/CustomSnackBar";
import { DATE_FORMAT } from "config/constants";
import { getLocalDate, getTimeSlot } from "helpers/utils";

import * as Actions from "actions";
import * as ActionsBase from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";
import { getCartTotal } from "services";

const ShopPaymentCheckout = (props) => {
  const { location } = props;
  const {
    address, items, shipping, total_price, total_shipping, total_order, order_type, delivery_date, delivery_time,
  } = location.state || {};

  const [state, setState] = useState({
    address,
    paymentoption: "cash",
    shippingoption: order_type,
    delivery_date,
    delivery_time,
  });

  useEffect(() => {
    switch (props.reqStatus) {
      case ActionTypes.USER_GENERATE_PRODUCT_ORDER_SUCCESS:
        if (props.serviceCode === 200) {
          if (props.bootupInfo.appState.isWebsite) {
            props.history.push({
              pathname: "/cart/order-success",
              state: {
                // payment: { paymentID: props.order.order.order_id },
                order_id: props.order.order.order_id,
                items: props.order.order.products,
                shipping: props.order.order.shipping,
                created_at: props.order.order.created_at,
                order_type: props.order.order.order_type,
                delivery_date: props.order.order.delivery_date,
                delivery_time: props.order.order.delivery_time,
                total_price: props.order.order.total_price,
                total_shipping: props.order.order.total_shipping,
                total_order: props.order.order.total_order,
                symbol: props.symbol,
              },
            });
          } else {
            props.history.push({
              pathname: `/shop/${props.bootupInfo.appState.subdomain_shopId}/cart/order-success`,
              state: {
                // payment: { paymentID: props.order.order.order_id },
                order_id: props.order.order.order_id,
                items: props.order.order.products,
                shipping: props.order.order.shipping,
                created_at: props.order.order.created_at,
                order_type: props.order.order.order_type,
                delivery_date: props.order.order.delivery_date,
                delivery_time: props.order.order.delivery_time,
                total_price: props.order.order.total_price,
                total_shipping: props.order.order.total_shipping,
                symbol: props.symbol,
              },
            });
          }
        }
        break;
      default:
        break;
    }
  }, [props.reqStatus]);

  const handlePaymentOption = (event) => {
    setState({
      paymentoption: event.target.value,
    });
  };

  const handlePlaceOrder = () => {
    if (state.isDelivery && !state.address) { return; }

    props.generateProductOrder(state,
      props.bootupInfo.appState.subdomain_shopId,
      props.bootupInfo.appState.isWebsite);
  };

  const handlePage = (page) => () => {
    props.history.push({
      pathname: page,
    });
  };

  const {
    order, cartItems, symbol, total, shippingAddress,
  } = props;

  // Paypal Integration
  const onSuccess = (payment) => {
    if (props.bootupInfo.appState.isWebsite) {
      props.history.push({
        pathname: "/order-success",
        state: {
          payment,
          items: order.products,
          shipping: order.shipping,
          delivery_date: order.delivery_date,
          delivery_time: order.delivery_time,
          orderTotal: total,
          symbol,
        },
      });
    } else {
      props.history.push({
        pathname: "/order-success",
        state: {
          payment,
          items: order.products,
          shipping: order.shipping,
          delivery_date: order.delivery_date,
          delivery_time: order.delivery_time,
          orderTotal: total,
          symbol,
        },
      });
    }
  };

  const client = {
    sandbox: "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
    production: "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
  };

  const getPrice = (symbol, item) => {
    const price = item.seller_price
        - (item.product && item.product.discount
          ? (item.seller_price * (parseInt(item.product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {symbol}
        {price}
        {item.product.discount && (
          <del>
            <span className="money">
              {symbol}
              {item.seller_price}
            </span>
          </del>
        )}
      </Typography>
    );
  };

  const renderOrderItems = () => (
    <Box>
      <Card className="p-3">
        {cartItems.map((item, index) => (
          <Box className={item.product.images.length ? "styled-card" : "no-img-product"} width="100%" display="flex" p={1}>
            <Box width="15%" order={1}>
              <img
                alt=""
                className="img-product"
                src={item.product.images && item.product.images[0] ? item.product.images[0].cdn.url : ""}
              />
            </Box>
            <Box width="85%" className="ml-1" order={2} display="flex">
              <Box flexGrow={1}>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                  {item.product.name}
                </Typography>
                {getPrice(symbol, item)}
              </Box>
            </Box>
          </Box>
        ))}
      </Card>
    </Box>
  );

  const renderShippingAddress = () => (
    <Card className="p-3">
      <Typography variant="h6">Delivery</Typography>
      <Typography variant="subtitle2" display="block">
        {getLocalDate(delivery_date, DATE_FORMAT)}
        ,
        {getTimeSlot(delivery_time)}
      </Typography>
      <Typography variant="h6">Address</Typography>
      <Typography variant="subtitle2" display="block">
        {shipping.name}
        {" "}
        {shipping.phone || ""}
      </Typography>
      <Typography variant="subtitle2" display="block">
        {shipping.address}
        ,
        {shipping.city}
        ,
        {shipping.pincode}
      </Typography>
    </Card>
  );

  const renderShopPickup = () => (
    <Card className="p-3">
      <Typography variant="h6">Pickup</Typography>
      <Typography variant="subtitle2" display="block">
        {getLocalDate(delivery_date, DATE_FORMAT)}
        ,
        {getTimeSlot(delivery_time)}
      </Typography>
    </Card>
  );

  const renderOrderTotal = () => (
    <Card className="p-3">
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Subtotal: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_price}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Shipping: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_shipping}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Total: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_order}
          </Typography>
        </Box>
      </Box>
    </Card>
  );

  const options2 = [
    { label: "Cash/CoD", value: "cash" },
  ];

  const inputPaymentOptions = options2.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });

  return (
    <div>
      {props.serviceCode !== 200
        ? (
          <CustomSnackBar
            status
            errorMessage={props.serviceError}
          />
        ) : ""}

      {
        ((order_type === "delivery" && address) || (order_type === "pickup"))
          ? (

              <div>
                <form>
                  <Container>

                    <Box display={window.innerWidth < 400 ? "" : "flex"}>
                      <Box width={window.innerWidth < 400 ? "100%" : "50%"}
                           className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                        {order_type === "delivery" ? renderShippingAddress() : renderShopPickup()}
                      </Box>
                    </Box>

                    <Container>
                      <Box>
                        {renderOrderTotal()}
                      </Box>
                    </Container>

                    <Box className="textfield" m={2}>
                      <RadioButtonFieldComponent
                          required
                          options={inputPaymentOptions}
                          label="paymentoption"
                          value={state.paymentoption}
                          onChange={(event) => handlePaymentOption(event)}
                      />
                    </Box>

                    <div className="payment-box">
                      {(total || !total)
                          ? (

                              <Typography className="m-2" align="center">
                                <ButtonComponent
                                    variant="contained"
                                    type="primary"
                                    name="Place Order"
                                    fullWidth
                                    onClick={() => handlePlaceOrder()}
                                />
                              </Typography>
                          )
                          : ""}
                    </div>

                  </Container>
                </form>
              </div>
          )
          : (
              <section className="p-0">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="error-section">
                        <h1>404</h1>
                        <h2>page not found</h2>
                        <a href="/" className="btn btn-solid">back to home</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          )
      }
    </div>
  );
};

// Mapping Props and State.
const shopPaymentCheckout = connect(
  ({ cartList, bootupInfo, userproducts }) => ({
    bootupInfo,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
    serviceCode: cartList.serviceCode,
    cartItems: cartList.cart,
    order: cartList.order,
    total: getCartTotal(cartList.cart),
    symbol: userproducts.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions, ...ActionsBase }, dispatch),
)(ShopPaymentCheckout);

export { shopPaymentCheckout as default };
