export const BreakPoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const TimeSlots = {
  "10to12" : "10:00AM to 12:00PM",
  "12to2" : "12:00PM to 2:00PM",
  "2to4" : "2:00PM to 4:00PM",
  "4to6" : "4:00PM to 6:00PM",
    "6to8" : "6:00PM to 8:00PM",
};

export const CURRENCY = "\u0024"; // \u0024 - dollar, \u20B9 - Rupee
export const DATE_FORMAT = "MMMM Do YYYY";
export const DATE_TIME_FORMAT = "MMMM Do YYYY h:mm a";
