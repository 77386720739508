export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_PRE_OTP_START = "LOGIN_PRE_OTP_START";
export const LOGIN_PRE_OTP_SUCCESS = "LOGIN_PRE_OTP_SUCCESS";
export const LOGIN_PRE_OTP_FAILURE = "LOGIN_PRE_OTP_FAILURE";

export const LOGIN_POST_OTP_START = "LOGIN_POST_OTP_START";
export const LOGIN_POST_OTP_SUCCESS = "LOGIN_POST_OTP_SUCCESS";
export const LOGIN_POST_OTP_FAILURE = "LOGIN_POST_OTP_FAILURE";

export const SOCIAL_LOGIN_START = "SOCIAL_LOGIN_START";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_USER_PROFILE_START = "GET_USER_PROFILE_START";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const UPDATE_USER_PROFILE_UUID_START = "UPDATE_USER_PROFILE_UUID_START";
export const UPDATE_USER_PROFILE_UUID_SUCCESS = "UPDATE_USER_PROFILE_UUID_SUCCESS";
export const UPDATE_USER_PROFILE_UUID_FAILURE = "UPDATE_USER_PROFILE_UUID_FAILURE";

export const GET_SHOP_USER_PROFILE_START = "GET_SHOP_USER_PROFILE_START";
export const GET_SHOP_USER_PROFILE_SUCCESS = "GET_SHOP_USER_PROFILE_SUCCESS";
export const GET_SHOP_USER_PROFILE_FAILURE = "GET_SHOP_USER_PROFILE_FAILURE";

export const GET_SHOP_AND_USER_PROFILE_START = "GET_SHOP_AND_USER_PROFILE_START";
export const GET_SHOP_AND_USER_PROFILE_SUCCESS = "GET_SHOP_AND_USER_PROFILE_SUCCESS";
export const GET_SHOP_AND_USER_PROFILE_FAILURE = "GET_SHOP_AND_USER_PROFILE_FAILURE";

export const GET_SHOP_PROFILE_START = "GET_SHOP_PROFILE_START";
export const GET_SHOP_PROFILE_SUCCESS = "GET_SHOP_PROFILE_SUCCESS";
export const GET_SHOP_PROFILE_FAILURE = "GET_SHOP_PROFILE_FAILURE";

export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const GET_BILLING_ADDRESS_START = "GET_BILLING_ADDRESS_START";
export const GET_BILLING_ADDRESS_SUCCESS = "GET_BILLING_ADDRESS_SUCCESS";
export const GET_BILLING_ADDRESS_FAILURE = "GET_BILLING_ADDRESS_FAILURE";

export const UPDATE_BILLING_ADDRESS_START = "UPDATE_BILLING_ADDRESS_START";
export const UPDATE_BILLING_ADDRESS_SUCCESS = "UPDATE_BILLING_ADDRESS_SUCCESS";
export const UPDATE_BILLING_ADDRESS_FAILURE = "UPDATE_BILLING_ADDRESS_FAILURE";

export const ADD_SHIPPING_ADDRESS_START = "ADD_SHIPPING_ADDRESS_START";
export const ADD_SHIPPING_ADDRESS_SUCCESS = "ADD_SHIPPING_ADDRESS_SUCCESS";
export const ADD_SHIPPING_ADDRESS_FAILURE = "ADD_SHIPPING_ADDRESS_FAILURE";

export const GET_SHIPPING_ADDRESS_START = "GET_SHIPPING_ADDRESS_START";
export const GET_SHIPPING_ADDRESS_SUCCESS = "GET_SHIPPING_ADDRESS_SUCCESS";
export const GET_SHIPPING_ADDRESS_FAILURE = "GET_SHIPPING_ADDRESS_FAILURE";

export const UPDATE_SHIPPING_ADDRESS_START = "UPDATE_SHIPPING_ADDRESS_START";
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = "UPDATE_SHIPPING_ADDRESS_SUCCESS";
export const UPDATE_SHIPPING_ADDRESS_FAILURE = "UPDATE_SHIPPING_ADDRESS_FAILURE";

export const DELETE_SHIPPING_ADDRESS_START = "DELETE_SHIPPING_ADDRESS_START";
export const DELETE_SHIPPING_ADDRESS_SUCCESS = "DELETE_SHIPPING_ADDRESS_SUCCESS";
export const DELETE_SHIPPING_ADDRESS_FAILURE = "DELETE_SHIPPING_ADDRESS_FAILURE";

export const UPDATE_USER_PIC_START = "UPDATE_USER_PIC_START";
export const UPDATE_USER_PIC_SUCCESS = "UPDATE_USER_PIC_SUCCESS";
export const UPDATE_USER_PIC_FAILURE = "UPDATE_USER_PIC_FAILURE";

export const GET_COMMON_ORDERS_START = "GET_COMMON_ORDERS_START";
export const GET_COMMON_ORDERS_SUCCESS = "GET_COMMON_ORDERS_SUCCESS";
export const GET_COMMON_ORDERS_FAILURE = "GET_COMMON_ORDERS_FAILURE";

export const GET_STORE_ORDER_CATEGORIES_START = "GET_STORE_ORDER_CATEGORIES_START";
export const GET_STORE_ORDER_CATEGORIES_SUCCESS = "GET_STORE_ORDER_CATEGORIES_SUCCESS";
export const GET_STORE_ORDER_CATEGORIES_FAILURE = "GET_STORE_ORDER_CATEGORIES_FAILURE";

export const GET_STORE_ORDERS_START = "GET_STORE_ORDERS_START";
export const GET_STORE_ORDERS_SUCCESS = "GET_STORE_ORDERS_SUCCESS";
export const GET_STORE_ORDERS_FAILURE = "GET_STORE_ORDERS_FAILURE";

export const GET_ORDER_DETAIL_START = "GET_ORDER_DETAIL_START";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_FAILURE = "GET_ORDER_DETAIL_FAILURE";

export const ADD_ORDER_START = "ADD_ORDER_START";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

export const UPDATE_ORDER_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const REMOVE_ORDER_START = "REMOVE_ORDER_START";
export const REMOVE_ORDER_SUCCESS = "REMOVE_ORDER_SUCCESS";
export const REMOVE_ORDER_FAILURE = "REMOVE_ORDER_FAILURE";

export const ADD_ORDER_UPC_START = "ADD_ORDER_UPC_START";
export const ADD_ORDER_UPC_SUCCESS = "ADD_ORDER_UPC_SUCCESS";
export const ADD_ORDER_UPC_FAILURE = "ADD_ORDER_UPC_FAILURE";

export const SEARCH_ORDERS_START = "SEARCH_ORDERS_START";
export const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
export const SEARCH_ORDERS_FAILURE = "SEARCH_ORDERS_FAILURE";

export const SEARCH_ORDERS_KEYWORDS_START = "SEARCH_ORDERS_KEYWORDS_START";
export const SEARCH_ORDERS_KEYWORDS_SUCCESS = "SEARCH_ORDERS_KEYWORDS_SUCCESS";
export const SEARCH_ORDERS_KEYWORDS_FAILURE = "SEARCH_ORDERS_KEYWORDS_FAILURE";

export const CHECK_ORDER_START = "CHECK_ORDER_START";
export const CHECK_ORDER_SUCCESS = "CHECK_ORDER_SUCCESS";
export const CHECK_ORDER_FAILURE = "CHECK_ORDER_FAILURE";

export const GET_ACTIVE_CATEGORIES_START = "GET_ACTIVE_CATEGORIES_START";
export const GET_ACTIVE_CATEGORIES_SUCCESS = "GET_ACTIVE_CATEGORIES_SUCCESS";
export const GET_ACTIVE_CATEGORIES_FAILURE = "GET_ACTIVE_CATEGORIES_FAILURE";

export const GET_COMMON_PRODUCTS_START = "GET_COMMON_PRODUCTS_START";
export const GET_COMMON_PRODUCTS_SUCCESS = "GET_COMMON_PRODUCTS_SUCCESS";
export const GET_COMMON_PRODUCTS_FAILURE = "GET_COMMON_PRODUCTS_FAILURE";

export const GET_STORE_PRODUCT_CATEGORIES_START = "GET_STORE_PRODUCT_CATEGORIES_START";
export const GET_STORE_PRODUCT_CATEGORIES_SUCCESS = "GET_STORE_PRODUCT_CATEGORIES_SUCCESS";
export const GET_STORE_PRODUCT_CATEGORIES_FAILURE = "GET_STORE_PRODUCT_CATEGORIES_FAILURE";

export const GET_STORE_PRODUCTS_START = "GET_STORE_PRODUCTS_START";
export const GET_STORE_PRODUCTS_SUCCESS = "GET_STORE_PRODUCTS_SUCCESS";
export const GET_STORE_PRODUCTS_FAILURE = "GET_STORE_PRODUCTS_FAILURE";

export const ADD_PRODUCT_START = "ADD_PRODUCT_START";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_START = "UPDATE_PRODUCT_START";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const REMOVE_PRODUCT_START = "REMOVE_PRODUCT_START";
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS";
export const REMOVE_PRODUCT_FAILURE = "REMOVE_PRODUCT_FAILURE";

export const ADD_PRODUCT_UPC_START = "ADD_PRODUCT_UPC_START";
export const ADD_PRODUCT_UPC_SUCCESS = "ADD_PRODUCT_UPC_SUCCESS";
export const ADD_PRODUCT_UPC_FAILURE = "ADD_PRODUCT_UPC_FAILURE";

export const SEARCH_PRODUCTS_START = "SEARCH_PRODUCTS_START";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE";

export const SEARCH_KEYWORDS_START = "SEARCH_KEYWORDS_START";
export const SEARCH_KEYWORDS_SUCCESS = "SEARCH_KEYWORDS_SUCCESS";
export const SEARCH_KEYWORDS_FAILURE = "SEARCH_KEYWORDS_FAILURE";

export const CHECK_PRODUCT_START = "CHECK_PRODUCT_START";
export const CHECK_PRODUCT_SUCCESS = "CHECK_PRODUCT_SUCCESS";
export const CHECK_PRODUCT_FAILURE = "CHECK_PRODUCT_FAILURE";

export const ADD_PRIVATE_PRODUCT_START = "ADD_PRIVATE_PRODUCT_START";
export const ADD_PRIVATE_PRODUCT_SUCCESS = "ADD_PRIVATE_PRODUCT_SUCCESS";
export const ADD_PRIVATE_PRODUCT_FAILURE = "ADD_PRIVATE_PRODUCT_FAILURE";

export const UPDATE_PRIVATE_PRODUCT_START = "UPDATE_PRIVATE_PRODUCT_START";
export const UPDATE_PRIVATE_PRODUCT_SUCCESS = "UPDATE_PRIVATE_PRODUCT_SUCCESS";
export const UPDATE_PRIVATE_PRODUCT_FAILURE = "UPDATE_PRIVATE_PRODUCT_FAILURE";

export const REMOVE_PRIVATE_PRODUCT_START = "REMOVE_PRIVATE_PRODUCT_START";
export const REMOVE_PRIVATE_PRODUCT_SUCCESS = "REMOVE_PRIVATE_PRODUCT_SUCCESS";
export const REMOVE_PRIVATE_PRODUCT_FAILURE = "REMOVE_PRIVATE_PRODUCT_FAILURE";

export const ADD_PRIVATE_PRODUCT_IMAGE_START = "ADD_PRIVATE_PRODUCT_IMAGE_START";
export const ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS = "ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS";
export const ADD_PRIVATE_PRODUCT_IMAGE_FAILURE = "ADD_PRIVATE_PRODUCT_IMAGE_FAILURE";

export const REMOVE_PRIVATE_PRODUCT_IMAGE_START = "REMOVE_PRIVATE_PRODUCT_IMAGE_START";
export const REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS = "REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS";
export const REMOVE_PRIVATE_PRODUCT_IMAGE_FAILURE = "REMOVE_PRIVATE_PRODUCT_IMAGE_FAILURE";

export const ADD_PRIVATE_CATEGORY_START = "ADD_PRIVATE_CATEGORY_START";
export const ADD_PRIVATE_CATEGORY_SUCCESS = "ADD_PRIVATE_CATEGORY_SUCCESS";
export const ADD_PRIVATE_CATEGORY_FAILURE = "ADD_PRIVATE_CATEGORY_FAILURE";

export const UPDATE_PRIVATE_CATEGORY_START = "UPDATE_PRIVATE_CATEGORY_START";
export const UPDATE_PRIVATE_CATEGORY_SUCCESS = "UPDATE_PRIVATE_CATEGORY_SUCCESS";
export const UPDATE_PRIVATE_CATEGORY_FAILURE = "UPDATE_PRIVATE_CATEGORY_FAILURE";

export const UPDATE_PRIVATE_CATEGORY_SETTING_START = "UPDATE_PRIVATE_CATEGORY_SETTING_START";
export const UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS = "UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS";
export const UPDATE_PRIVATE_CATEGORY_SETTING_FAILURE = "UPDATE_PRIVATE_CATEGORY_SETTING_FAILURE";

export const REMOVE_PRIVATE_CATEGORY_START = "REMOVE_PRIVATE_CATEGORY_START";
export const REMOVE_PRIVATE_CATEGORY_SUCCESS = "REMOVE_PRIVATE_CATEGORY_SUCCESS";
export const REMOVE_PRIVATE_CATEGORY_FAILURE = "REMOVE_PRIVATE_CATEGORY_FAILURE";

export const ADD_PRIVATE_CATEGORY_IMAGE_START = "ADD_PRIVATE_CATEGORY_IMAGE_START";
export const ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS = "ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS";
export const ADD_PRIVATE_CATEGORY_IMAGE_FAILURE = "ADD_PRIVATE_CATEGORY_IMAGE_FAILURE";

export const REMOVE_PRIVATE_CATEGORY_IMAGE_START = "REMOVE_PRIVATE_CATEGORY_IMAGE_START";
export const REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS = "REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS";
export const REMOVE_PRIVATE_CATEGORY_IMAGE_FAILURE = "REMOVE_PRIVATE_CATEGORY_IMAGE_FAILURE";

export const GET_USER_LOCATION_START = "GET_USER_LOCATION_START";
export const GET_USER_LOCATION_SUCCESS = "GET_USER_LOCATION_SUCCESS";
export const GET_USER_LOCATION_FAILURE = "GET_USER_LOCATION_FAILURE";

export const GET_PLACE_SUGGESTIONS_START = "GET_PLACE_SUGGESTIONS_START";
export const GET_PLACE_SUGGESTIONS_SUCCESS = "GET_PLACE_SUGGESTIONS_SUCCESS";
export const GET_PLACE_SUGGESTIONS_FAILURE = "GET_PLACE_SUGGESTIONS_FAILURE";

export const GET_STORES_FOR_LOCATION_START = "GET_STORES_FOR_LOCATION_START";
export const GET_STORES_FOR_LOCATION_SUCCESS = "GET_STORES_FOR_LOCATION_SUCCESS";
export const GET_STORES_FOR_LOCATION_FAILURE = "GET_STORES_FOR_LOCATION_FAILURE";

export const ADD_SHOP_TO_ACCOUNT_START = "ADD_SHOP_TO_ACCOUNT_START";
export const ADD_SHOP_TO_ACCOUNT_SUCCESS = "ADD_SHOP_TO_ACCOUNT_SUCCESS";
export const ADD_SHOP_TO_ACCOUNT_FAILURE = "ADD_SHOP_TO_ACCOUNT_FAILURE";

export const GET_DOMAIN_START = "GET_DOMAIN_START";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_FAILURE = "GET_DOMAIN_FAILURE";

export const CHECK_DOMAIN_AVAILABILITY_START = "CHECK_DOMAIN_AVAILABILITY_START";
export const CHECK_DOMAIN_AVAILABILITY_SUCCESS = "CHECK_DOMAIN_AVAILABILITY_SUCCESS";
export const CHECK_DOMAIN_AVAILABILITY_FAILURE = "CHECK_DOMAIN_AVAILABILITY_FAILURE";

export const UPDATE_DOMAIN_START = "UPDATE_DOMAIN_START";
export const UPDATE_DOMAIN_SUCCESS = "UPDATE_DOMAIN_SUCCESS";
export const UPDATE_DOMAIN_FAILURE = "UPDATE_DOMAIN_FAILURE";

export const GET_STORE_PROFILE_START = "GET_STORE_PROFILE_START";
export const GET_STORE_PROFILE_SUCCESS = "GET_STORE_PROFILE_SUCCESS";
export const GET_STORE_PROFILE_FAILURE = "GET_STORE_PROFILE_FAILURE";

export const UPDATE_STORE_PROFILE_START = "UPDATE_STORE_PROFILE_START";
export const UPDATE_STORE_PROFILE_SUCCESS = "UPDATE_STORE_PROFILE_SUCCESS";
export const UPDATE_STORE_PROFILE_FAILURE = "UPDATE_STORE_PROFILE_FAILURE";

export const GET_STORE_SERVICE_START = "GET_STORE_SERVICE_START";
export const GET_STORE_SERVICE_SUCCESS = "GET_STORE_SERVICE_SUCCESS";
export const GET_STORE_SERVICE_FAILURE = "GET_STORE_SERVICE_FAILURE";

export const UPDATE_STORE_SERVICE_START = "UPDATE_STORE_SERVICE_START";
export const UPDATE_STORE_SERVICE_SUCCESS = "UPDATE_STORE_SERVICE_SUCCESS";
export const UPDATE_STORE_SERVICE_FAILURE = "UPDATE_STORE_SERVICE_FAILURE";

export const GET_STORE_ADDRESS_START = "GET_STORE_ADDRESS_START";
export const GET_STORE_ADDRESS_SUCCESS = "GET_STORE_ADDRESS_SUCCESS";
export const GET_STORE_ADDRESS_FAILURE = "GET_STORE_ADDRESS_FAILURE";

export const UPDATE_STORE_ADDRESS_START = "UPDATE_STORE_ADDRESS_START";
export const UPDATE_STORE_ADDRESS_SUCCESS = "UPDATE_STORE_ADDRESS_SUCCESS";
export const UPDATE_STORE_ADDRESS_FAILURE = "UPDATE_STORE_ADDRESS_FAILURE";

export const GET_STORE_IMAGES_START = "GET_STORE_IMAGES_START";
export const GET_STORE_IMAGES_SUCCESS = "GET_STORE_IMAGES_SUCCESS";
export const GET_STORE_IMAGES_FAILURE = "GET_STORE_IMAGES_FAILURE";

export const ADD_STORE_IMAGE_START = "ADD_STORE_IMAGE_START";
export const ADD_STORE_IMAGE_SUCCESS = "ADD_STORE_IMAGE_SUCCESS";
export const ADD_STORE_IMAGE_FAILURE = "ADD_STORE_IMAGE_FAILURE";

export const REMOVE_STORE_IMAGE_START = "REMOVE_STORE_IMAGE_START";
export const REMOVE_STORE_IMAGE_SUCCESS = "REMOVE_STORE_IMAGE_SUCCESS";
export const REMOVE_STORE_IMAGE_FAILURE = "REMOVE_STORE_IMAGE_FAILURE";

export const UPDATE_STORE_LOGO_START = "UPDATE_STORE_LOGO_START";
export const UPDATE_STORE_LOGO_SUCCESS = "UPDATE_STORE_LOGO_SUCCESS";
export const UPDATE_STORE_LOGO_FAILURE = "UPDATE_STORE_LOGO_FAILURE";

export const GET_USER_SUBSCRIPTIONS_START = "GET_USER_SUBSCRIPTIONS_START";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_SUBSCRIPTIONS_FAILURE = "GET_USER_SUBSCRIPTIONS_FAILURE";

export const GET_ALL_SUBSCRIPTIONS_START = "GET_ALL_SUBSCRIPTIONS_START";
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const GET_ALL_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const ADD_SUBSCRIPTION_TO_CART_START = "ADD_SUBSCRIPTION_TO_CART_START";
export const ADD_SUBSCRIPTION_TO_CART_SUCCESS = "ADD_SUBSCRIPTION_TO_CART_SUCCESS";
export const ADD_SUBSCRIPTION_TO_CART_FAILURE = "ADD_SUBSCRIPTION_TO_CART_FAILURE";

export const REMOVE_SUBSCRIPTION_FROM_CART_START = "REMOVE_SUBSCRIPTION_FROM_CART_START";
export const REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS = "REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS";
export const REMOVE_SUBSCRIPTION_FROM_CART_FAILURE = "REMOVE_SUBSCRIPTION_FROM_CART_FAILURE";

export const CHECKOUT_SUBSCRIPTION_START = "CHECKOUT_SUBSCRIPTION_START";
export const CHECKOUT_SUBSCRIPTION_SUCCESS = "CHECKOUT_SUBSCRIPTION_SUCCESS";
export const CHECKOUT_SUBSCRIPTION_FAILURE = "CHECKOUT_SUBSCRIPTION_FAILURE";

// User base

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_SOCIAL_LOGIN_START = "USER_SOCIAL_LOGIN_START";
export const USER_SOCIAL_LOGIN_SUCCESS = "USER_SOCIAL_LOGIN_SUCCESS";
export const USER_SOCIAL_LOGIN_FAILURE = "USER_SOCIAL_LOGIN_FAILURE";

export const USER_LOGOUT_START = "USER_LOGOUT_START";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const USER_RESET_PASSWORD_START = "USER_RESET_PASSWORD_START";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";

export const USER_GET_USER_PROFILE_START = "USER_GET_USER_PROFILE_START";
export const USER_GET_USER_PROFILE_SUCCESS = "USER_GET_USER_PROFILE_SUCCESS";
export const USER_GET_USER_PROFILE_FAILURE = "USER_GET_USER_PROFILE_FAILURE";

export const USER_UPDATE_USER_PROFILE_START = "USER_UPDATE_USER_PROFILE_START";
export const USER_UPDATE_USER_PROFILE_SUCCESS = "USER_UPDATE_USER_PROFILE_SUCCESS";
export const USER_UPDATE_USER_PROFILE_FAILURE = "USER_UPDATE_USER_PROFILE_FAILURE";

export const USER_GET_BILLING_ADDRESS_START = "USER_GET_BILLING_ADDRESS_START";
export const USER_GET_BILLING_ADDRESS_SUCCESS = "USER_GET_BILLING_ADDRESS_SUCCESS";
export const USER_GET_BILLING_ADDRESS_FAILURE = "USER_GET_BILLING_ADDRESS_FAILURE";

export const USER_UPDATE_BILLING_ADDRESS_START = "USER_UPDATE_BILLING_ADDRESS_START";
export const USER_UPDATE_BILLING_ADDRESS_SUCCESS = "USER_UPDATE_BILLING_ADDRESS_SUCCESS";
export const USER_UPDATE_BILLING_ADDRESS_FAILURE = "USER_UPDATE_BILLING_ADDRESS_FAILURE";

export const USER_GET_USER_LOCATION_START = "USER_GET_USER_LOCATION_START";
export const USER_GET_USER_LOCATION_SUCCESS = "USER_GET_USER_LOCATION_SUCCESS";
export const USER_GET_USER_LOCATION_FAILURE = "USER_GET_USER_LOCATION_FAILURE";

// User
export const USER_GET_COMMON_PRODUCTS_START = "USER_GET_COMMON_PRODUCTS_START";
export const USER_GET_COMMON_PRODUCTS_SUCCESS = "USER_GET_COMMON_PRODUCTS_SUCCESS";
export const USER_GET_COMMON_PRODUCTS_FAILURE = "USER_GET_COMMON_PRODUCTS_FAILURE";

export const USER_GET_POPULAR_PRODUCTS_START = "USER_GET_POPULAR_PRODUCTS_START";
export const USER_GET_POPULAR_PRODUCTS_SUCCESS = "USER_GET_POPULAR_PRODUCTS_SUCCESS";
export const USER_GET_POPULAR_PRODUCTS_FAILURE = "USER_GET_POPULAR_PRODUCTS_FAILURE";

export const USER_GET_ACTIVE_CATEGORIES_START = "USER_GET_ACTIVE_CATEGORIES_START";
export const USER_GET_ACTIVE_CATEGORIES_SUCCESS = "USER_GET_ACTIVE_CATEGORIES_SUCCESS";
export const USER_GET_ACTIVE_CATEGORIES_FAILURE = "USER_GET_ACTIVE_CATEGORIES_FAILURE";

export const USER_GET_POPULAR_ACTIVE_CATEGORIES_START = "USER_GET_POPULAR_ACTIVE_CATEGORIES_START";
export const USER_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS = "USER_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS";
export const USER_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE = "USER_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE";

export const USER_SEARCH_PRODUCTS_START = "USER_SEARCH_PRODUCTS_START";
export const USER_SEARCH_PRODUCTS_SUCCESS = "USER_SEARCH_PRODUCTS_SUCCESS";
export const USER_SEARCH_PRODUCTS_FAILURE = "USER_SEARCH_PRODUCTS_FAILURE";

export const USER_SEARCH_PRODUCTS_RESET = "USER_SEARCH_PRODUCTS_RESET";

export const USER_SEARCH_KEYWORDS_START = "USER_SEARCH_KEYWORDS_START";
export const USER_SEARCH_KEYWORDS_SUCCESS = "USER_SEARCH_KEYWORDS_SUCCESS";
export const USER_SEARCH_KEYWORDS_FAILURE = "USER_SEARCH_KEYWORDS_FAILURE";

export const USER_SEARCH_KEYWORDS_RESET = "USER_SEARCH_KEYWORDS_RESET";

export const USER_GET_PRODUCT_PRICES_UPC_START = "USER_GET_PRODUCT_PRICES_UPC_START";
export const USER_GET_PRODUCT_PRICES_UPC_SUCCESS = "USER_GET_PRODUCT_PRICES_UPC_SUCCESS";
export const USER_GET_PRODUCT_PRICES_UPC_FAILURE = "USER_GET_PRODUCT_PRICES_UPC_FAILURE";

export const USER_GET_CART_PRODUCTS_START = "USER_GET_CART_PRODUCTS_START";
export const USER_GET_CART_PRODUCTS_SUCCESS = "USER_GET_CART_PRODUCTS_SUCCESS";
export const USER_GET_CART_PRODUCTS_FAILURE = "USER_GET_CART_PRODUCTS_FAILURE";

export const USER_ADD_PRODUCT_TO_CART_START = "USER_ADD_PRODUCT_TO_CART_START";
export const USER_ADD_PRODUCT_TO_CART_SUCCESS = "USER_ADD_PRODUCT_TO_CART_SUCCESS";
export const USER_ADD_PRODUCT_TO_CART_FAILURE = "USER_ADD_PRODUCT_TO_CART_FAILURE";

export const USER_REMOVE_PRODUCT_FROM_CART_START = "USER_REMOVE_PRODUCT_FROM_CART_START";
export const USER_REMOVE_PRODUCT_FROM_CART_SUCCESS = "USER_REMOVE_PRODUCT_FROM_CART_SUCCESS";
export const USER_REMOVE_PRODUCT_FROM_CART_FAILURE = "USER_REMOVE_PRODUCT_FROM_CART_FAILURE";

export const USER_INCREMENT_PRODUCT_IN_CART_START = "USER_INCREMENT_PRODUCT_IN_CART_START";
export const USER_INCREMENT_PRODUCT_IN_CART_SUCCESS = "USER_INCREMENT_PRODUCT_IN_CART_SUCCESS";
export const USER_INCREMENT_PRODUCT_IN_CART_FAILURE = "USER_INCREMENT_PRODUCT_IN_CART_FAILURE";

export const USER_DECREMENT_PRODUCT_IN_CART_START = "USER_DECREMENT_PRODUCT_IN_CART_START";
export const USER_DECREMENT_PRODUCT_IN_CART_SUCCESS = "USER_DECREMENT_PRODUCT_IN_CART_SUCCESS";
export const USER_DECREMENT_PRODUCT_IN_CART_FAILURE = "USER_DECREMENT_PRODUCT_IN_CART_FAILURE";

export const USER_CHECKOUT_PRODUCT_ORDER_START = "USER_CHECKOUT_PRODUCT_ORDER_START";
export const USER_CHECKOUT_PRODUCT_ORDER_SUCCESS = "USER_CHECKOUT_PRODUCT_ORDER_SUCCESS";
export const USER_CHECKOUT_PRODUCT_ORDER_FAILURE = "USER_CHECKOUT_PRODUCT_ORDER_FAILURE";

export const USER_GENERATE_PRODUCT_ORDER_START = "USER_GENERATE_PRODUCT_ORDER_START";
export const USER_GENERATE_PRODUCT_ORDER_SUCCESS = "USER_GENERATE_PRODUCT_ORDER_SUCCESS";
export const USER_GENERATE_PRODUCT_ORDER_FAILURE = "USER_GENERATE_PRODUCT_ORDER_FAILURE";

export const USER_GET_WISHLIST_PRODUCTS_START = "USER_GET_WISHLIST_PRODUCTS_START";
export const USER_GET_WISHLIST_PRODUCTS_SUCCESS = "USER_GET_WISHLIST_PRODUCTS_SUCCESS";
export const USER_GET_WISHLIST_PRODUCTS_FAILURE = "USER_GET_WISHLIST_PRODUCTS_FAILURE";

export const USER_GET_SHOP_WISHLIST_PRODUCTS_START = "USER_GET_SHOP_WISHLIST_PRODUCTS_START";
export const USER_GET_SHOP_WISHLIST_PRODUCTS_SUCCESS = "USER_GET_SHOP_WISHLIST_PRODUCTS_SUCCESS";
export const USER_GET_SHOP_WISHLIST_PRODUCTS_FAILURE = "USER_GET_SHOP_WISHLIST_PRODUCTS_FAILURE";

export const USER_ADD_PRODUCT_TO_WISHLIST_START = "USER_ADD_PRODUCT_TO_WISHLIST_START";
export const USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS = "USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS";
export const USER_ADD_PRODUCT_TO_WISHLIST_FAILURE = "USER_ADD_PRODUCT_TO_WISHLIST_FAILURE";

export const USER_REMOVE_PRODUCT_FROM_WISHLIST_START = "USER_REMOVE_PRODUCT_FROM_WISHLIST_START";
export const USER_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS = "USER_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS";
export const USER_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE = "USER_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE";

export const USER_GET_STORE_LIST_START = "USER_GET_STORE_LIST_START";
export const USER_GET_STORE_LIST_SUCCESS = "USER_GET_STORE_LIST_SUCCESS";
export const USER_GET_STORE_LIST_FAILURE = "USER_GET_STORE_LIST_FAILURE";

export const USER_SEARCH_SHOPS_START = "USER_SEARCH_SHOPS_START";
export const USER_SEARCH_SHOPS_SUCCESS = "USER_SEARCH_SHOPS_SUCCESS";
export const USER_SEARCH_SHOPS_FAILURE = "USER_SEARCH_SHOPS_FAILURE";

export const USER_GET_STORE_FAVLIST_START = "USER_GET_STORE_FAVLIST_START";
export const USER_GET_STORE_FAVLIST_SUCCESS = "USER_GET_STORE_FAVLIST_SUCCESS";
export const USER_GET_STORE_FAVLIST_FAILURE = "USER_GET_STORE_FAVLIST_FAILURE";

export const USER_ADD_STORE_TO_FAVLIST_START = "USER_ADD_STORE_TO_FAVLIST_START";
export const USER_ADD_STORE_TO_FAVLIST_SUCCESS = "USER_ADD_STORE_TO_FAVLIST_SUCCESS";
export const USER_ADD_STORE_TO_FAVLIST_FAILURE = "USER_ADD_STORE_TO_FAVLIST_FAILURE";

export const USER_REMOVE_STORE_FROM_FAVLIST_START = "USER_REMOVE_STORE_FROM_FAVLIST_START";
export const USER_REMOVE_STORE_FROM_FAVLIST_SUCCESS = "USER_REMOVE_STORE_FROM_FAVLIST_SUCCESS";
export const USER_REMOVE_STORE_FROM_FAVLIST_FAILURE = "USER_REMOVE_STORE_FROM_FAVLIST_FAILURE";

export const USER_GET_SHOP_INFO_BYID_START = "USER_GET_SHOP_INFO_BYID_START";
export const USER_GET_SHOP_INFO_BYID_SUCCESS = "USER_GET_SHOP_INFO_BYID_SUCCESS";
export const USER_GET_SHOP_INFO_BYID_FAILURE = "USER_GET_SHOP_INFO_BYID_FAILURE";

// All order list and order detail
export const USER_GET_ORDERLIST_PRODUCTS_START = "USER_GET_ORDERLIST_PRODUCTS_START";
export const USER_GET_ORDERLIST_PRODUCTS_SUCCESS = "USER_GET_ORDERLIST_PRODUCTS_SUCCESS";
export const USER_GET_ORDERLIST_PRODUCTS_FAILURE = "USER_GET_ORDERLIST_PRODUCTS_FAILURE";

export const USER_GET_ORDER_DETAIL_START = "USER_GET_ORDER_DETAIL_START";
export const USER_GET_ORDER_DETAIL_SUCCESS = "USER_GET_ORDER_DETAIL_SUCCESS";
export const USER_GET_ORDER_DETAIL_FAILURE = "USER_GET_ORDER_DETAIL_FAILURE";

// Shop specific order list and order detail
export const USER_GET_SHOP_ORDERLIST_PRODUCTS_START = "USER_GET_SHOP_ORDERLIST_PRODUCTS_START";
export const USER_GET_SHOP_ORDERLIST_PRODUCTS_SUCCESS = "USER_GET_SHOP_ORDERLIST_PRODUCTS_SUCCESS";
export const USER_GET_SHOP_ORDERLIST_PRODUCTS_FAILURE = "USER_GET_SHOP_ORDERLIST_PRODUCTS_FAILURE";

export const USER_GET_SHOP_ORDER_DETAIL_START = "USER_GET_SHOP_ORDER_DETAIL_START";
export const USER_GET_SHOP_ORDER_DETAIL_SUCCESS = "USER_GET_SHOP_ORDER_DETAIL_SUCCESS";
export const USER_GET_SHOP_ORDER_DETAIL_FAILURE = "USER_GET_SHOP_ORDER_DETAIL_FAILURE";

// Admin actions

// Admin - Orders

export const ADMIN_GET_COMMON_ORDERS_START = "ADMIN_GET_COMMON_ORDERS_START";
export const ADMIN_GET_COMMON_ORDERS_SUCCESS = "ADMIN_GET_COMMON_ORDERS_SUCCESS";
export const ADMIN_GET_COMMON_ORDERS_FAILURE = "ADMIN_GET_COMMON_ORDERS_FAILURE";

export const ADMIN_GET_STORE_ORDERS_START = "ADMIN_GET_STORE_ORDERS_START";
export const ADMIN_GET_STORE_ORDERS_SUCCESS = "ADMIN_GET_STORE_ORDERS_SUCCESS";
export const ADMIN_GET_STORE_ORDERS_FAILURE = "ADMIN_GET_STORE_ORDERS_FAILURE";

export const ADMIN_SEARCH_ORDERS_START = "ADMIN_SEARCH_ORDERS_START";
export const ADMIN_SEARCH_ORDERS_SUCCESS = "ADMIN_SEARCH_ORDERS_SUCCESS";
export const ADMIN_SEARCH_ORDERS_FAILURE = "ADMIN_SEARCH_ORDERS_FAILURE";

export const ADMIN_SEARCH_ORDERS_KEYWORDS_START = "ADMIN_SEARCH_ORDERS_KEYWORDS_START";
export const ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS = "ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS";
export const ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE = "ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE";

// Admin - Products

export const ADMIN_GET_STORE_PRIVATE_PRODUCTS_START = "ADMIN_GET_STORE_PRIVATE_PRODUCTS_START";
export const ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS = "ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS";
export const ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE = "ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE";

export const ADMIN_GET_STORE_PRIVATE_CATEGORIES_START = "ADMIN_GET_STORE_PRIVATE_CATEGORIES_START";
export const ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS = "ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS";
export const ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE = "ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE";

export const ADMIN_GET_COMMON_PRODUCTS_START = "ADMIN_GET_COMMON_PRODUCTS_START";
export const ADMIN_GET_COMMON_PRODUCTS_SUCCESS = "ADMIN_GET_COMMON_PRODUCTS_SUCCESS";
export const ADMIN_GET_COMMON_PRODUCTS_FAILURE = "ADMIN_GET_COMMON_PRODUCTS_FAILURE";

export const ADMIN_GET_COMMON_CATEGORIES_START = "ADMIN_GET_COMMON_CATEGORIES_START";
export const ADMIN_GET_COMMON_CATEGORIES_SUCCESS = "ADMIN_GET_COMMON_CATEGORIES_SUCCESS";
export const ADMIN_GET_COMMON_CATEGORIES_FAILURE = "ADMIN_GET_COMMON_CATEGORIES_FAILURE";

export const ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START = "ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START";
export const ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS = "ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS";
export const ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE = "ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE";

export const ADMIN_SEARCH_PRODUCTS_START = "ADMIN_SEARCH_PRODUCTS_START";
export const ADMIN_SEARCH_PRODUCTS_SUCCESS = "ADMIN_SEARCH_PRODUCTS_SUCCESS";
export const ADMIN_SEARCH_PRODUCTS_FAILURE = "ADMIN_SEARCH_PRODUCTS_FAILURE";

export const ADMIN_SEARCH_PRODUCTS_KEYWORDS_START = "ADMIN_SEARCH_PRODUCTS_KEYWORDS_START";
export const ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS = "ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS";
export const ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE = "ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE";

export const ADMIN_ADD_COMMON_PRODUCT_START = "ADMIN_ADD_COMMON_PRODUCT_START";
export const ADMIN_ADD_COMMON_PRODUCT_SUCCESS = "ADMIN_ADD_COMMON_PRODUCT_SUCCESS";
export const ADMIN_ADD_COMMON_PRODUCT_FAILURE = "ADMIN_ADD_COMMON_PRODUCT_FAILURE";

export const ADMIN_UPDATE_COMMON_PRODUCT_START = "ADMIN_UPDATE_COMMON_PRODUCT_START";
export const ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS = "ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS";
export const ADMIN_UPDATE_COMMON_PRODUCT_FAILURE = "ADMIN_UPDATE_COMMON_PRODUCT_FAILURE";

export const ADMIN_ADD_COMMON_PRODUCT_IMAGE_START = "ADMIN_ADD_COMMON_PRODUCT_IMAGE_START";
export const ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS = "ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS";
export const ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE = "ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE";

export const ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START = "ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START";
export const ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS = "ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS";
export const ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE = "ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE";

export const ADMIN_ADD_COMMON_CATEGORY_START = "ADMIN_ADD_COMMON_CATEGORY_START";
export const ADMIN_ADD_COMMON_CATEGORY_SUCCESS = "ADMIN_ADD_COMMON_CATEGORY_SUCCESS";
export const ADMIN_ADD_COMMON_CATEGORY_FAILURE = "ADMIN_ADD_COMMON_CATEGORY_FAILURE";

export const ADMIN_UPDATE_COMMON_CATEGORY_START = "ADMIN_UPDATE_COMMON_CATEGORY_START";
export const ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS = "ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS";
export const ADMIN_UPDATE_COMMON_CATEGORY_FAILURE = "ADMIN_UPDATE_COMMON_CATEGORY_FAILURE";

export const ADMIN_ADD_COMMON_CATEGORY_IMAGE_START = "ADMIN_ADD_COMMON_CATEGORY_IMAGE_START";
export const ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS = "ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS";
export const ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE = "ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE";

export const ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START = "ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START";
export const ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS = "ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS";
export const ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE = "ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE";

// Admin shops

export const ADMIN_GET_LOCATION_STORE_LIST_START = "ADMIN_GET_LOCATION_STORE_LIST_START";
export const ADMIN_GET_LOCATION_STORE_LIST_SUCCESS = "ADMIN_GET_LOCATION_STORE_LIST_SUCCESS";
export const ADMIN_GET_LOCATION_STORE_LIST_FAILURE = "ADMIN_GET_LOCATION_STORE_LIST_FAILURE";

export const ADMIN_SEARCH_SHOPS_START = "ADMIN_SEARCH_SHOPS_START";
export const ADMIN_SEARCH_SHOPS_SUCCESS = "ADMIN_SEARCH_SHOPS_SUCCESS";
export const ADMIN_SEARCH_SHOPS_FAILURE = "ADMIN_SEARCH_SHOPS_FAILURE";

export const ADMIN_SEARCH_SHOPS_KEYWORD_START = "ADMIN_SEARCH_SHOPS_KEYWORD_START";
export const ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS = "ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS";
export const ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE = "ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE";

export const ADMIN_GET_SHOP_INFO_BYID_START = "ADMIN_GET_SHOP_INFO_BYID_START";
export const ADMIN_GET_SHOP_INFO_BYID_SUCCESS = "ADMIN_GET_SHOP_INFO_BYID_SUCCESS";
export const ADMIN_GET_SHOP_INFO_BYID_FAILURE = "ADMIN_GET_SHOP_INFO_BYID_FAILURE";

export const ADMIN_UPDATE_STORE_STATUS_START = "ADMIN_UPDATE_STORE_STATUS_START";
export const ADMIN_UPDATE_STORE_STATUS_SUCCESS = "ADMIN_UPDATE_STORE_STATUS_SUCCESS";
export const ADMIN_UPDATE_STORE_STATUS_FAILURE = "ADMIN_UPDATE_STORE_STATUS_FAILURE";

// Admin subscription

export const ADMIN_GET_ALL_SUBSCRIPTIONS_START = "GET_ALL_SUBSCRIPTIONS_START";
export const ADMIN_GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const ADMIN_GET_ALL_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const ADMIN_ADD_SUBSCRIPTION_PLAN_START = "ADMIN_ADD_SUBSCRIPTION_PLAN_START";
export const ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS = "ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS";
export const ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE = "ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE";

export const ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START = "ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START";
export const ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS = "ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS";
export const ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE = "ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE";

// Get Products

export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";
