import * as React from "react";
import { Form, Input, Button, Alert, Divider } from "components/Atoms";
import * as ActionTypes     from 'constants/ActionTypes';

const PasswordResetForm = ({resetPassword, showLogin, showRegister, serviceError, serviceCode, reqStatus}) => {
  const [form] = Form.useForm();

  const onFinish = (email) => {
    form.validateFields().then((email) => {
      resetPassword(email);
    });
  };

  return (
    <div className="form reset-password">
      <h3>Reset Password</h3>
      <Form
        name="reset-password-form"
        form={form}
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Form.Item
          className="login-input"
          label="Registered Email Address"
          name="email"
          rules={[{ required: true, message: "Please a valid email Id" }]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          style={{ width: "50%" }}
          onClick={onFinish}
        >
          Reset Password
        </Button>
        {serviceError && reqStatus === ActionTypes.RESET_PASSWORD_FAILURE && (
            <Alert
                className="error-message"
                message="Unable to process Request"
                type="error"
                description="Please try again later"
                showIcon
            />
        )}
          { serviceCode && serviceCode !== 200 && (
              <Alert
                  className="message"
                  message={serviceError}
                  type="error"
                  showIcon
              />
          )}
      </Form.Item>
    <Divider/>
    Back to :
      <a
          type="link"
          htmlType="button"
          onClick={showLogin}
      >
        Login
      </a>
    </div>
  );
};

export default PasswordResetForm;
