//  @flow
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Page,
  Notification,
  Pace,
} from "components/Atoms";
import {
  Container, Box, Card
} from "@mui/material";
import { H4 } from 'components/CustomTypography';

import * as ActionTypes from "constants/ActionTypes";
import * as Actions from "actions/seller/seller.store";

import AddressPreview from "components/elements/seller/AddressPreview";
import AddressEditor from "components/elements/seller/AddressEditor";

import "./styles.scss";

const StoreProfileAddress = (props) => {

  const [state, setState] = useState({ addressMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopAddress,
    getStoreAddress, updateStoreAddress,
  } = props;

  useEffect(() => {
    getStoreAddress();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_ADDRESS_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { addressMode, storeAddress = {} } = state;

  const handleAddressEdit = (address) => {
    setState({ addressMode: "EDIT" });
    setState({
      storeAddress: address,
    });
  };

  const handleAddressEditCancel = (mode) => (e) => {
    setState({ addressMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateAddressCall = (address) => {
    setState({ addressMode: "PREVIEW" });
    updateStoreAddress(address);
  };

  return (
    <Container>
      <Box m={2}>
        <Card>
          <Box>
      {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <H4 sx={{
          m:2
        }}>Shop Address</H4>
        {addressMode === "PREVIEW" ? (
          <AddressPreview
              isLoading={isLoading}
              address={shopAddress}
            editAddress={() => handleAddressEdit(shopAddress)}
            cancelEdit={handleAddressEditCancel("PREVIEW")}
          />
        ) : (
          <AddressEditor
            setState={(event, field) => setState({
              ...state,
              storeAddress: {
                ...storeAddress,
                [field]: event.target.value,
              },
            })}
            storeAddress={storeAddress}
            updateAddressCall={updateAddressCall}
            cancelEdit={handleAddressEditCancel("PREVIEW")}
          />
        )}
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

StoreProfileAddress.propTypes = {
  history: PropTypes.object.isRequired,
  shopAddress: PropTypes.object.isRequired,
  updateStoreAddress: PropTypes.func.isRequired,
  getStoreAddress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const _StoreProfileAddress = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopAddress: store.storeAddress,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileAddress);

export { _StoreProfileAddress as default };
