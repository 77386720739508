/* eslint-disable no-shadow */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import {
  Box, Typography, Card, IconButton, Container, Divider,
} from "@mui/material";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import IndeterminateCheckBoxSharpIcon from "@mui/icons-material/IndeterminateCheckBoxSharp";
import ButtonComponent from "components/Button";
import MockProductList from "components/elements/main/MockProductList";
import { H6 } from "components/CustomTypography";

import * as Actions from "actions";
import { getCartTotal } from "services";

const ShopCart = (props) => {
  const {
    cartItems, symbol, total, getCartProducts, bootupInfo, removeProductFromCart,
    incrementProductInCart, decrementProductInCart, isLoading,
  } = props;

  useEffect(() => {
    getCartProducts(bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite);
  }, []);

  let checkOutLink;

  const getPrice = (symbol, item) => {
    const price = item.seller_price
    - (item.product && item.product.discount
      ? (item.seller_price * (parseInt(item.product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {symbol}
        {price}
        {item.product.discount && (
        <del>
          <span className="money">
            {symbol}
            {item.seller_price}
          </span>
        </del>
        )}
      </Typography>
    );
  };

  const getquantity = (cartItem) => (
    <Box display="flex">
      <IconButton color="primary" size="small" onClick={cartItem.product_quantity > 1 && !isLoading ? () => decrementProductInCart(cartItem, props.bootupInfo.appState.subdomain_shopId, props.bootupInfo.appState.isWebsite) : ""}>
        <IndeterminateCheckBoxSharpIcon />
      </IconButton>
      <Typography className="p-1">{cartItem.product_quantity}</Typography>
      <IconButton color="primary" size="small" onClick={!isLoading ? () => incrementProductInCart(cartItem, props.bootupInfo.appState.subdomain_shopId, props.bootupInfo.appState.isWebsite) : ""}>
        <AddBoxSharpIcon />
      </IconButton>
    </Box>
  );

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  if (bootupInfo.appState.isWebsite) {
    checkOutLink = "/cart/checkout";
  } else {
    checkOutLink = `/shop/${props.bootupInfo.appState.subdomain_shopId}/cart/checkout`;
  }

  const renderOrderItems = () => (
    <Box>
      {cartItems.map((item, index) => (
        <Card key={`${index + 1}`} className="m-3">
          <Box className={item.product.images.length ? "styled-card" : "no-img-product"} width="100%" display="flex" p={1}>
            <Box width="15%" order={1}>
              <img
                alt=""
                className="img-product"
                src={item.product.images && item.product.images[0] ? item.product.images[0].cdn.url : ""}
              />
            </Box>
            <Box width="85%" className="ml-1" order={2} display="flex">
              <Box flexGrow={1}>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                  {item.product.name}
                </Typography>
                {getPrice(symbol, item)}
              </Box>
              <Box>
                <Typography align="right">
                  <IconButton border="1px solid" size="small" color="primary" onClick={() => removeProductFromCart(item, bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite)}>
                    <ClearSharpIcon />
                  </IconButton>
                </Typography>
                <br />
                {getquantity(item)}
              </Box>
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );

  const renderEmptyCart = () => (
    <Container>
      <Box>
        <Typography className="m-2" align="center">
          <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
          <H6>
            Cart is Empty
          </H6>
        </Typography>
      </Box>
    </Container>
  );

  return (
    <div>
      {/* SEO Support */}
      <Helmet>
        <title>{bootupInfo.shopinfo.title}</title>
        <meta name="description" content={bootupInfo.shopinfo.title} />
      </Helmet>
      {/* SEO Support End */}
      {isLoading ? <MockProductList />
        : (cartItems.length > 0
          ? (
            <Container>
              {renderOrderItems()}
              <Container>
                <Typography display="block" align="right" component="caption" variant="h6">{`Total: ${symbol} ${total}`}</Typography>
              </Container>
                <Typography className="m-2" align="center">
                    <ButtonComponent
                        variant="contained"
                        type="primary"
                        name="Checkout"
                        fullWidth
                        onClick={handlePage(checkOutLink)}
                    />
                </Typography>
            </Container>
          )
          : renderEmptyCart())}
    </div>
  );
};

ShopCart.propTypes = {
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeProductFromCart: PropTypes.func.isRequired,
  incrementProductInCart: PropTypes.func.isRequired,
  decrementProductInCart: PropTypes.func.isRequired,
  getCartProducts: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
};

const shopCart = connect(
  ({ cartList, userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
    symbol: userproducts.symbol,
    cartItems: cartList.cart,
    total: getCartTotal(cartList.cart),
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopCart);

export { shopCart as default };
