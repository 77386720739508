// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/core/core.actions";


function* login(action: Object) {
  try {
    const response = yield API.post(`/sellers/login`, action.payload);
    yield put(Actions.loginSuccess(response.response));
  } catch (error) {
    yield put(Actions.loginFailure(error));
  }
}

function* loginPreOTP(action: Object) {
  try {
    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/preotp_login`;
        break;
      case "ADMINAPP":
        url = `/admin/preotp_login`;
        break;
        case "USERAPP":
      default:
        url = `/users/preotp_login`;
        break;
    }

    const response = yield API.post(url, action.payload);
    yield put(Actions.loginPreOTPSuccess(response));
  } catch (error) {
    yield put(Actions.loginPreOTPFailure(error));
  }
}

function* loginPostOTP(action: Object) {
  try {
    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/postotp_login`;
        break;
      case "ADMINAPP":
        url = `/admin/postotp_login`;
        break;
      case "USERAPP":
      default:
        url = `/users/postotp_login`;
        break;
    }

    const response = yield API.post(url, action.payload);
    yield put(Actions.loginPostOTPSuccess(response.response));
  } catch (error) {
    yield put(Actions.loginPostOTPFailure(error));
  }
}

function* Sociallogin(action: Object) {
  try {
    // Needs cookie from Google to request JWT
    const response = yield API.get(`/sellers/login/success`, {
      withCredentials: true
    },  action.payload );
    yield put(Actions.SocialloginSuccess(response.response));
  } catch (error) {
    yield put(Actions.SocialloginFailure(error));
  }
}

function* logout(action: Object) {
  try {
    const response = yield API.post(`/auth/logout`, action.payload);
    yield put(Actions.logoutSuccess(response));
  } catch (error) {
    yield put(Actions.logoutFailure(error));
  }
}

function* resetPassword(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/forgot-password`,
      action.payload
    );

    yield put(Actions.resetPasswordSuccess(response));
  } catch (error) {
    yield put(Actions.resetPasswordFailure(error));
  }
}

function* register(action: Object) {
  try {
    const response = yield API.post(`/sellers/register`, action.payload);
    yield put(Actions.registerSuccess(response));
  } catch (error) {
    yield put(Actions.registerFailure(error));
  }
}

function* getUserProfile(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/account/get-shop-profile`,
      action.payload
    );

    yield put(Actions.getUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getUserProfileFailure(error));
  }
}

function* updateUserUUID(action: Object) {
  try {
    let query = {};

    const Firebase = window.FirebasePlugin;

//    if (Firebase) {
      const usergetUserToken = () =>
          new Promise((resolve, reject) => {
            window.FirebasePlugin.getToken(function(token) {
              resolve(token);
            }, function(error) {
              console.log('Failed to get FCM token', error);
            });

          });

      const token = yield call(usergetUserToken);

 //   }

    const update = yield API.post(`/sellers/account/update-uuid`, { token: token });

    yield put(Actions.updateUserUUIDSuccess(update));
  } catch (error) {
    yield put(Actions.updateUserUUIDFailure(error));
  }
}

function* getShopUserProfile(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/account/get-shop-profile`,
        action.payload
    );

    yield put(Actions.getUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getUserProfileFailure(error));
  }
}

function* getShopAndUserProfile(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-shop-user-profile?shop=`+ searchQuery
    );

    yield put(Actions.getShopAndUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getShopAndUserProfileFailure(error));
  }
}

function* getShopProfile(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-shopinfo?id=`+ searchQuery
    );

    yield put(Actions.getShopProfileSuccess(response));
  } catch (error) {
    yield put(Actions.getShopProfileFailure(error));
  }
}

function* updateUserProfile(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/account/update-profile`,
      action.payload
    );

    yield put(Actions.updateUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.updateUserProfileFailure(error));
  }
}

function* getBillingAddress(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/account/get-billing-address`,
      action.payload
    );

    yield put(Actions.getBillingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.getBillingAddressFailure(error));
  }
}
function* updateBillingAddress(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/account/update-billing-address`,
        action.payload
    );

    yield put(Actions.updateBillingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.updateBillingAddressFailure(error));
  }
}

function* addShippingAddress(action: Object) {
  try {
    const response = yield API.post(
        `/users/account/add-shipping-address`,
        action.payload
    );

    yield put(Actions.addShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.addShippingAddressFailure(error));
  }
}

function* getShippingAddress(action: Object) {
  try {
    const response = yield API.get(
        `/users/account/get-shipping-address`,
        action.payload
    );

    yield put(Actions.getShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.getShippingAddressFailure(error));
  }
}
function* updateShippingAddress(action: Object) {
  try {
    const response = yield API.put(
        `/users/account/update-shipping-address`,
        action.payload
    );

    yield put(Actions.updateShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.updateShippingAddressFailure(error));
  }
}

function* deleteShippingAddress(action: Object) {
  try {
    const response = yield API.post(
        `/users/account/remove-shipping-address`,
        action.payload
    );

    yield put(Actions.deleteShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.deleteShippingAddressFailure(error));
  }
}

function* updateUserPic(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/account/update-profile-pic`,
        action.payload
    );

    yield put(Actions.updateUserPicSuccess(response));
  } catch (error) {
    yield put(Actions.updateUserPicFailure(error));
  }
}
export default function* AppBaseSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.LOGIN_START, login);
  yield takeLatest(ActionTypes.LOGIN_PRE_OTP_START, loginPreOTP);
  yield takeLatest(ActionTypes.LOGIN_POST_OTP_START, loginPostOTP);
  yield takeLatest(ActionTypes.SOCIAL_LOGIN_START, Sociallogin);
  yield takeLatest(ActionTypes.LOGOUT_START, logout);
  yield takeLatest(ActionTypes.REGISTER_START, register);
  yield takeLatest(ActionTypes.RESET_PASSWORD_START, resetPassword);
  yield takeLatest(ActionTypes.GET_USER_PROFILE_START, getUserProfile);
  yield takeLatest(ActionTypes.UPDATE_USER_PROFILE_UUID_START, updateUserUUID);
  yield takeLatest(ActionTypes.GET_SHOP_USER_PROFILE_START, getShopUserProfile);
  yield takeLatest(ActionTypes.GET_SHOP_AND_USER_PROFILE_START, getShopAndUserProfile);
  yield takeLatest(ActionTypes.GET_SHOP_PROFILE_START, getShopProfile);
  yield takeLatest(ActionTypes.UPDATE_USER_PROFILE_START, updateUserProfile);
  yield takeLatest(ActionTypes.GET_BILLING_ADDRESS_START, getBillingAddress);
  yield takeLatest(ActionTypes.UPDATE_BILLING_ADDRESS_START, updateBillingAddress);
  yield takeLatest(ActionTypes.ADD_SHIPPING_ADDRESS_START, addShippingAddress);
  yield takeLatest(ActionTypes.GET_SHIPPING_ADDRESS_START, getShippingAddress);
  yield takeLatest(ActionTypes.UPDATE_SHIPPING_ADDRESS_START, updateShippingAddress);
  yield takeLatest(ActionTypes.DELETE_SHIPPING_ADDRESS_START, deleteShippingAddress);
  yield takeLatest(ActionTypes.UPDATE_USER_PIC_START, updateUserPic);
}
