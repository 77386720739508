import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import CartPage from '../components/elements/main/headers/common/cart-header';
import {removeFromCart, removeProductFromCart} from '../actions';
import {getCartTotal} from '../services';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";

const CartContainer = ({cartlink, checkoutlink, cartList, total, symbol, removeFromCart, removeProductFromCart}) => (
    <section>
        <Link  to='/cart'>
            <IconButton style={{ backgroundColor: "transparent" }}>
                <Badge badgeContent={cartList.length} color="secondary">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
        </Link>
    </section>
)


function mapStateToProps(state) {
    return {
        cartList: state.cartList.cart,
        total: getCartTotal(state.cartList.cart),
        symbol: state.userproducts.symbol,
    }
}

export default connect(mapStateToProps, {removeFromCart, removeProductFromCart})(CartContainer);
