//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
  Input,
  Notification,
  Roller,
  AntdModal,
} from "components/Atoms";

import * as _ from "lodash";

import * as Actions       from "actions/seller/seller.products";


import Product            from "components/elements/seller/Product";
import ProductEntryForm   from "components/elements/seller/ProductEntryForm";
import CategoryBrowser    from "components/elements/seller/CategoryBrowser";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class ProductCatalog extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      bShowProductEntryDialog: false,
      bShowCategoryBrowser: false,
      selectedProduct: null,
    };
    this.filterCategories = _.debounce(this.filterProducts, 1000);
    props.getCommonProducts();
    props.getActiveCategories();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  saveProduct = (product, prodId) => {
    this.props.addProduct(prodId);
  };

  filterProducts = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };
  /* Header actions*/
  showProductEntryDialog = (product) => () => {
    this.setState({ selectedProduct: product, bShowProductEntryDialog: true });
  };
  hideProductEntryDialog = () => {
    this.setState({ selectedProduct: null, bShowProductEntryDialog: false });
  };

  showCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: true });
  };
  hideCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: false });
  };

  setCategory = (category) => {
    this.state.selectedCategory = category;
    this.hideCategoryBrowser();
  };

  render() {
    const { isLoading, productsByCategory, categoryList } = this.props;
    const {
      bShowProductEntryDialog,
      bShowCategoryBrowser,
      selectedProduct,
      selectedCategory
    } = this.state;
    let products = [
      ...productsByCategory.records
    ];
    return (
      <Page className="products-catalog">
        <h2 className="page-title">Product Catalog</h2>
        <div className="content">
          <div className="header">
            <div className="actions">
              <Button
                icon={<GroupOutlined />}
                onClick={this.showCategoryBrowser}
              />
              {selectedCategory ? "Search in category: " + selectedCategory.name : ""}
            </div>
          </div>
          <div className="product-list">
            <Row gutter={[24, 24]}>
              {products.map((product) => {
                return (
                  <Col span={6} key={`pr-item-${product.id}`}>
                    <Product
                      className="product-item"
                      key={product.id}
                      data={product}
                      actions={
                        <>
                          <Button
                            type="primary"
                            onClick={this.showProductEntryDialog(product)}
                          >
                            Add to Store
                          </Button>
                        </>
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <ProductEntryForm
          visible={bShowProductEntryDialog}
          data={selectedProduct}
          onOk={this.saveProduct}
          onCancel={this.hideProductEntryDialog}
        />
        <CategoryBrowser
          title="All Product Categories"
          visible={bShowCategoryBrowser}
          categoryList={categoryList}
          onCategorySelect={this.setCategory}
          onOk={this.hideCategoryBrowser}
          onCancel={this.hideCategoryBrowser}
        />
      </Page>
    );
  }
}
// Mapping Props and State.
const _ProductCatalog = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    productsByCategory: products.productsByCategory,
    categoryList: products.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProductCatalog);

export { _ProductCatalog as default };
