/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container, Card, Box, Typography, IconButton, Button,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MockProductList from "components/elements/main/MockProductList";

import * as Actions from "actions";

const ShopList = (props) => {
  const {
    isLoading, storeswishlist, getStoreFavlist,
  } = props;

  useEffect(() => {
    getStoreFavlist();
  }, []);

  if (isLoading) {
    return <MockProductList />;
  }

  return (
    <div>
      {storeswishlist.length > 0
        ? (
          <Container maxWidth="md">
            {storeswishlist.map((item, index) => (
              <Card key={`${index + 1}`} className="m-3">
                <Box className={item.shop.images.length ? "styled-card" : "no-img-product"} width="100%" display="flex" p={1}>
                  <Box width="20%" order={1}>
                    <img
                      alt=""
                      className="img-product"
                      src={item.shop.images && item.shop.images[0] ? item.shop.images[0].cdn.url : ""}
                    />
                  </Box>
                  <Box width="80%" className="ml-1" order={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography className="styled-link" gutterBottom variant="h6" component="p">
                        {item.shop.title}
                      </Typography>
                      <Typography display="block" color="secondary" variant="caption">
                        {item.shop.subdomain}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton size="small" onClick={() => props.addStoreToFavlist(item, 1)}>
                        <FavoriteBorderIcon color="secondary" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => props.removeStoreFromFavlist(item)}
                      >
                        <HighlightOffIcon color="secondary" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Card>
            ))}
            <Container>
              <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                <Button to={() => props.history.push(`${process.env.PUBLIC_URL}/cart`)} className="btn m-1 btn-solid">View cart</Button>
                <Button to={() => props.history.push(`${process.env.PUBLIC_URL}/left-sidebar/collection`)} className="btn m-1 btn-solid">continue shopping</Button>
              </Box>
            </Container>
            <br />
            <br />
          </Container>
        )
        : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <h3>
                        <strong>No shop added to favourite list</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
    </div>
  );
};

ShopList.defaultProps = {
  isLoading: false,
};

ShopList.propTypes = {
  isLoading: PropTypes.bool,
  storeswishlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  getStoreFavlist: PropTypes.func.isRequired,
  addStoreToFavlist: PropTypes.func.isRequired,
  removeStoreFromFavlist: PropTypes.func.isRequired,
};

const shopList = connect(
  ({ userstoreList, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userstoreList.reqStatus,
    isLoading: userstoreList.isLoading,
    serviceError: userstoreList.serviceError,
    storeswishlist: userstoreList.storeswishlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopList);

export default shopList;
