import * as React from "react";
import PropTypes from "prop-types";
import { Box, Container, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { schema, getTextfieldData, getButtonData } from "./helper";

const ShopEditor = ({
  setState, address, addShopToAccountClicked, cancelEdit,
}) => {
  const [blur, setblur] = React.useState({
    title: false,
    cityname: false,
    areaname: false,
    addressline: false,
    pincode: false,
    phone: false,
  });

  const {
    title, cityname, areaname, addressline, pincode, phone, placeid,
  } = address;

  const onFinish = () => {
    addShopToAccountClicked({
      title, cityname, areaname, addressline, pincode, phone
    });
  };

  const handleChange = (event, feild) => {
    setState(event, feild);
  };

  const hasError = () => {
    try {
      return !schema.validateSync(address);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.title || blur.cityname || blur.areaname || blur.addressline || blur.pincode || blur.phone);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, address);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(title, cityname, areaname, addressline, pincode, phone).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onBlur={() => handleBlur(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              variant={element.variant}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

ShopEditor.propTypes = {
  address: PropTypes.objectOf(PropTypes.any).isRequired,
  addShopToAccountClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ShopEditor;
