import React from "react";
import { Box } from '@mui/material';
import PropTypes from "prop-types";

const FooterOne = ({ children, ...props }) => (
    <Box display="flex" {...props}>
        {children}
    </Box>
)

FooterOne.defaultProps = {
    display: 'flex',
}

FooterOne.propTypes = {
    display: PropTypes.string,
};

export default FooterOne
