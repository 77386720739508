/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container, Typography, IconButton, Box,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import * as Actions from "actions";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ProductDetail = (props) => {
  const {
    match,
    bootupInfo,
    addProductToCart,
    products,
    usergetCommonProducts,
    history,
  } = props;

  console.log(props);
  const [share, setShare] = useState(false);
  const { id } = match.params;

  useEffect(() => {
    usergetCommonProducts(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite);
  }, []);
  const product = products.find((element) => element.id === id);

  if (!product) {
    return (
      <Typography variant="h3">
        Not Found
      </Typography>
    );
  }

  return (
    <Container>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content quick-view-modal">
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6  col-xs-12">
                <div className="quick-view-img">
                  <img src={`${product.images && product.images[0] ? product.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 rtl-text">
                <div className="product-right">
                  <Typography variant="h2">
                    {product.name}
                  </Typography>
                  <div className="border-product">
                    <Typography variant="h6">Product Details</Typography>
                    <Typography>{product.shortDetails}</Typography>
                  </div>
                  <Typography align="right">
                    <IconButton onClick={() => setShare(!share)} size="large">
                      <ShareIcon />
                    </IconButton>
                  </Typography>
                  {
                      share && (
                        <>
                          <EmailShareButton url={window.location.href}>
                            <IconButton size="small">
                              <MailIcon />
                            </IconButton>
                          </EmailShareButton>
                          <FacebookShareButton url={window.location.href}>
                            <IconButton size="small">
                              <FacebookIcon />
                            </IconButton>
                          </FacebookShareButton>
                          <TwitterShareButton url={window.location.href}>
                            <IconButton size="small">
                              <TwitterIcon />
                            </IconButton>
                          </TwitterShareButton>
                          <WhatsappShareButton url={window.location.href}>
                            <IconButton size="small">
                              <WhatsAppIcon />
                            </IconButton>
                          </WhatsappShareButton>
                        </>
                      )
                    }
                  <Box className="product-buttons">
                    <button
                      className="btn m-2 btn-solid"
                      type="button"
                      onClick={() => () => addProductToCart(product,
                        bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite)}
                    >
                      add to cart
                    </button>
                    <button
                      className="btn m-2 btn-solid"
                      type="button"
                      onClick={() => { history.goBack(); }}
                    >
                      go back
                    </button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </Container>
  );
};

ProductDetail.propTypes = {
  addProductToCart: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  usergetCommonProducts: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ProductDetailComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.categoryproducts,
    categoryList: userproducts.categoryList,
    searchkeywords: userproducts.searchkeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProductDetail);

export { ProductDetailComponent as default };
