import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';


import makeStyles from '@mui/styles/makeStyles';
import {Box, Card, Container, Typography} from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  rootActive: ({ accentColor = '#ddd' }) => ({
    backgroundColor: accentColor,
  }),
  icon: ({ accentColor = '#ddd' }) => ({
    minWidth: 40,
    opacity: 0.6,
    color: accentColor,
  }),
  primary: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily:
        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    fontWeight: 900,
    opacity: 0.6,
  },
  iconActive: () => ({
    opacity: 0.87,
  }),
  primaryActive: {
    opacity: 1,
  },
}));

const MockProductList = ({
  active,
  accentColor,
  icon,
  primaryText,
  secondaryText,
}) => {
  const styles = useStyles({ accentColor });
  return (
      <div>
          <Container>
              <Box alignContent="center" width="100%" display="flex" p={2}>
                  <Box width="30%" order={1}>
                      <Typography variant="h6" align="center"></Typography>
                  </Box>
                  <Box width="45%" order={3}>
                      <Typography variant="h6" align="center"></Typography>
                  </Box>
                  <Box width="25%" order={4}>
                      <Typography variant="h6" align="center"></Typography>
                  </Box>
              </Box>
              <Card key={1} className="m-2">
                  <Box alignContent="center" width="100%" display="flex" p={2}>
                      <Box width="30%" order={1}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                      <Box width="45%" order={3}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                      <Box width="25%" order={4}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                  </Box>
              </Card>
              <Card key={2} className="m-2">
                  <Box alignContent="center" width="100%" display="flex" p={2}>
                      <Box width="30%" order={1}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                      <Box width="45%" order={3}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                      <Box width="25%" order={4}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                  </Box>
              </Card>
              <Card key={3} className="m-2">
                  <Box alignContent="center" width="100%" display="flex" p={2}>
                      <Box width="30%" order={1}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                      <Box width="45%" order={3}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                      <Box width="25%" order={4}>
                          <Typography align="center">
                          </Typography>
                      </Box>
                  </Box>
              </Card>
          </Container>
      </div>
  );
};

MockProductList.propTypes = {
  active: PropTypes.bool,
  accentColor: PropTypes.string,
  icon: PropTypes.node,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
};
MockProductList.defaultProps = {
  active: true,
  accentColor: undefined,
  icon: undefined,
  primaryText: undefined,
  secondaryText: undefined,
};

export default MockProductList;
