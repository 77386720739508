//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
    Pace
} from "components/Atoms";

import * as Actions       from "actions/seller/seller.store";

import "./styles.scss";

import ShopImageEditor from "components/widgets/seller/ShopImageEditor";
// Component Imports

type Props = {};

type State = {};

class StoreProfileImages extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pictureMode: "PREVIEW"
    };
    props.getStoreImages();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;
    if (reqStatus == prevProps.reqStatus) return;
    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  handleImagesEdit = (mode) => (e) => {
    this.setState({ pictureMode: mode });
  };

  handleImagesEditCancel = (mode) => (e) => {
    this.setState({ pictureMode: mode });
    this.props.history.push({
      pathname: '/shop'
    });
  }

  render() {
    const {
      isLoading,
      reqStatus,
      storeImages,
      addStoreImage,
        removeStoreImage,
    } = this.props;
    const { pictureMode } = this.state;

    return (
      <Page className="subscriptions-home">
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px"/>}
        <div className="panel--white">
          <h4>Shop Pictures</h4>
          {pictureMode === "PREVIEW" ? (
              <ShopImageEditor
                  storeImages={storeImages}
                  addStoreImage={addStoreImage}
                  removeStoreImage={removeStoreImage}
                  cancelEdit={this.handleImagesEditCancel("EDIT")}
              />
          ) : (
              <ShopImageEditor
                  storeImages={storeImages}
                  cancelEdit={this.handleImagesEditCancel("PREVIEW")}
              />
          )}
        </div>
      </Page>
    );
  }
}
// Mapping Props and State.
const _StoreProfileImages = connect(
  ({ store, appBase }) => ({
    appBase: appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeImages: store.storeImages,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StoreProfileImages);

export { _StoreProfileImages as default };
