/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */

import CollectionLeftSidebarShop from "../components/pages/user/collections/CollectionLeftSidebarShop";
import CollectionShopsSidebar from "../components/pages/user/collections/CollectionShopsSidebar";

// User or Marketplace site
import UserMain from "../components/pages/user/home/UserMain";
import UserSearch from "../components/pages/user/home/UserSearch";
// Shop
import ShopViewComponent from "../components/pages/user/shop/ShopView";
// Products
import ShopCategoryProduct from "../components/pages/user/products/ShopCategoryProduct";
import ProductList from "../components/pages/user/products/ProductList";
// Orders
import orderList from "../components/pages/user/orders/orderlist";
import orderDetail from "../components/pages/user/orders/orderdetail";
import shopOrderList from "../components/pages/user/orders/shoporderlist";
import ShopOrderDetail from "../components/pages/user/orders/shoporderdetail";
// Favourites
import wishList from "../components/pages/user/favourites/wishlist";
import shopWishList from "../components/pages/user/favourites/shopwishlist";
import shopList from "../components/pages/user/favourites/shoplist";
// Checkout
import shopCart from "../components/pages/user/checkout/shopCart";
import shopCheckOut from "../components/pages/user/checkout/shopCheckout";
import shopOrderSuccess from "../components/pages/user/checkout/shopCheckout/success-page";

// CHANGE THIS TO PAGE
import UserBarCodeScanner from "../components/elements/user/UserBarCodeScanner";

export const MainSiteRoutes = [

  // User main page
  { path: "/user", component: UserMain, exact: true },
  { path: "/user/search/:query", component: UserSearch, exact: true },
  { path: "/user/search", component: UserSearch, exact: true },
  { path: "/user/shops/search", component: CollectionShopsSidebar, exact: true },
  { path: "/user/wishlist", component: wishList, exact: true },
  { path: "/user/orderlist", component: orderList, exact: true },
  { path: "/user/order/:orderId", component: orderDetail, exact: true },
  { path: "/user/storelist", component: shopList },
  { path: "/user/scan", component: UserBarCodeScanner, exact: true },
	// All products - main site
  { path: "/user/products", component: ProductList, exact: true },
  { path: "/user/stores", component: ShopViewComponent, exact: true },

  // User shop pages on main site
	// Common with shop/seller site on seller site
  { path: "/user/shop/:shopId", component: CollectionLeftSidebarShop, exact: true },
  { path: "/user/shop/:shopId/search/:query", component: CollectionLeftSidebarShop, exact: true },
	// Products - common
  { path: "/category/:category", component: ShopCategoryProduct, exact: true },
	// Orders - common
  { path: "/user/shop/:shopId/orders", component: shopOrderList, exact: true },
  { path: "/user/shop/:shopId/order/:orderId", component: ShopOrderDetail, exact: true },
	// Favourites - products in shop - common
  { path: "/user/shop/:shopId/wishlist", component: shopWishList, exact: true },
	// Checkout - common
  { path: "/user/shop/:shopId/cart", component: shopCart, exact: true },
  { path: "/user/shop/:shopId/checkout", component: shopCheckOut },
  { path: "/user/shop/:shopId/order-success", component: shopOrderSuccess },
];
