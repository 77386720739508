//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
  Alert,
} from "components/Atoms";
import * as _ from "lodash";

import * as Actions         from "actions/core/core.actions";
import * as ActionTypes     from 'constants/ActionTypes';

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class LoginSocial extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    props.Sociallogin();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      bootupInfo,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (reqStatus === prevProps.reqStatus) return;
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
        break;
      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  render() {
    const { isLoading, userDetails } = this.props;
    return (
      <section className="page-home">
        <div className="note line">
          <span className="title bold">{userDetails.name}</span>
        </div>
        <div style={{ marginBottom: "10px" }} />

      </section>
    );
  }
}
// Mapping Props and State.
const _LoginSocial = connect(
  ({ stores, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(LoginSocial);

export { _LoginSocial as default };
