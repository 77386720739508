/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// @flow

// SellerApp routes

import SearchOrderList from "../components/pages/seller/orders/SearchOrderList";
import OrdersAll from "../components/pages/seller/orders/OrdersAll";
import OrdersNew from "../components/pages/seller/orders/OrdersNew";
import OrdersAccepted from "../components/pages/seller/orders/OrdersAccepted";
import OrdersOpen from "../components/pages/seller/orders/OrdersOpen";
import OrdersDelivery from "../components/pages/seller/orders/OrdersDelivery";
import OrdersPickup from "../components/pages/seller/orders/OrdersPickup";
import OrdersReadyPickup from "../components/pages/seller/orders/OrdersReadyPickup";
import OrdersReadyDelivery from "../components/pages/seller/orders/OrdersReadyDelivery";
import OrdersPicked from "../components/pages/seller/orders/OrdersPicked";
import OrdersDelivered from "../components/pages/seller/orders/OrdersDelivered";
import OrdersRejected from "../components/pages/seller/orders/OrdersRejected";

import PrivateProducts from "../components/pages/seller/products/PrivateProducts";
import PrivateCategories from "../components/pages/seller/products/PrivateCategories";
import PrivateProductsByCategory from "../components/pages/seller/products/PrivateProductsByCategory";
import BrandedProductsMain from "../components/pages/seller/products/BrandedProductsMain";
import BrandedProductCatalog from "../components/pages/seller/products/BrandedProductCatalog";
import BrandedProductByCategory from "../components/pages/seller/products/BrandedProductByCategory";
import BrandedBarCodeScannerList from "../components/pages/seller/products/BrandedBarCodeScannerList";
import SearchProductList from "../components/pages/seller/products/SearchProductList";

import StoreHome from "../components/pages/seller/register-store/Home";
import ClaimStore from "../components/pages/seller/register-store/ClaimStore";

import StoreProfileHome from "../components/pages/seller/store/StoreProfileHome";
import StoreProfileName from "../components/pages/seller/store/StoreProfileName";
import StoreProfileLogo from "../components/pages/seller/store/StoreProfileLogo";
import StoreProfileAddress from "../components/pages/seller/store/StoreProfileAddress";
import StoreProfileDomain from "../components/pages/seller/store/StoreProfileDomain";
import StoreProfileImages from "../components/pages/seller/store/StoreProfileImages";
import StoreProfileService from "../components/pages/seller/store/StoreProfileService";

import SubscriptionHome from "../components/pages/seller/subscriptions/Home";
import SubscriptionStore from "../components/pages/seller/subscriptions/SubscriptionStore";
import SubscriptionCheckout from "../components/pages/seller/subscriptions/SubscriptionCheckout";

export const SellerAppRoutes = [

  {
    path: "/orders/all",
    component: OrdersAll,
    exact: true,
  },
  {
    path: "/orders/new",
    component: OrdersNew,
    exact: true,
  },
  {
    path: "/orders/accepted",
    component: OrdersAccepted,
    exact: true,
  },
  {
    path: "/orders/inprogress",
    component: OrdersOpen,
    exact: true,
  },
  {
    path: "/orders/readypickup",
    component: OrdersReadyPickup,
    exact: true,
  },
  {
    path: "/orders/readydelivery",
    component: OrdersReadyDelivery,
    exact: true,
  },
  {
    path: "/orders/picked",
    component: OrdersPicked,
    exact: true,
  },
  {
    path: "/orders/delivered",
    component: OrdersDelivered,
    exact: true,
  },
  {
    path: "/orders/rejected",
    component: OrdersRejected,
    exact: true,
  },
  {
    path: "/orders/delivery",
    component: OrdersDelivery,
    exact: true,
  },
  {
    path: "/orders/pickup",
    component: OrdersPickup,
    exact: true,
  },
  {
    path: "/orders/search",
    component: SearchOrderList,
    exact: true,
  },

  {
    path: "/products/all",
    component: BrandedProductsMain,
    exact: true,
  },
  {
    path: "/products/private",
    component: PrivateProducts,
    exact: true,
  },
  {
    path: "/products/categories",
    component: PrivateCategories,
    exact: true,
  },
  {
    path: "/products/category/:categoryId",
    component: PrivateProductsByCategory,
    exact: true,
  },
  {
    path: "/products/scan",
    component: BrandedBarCodeScannerList,
    exact: true,
  },
  {
    path: "/products/search",
    component: SearchProductList,
    exact: true,
  },

  {
    path: "/brandedproducts/catalog",
    component: BrandedProductCatalog,
    exact: true,
  },
  {
    path: "/brandedproducts/category/:categoryId",
    component: BrandedProductByCategory,
    exact: true,
  },
  {
    path: "/brandedproducts/:storeId/category/:categoryId",
    component: BrandedProductByCategory,
    exact: true,
  },

  { path: "/register-store", component: StoreHome, exact: true },
  {
    path: "/register-store/claim/:storeId",
    component: ClaimStore,
    exact: true,
  },

  { path: "/shop", component: StoreProfileHome, exact: true },
  { path: "/shop/logo", component: StoreProfileLogo, exact: true },
  { path: "/shop/profile", component: StoreProfileName, exact: true },
  { path: "/shop/address", component: StoreProfileAddress, exact: true },
  { path: "/shop/domain", component: StoreProfileDomain, exact: true },
  { path: "/shop/photos", component: StoreProfileImages, exact: true },
  { path: "/shop/service", component: StoreProfileService, exact: true },

  { path: "/subscriptions", component: SubscriptionHome, exact: true },
  {
    path: "/subscriptions/store",
    component: SubscriptionStore,
    exact: true,
  },
  {
    path: "/subscription/checkout",
    component: SubscriptionCheckout,
    exact: true,
  },

];
