// @flow
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Container,
  MenuItem, CircularProgress,
} from "@mui/material";
import { H6, SpanInline } from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import {
  Button,
  Notification,
  AutoComplete,
  Divider,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.products";
import * as ActionTypes from "constants/ActionTypes";

import CategorySelector from "components/elements/seller/CategorySelector";
import PrivateProductEditor from "components/elements/seller/PrivateProductEditor";
import SellerProductEntryForm from "components/elements/seller/SellerProductEntryForm";
import SellerProduct from "components/elements/seller/SellerProduct";

import PrivateProductImageMain from "components/pages/seller/products/PrivateProductImageMain";

import MockProductList from "components/elements/main/MockProductList";

import "./style.scss";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const { Option } = AutoComplete;

const PrivateProductsMain = (props) => {
  const [state, setState] = useState({ productMode: "PREVIEW" });
  const [categoryState, setCategoryState] = useState({ selectedCategory: (props.categoryInput ? props.categoryInput : null) });
  const [productState, setProductState] = useState({ storeProduct: "" });

  const {
    serviceError, reqStatus, isLoading, storeProducts, categoryList, symbol = "$", bootupInfo = {},
    getStoreProductCategories, getStoreProducts,
  } = props;

  useEffect(() => {
    getStoreProductCategories();
    getStoreProducts(null, true);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADD_PRIVATE_PRODUCT_SUCCESS:
      case ActionTypes.UPDATE_PRIVATE_PRODUCT_SUCCESS:
      case ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS:
      case ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS:
      case ActionTypes.UPDATE_PRODUCT_SUCCESS:
        props.getStoreProducts(categoryState.selectedCategory, true);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateProductToAccountCall = (values) => {
    if (!values.prod_id) {
      props.addPrivateProduct(values);
    } else {
      props.updatePrivateProduct(values);
    }
    setState({ productMode: "PREVIEW" });
  };

  const updateProductSettingCall = (values, id) => {
    values.id = id;
    props.updateProduct(values);
    setState({ productMode: "PREVIEW" });
  };

  const handleProductEdit = (mode, item, reset) => (e) => {
    setState({ productMode: mode });
    if (reset) {
      setProductState({
        storeProduct: {
          name: "",
          price: "",
          description: "",
          category: "",
          is_food: "",
          is_nonveg: "",
          id: "",
          prod_id: "",
          images: [],
        },
      });
    } else {
      item.category = item.categories.length > 0 ? item.categories[0]._id : "NA";
      setProductState({
        storeProduct: {
          name: item.name,
          description: item.description,
          category: item.category,
          is_food: item.is_food,
          is_nonveg: item.is_nonveg,
          price: item.price,
          id: item.id,
          prod_id: item.prod_id,
          images: item.images,
        },
      });
    }
  };

  const handleProductSetting = (mode, item) => (e) => {
    setState({ productMode: mode });
    setProductState({
      storeProduct: {
        name: item.name,
        price: item.price,
        id: item.id,
        prod_id: item.prod_id,
        is_popular: item.is_popular,
        is_price_visible: item.is_price_visible,
        is_active: item.is_active,
      },
    });
  };

  const handleShopImages = (mode, item, reset) => (e) => {
    setState({ productMode: mode });
    if (reset) {
      setProductState({
        storeProduct: {
          name: "", description: "", id: "", prod_id: "", images: [],
        },
      });
    } else {
      setProductState({
        storeProduct: {
          name: item.name,
          description: item.description,
          id: item.id,
          prod_id: item.prod_id,
          images: item.images,
        },
      });
    }
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const resetCategory = () => {
    setCategoryState({ selectedCategory: null });
    props.getStoreProducts(null, true);
  };

  const setCategory = (category) => {
    setCategoryState({ selectedCategory: category.id });
    props.getStoreProducts(category.id, true);
  };

  const { records } = storeProducts;
  const { categories } = categoryList;
  const { productMode } = state;
  const { storeProduct = {} } = productState;
  const { selectedCategory = {} } = categoryState;

  const inputOptions = categories.map((item) => {
    const { id, name } = item;
    return (
      <MenuItem value={id}>{name}</MenuItem>
    );
  });

  const renderNoResults = () => (
    <section className="cart-section section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div>
              <div className="col-sm-12 empty-cart-cls text-center">
                <H6>No product found
                </H6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const renderResults = () => (
        <Container>

          <div className="product-wrapper-grid">
            <div className="container-fluid">
              {isLoading ? <CircularProgress />
                  : records.length > 0
                      ? (
                          <div className="row">
                            { records.map((product, index) => (
                                <div className="product-card-container" key={`${index + 1}`}>
                                  <SellerProduct
                                      className="product-item"
                                      key={product.id}
                                      product={product}
                                      editShopImages={handleShopImages("EDIT_IMG", product, false)}
                                      editProductInfo={handleProductEdit("EDIT", product, false)}
                                      editProductSettings={handleProductSetting("EDIT_SET", product)}
                                  />
                                </div>
                            ))}
                          </div>
                      )
                      : (
                          <div className="row">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                              <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="" />
                              <h3>Sorry! Couldn`t find the product you were looking For!!!    </h3>
                              <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                            </div>
                          </div>
                      )}
            </div>
          </div>
        </Container>
  );

  const renderProductList = () => (
      <Container>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          width: '100%',
          bottom: 70,
          justifyContent: 'center',
          zIndex: 'modal' }}>
         <Button
             size="large"
              color="primary"
              variant="contained"
              onClick={handleProductEdit("EDIT", null, true)}
          >
            Add New {process.env.REACT_APP_PRODUCT_TAG}
          </Button>
        </CustomFlexBox>
      <CategorySelector
        title="All Product Categories"
        categoryList={categoryList}
        handlePage={handlePage}
        resetCategory={resetCategory}
        onCategorySelect={setCategory}
        selectedCategory={selectedCategory}
      />
      {isLoading ? <MockProductList />
        : records.length > 0
          ? renderResults()
          : renderNoResults()}
      </Container>
  );

  const renderProductEdit = () => {
    switch (productMode) {
      case "EDIT":
        return (
          <PrivateProductEditor
              setState={(value, field) => setProductState({
                ...state,
                storeProduct: {
                  ...storeProduct,
                  [field]: value,
                },
              })}
            product={storeProduct}
            categoriesOption={inputOptions}
            addUpdateProductToShopClicked={addUpdateProductToAccountCall}
            addCategoryClicked={handlePage("/products/categories")}
            cancelEdit={() => setState({ productMode: "PREVIEW" })}
          />
        );
      case "EDIT_IMG":
        return (
          <PrivateProductImageMain
            product={storeProduct}
            cancelEdit={() => setState({ productMode: "PREVIEW" })}
          />
        );
      case "EDIT_SET":
      default:
        return (
          <SellerProductEntryForm
              setState={(value, field) => setProductState({
                ...state,
                storeProduct: {
                  ...storeProduct,
                  [field]: value,
                },
              })}
            product={storeProduct}
            updateProductSettingClicked={updateProductSettingCall}
            cancelEdit={() => setState({ productMode: "PREVIEW" })}
          />
        );
    }
  };

  return (
    <Container>
      {productMode === "PREVIEW" ? (
        renderProductList()
      ) : (
        renderProductEdit(productMode)
      )}
    </Container>
  );
};

PrivateProductsMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  storeProducts: [],
  categoryList: [],
  symbol: "$",
};

PrivateProductsMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  storeProducts: PropTypes.arrayOf(PropTypes.object),
  categoryList: PropTypes.arrayOf(PropTypes.object),
  symbol: PropTypes.string,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  getStoreProducts: PropTypes.func.isRequired,
  getStoreProductCategories: PropTypes.func.isRequired,
  addPrivateProduct: PropTypes.func.isRequired,
  updatePrivateProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
};

const privateProductsMain = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    storeProducts: products.storeProducts,
    categoryList: products.storeProductCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(PrivateProductsMain);

export default privateProductsMain;
