import * as React from "react";
import Col from "antd/lib/col";
import "./style.scss";
const defaultSpans = {
  6: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 6,
    xxl: 4,
  },
  8: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 6,
  },
};

const _Col = (props) => {
  const colProps = {
    ...defaultSpans[props.span],
    ...props,
    className: `spn-col ${props.className || ""}`,
  };
  return <Col {...colProps}>{props.children}</Col>;
};

export { _Col as Col };
