import * as React from "react";
import Modal from "antd/lib/modal";
import "./style.scss";
import { CloseOutlined } from "@ant-design/icons";

const _Modal = (props) => {
  const modalProps = {
    ...props,
    className: `spn-modal ${props.className || ""}`,
  };
  const { type } = modalProps;
  if (type === "full-page") {
    modalProps.wrapClassName = "full-page";
    modalProps.className += " full-page-content";
    modalProps.title = <h1 className="modal-title">{modalProps.title}</h1>;
    return (
      <Modal {...modalProps} data-test="modal" closeIcon={<CloseOutlined />} />
    );
  }
  return <Modal {...modalProps} data-test="modal" />;
};

export default _Modal;
