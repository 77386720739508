// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";

function* usergetStoreList(action: Object) {
  try {
    const response = yield API.get(
      `/users/shops/get-shops`,
      action.payload
    );
    yield put(Actions.usergetStoreListSuccess(response.response));
  } catch (error) {
    yield put(Actions.usergetStoreListFailure(error));
  }
}

function* usersearchShops(action: Object) {
  try {
    const response = yield API.get(
        `/users/shops/get-shops?name=`+
        action.payload
    );
    yield put(Actions.usersearchShopsSuccess(response.response));
  } catch (error) {
    yield put(Actions.usersearchShopsFailure(error));
  }
}

function* usergetShopInfoById(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-shopinfo?id=`+ searchQuery
    );
    yield put(Actions.usergetShopInfoByIdSuccess(response.response));
  } catch (error) {
    yield put(Actions.usergetShopInfoByIdFailure(error));
  }
}

function* getStoreFavlist(action: Object) {
  try {
    const response = yield API.get(
        `/users/shops/shoplist/get-shoplist`,
        { withCredentials: true }
    );
    yield put(Actions.getStoreFavlistSuccess(response.response.shoplist));
  } catch (error) {
    yield put(Actions.getStoreFavlistFailure(error));
  }
}

function* addStoreToFavlist(action: Object) {
  try {
    const response = yield API.post(
        `users/shops/shoplist/add-shop`,
        { "shop_id" : action.payload.id},
        { withCredentials: true }
    );
    yield put(Actions.addStoreToFavlistSuccess(response.response, action.payload.id));
  } catch (error) {
    yield put(Actions.addStoreToFavlistFailure(error));
  }
}

function* removeStoreFromFavlist(action: Object) {
  try {
    const response = yield API.post(
        `users/shops/shoplist/remove-shop`,
        { "shop_id" : action.payload.shop._id},
        { withCredentials: true }
    );
    yield put(Actions.removeStoreFromFavlistSuccess(response, action.payload.shop._id));
  } catch (error) {
    yield put(Actions.removeStoreFromFavlistFailure(error));
  }
}


export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_GET_STORE_LIST_START,            usergetStoreList);
  yield takeLatest(ActionTypes.USER_SEARCH_SHOPS_START,              usersearchShops);
  yield takeLatest(ActionTypes.USER_GET_SHOP_INFO_BYID_START,        usergetShopInfoById);

  yield takeLatest(ActionTypes.USER_GET_STORE_FAVLIST_START,         getStoreFavlist);
  yield takeLatest(ActionTypes.USER_ADD_STORE_TO_FAVLIST_START,      addStoreToFavlist);
  yield takeLatest(ActionTypes.USER_REMOVE_STORE_FROM_FAVLIST_START, removeStoreFromFavlist);
}
