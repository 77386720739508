import { Box } from '@mui/material';
import styled from "@mui/material/styles/styled";


const CustomImageBox = styled(Box)({
  position: 'relative',

  '&:after': {
    position: 'absolute',
    content: '" "',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

})

CustomImageBox.defaultProps = {
  overflow: 'hidden',
}

export default CustomImageBox
