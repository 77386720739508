/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container, Typography, Box, Avatar,
} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import * as Actions from "../../../../../actions";

const ApproveShop = () => {
  const [value, setValue] = useState({
    shopName: "Famous Bakery",
    street: "street1",
    city: "Bangalore",
    pinCode: "562478",
  });
  return (
    <div>
      <Container maxWidth="md">
        <Box display="flex" className="mt-3">
          <Typography variant="h4">{value.shopName}</Typography>
          <Box width="25%">
            <Avatar style={{ margin: "auto" }} />
            <Typography align="center">Photos</Typography>
          </Box>
        </Box>
        <br />
        <TextFieldComponent
          value={value.street}
          label="Street"
          fullWidth
        />
        <br />
        <br />
        <TextFieldComponent
          value={value.city}
          label="City"
          fullWidth
        />
        <br />
        <br />
        <TextFieldComponent
          value={value.pinCode}
          label="Pincode / Zipcode"
          fullWidth
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Typography align="center">
          <ButtonComponent
            name="approve"
          />
          <ButtonComponent
            name="Reject"
            className="secondary-button"
            variant="outlined"
            color="secondary"
          />
        </Typography>
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
};

const approveShop = connect(
  ({ adminShops, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
    storeList: adminShops.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ApproveShop);

export default approveShop;
