import React from "react";
import { Container } from "@mui/material";

import { SearchOptions } from "./helper";
import { SearchBar } from "../../components";

const SearchLandingPage = () => {
  console.log("");
  return (
    <Container>
      <br />
      {SearchOptions.map((element) => (
        <>
          <SearchBar category={element.category} placeholder={element.placeholder} />
          <br />
        </>
      ))}
    </Container>
  );
};

export default SearchLandingPage;
