// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  storeList: {
    count: 0,
    records: [],
  },
  shopinfo: [],
  shopDetail: {
    id: "",
    title: "",
    ratings: [],
    images: [],
    domain: "",
    subdomain: "",
  },
  searchkeywords: {
    keywords: [],
  },
};

export default function (state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */

    case ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_START:
    case ActionTypes.ADMIN_SEARCH_SHOPS_START:
    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_START:
    case ActionTypes.ADMIN_GET_SHOP_INFO_BYID_START:
    case ActionTypes.ADMIN_UPDATE_STORE_STATUS_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_FAILURE:
    case ActionTypes.ADMIN_SEARCH_SHOPS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE:
    case ActionTypes.ADMIN_GET_SHOP_INFO_BYID_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {shop_count, shops} = action.payload;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeList: {
            count: shop_count,
            records: shops,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeList: {
            count: 0,
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SHOPS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {shop_count, shops} = action.payload;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeList: {
            count: shop_count,
            records: shops,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeList: {
            count: 0,
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS:
      if (action.payload.statusCode === 200) {
        const {keywords} = action.payload;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: keywords,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: [],
          },
        };
      }

    case ActionTypes.ADMIN_GET_SHOP_INFO_BYID_SUCCESS:
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          shopDetail: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          shopDetail: {
            id: "",
            title: "",
            subdomain: "",
            domain: "",
            images: [],
          },
        };
      };

    case ActionTypes.ADMIN_UPDATE_STORE_STATUS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    default:
      return state;
  }
}
