import * as React from "react";
import {
  Box, Card, Container, FormControlLabel, Radio, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import { Link } from "react-router-dom";
import {
  getButtonData, getRadioButtonData, getButtonDataAccept, getButtonDataReject, schema,
} from "./helper";

const SellerOrderEditor = ({
  setState, selectedOrder, symbol, visible, updateOrderClicked, onCancel, orderdetail,
}) => {
  const {
    order_id, _id, status, order_type, user,
  } = selectedOrder || {};

  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinish = () => {
    updateOrderClicked({ status, order_id, _id });
  };

  const onFinishAccept = () => {
    updateOrderClicked({ status: "accepted", order_id, _id });
  };

  const onFinishReject = () => {
    updateOrderClicked({ status: "rejected", order_id, _id });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedOrder);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOrder);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  const handleRadioButtonChange = (event, feild) => {
    setState(event.target.value, feild);
  };

  let options;
  { order_type === "pickup"
    ? options = [
      { label: "Accepted", value: "accepted" },
      { label: "In progress", value: "inprogress" },
      { label: "Ready for Pickup", value: "pickupready" },
      { label: "Picked", value: "picked" },
    ]
    : options = [
      { label: "Accepted", value: "accepted" },
      { label: "In progress", value: "inprogress" },
      { label: "Ready for Delivery", value: "readydelivery" },
      { label: "Delivered", value: "delivered" },
    ];
  }

  const inputOptions = options.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });

  const {
    products,
  } = orderdetail || {};

  return (
    <Container>
      <Box mb={2}>
        <Card>
          Order:
          {order_id}
          {(status !== "new" && status !== "rejected")
            ? [getRadioButtonData(status).map((element) => (
              <Box className="textfield" m={2}>
                <RadioButtonFieldComponent
                  required
                  options={inputOptions}
                  label={element.label}
                  value={element.value}
                  onChange={(event) => handleRadioButtonChange(event, element.onChangeField)}
                />
              </Box>
            )),
            getButtonData(onFinish, onCancel, hasError, isTouched).map((element) => (
              <ButtonComponent
                type={element.type}
                onClick={element.onClick}
                disabled={element.disabled}
                name={element.name}
              />
            ))]
            : [getButtonDataAccept(onFinishAccept).map((element) => (
              <ButtonComponent
                  type={element.type}
                onClick={element.onClick}
                disabled={element.disabled}
                name={element.name}
              />
            )),
                getButtonDataReject(onFinishReject).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                    />
                ))]
          }

        </Card>
      </Box>
      <Box mb={2}>
        <Card>
          Order:
          {user ? (
            <div>
              <div>{user.name}</div>
              <div>{user.email}</div>
              <div>{user.phone}</div>
            </div>
          ) : <div>User not available (please contact us)</div>}
          {products.map((item, index) => (
            <div>
              {item.product.name}
              {" "}
              /
              {symbol}
              {item.seller_price}
              {" "}
              /
              {" "}
              {item.quantity}
            </div>
          ))}
        </Card>
      </Box>
    </Container>
  );
};

export default SellerOrderEditor;
