/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Notification,
} from "components/Atoms";
import { H4 } from 'components/CustomTypography';
import { Container, LinearProgress, Box } from "@mui/material";
import * as Actions from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";

import ProfileEditor from "components/elements/core/ProfileEditor";

import "./styles.scss";
// Component Imports

const ProfileName = (props) => {
  const [state, setState] = useState({
    userProfileNameMode: "PREVIEW",
  });

  const {
    isLoading,
    userDetails,
    updateUserProfile,
    serviceError,
    reqStatus,
  } = props;

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
        props.history.push({
          pathname: "/me",
        });
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const updateProfileCall = (values) => {
    setState({ userProfileNameMode: "PREVIEW" });
    updateUserProfile(values);

  };

  const handleAddressEditCancel = (mode) => () => {
    setState({ userProfileNameMode: mode });
    props.history.push({
      pathname: "/me",
    });
  };

  return (
    <Container>
      <Box>
        <H4 sx={{
            mb: 2,
            mt: 2,
        }}>Profile {isLoading && <LinearProgress />}</H4>
        <ProfileEditor
          profile={userDetails}
          updateProfileCall={updateProfileCall}
          cancelEdit={handleAddressEditCancel("PREVIEW")}
        />
      </Box>
    </Container>
  );
};

ProfileName.defaultProps = {
  isLoading: false,
};

ProfileName.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ProfileNameComponent = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProfileName);

export { ProfileNameComponent as default };
