import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import debounce from "lodash/debounce";
import { Input, AutoComplete, Popover, Avatar } from "antd";

import {
    Notification
} from "components/Atoms";

import "./style.scss";

import * as Actions         from "actions/seller/seller.registerstore";
import * as ActionTypes     from 'constants/ActionTypes';

import ShopEditor       from "components/elements/seller/ShopEditor";
import {useState} from "react";
import {useEffect} from "react";
import PropTypes from "prop-types";

const { Search } = Input;
const { Option } = AutoComplete;

type Props = {};

type State = {};


const ShopClaimList = (props) => {

    const [state, setState] = useState({ shopMode: "EDIT" });

    const {
        serviceError, reqStatus, isLoading, shopAddress, placeSuggestions,
        updateUserLocation, searchLocation, addShopToAccount
    } = props;

    useEffect(() => {
        // block for now - allow add shop only
        //props.getStoresForLocation();
//        this.handleLocationSearch = debounce(handleLocationSearch, 200);
    }, []);


    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        switch (reqStatus) {
            case ActionTypes.ADD_SHOP_TO_ACCOUNT_SUCCESS:
                props.history.push({
                    pathname: '/shop'
                });
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);

    const { shopMode, storeAddress = {} } = state;



  const handleLocationSearch = (value) => {
    if (value.length > 3) {
      searchLocation({ searchText: value });
    }
  };

  const onSelect = (value) => {
    updateUserLocation({ address: value });
  };

  const renderOption = (item) => {
    const { id, description, types } = item;
    return (
      <Option key={description} value={item}>
        <p className="">{description}</p>
      </Option>
    );
  };

    const addShopToAccountCall = (values) => {
        addShopToAccount(values);
    }

    const handleShopEdit = (mode, item, reset) => (e) => {
        setState({ shopMode: mode });
        if (reset) {
            setState({ storeAddress: {title: "",  addressline: "", pincode: "",
                    phone: "", cityname: "", areaname: "", placeid: ""} })
        } else {
            setState({ storeAddress: {title: item.title,  addressline: item.addressline, pincode: "",
                phone: "", cityname: "", areaname: "", placeid: item.place_id} });
        }
    };


    const inputOptions = placeSuggestions.map((item) => {
      const { place_id, title } = item;
      return (
        <Option key={place_id} value={title}>
          <p className="option">{title}</p>
        </Option>
      );
    });

    return (
      <div className="header-content">
        <div className="main">
          <div className="search-bar">
              <div className="control search">
                  {shopMode === "PREVIEW" ? (
                      <table className="table cart-table table-responsive-xs">
                          <thead>
                          <tr className="table-head">
                              <th scope="col">Shop name</th>
                              <th scope="col">action</th>
                          </tr>
                          </thead>
                          <td>
                              <a href="javascript:void(0)" className="cart"
                                 onClick={handleShopEdit("EDIT", null, true)}>
                                  Create shop<i className="fa fa-arrow-right"></i>
                              </a>
                          </td>
                          {placeSuggestions.map((item, index) => {
                              return (
                                  <tbody key={index}>
                                  <tr>
                                      <td>{item.title}
                                          <div className="mobile-cart-content row">
                                              <div className="col-xs-3">
                                                  <h2 className="td-color">
                                                      <a href="javascript:void(0)" className="cart"
                                                         onClick={handleShopEdit("EDIT", item, false)}>
                                                          <i className="fa fa-arrow-right"></i>
                                                      </a>
                                                  </h2>
                                              </div>
                                          </div>
                                      </td>
                                      <td>
                                          <a href="javascript:void(0)" className="cart"
                                             onClick={handleShopEdit("EDIT", item, false)}>
                                              <i className="fa fa-arrow-right"></i>
                                          </a>
                                      </td>
                                  </tr>
                                  </tbody>)
                          })}
                      </table>
                  ) : (
                      <ShopEditor
                          setState={(event, field) => setState({
                              ...state,
                              storeAddress: {
                                  ...storeAddress,
                                  [field]: event.target.value,
                              },
                          })}
                          address={storeAddress}
                          addShopToAccountClicked={addShopToAccountCall}
                          cancelEdit={() => setState({ shopMode: "PREVIEW" })}
                      />
                  )}
              </div>
          </div>
        </div>
      </div>
    );
}

ShopClaimList.propTypes = {
    history: PropTypes.object.isRequired,
    shopAddress: PropTypes.object.isRequired,
    addShopToAccount: PropTypes.func.isRequired,
    updateUserLocation: PropTypes.func.isRequired,
    searchLocation: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    serviceError: PropTypes.object.isRequired,
    reqStatus: PropTypes.object.isRequired,
};

const _ShopClaimList = connect(
    ({ registerStore, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: registerStore.reqStatus,
      isLoading: registerStore.isLoading,
      serviceError: registerStore.serviceError,
      placeSuggestions: registerStore.placeSuggestions,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ShopClaimList);

export { _ShopClaimList as default };
