import * as React from "react";

export const SellerWebSideBarConfig = [
  {
    id: "homesellerweb",
    name: "Home",
    icon: "icon-home",
    header: "",
    route: "",
    routestart: "/user/shop/",
    routeend: "",
  },
  {
    id: "sellerrorders",
    name: "Orders",
    icon: "icon-website",
    header: "Orders",
    route: "",
    routestart: "/user/shop/",
    routeend: "/orders",
    subMenu: [
      {
        id: "inprogress",
        name: "Orders -in progress",
        header: "Orders in progress",
        route: "",
        routestart: "/user/shop/",
        routeend: "/orders",
      },
      {
        id: "completed",
        name: "Orders - completed",
        header: "Orders Completed",
        route: "",
        routestart: "/user/shop/",
        routeend: "/orders",
      },
    ],
  },
  {
    id: "sellerproducts",
    name: "Products",
    header: "Products",
    icon: "icon-product",
    route: "",
    routestart: "/user/shop/",
    routeend: "/wishlist",
    subMenu: [
      {
        id: "my_products",
        name: "My wishlist",
        header: "Wishlist",
        route: "",
        routestart: "/user/shop/",
        routeend: "/wishlist",

      },
    ],
  },
  {
    id: "account",
    name: "Account",
    header: "Account",
    icon: "icon-home",
    route: "/me",
  },
];
