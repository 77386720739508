import React, { useState, useEffect } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Box, Container, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { H6 } from 'components/CustomTypography';

import PropTypes from "prop-types";
import {
  getButtonData1, getButtonData2, getTextfieldData, schema,
} from "./helper";

const DomainEditor = ({
  setState, storeDomain, newsubdomain, cancelEdit, checkDomainAvailability, updateDomainCall,
}) => {
  const [mode, setMode] = useState("CHECK_AVAILABILITY");

  const [blur, setblur] = React.useState({
    subdomain: false,
  });

  const handleChange = (event, feild) => {
    setState(event, feild);
  };

  const hasError = () => {
    try {
      return !schema.validateSync({ subdomain: newsubdomain });
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, { subdomain: newsubdomain });
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  const checkDomain = () => {
    checkDomainAvailability({ subdomain: newsubdomain /* domain: values.domain */ });
  };

  const saveDomain = () => {
    updateDomainCall({ subdomain: storeDomain.value.subdomain });
  };

  const { value, isLoading, status } = storeDomain;

  return (
    <Container>

      {status === "DOMAIN_AVAILABLE" ? (
        <div className="actions-update">
          {storeDomain.value.subdomain}
          .
          {storeDomain.value.domain}
          <H6>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          <span>Website name is available</span>
          </H6>
          <Typography align="center">
            {getButtonData1(saveDomain, cancelEdit, hasError).map((element) => (
              <ButtonComponent
                  sx={{
                    m: 2,
                  }}
                  variant={element.variant}
                type={element.type}
                onClick={element.onClick}
                disabled={element.disabled}
                name={element.name}
              />
            ))}
          </Typography>
        </div>
      ) : (
        <div className="actions-check">
          {status === "DOMAIN_UNAVAILABLE" && (
          <span>
            {value.subdomain}
            <CloseCircleTwoTone twoToneColor="#eb2f96" />
            <span>Website name is unavailable</span>
          </span>
          )}
          <Box display="flex" flexWrap="wrap">
            {getTextfieldData(newsubdomain).map((element) => (
              <Box className="textfield" m={2}>
                <TextFieldComponent
                  required
                  label={element.label}
                  value={element.value}
                  error={!!getError(element.onChangeField)}
                  helperText={getError(element.onChangeField)}
                  onBlur={() => handleBlur(element.onChangeField)}
                  onChange={(event) => handleChange(event, element.onChangeField)}
                />
              </Box>
            ))}
          </Box>
          <br />
          <Typography align="center">
            {getButtonData2(checkDomain, cancelEdit, hasError).map((element) => (
              <ButtonComponent
                  sx={{
                    m: 2,
                  }}
                  variant={element.variant}
                type={element.type}
                onClick={element.onClick}
                disabled={element.disabled}
                name={element.name}
              />
            ))}
          </Typography>
        </div>
      )}

    </Container>
  );
};

DomainEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  storeDomain: PropTypes.object.isRequired,
  checkDomainAvailability: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default DomainEditor;
