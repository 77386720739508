/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import * as Actions from "actions";
import {Container} from "@mui/material";

import ShopProductSearch from "components/widgets/user/ShopProductSearch";
import ShopProductSlider from "components/elements/user/ShopProductSlider";
import CategoryMenuSlider from "components/elements/user/CategoryMenuSlider";
import ShopSpecialProducts from "components/elements/user/ShopSpecialProducts";
import CategoryTitleSlider from "components/elements/user/CategoryTitleSlider";

const ShopMain = (props) => {
  const [state] = useState({
    layoutColumns: 3,
    storeid: "NA",
  });

  useEffect(() => {
    props.userResetSearchProducts();
    props.usergetProductCategories(props.bootupInfo.appState.subdomain_shopId,
      true, props.bootupInfo.appState.isWebsite);
  }, []);

    const refetch = () => {
        props.usergetProductCategories(props.bootupInfo.appState.subdomain_shopId,
            true, props.bootupInfo.appState.isWebsite);
    };

  return (
    <div>
      {/* SEO Support */}
      <Helmet>
        <title>{props.bootupInfo.shopinfo.title}</title>
        <meta name="description" content={props.bootupInfo.shopinfo.title} />
      </Helmet>
      {/* SEO Support End */}
      <ShopProductSearch {...props} colSize={state.layoutColumns} />
      <Container>
        <CategoryTitleSlider {...props} refetch={refetch} />
      </Container>
      <ShopProductSlider />
      <br />
      <CategoryMenuSlider {...props} />
      <br />
      <ShopSpecialProducts />
    </div>
  );
};

ShopMain.propTypes = {
  userResetSearchProducts: PropTypes.func.isRequired,
  usergetProductCategories: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

const _ShopMain = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    categoryList: userproducts.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopMain);

export { _ShopMain as default };
