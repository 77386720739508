/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import {
  Container, Typography, TextField, InputAdornment, IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from '@mui/material/Autocomplete';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "../../../../../actions";

const SearchBar = (props) => {
  const { category, placeholder } = props;
  const [value, setVaue] = React.useState();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log(value);
    }
  };

  const hanldeOnChange = (v) => {
    setVaue(v);
  };

  React.useState(() => {
    console.log(props);
  }, []);

  return (
    <Container className="search-container">
      <Typography variant="h5">{category}</Typography>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={["Abhishek", "Abhay", "Chandan", "aayushi"]}
        onKeyPress={handleKeyPress}
        onSelect={(input) => hanldeOnChange(input.target.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            margin="dense"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              type: "search",
              endAdornment: (
                <InputAdornment>
                  <IconButton size="large">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Container>
  );
};

SearchBar.propTypes = {
  category: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchShopByKeywords: PropTypes.func.isRequired,
};

const searchBar = connect(
  (props) => ({
    searchShopByKeywords: props.adminSearchShopKeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(SearchBar);

export default searchBar;
