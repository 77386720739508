// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";

function* getOrderlistProducts(action: Object) {
  try {
    const response = yield API.get(
        `/users/orders/get-orders`,
        { withCredentials: true }
    );
    yield put(Actions.getOrderlistProductsSuccess(response.response));
  } catch (error) {
    yield put(Actions.getOrderlistProductsFailure(error));
  }
}


function* getOrderDetail(action: Object) {
  try {
    const response = yield API.get(
        `/users/orders/get-order/` + action.payload,
        { withCredentials: true }
    );
    yield put(Actions.getOrderDetailSuccess(response.response));
  } catch (error) {
    yield put(Actions.getOrderDetailFailure(error));
  }
}

function* getShopOrderlistProducts(action: Object) {
  try {

    const searchQuery = action.payload
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.get(
        '/users/orders/get-orders?shop=' + searchQuery,
        { withCredentials: true });

    yield put(Actions.getShopOrderlistProductsSuccess(response.response));
  } catch (error) {
    yield put(Actions.getShopOrderlistProductsFailure(error));
  }
}

function* getShopOrderDetail(action: Object) {
  try {
    const searchQuery =  action.payload
        + `?shop=` + action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.get(
        `/users/orders/get-order/` + searchQuery,
        { withCredentials: true }
    );
    yield put(Actions.getOrderDetailSuccess(response.response));
  } catch (error) {
    yield put(Actions.getOrderDetailFailure(error));
  }
}

export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_GET_ORDERLIST_PRODUCTS_START,         getOrderlistProducts);
  yield takeLatest(ActionTypes.USER_GET_ORDER_DETAIL_START,               getOrderDetail);
  yield takeLatest(ActionTypes.USER_GET_SHOP_ORDERLIST_PRODUCTS_START,    getShopOrderlistProducts);
  yield takeLatest(ActionTypes.USER_GET_SHOP_ORDER_DETAIL_START,          getShopOrderDetail);
}
