//  @flow
import * as React from "react";
import {
  Page,
} from "components/Atoms";

import PrivateProductsMain    from "components/pages/seller/products/PrivateProductsMain";

import "./styles.scss";

class PrivateProductsByCategory extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const { categoryId } = props.match.params;
    this.categoryId = categoryId;
  }

  componentDidMount() {}

  render() {

    return (
        <Page className="products-home">
          <PrivateProductsMain categoryInput={this.categoryId}></PrivateProductsMain>
        </Page>
    );
  }
}

export default (PrivateProductsByCategory)
