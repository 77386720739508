/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

import { BottomNavigation, BottomNavigationAction } from "components/Atoms";

import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import StorageIcon from "@mui/icons-material/Storage";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

const BottomNavSeller = (props) => {
  const [tab, setTab] = React.useState("home");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const selectedTab = () => {
    if (props.location.pathname) {
      if (props.location.pathname.includes("me")) {
        setTab("account");
        return;
      }
      if (props.location.pathname.includes("product")) {
        setTab("products");
        return;
      }
      if ( props.location.pathname.includes("shop")) {
        setTab("shop");
        return;
      }
      if (props.location.pathname.includes("order")) {
        setTab("orders");
      } else {
        setTab("home");
      }
    }
  };

  React.useEffect(() => {
    selectedTab();
  });

  return (
    <BottomNavigation
      showLabels
      style={{
        position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "#fff", boxShadow: "0 -1px 1px 0 rgba(0,0,0,.1)",
      }}
      value={tab}
      onChange={handleChange}
    >
      <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} component={Link} to="/" />
      <BottomNavigationAction label="Shop" value="shop" icon={<StoreIcon />} component={Link} to="/shop" />
      <BottomNavigationAction label={process.env.REACT_APP_PRODUCT_TITLE} value="products" icon={<StorageIcon />} component={Link} to="/products/private" />
      <BottomNavigationAction label="Orders" value="orders" icon={<ShoppingCartRoundedIcon />} component={Link} to="/orders/all" />
      <BottomNavigationAction label="Account" value="account" icon={<PersonRoundedIcon />} component={Link} to="/me" />
    </BottomNavigation>

  );
};

BottomNavSeller.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTranslate(BottomNavSeller);
