/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Container } from "@mui/material";
import ShopItem from "components/elements/admin/ShopItemBox";
import * as Actions from "../../../../../actions";
import MockProductList from "../../../../elements/main/MockProductList";

const AdminShopList = (props) => {
  const {
    isLoading, storeList, adminSearchShop,
  } = props;

  useEffect(() => {
    adminSearchShop();
    //adminSearchShop("fashion");
  }, []);

  if (isLoading) {
    return <MockProductList />;
  }

  const { records } = storeList;

  if (!records.length) {
    return (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <h3>
                    <strong>No Shops found</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div>
      <Container maxWidth="md">
        <div className="no-slider row">
          {records.map((item, index) => (
            <div className="product-card-container" key={`${index + 1}`}>
              <ShopItem
                shop={item}
                onAddToFavlistClicked={() => {}}
              />
            </div>
          ))}
        </div>
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
};

AdminShopList.defaultProps = {
  isLoading: false,
};

AdminShopList.propTypes = {
  storeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  adminSearchShop: PropTypes.func.isRequired,
};

const adminShopList = connect(
  ({ adminShops, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
    storeList: adminShops.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopList);

export default adminShopList;
