import {
    USER_GET_STORE_LIST_SUCCESS,
    USER_GET_SHOP_INFO_BYID_SUCCESS,
    USER_GET_STORE_FAVLIST_SUCCESS,
    USER_ADD_STORE_TO_FAVLIST_SUCCESS,
    USER_REMOVE_STORE_FROM_FAVLIST_SUCCESS,
    USER_SEARCH_SHOPS_SUCCESS
} from "constants/ActionTypes";

import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    storeList: {
        count: 0,
        shops: [],
    },
    shopinfo: [],
    storeswishlist: [],
    searchshops: [],
};

export default function storeReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_GET_STORE_LIST_START:
        case ActionTypes.USER_GET_SHOP_INFO_BYID_START:
        case ActionTypes.USER_SEARCH_SHOPS_START:
        case ActionTypes.USER_GET_STORE_FAVLIST_START:
        case ActionTypes.USER_ADD_STORE_TO_FAVLIST_START:
        case ActionTypes.USER_REMOVE_STORE_FROM_FAVLIST_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        /* Common Failures */
        case ActionTypes.USER_GET_STORE_LIST_FAILURE:
        case ActionTypes.USER_GET_SHOP_INFO_BYID_FAILURE:
        case ActionTypes.USER_SEARCH_SHOPS_FAILURE:
        case ActionTypes.USER_GET_STORE_FAVLIST_FAILURE:
        case ActionTypes.USER_ADD_STORE_TO_FAVLIST_FAILURE:
        case ActionTypes.USER_REMOVE_STORE_FROM_FAVLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case USER_GET_STORE_LIST_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                storeList: action.payload };

        case USER_GET_SHOP_INFO_BYID_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                shopinfo: action.payload };

        case USER_SEARCH_SHOPS_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                searchshops: action.payload.shops };

        case USER_GET_STORE_FAVLIST_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                storeswishlist: action.payload };

        case USER_ADD_STORE_TO_FAVLIST_SUCCESS:
            if (state.storeswishlist.findIndex(wishItem => wishItem.shop.id === action.storeId) !== -1) {
                const wishlist = state.storeswishlist.reduce((cartAcc, wishItem) => {
                    if (wishItem.store._id === action.storeId) {
                        cartAcc.push({ ...wishItem }) // Increment qty
                    } else {
                        cartAcc.push(wishItem)
                    }

                    return cartAcc
                }, [])

                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist };
            }

            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                wishlist: [...state.wishlist, { ...action.payload }] };

        case USER_REMOVE_STORE_FROM_FAVLIST_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                storeswishlist: state.storeswishlist.filter(wishItem => wishItem.shop._id !== action.storeId)
            };

        default:
    }
    return state;
}
