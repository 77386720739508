import * as React from "react";

import "./style.scss";
import SellerCategoryMenuSlider from "components/elements/seller/SellerCategoryMenuSlider";
import PropTypes from "prop-types";

const CategorySelector = (props) => {
  const {
    categoryList, onCategorySelect, resetCategory, selectedCategory,
  } = props;

  const { categories } = categoryList;

  return (
    <div>
      <div className="category-list">
        <SellerCategoryMenuSlider
          resetCategory={resetCategory}
          categories={categories}
          selectedCategory={selectedCategory}
          onCategorySelect={onCategorySelect}
        />
      </div>
    </div>
  );
};

CategorySelector.defaultProps = {
  categoryList: [],
};

CategorySelector.propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.object),
  onCategorySelect: PropTypes.func.isRequired,
  resetCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.func.isRequired,
  handlePage: PropTypes.func.isRequired,
};

export default CategorySelector;
