import * as yup from "yup";

export const schema = yup.object().shape({
  price: yup.string().required().matches(/^[0-9.]+$/, "Must be number"),
  is_popular: yup.boolean().default(true),
  is_price_visible: yup.boolean().default(true),
  is_active: yup.boolean().default(true),
});

export const getTextfieldData = (price) => [
  {
    label: "Price",
    value: price,
    onChangeField: "price",
  },
];

export const getCheckboxData = (is_popular, is_price_visible, is_active) => [
  {
    label: "Popular Item",
    value: is_popular,
    onChangeField: "is_popular",
  },
  {
    label: "Display pricing",
    value: is_price_visible,
    onChangeField: "is_price_visible",
  },
  {
    label: "Enabled",
    value: is_active,
    onChangeField: "is_active",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];
