/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import * as Actions from "actions";

const ProductItem = (props) => {
  const [state, setState] = useState({
    open: false,
    stock: "InStock",
    quantity: 1,
    image: "",
  });
  const [share, setShare] = useState(false);

  // const onClickHandle = (img) => {
  //   setState({ image: img });
  // };
  const onOpenModal = () => {
    setState({ open: true });
    props.usergetProductPrices(props.product._id, true);
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

  // const minusQty = () => {
  //   if (state.quantity > 1) {
  //     setState({ stock: "InStock" });
  //     setState({ quantity: state.quantity - 1 });
  //   }
  // };

  // const plusQty = () => {
  //   if (props.product.stock >= state.quantity) {
  //     setState({ quantity: state.quantity + 1 });
  //   } else {
  //     setState({ stock: "Out of Stock !" });
  //   }
  // };
  // const changeQty = (e) => {
  //   setState({ quantity: parseInt(e.target.value, 10) });
  // };
  const {
    product, symbol, onAddToWishlistClicked, productprices,
  } = props;

  const handleShare = () => {
    setShare(!share);
  };

  const vegIconUrl = `${process.env.PUBLIC_URL}/assets/images/GreenIcon.png`;
  const nonVegIconUrl = `${process.env.PUBLIC_URL}/assets/images/RedIcon.png`;

  const RatingStars = [];
  for (let i = 0; i < product.rating; i = +1) {
    RatingStars.push(<i className="fa fa-star" key={i} />);
  }
  return (
    <Card className="product-card">
      <div className="image-container">
        <Box>
          {product.new ? <span className="lable3">new</span> : ""}
          {product.sale ? <span className="lable4">on sale</span> : ""}

        </Box>
        <Box className="front">
          {product.images && (
          <img
            onClick={onOpenModal}
            src={`${product.images[0] ? product.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`}
            className="product-image"
            alt=""
          />
          )}
        </Box>
        <Box className="actions" position="absolute" top={0} right={0} display="none" flexDirection="column">
          <IconButton color="secondary" size="small" onClick={onAddToWishlistClicked}>
            <FavoriteBorderIcon />
          </IconButton>
        </Box>
      </div>
      <Box display="flex" className="product-detail">
        <Box width="90%">
          <Link className="styled-link" to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`}>
            <Typography className="text-truncate styled-link">{product.name}</Typography>
          </Link>
          <Typography className="price-tag">
            {product.seller_price && `${symbol}${product.seller_price}`}
          </Typography>
        </Box>
        {product.is_food && <img className="food-icon" alt="" src={product.is_nonveg ? nonVegIconUrl : vegIconUrl} />}
      </Box>
      <Modal open={state.open} onClose={onCloseModal} center>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6  col-xs-12">
                  <div className="quick-view-img">
                    <img src={`${product.images && product.images[0] ? product.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <Typography variant="h2">
                      {product.name}
                    </Typography>
                    <div className="border-product">
                      <Typography variant="h6">Product Details</Typography>
                      <Typography>{product.shortDetails}</Typography>
                    </div>
                    <Typography align="right">
                      <IconButton onClick={handleShare} size="large">
                        <ShareIcon />
                      </IconButton>
                    </Typography>
                    {
                    share && (
                      <>
                        <EmailShareButton url={window.location.href}>
                          <IconButton size="small">
                            <MailIcon />
                          </IconButton>
                        </EmailShareButton>
                        <FacebookShareButton url={window.location.href}>
                          <IconButton size="small">
                            <FacebookIcon />
                          </IconButton>
                        </FacebookShareButton>
                        <TwitterShareButton url={window.location.href}>
                          <IconButton size="small">
                            <TwitterIcon />
                          </IconButton>
                        </TwitterShareButton>
                        <WhatsappShareButton url={window.location.href}>
                          <IconButton size="small">
                            <WhatsAppIcon />
                          </IconButton>
                        </WhatsappShareButton>
                      </>
                    )
                  }
                    <Box className="product-buttons">
                      <Button
                        className="btn m-2 btn-solid"
                        type="button"
                        onClick={onCloseModal}
                      >
                        close
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

ProductItem.defaultProps = {
  symbol: "$",
  productprices: 0,
};

ProductItem.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
  onAddToWishlistClicked: PropTypes.func.isRequired,
  usergetProductPrices: PropTypes.func.isRequired,
  productprices: PropTypes.number,
};

const ProductItemComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    productprices: userproducts.productprices,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProductItem);

export default ProductItemComponent;

// export default ProductItem;
