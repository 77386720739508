/* eslint-disable import/prefer-default-export */
import * as React from "react";

export const AppContext = React.createContext({
  pageHeader: {
    title: "",
    breadCrumb: "",
  },
  setPageHeader: () => {},
});
