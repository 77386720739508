import * as React from "react";

export const UserSideBarConfig = [
  {
    id: "userhome",
    name: "Home",
    header: `${process.env.REACT_APP_NAME}`,
    icon: "icon-home",
    route: "/user",
    routestart: "/user",
    routeend: "",
  },

  {
    id: "userproducts",
    name: `${process.env.REACT_APP_PRODUCT_TITLE}`,
    icon: "icon-product",
    route: "/user/products",
    routestart: "/user/products",
    routeend: "",
    subMenu: [
      {
        id: "my_products",
        name: "My wish list",
        route: "/user/products",
      },
    ],
  },
  {
    id: "usershops",
    name: "Shops",
    icon: "icon-subscriptions",
    route: "/user/stores",
    routestart: "/user/stores",
    routeend: "",
    subMenu: [
      {
        id: "myshoplist",
        name: "My Shop list",
        route: "/user/stores",
      },
    ],
  },
  {
    id: "account",
    name: "Account",
    icon: "icon-home",
    route: "/me",
  },
  /*
  {
    id: "terms",
    name: "Terms&Conditions",
    icon: "icon-info",
    route: "/terms",
  },
  {
    id: "contact",
    name: "Contact Support",
    icon: "icon-phone",
    route: "/contact",
  },

     */
];
