/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Typography, FormControl, Radio, RadioGroup, FormControlLabel, Box, Card, IconButton, Container, Divider, MenuItem,
} from "@mui/material";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import SelectFieldComponent from "components/SelectField";
import { H6, SpanInline } from "components/CustomTypography";
import CustomSnackBar from "components/CustomSnackBar";

import * as Actions from "actions";
import * as ActionsBase from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";
import { getCartTotal } from "services";
import ClearSharpIcon from "@mui/material/SvgIcon/SvgIcon";

const ShopCheckout = (props) => {
  const [state, setState] = useState({
    address: "",
    isDelivery: false,
    shippingoption: "pickup",
    delivery_time: "10to12",
  });

  useEffect(() => {
    props.getShippingAddress();
  }, []);

  useEffect(() => {
    switch (props.reqStatus) {
      case ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_SUCCESS:
        if (props.serviceCode === 200) {
          if (props.bootupInfo.appState.isWebsite) {
            props.history.push({
              pathname: "/cart/payment-page",
              state: {
              // payment: { paymentID: props.order.order.order_id },
                order_id: props.order.order.order_id,
                items: props.order.order.products,
                address: state.address,
                shipping: props.order.order.shipping,
                created_at: props.order.order.created_at,
                order_type: props.order.order.order_type,
                delivery_date: props.order.order.delivery_date,
                delivery_time: props.order.order.delivery_time,
                total_price: props.order.order.total_price,
                total_shipping: props.order.order.total_shipping,
                total_order: props.order.order.total_order,
                symbol: props.symbol,
              },
            });
          } else {
            props.history.push({
              pathname: `/shop/${props.bootupInfo.appState.subdomain_shopId}/cart/payment-page`,
              state: {
              // payment: { paymentID: props.order.order.order_id },
                order_id: props.order.order.order_id,
                items: props.order.order.products,
                address: state.address,
                shipping: props.order.order.shipping,
                created_at: props.order.order.created_at,
                order_type: props.order.order.order_type,
                delivery_date: props.order.order.delivery_date,
                delivery_time: props.order.order.delivery_time,
                total_price: props.order.order.total_price,
                total_shipping: props.order.order.total_shipping,
                total_order: props.order.order.total_order,
                symbol: props.symbol,
              },
            });
          }
        }
        break;
      default:
        break;
    }
  }, [props.reqStatus]);

  const handleShippingOption = (event) => {
    if (event.target.value === "delivery") {
      setState({ ...state, isDelivery: true, shippingoption: "delivery" });
    } else {
      setState({ ...state, isDelivery: false, shippingoption: "pickup" });
    }
  };

  const handleDeliveryDate = (update) => {
    setState({ ...state, delivery_date: update });
  };

  const handleDeliveryTime = (event) => {
    setState({ ...state, delivery_time: event.target.value });
  };

  const handleAddressSelect = (event) => {
    setState({ ...state, address: event.target.value });
  };

  const handleCheckoutOrder = () => {
    if (state.isDelivery && !state.address) { return; }

    props.checkoutProductOrder(state, props.bootupInfo.appState.subdomain_shopId, props.bootupInfo.appState.isWebsite);
  };

  const handlePage = (page) => () => {
    props.history.push({
      pathname: page,
    });
  };

  const {
    order, cartItems, symbol, total, shippingAddress,
  } = props;

  // Paypal Integration
  const onSuccess = (payment) => {
    if (props.bootupInfo.appState.isWebsite) {
      props.history.push({
        pathname: "/order-success",
        state: {
          payment,
          items: order.products,
          shipping: order.shipping,
          delivery_date: order.delivery_date,
          delivery_time: order.delivery_time,
          orderTotal: total,
          symbol,
        },
      });
    } else {
      props.history.push({
        pathname: "/order-success",
        state: {
          payment,
          items: order.products,
          shipping: order.shipping,
          delivery_date: order.delivery_date,
          delivery_time: order.delivery_time,
          orderTotal: total,
          symbol,
        },
      });
    }
  };

  const client = {
    sandbox: "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
    production: "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
  };

  const getPrice = (symbol, item) => {
    const price = item.seller_price
        - (item.product && item.product.discount
          ? (item.seller_price * (parseInt(item.product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {symbol}
        {price}
        {item.product.discount && (
          <del>
            <span className="money">
              {symbol}
              {item.seller_price}
            </span>
          </del>
        )}
      </Typography>
    );
  };

  const renderOrderItems = () => (
    <Box>
      <Card className="p-3">
        {cartItems.map((item, index) => (
          <Box className={item.product.images.length ? "styled-card" : "no-img-product"} width="100%" display="flex" p={1}>
            <Box width="15%" order={1}>
              <img
                alt=""
                className="img-product"
                src={item.product.images && item.product.images[0] ? item.product.images[0].cdn.url : ""}
              />
            </Box>
            <Box width="85%" className="ml-1" order={2} display="flex">
              <Box flexGrow={1}>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                  {item.product.name}
                </Typography>
                {getPrice(symbol, item)}
              </Box>
            </Box>
          </Box>
        ))}
      </Card>
    </Box>
  );

  const options1 = [
    { label: "Pickup", value: "pickup" },
    { label: "Delivery", value: "delivery" },
  ];

  const inputShippingOptions = options1.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });

  const addressOptions = shippingAddress.length > 0 && shippingAddress.map((item) => {
    const {
      _id, name, address, city,
    } = item;

    return (
      <MenuItem value={_id}>{address}</MenuItem>
    );
  });

  return (
    <div>
      {props.serviceCode !== 200 ?
          <CustomSnackBar
              status
              errorMessage={props.serviceError}
          /> : ""
      }
      <form>
        <Container>
          <Box alignItems="flex-start" mb={2}>
            <Card className="p-3">
              <Box>
                <RadioButtonFieldComponent
                  row
                  required
                  options={inputShippingOptions}
                  label="shippingoption"
                  value={state.shippingoption}
                  onChange={(event) => handleShippingOption(event)}
                />
                { state.isDelivery && (
                  <Box mb={3}>
                    {shippingAddress.length < 1 ? (
                      <ButtonComponent
                        variant="contained"
                        type="primary"
                        name="Add Delivery Address"
                        fullWidth
                        onClick={() => handlePage("/me/address")}
                      />
                    )
                      : (
                        <SelectFieldComponent
                          required
                          options={addressOptions}
                          label="Delivery Address"
                          value={state.address}
                          onChange={(event) => handleAddressSelect(event)}
                        />
                      )}
                  </Box>
                )}
              </Box>
              <Box>
                <SpanInline>
                  { state.isDelivery ? "Delivery" : "Pickup"}
                  {" "}
                  Date
                </SpanInline>
                <Box display="flex">
                  <Box width="50%">
                    <style>
                      {`.date-picker input {
                        border:2px;
                        background-color: #eee;
                        color:#000;
                        }`}
                    </style>
                    <DatePicker
                      wrapperClassName="date-picker"
                      placeholderText="Delivery Date"
                      selected={state.delivery_date ? state.delivery_date : state.delivery_date = new Date()}
                      minDate={new Date()}
                      onChange={handleDeliveryDate}
                    />
                  </Box>
                  <Box width="50%">
                    <SelectFieldComponent name="delivery_time" label="Preferred Time" value={state.delivery_time} onChange={(event) => handleDeliveryTime(event)}>
                      <MenuItem value="10to12">10:00am to 12:00pm</MenuItem>
                      <MenuItem value="12to2">12:00pm to 2:00pm</MenuItem>
                      <MenuItem value="2to4">2:00pm to 4:00pm</MenuItem>
                      <MenuItem value="4to6">4:00pm to 6:00pm</MenuItem>
                      <MenuItem value="6to8">6:00pm to 8:00pm</MenuItem>
                    </SelectFieldComponent>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>

          {renderOrderItems()}

          <Container>
            <Typography display="block" align="right" component="caption" variant="h6">{`Total: ${symbol} ${total}`}</Typography>
          </Container>

          <div className="payment-box">
            {(total || !total)
              ? (

                <Typography className="m-2" align="center">
                  <ButtonComponent
                    variant="contained"
                    type="primary"
                    name="Continue"
                    fullWidth
                    onClick={() => handleCheckoutOrder()}
                  />
                </Typography>
              )
              : ""}
          </div>

        </Container>
      </form>
    </div>

  );
};

// Mapping Props and State.
const shopCheckout = connect(
  ({ cartList, bootupInfo, userproducts }) => ({
    bootupInfo,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
    serviceCode: cartList.serviceCode,
    cartItems: cartList.cart,
    total: getCartTotal(cartList.cart),
    order: cartList.order,
    shippingAddress: bootupInfo.shippingAddress,
    symbol: userproducts.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions, ...ActionsBase }, dispatch),
)(ShopCheckout);

export { shopCheckout as default };
