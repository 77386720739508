// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";

const querystring = require('querystring');

function* usergetCommonProducts(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "", category: action.category });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/users/products/get-products` + query
    );
    yield put(Actions.usergetCommonProductsSuccess(response));
  } catch (error) {
    yield put(Actions.usergetCommonProductsFailure(error));
  }
}

function* usergetPopularProducts(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "", popular: "1" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/products/get-products`+ query
    );
    yield put(Actions.usergetPopularProductsSuccess(response));
  } catch (error) {
    yield put(Actions.usergetPopularProductsFailure(error));
  }
}

function* usergetProductCategories(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/categories/get-categories`+ query
    );
    yield put(Actions.usergetProductCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.usergetProductCategoriesFailure(error));
  }
}

function* usergetProductPopularCategories(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "", popular: "1" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/categories/get-categories`+ query
    );
    yield put(Actions.usergetProductPopularCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.usergetProductPopularCategoriesFailure(error));
  }
}

function* usersearchProducts(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "", name: action.keyword });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/products/get-products`+ query
    );
    yield put(Actions.usersearchProductsSuccess(response));
  } catch (error) {
    yield put(Actions.usersearchProductsFailure(error));
  }
}

function* usersearchKeywords(action: Object) {
  try {
    const searchQuery = (action.isShop) ?
        ('/users/products/seller/get-keywords?name=' + action.keyword + '&shop=' + action.shopId + ((action.isShopDomain) ? "&isDomain=1" : "")) :
        ('/users/products/get-keywords?name=' + action.keyword);

    const response = yield API.get(searchQuery);
    yield put(Actions.usersearchKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.usersearchKeywordsFailure(error));
  }
}

function* usergetProductPrices(action: Object) {

  try {

    let query = {};

//    if ('geolocation' in window) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

       query = {
        lat: latitude,
        lng: longitude,
      };
//    }

    const prices = yield API.get(
        `/users/products/seller/get-prices/` + action.payload + ((action.isProd) ? "?isProd=1" : ""),
        { params: query }
    );
    yield put(Actions.usergetProductPricesSuccess(prices));
  } catch (error) {
    yield put(Actions.usergetProductPricesFailure(error));
  }
}

function* getCartProducts(action: Object) {
  try {
    const searchQuery = action.payload
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.get(
        `/users/cart/get-cart?shop=` + searchQuery,
        { withCredentials: true }
    );
    yield put(Actions.getCartProductsSuccess(response));
  } catch (error) {
    yield put(Actions.getCartProductsFailure(error));
  }
}

function* addProductToCart(action: Object) {
  try {
    const searchQuery = action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.post(
        `users/cart/add-product?shop=` + searchQuery,
        { "product_id" : action.payload._id},
        { withCredentials: true }
    );
    yield put(Actions.addProductToCartSuccess(response, action.payload._id));
  } catch (error) {
    yield put(Actions.addProductToCartFailure(error));
  }
}

function* removeProductFromCart(action: Object) {
  try {
    const searchQuery = action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.post(
        `users/cart/remove-product?shop=` + searchQuery,
        { "product_id" : action.payload.product.id},
        { withCredentials: true }
    );
    yield put(Actions.removeProductFromCartSuccess(response, action.payload.product.id));
  } catch (error) {
    yield put(Actions.removeProductFromCartFailure(error));
  }
}

function* incrementProductInCart(action: Object) {
  try {
    const searchQuery = action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.post(
        `users/cart/increment/1?shop=` + searchQuery,
        { "product_id" : action.payload.product.id},
        { withCredentials: true }
    );
    yield put(Actions.incrementProductInCartSuccess(response, action.payload.product.id));
  } catch (error) {
    yield put(Actions.incrementProductInCartFailure(error));
  }
}

function* decrementProductInCart(action: Object) {
  try {
    const searchQuery = action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.post(
        `users/cart/decrement/1?shop=` + searchQuery,
        { "product_id" : action.payload.product.id},
        { withCredentials: true }
    );
    yield put(Actions.decrementProductInCartSuccess(response, action.payload.product.id));
  } catch (error) {
    yield put(Actions.decrementProductInCartFailure(error));
  }
}

function* checkoutProductOrder(action: Object) {
  try {
    const searchQuery = action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.post(
        `users/checkout/checkout-cart-verify?shop=` + searchQuery,
        action.payload
    );
    yield put(Actions.checkoutProductOrderSuccess(response));
  } catch (error) {
    yield put(Actions.checkoutProductOrderFailure(error));
  }
}

function* generateProductOrder(action: Object) {
  try {
    const searchQuery = action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.post(
        `users/checkout/buy-cart-completed?shop=` + searchQuery,
          action.payload
    );
    yield put(Actions.generateProductOrderSuccess(response));
  } catch (error) {
    yield put(Actions.generateProductOrderFailure(error));
  }
}

function* getWishlistProducts(action: Object) {
  try {
    const response = yield API.get(
        `/users/products/wishlist/get-wishlist`,
        { withCredentials: true }
    );
    yield put(Actions.getWishlistProductsSuccess(response));
  } catch (error) {
    yield put(Actions.getWishlistProductsFailure(error));
  }
}

function* getShopWishlistProducts(action: Object) {
  try {
    const searchQuery = action.payload
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.get(
        `/users/products/wishlist/get-wishlist?shop=` + searchQuery,
        { withCredentials: true }
    );
    yield put(Actions.getShopWishlistProductsSuccess(response));
  } catch (error) {
    yield put(Actions.getShopWishlistProductsFailure(error));
  }
}

function* addProductToWishlist(action: Object) {
  try {
    const response = yield API.post(
        `users/products/wishlist/add-product`,
        { "product_id" : action.payload._id},
        { withCredentials: true }
    );
    yield put(Actions.addProductToWishlistSuccess(response, action.payload._id));
  } catch (error) {
    yield put(Actions.addProductToWishlistFailure(error));
  }
}

function* removeProductFromWishlist(action: Object) {
  try {
    const response = yield API.post(
        `users/products/wishlist/remove-product`,
        { "product_id" : action.payload.product.id},
        { withCredentials: true }
    );
    yield put(Actions.removeProductFromWishlistSuccess(response, action.payload.product.id));
  } catch (error) {
    yield put(Actions.removeProductFromWishlistFailure(error));
  }
}

export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_GET_COMMON_PRODUCTS_START,       usergetCommonProducts);
  yield takeLatest(ActionTypes.USER_GET_POPULAR_PRODUCTS_START,      usergetPopularProducts);
  yield takeLatest(ActionTypes.USER_GET_ACTIVE_CATEGORIES_START,     usergetProductCategories);
  yield takeLatest(ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_START, usergetProductPopularCategories);
  yield takeLatest(ActionTypes.USER_SEARCH_PRODUCTS_START,           usersearchProducts);
  yield takeLatest(ActionTypes.USER_SEARCH_KEYWORDS_START,           usersearchKeywords);
  yield takeLatest(ActionTypes.USER_GET_PRODUCT_PRICES_UPC_START,    usergetProductPrices);
  yield takeLatest(ActionTypes.USER_GET_CART_PRODUCTS_START,         getCartProducts);
  yield takeLatest(ActionTypes.USER_ADD_PRODUCT_TO_CART_START,       addProductToCart);
  yield takeLatest(ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_START,  removeProductFromCart);
  yield takeLatest(ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_START, incrementProductInCart);
  yield takeLatest(ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_START, decrementProductInCart);
  yield takeLatest(ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_START,    checkoutProductOrder);
  yield takeLatest(ActionTypes.USER_GENERATE_PRODUCT_ORDER_START,    generateProductOrder);
  yield takeLatest(ActionTypes.USER_GET_WISHLIST_PRODUCTS_START,         getWishlistProducts);
  yield takeLatest(ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_START,    getShopWishlistProducts);
  yield takeLatest(ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_START,       addProductToWishlist);
  yield takeLatest(ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_START,  removeProductFromWishlist);

}
