//  @flow
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Notification,
  Pace,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.store";
import * as ActionTypes from "constants/ActionTypes";

import ShopProfileEditor from "components/elements/seller/ShopProfileEditor";

import "./styles.scss";

const StoreProfileName = (props) => {
  const [state, setState] = useState({shopprofileMode: "PREVIEW" });

  const {
    isLoading, serviceError, reqStatus, storeProfile,
    updateStoreProfile,
  } = props;

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_PROFILE_SUCCESS:
        props.history.push({
          pathname: "/shop",
        });
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const handleShopProfileEdit = (mode) => (e) => {
    setState({ shopprofileMode: mode });
  };

  const updateShopProfileCall = (values) => {
    setState({ userProfileNameMode: "PREVIEW" });
    updateStoreProfile(values);
  };

  const handleShopProfileEditCancel = (mode) => (e) => {
    setState({ shopprofileMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  return (
    <Page className="subscriptions-home">
      {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
      <div className="panel--white">
        <ShopProfileEditor
          profile={storeProfile}
          updateShopProfileCall={updateShopProfileCall}
          cancelEdit={handleShopProfileEditCancel("PREVIEW")}
        />
      </div>
    </Page>
  );
};

StoreProfileName.defaultProps = {
  isLoading: false,
};

StoreProfileName.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  storeProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  updateStoreProfile: PropTypes.func.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const _StoreProfileName = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileName);

export { _StoreProfileName as default };
