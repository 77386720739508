/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import "components/elements/main/index.scss";

import UserProductSlider from "components/elements/user/UserProductSlider";
import UserShopsSlider from "components/elements/user/UserShopsSlider";
import SpecialProducts from "components/elements/user/SpecialProducts";
import ShopProductSearch from "components/widgets/user/ShopProductSearch";

const User = (props) => {
  const [state, setState] = useState({
    layoutColumns: 3,
  });

  useEffect(() => {
    document.getElementById("color").setAttribute("href", "#");
  }, []);

  return (
    <div>
      <ShopProductSearch {...props} colSize={state.layoutColumns} />
      <UserShopsSlider />
      <UserProductSlider />
      <SpecialProducts />
      {/* <Trending2 /> */}
      <br />
      <br />
      <br />
    </div>
  );
};

export default User;
