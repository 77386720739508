import * as React from "react";
import { Form, Input, Button, Alert } from "components/Atoms";
import * as ActionTypes     from 'constants/ActionTypes';

const PasswordResetSuccess = ({ showLogin, serviceError, serviceCode, reqStatus }) => {
  const [form] = Form.useForm();

  return (
    <div className="form login">
        <h4>Password Reset: </h4>
      Please check your email. If an account exists with the provided email then reset password by clicking the link provided in email
      <Form
        name="login-form"
        form={form}
        layout={"vertical"}
      >
      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          style={{ width: "50%" }}
          onClick={showLogin}
        >
          Login
        </Button>
      </Form.Item>
      <Form.Item>
        {reqStatus === ActionTypes.REGISTER_FAILURE && (
            <Alert
                className="error-message"
                message={serviceError}
                type="error"
                description={serviceError}
                showIcon
            />
        )}
        { serviceCode && serviceCode !== 200 && (
            <Alert
                className="message"
                message={serviceError}
                type="error"
                showIcon
            />
        )}
      </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordResetSuccess;
