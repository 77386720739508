import * as React from "react";
import ImageGallery from "react-image-gallery";
import { LeftOutlined, RightOutlined, ExpandOutlined } from "@ant-design/icons";
import "./style.scss";

const _ImageGallery = (props) => {
  const galleryProps = {
    ...props,
    additionalClass: `spn-gallery ${props.additionalClass}`,
    renderLeftNav: (onClick, disabled) => (
      <LeftOutlined
        className="nav-button left-nv"
        disabled={disabled}
        onClick={onClick}
      />
    ),
    renderRightNav: (onClick, disabled) => (
      <RightOutlined
        className="nav-button right-nv"
        disabled={disabled}
        onClick={onClick}
      />
    ),
    renderFullscreenButton: (onClick, isFullscreen) => {
      return (
        <ExpandOutlined
          className={`full-screen${isFullscreen ? " active" : ""}`}
          onClick={onClick}
        />
      );
    },
  };

  return <ImageGallery showFullscreenButton={false} {...galleryProps} />;
};

export default _ImageGallery;
