/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";

import { Router, Switch } from "react-router-dom";
import { IntlProvider } from "react-redux-multilingual";
import { createHashHistory } from "history";
import "./styles/App.scss";
import "./styles/App.less";
import "./index.scss";
import store from "./store";
import * as translations from "./constants/translations.json";
import ErrorBoundary from "./routes/ErrorBoundary";
import {
  UserWebsiteRoutes, SellerMgmtRoutes, AdminMgmtRoutes, SellerWebsiteRoutes,
} from "./routes";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import { SellerWebSiteExcludedRoutes, UserWebSiteExcludedRoutes, AdminMgmtExcludededRoutes, SellerMgmtExcludedRoutes } from "./config/ExcludedRoutes";

import Bootstrap from "./components/hoc/Bootstrap";
import theme from "./theme/theme";

const history = createHashHistory();

const App = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider translations={translations} locale="en">
          <Router basename="/#" history={history}>
            <ErrorBoundary>
              <Bootstrap>
                <Suspense fallback={<p className="loading">loading</p>}>
                    <Switch>
                      {
                          {
                            SELLERAPP: SellerMgmtRoutes.map((item, index) => (
                              <AuthenticatedRoute
                                excluded={SellerMgmtExcludedRoutes}
                                key={`${index + 1}`}
                                path={item.path}
                                exact={item.exact}
                                component={item.component}
                                store={store}
                              />
                            )),
                            ADMINAPP: AdminMgmtRoutes.map((item, index) => (
                              <AuthenticatedRoute
                                excluded={AdminMgmtExcludededRoutes}
                                key={`${index + 1}`}
                                path={item.path}
                                exact={item.exact}
                                component={item.component}
                                store={store}
                              />
                            )),
                            USERSITE: SellerWebsiteRoutes.map((item, index) => (
                              <AuthenticatedRoute
                                  excluded={SellerWebSiteExcludedRoutes}
                                  key={`${index + 1}`}
                                path={item.path}
                                exact={item.exact}
                                component={item.component}
                                store={store}
                              />
                            )),
                          }[process.env.REACT_APP_TYPE] || UserWebsiteRoutes.map((item, index) => (
                            <AuthenticatedRoute
                                excluded={UserWebSiteExcludedRoutes}
                                key={`${index + 1}`}
                              path={item.path}
                              exact={item.exact}
                              component={item.component}
                              store={store}
                            />
                          ))
                        }
                    </Switch>
                </Suspense>
              </Bootstrap>
            </ErrorBoundary>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default App;
