// @flow
import * as React from "react";

import { Button } from "antd";
import { StopOutlined } from "@ant-design/icons";

import "./styles.scss";

type Props = {
  errorInfo?: string
};

const ErrorBoundary = (props: Props) => {
  const { errorInfo } = props;
  return (
    <div className="error-boundary" data-test="error-boundary">
      <StopOutlined className="icon" />
      <h2 className="title">Something went wrong!</h2>
      <Button
        className="link-home text-large"
        onClick={() => window.location.reload(window.location.origin)}
      >
        Click here to reload
      </Button>
      <div className="error">
        {errorInfo && <pre className="error-info">{errorInfo}</pre>}
      </div>
    </div>
  );
};

export { ErrorBoundary as default };
