import React from "react";
import PropTypes from "prop-types";
import { DATE_FORMAT } from "config/constants";
import { getLocalDate, getTimeSlot } from "helpers/utils";
import {
  Avatar, Box, Card, Container, Typography,
} from "@mui/material";

const OrderSuccess = (props) => {
  const { location } = props;
  const {
    order_id, items, shipping, symbol, total_price, total_shipping, total_order, created_at, order_type, delivery_date, delivery_time,
  } = location.state || {};

  const renderOrderInformation = () => (
    <Box p={2} width="100%" display="flex">
      <Box display="flex">
        <Typography variant="h5" display="block">Order Confirmation: {order_id}</Typography>
      </Box>
    </Box>
  );

  const renderOrderItems = () => (
    <Card className="p-3">
      {items.map((cartItem, index) => (
        <Card className="order-card" key={`${index + 1}`}>
          <Box minHeight="100%" width="100%" display="flex">
            <Box className="styled-card" width={window.innerWidth < 400 ? "17%" : "12%"}>
              {cartItem.product.images && cartItem.product.images.length ? <Avatar variant="square" src={`${cartItem.product.images && cartItem.product.images[0] ? cartItem.product.images[0].cdn.url : ""}`} alt="" /> : ""}
            </Box>
            <Box flexGrow={1}>
              <Typography className="styled-link" gutterBottom variant="h6" component="p">
                {cartItem.product.name}
              </Typography>
              <Typography display="block" variant="caption">
                {cartItem.quantity}
                {" "}
                x
                {symbol}
                {cartItem.seller_price}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {symbol}
                {cartItem.quantity * cartItem.seller_price}
              </Typography>
            </Box>
          </Box>
        </Card>
      ))}
    </Card>
  );

  const renderShippingAddress = () => (
    <Card className="p-3">
      <Typography variant="h6">Delivery</Typography>
      <Typography variant="subtitle2" display="block">
        {getLocalDate(delivery_date, DATE_FORMAT)}
        ,
        {getTimeSlot(delivery_time)}
      </Typography>
      <Typography variant="h6">Address</Typography>
      <Typography variant="subtitle2" display="block">
        {shipping.name}
      </Typography>
      <Typography variant="subtitle2" display="block">
        {shipping.address}
        ,
        {shipping.city}
        ,
        {shipping.pincode}
      </Typography>
      <Typography display="block" variant="subtitle2">
        {shipping.phone}
      </Typography>
    </Card>
  );

  const renderShopPickup = () => (
    <Card className="p-3">
      <Typography variant="h6">Pickup</Typography>
      <Typography variant="subtitle2" display="block">
        {getLocalDate(delivery_date, DATE_FORMAT)}
        ,
        {getTimeSlot(delivery_time)}
      </Typography>
    </Card>
  );

  const renderOrderTotal = () => (
    <Card className="p-3">
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Subtotal: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_price}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Shipping: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_shipping}
          </Typography>
        </Box>
      </Box>
      <hr className="solid" />
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Total price: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_order}
          </Typography>
        </Box>
      </Box>
    </Card>
  );

  return (
    (order_id)
      ? (
        <div>
          <Container maxWidth="md">
            <Container className="header-color">
              {renderOrderInformation()}
            </Container>

            <Box display={window.innerWidth < 400 ? "" : "flex"}>
              <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                {order_type === "delivery" ? renderShippingAddress() : renderShopPickup()}
              </Box>
            </Box>

            <Container>
              <Box>
                {renderOrderItems()}
              </Box>
              <Box>
                {renderOrderTotal()}
              </Box>
            </Container>

          </Container>
        </div>
      )
      : (
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <a href="/" className="btn btn-solid">back to home</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
  );
};

OrderSuccess.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderSuccess;
