/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography, Snackbar, Divider, Button,
} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CustomSnackBar from "components/CustomSnackBar";
import * as ActionTypes from "constants/ActionTypes";
// import {schema} from "../../seller/AddressEditor/helper";
import * as yup from "yup";

const LoginPreOTPForm = ({
  loginPreOTP, serviceError, reqStatus, showPasswordLogin,
}) => {
  const schema = yup.object().shape({
    mobile: yup.string().required("Requires valid mobile number").matches(/^[0-9]+$/, "Must be only digits").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits'),
  });

  const [values, setValues] = useState({
      mobile: "",
  });
  const [blur, setBlur] = useState(false);

  const onFinish = () => {
    loginPreOTP(values);
  };

  const hasError = () => {
    try {
      return !schema.validateSync({ mobile: values.mobile });
    } catch (err) {
      return true;
    }
  };

  const handleBlur = () => {
    setBlur(true);
  };

  const getError = (field) => {
    if (hasError()) {
      try {
        schema.validateSyncAt(field, values[field]);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <div className="form login">
      <Typography align="center" variant="h5">
        {process.env.REACT_APP_NAME}
      </Typography>
      <Typography align="center" variant="h6">Login</Typography>
      <br />
      <TextFieldComponent
        fullWidth
        value={values.mobile}
        label="Phone"
        required
        helperText={getError("mobile")}
        onChange={(event) => { setValues({ mobile: event.target.value }); setBlur(true); }}
        onBlur={handleBlur}
        error={!!getError("mobile")}
      />
      <Typography align="center">
        <br />
        <ButtonComponent
          variant="contained"
          color="primary"
          onClick={onFinish}
          name="Login"
          disabled={hasError()}
        />

        {reqStatus === ActionTypes.LOGIN_POST_OTP_FAILURE && (
            <CustomSnackBar
                status
                errorMessage={serviceError}
            />
        )}
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography align="right" variant="h6">
        <Button
          variant="text"
          onClick={showPasswordLogin}
        >
          Password Login
          {" "}
        </Button>
      </Typography>
    </div>
  );
};

LoginPreOTPForm.propTypes = {
  loginPreOTP: PropTypes.func.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LoginPreOTPForm;
