// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/seller/seller.products";

function* getActiveCategories(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/categories/get-active-categories`,
      action.payload
    );
    yield put(Actions.getActiveCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.getActiveCategoriesFailure(error));
  }
}
function* getCommonProducts(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/products/search-products-common` + (action.category ? `?category=` + action.category : ""),
      action.payload
    );
    yield put(Actions.getCommonProductsSuccess(response));
  } catch (error) {
    yield put(Actions.getCommonProductsFailure(error));
  }
}

function* getStoreProductCategories(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/categories/get-active-categories`,
      action.payload
    );
    yield put(Actions.getStoreProductCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreProductCategoriesFailure(error));
  }
}

function* getStoreProducts(action: Object) {
  try {
    const searchQuery = ((action.private === true) ? (`private=1`) : "") + ((action.category ? `&category=` + action.category : ""));

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/sellers/products/seller-products-common` + query,
      action.payload
    );
    yield put(Actions.getStoreProductsSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreProductsFailure(error));
  }
}
function* addProduct(action: Object) {
  try {
    const response = yield API.post(
      `sellers/products/add-product-common/` + action.payload.id,
      action.payload
    );
    yield put(Actions.addProductSuccess(response));
  } catch (error) {
    yield put(Actions.addProductFailure(error));
  }
}

function* updateProduct(action: Object) {
  try {
    const response = yield API.post(
        `sellers/products/update-product-common/` + action.payload.id,
        action.payload
    );
    yield put(Actions.updateProductSuccess(response));
  } catch (error) {
    yield put(Actions.updateProductFailure(error));
  }
}

function* removeProduct(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/products/remove-product-common/` + action.payload,
      action.payload
    );
    yield put(Actions.removeProductSuccess(response));
  } catch (error) {
    yield put(Actions.removeProductFailure(error));
  }
}
function* addProductUPC(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/products/add-product-using-upc/` + action.payload,
      action.payload
    );
    yield put(Actions.addProductUPCSuccess(response));
  } catch (error) {
    yield put(Actions.addProductUPCFailure(error));
  }
}
function* searchProducts(action: Object) {
  try {
    const searchQuery = ((action.isUPCBased) ? (`upc=` + action.upc) : (`name=` + action.payload +  (action.category ? `&category=` + action.category : "")));
    const response = yield API.get(
      `/sellers/products/search-products-common?` + searchQuery,
      action.payload
    );
    yield put(Actions.searchProductsSuccess(response.response));
  } catch (error) {
    yield put(Actions.searchProductsFailure(error));
  }
}
function* searchKeywords(action: Object) {
  try {
    //const searchQuery = ((action.isUPCBased) ? (`upc=` + action.upc) : (`name=` + action.payload +  (action.category ? `&category=` + action.category.id : "")));
    const response = yield API.get(
        `/sellers/products/seller-products-keywords?name=` + action.payload,
        action.payload
    );
    yield put(Actions.searchKeywordsSuccess(response.response));
  } catch (error) {
    yield put(Actions.searchKeywordsFailure(error));
  }
}
function* checkProducts(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/products/check-product-common/:id`,
      action.payload
    );
    yield put(Actions.checkProductsSuccess(response));
  } catch (error) {
    yield put(Actions.checkProductsFailure(error));
  }
}

function* addPrivateProduct(action: Object) {
  try {
    const response = yield API.post(
        `sellers/products/add-product-private`,
        action.payload
    );
    yield put(Actions.addPrivateProductSuccess(response));
  } catch (error) {
    yield put(Actions.addPrivateProductFailure(error));
  }
}

function* updatePrivateProduct(action: Object) {
  try {
    const response = yield API.post(
        `sellers/products/update-product-private/` + action.payload.prod_id,
        action.payload
    );
    yield put(Actions.updatePrivateProductSuccess(response));
  } catch (error) {
    yield put(Actions.updatePrivateProductFailure(error));
  }
}

function* removePrivateProduct(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/products/remove-product-private/` + action.payload,
        action.payload
    );
    yield put(Actions.removePrivateProductSuccess(response));
  } catch (error) {
    yield put(Actions.removePrivateProductFailure(error));
  }
}

function* addPrivateProductImage(action: Object) {
  try {
    const response = yield API.post(
        `sellers/products/add-private-images/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.addPrivateProductImageSuccess(response));
  } catch (error) {
    yield put(Actions.addPrivateProductImageFailure(error));
  }
}

function* removePrivateProductImage(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/products/remove-private-image`,
        action.payload
    );
    yield put(Actions.removePrivateProductImageSuccess(response));
  } catch (error) {
    yield put(Actions.removePrivateProductImageFailure(error));
  }
}

function* addPrivateCategory(action: Object) {
  try {
    const response = yield API.post(
        `sellers/categories/add-category`,
        action.payload
    );
    yield put(Actions.addPrivateCategorySuccess(response));
  } catch (error) {
    yield put(Actions.addPrivateCategoryFailure(error));
  }
}

function* updatePrivateCategory(action: Object) {
  try {
    const response = yield API.post(
        `sellers/categories/update-category/` + action.payload.id,
        action.payload
    );
    yield put(Actions.updatePrivateCategorySuccess(response));
  } catch (error) {
    yield put(Actions.updatePrivateCategoryFailure(error));
  }
}

function* updatePrivateCategorySetting(action: Object) {
  try {
    const response = yield API.post(
        `sellers/categories/update-category-setting/` + action.payload.id,
        action.payload
    );
    yield put(Actions.updatePrivateCategorySettingSuccess(response));
  } catch (error) {
    yield put(Actions.updatePrivateCategorySettingFailure(error));
  }
}

function* removePrivateCategory(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/categories/remove-category/` + action.payload,
        action.payload
    );
    yield put(Actions.removePrivateCategorySuccess(response));
  } catch (error) {
    yield put(Actions.removePrivateCategoryFailure(error));
  }
}

function* addPrivateCategoryImage(action: Object) {
  try {
    const response = yield API.post(
        `sellers/categories/update-category-image/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.addPrivateCategoryImageSuccess(response));
  } catch (error) {
    yield put(Actions.addPrivateCategoryImageFailure(error));
  }
}

function* removePrivateCategoryImage(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/categories/remove-category-image`,
        action.payload
    );
    yield put(Actions.removePrivateCategoryImageSuccess(response));
  } catch (error) {
    yield put(Actions.removePrivateCategoryImageFailure(error));
  }
}

export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(
    ActionTypes.GET_ACTIVE_CATEGORIES_START,
    getActiveCategories
  );
  yield takeLatest(ActionTypes.GET_COMMON_PRODUCTS_START, getCommonProducts);
  yield takeLatest(
    ActionTypes.GET_STORE_PRODUCT_CATEGORIES_START,
    getStoreProductCategories
  );
  yield takeLatest(ActionTypes.GET_STORE_PRODUCTS_START, getStoreProducts);
  yield takeLatest(ActionTypes.ADD_PRODUCT_START, addProduct);
  yield takeLatest(ActionTypes.UPDATE_PRODUCT_START, updateProduct);
  yield takeLatest(ActionTypes.REMOVE_PRODUCT_START, removeProduct);
  yield takeLatest(ActionTypes.ADD_PRODUCT_UPC_START, addProductUPC);
  yield takeLatest(ActionTypes.SEARCH_PRODUCTS_START, searchProducts);
  yield takeLatest(ActionTypes.SEARCH_KEYWORDS_START, searchKeywords);
  yield takeLatest(ActionTypes.CHECK_PRODUCT_START, checkProducts);
  yield takeLatest(ActionTypes.ADD_PRIVATE_PRODUCT_START, addPrivateProduct);
  yield takeLatest(ActionTypes.UPDATE_PRIVATE_PRODUCT_START, updatePrivateProduct);
  yield takeLatest(ActionTypes.REMOVE_PRIVATE_PRODUCT_START, removePrivateProduct);
  yield takeLatest(ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_START, addPrivateProductImage);
  yield takeLatest(ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_START, removePrivateProductImage);
  yield takeLatest(ActionTypes.ADD_PRIVATE_CATEGORY_START, addPrivateCategory);
  yield takeLatest(ActionTypes.UPDATE_PRIVATE_CATEGORY_START, updatePrivateCategory);
  yield takeLatest(ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_START, updatePrivateCategorySetting);
  yield takeLatest(ActionTypes.REMOVE_PRIVATE_CATEGORY_START, removePrivateCategory);
  yield takeLatest(ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_START, addPrivateCategoryImage);
  yield takeLatest(ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_START, removePrivateCategoryImage);

}
