/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { RadioGroup } from "@mui/material";
import PropTypes from "prop-types";

const RadioButtonFieldComponent = ({
   label, value, options, error, onChange, ...rest
}) => (
  <RadioGroup
    label={label}
    variant="standard"
    value={value}
    error={error}
    onChange={onChange}
    children={options}
    {...rest}
  />
);

RadioButtonFieldComponent.defaultProps = {
  type: "text",
};

RadioButtonFieldComponent.propTypes = {
  type: PropTypes.string,
};

export default RadioButtonFieldComponent;
