import * as React from "react";
import { Form, Input, Button, Alert, Divider } from "components/Atoms";
import * as ActionTypes     from 'constants/ActionTypes';

const LoginRegister = ({ register, showLogin, serviceError, serviceCode, reqStatus }) => {
  const [form] = Form.useForm();

  const onFinish = (registerDetails) => {
    form.validateFields().then((registerDetails) => {
      register(registerDetails);
    });
  };

  return (
    <div className="form login">
      <h3>Register</h3>
      <Form
        name="login-form"
        form={form}
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Form.Item
          className="login-input"
          label="Email Id"
          name="email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="login-input"
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter password" }]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
            className="login-input"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
            className="login-input"
            label="phone"
            name="phone"
            rules={[{ required: true, message: "Please enter phone" }]}
        >
          <Input type="phone" />
        </Form.Item>
      </Form>

      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          style={{ width: "50%", float: "right" }}
          onClick={onFinish}
        >
          Register
        </Button>
      </Form.Item>
        <Divider />
      <Form.Item>
          Already a member?
      <a
          type="link"
          style={{ width: "50%" }}
          onClick={showLogin}
      >
        Login
      </a>

        {  reqStatus === ActionTypes.REGISTER_FAILURE && (
            <Alert
                className="message"
                message={serviceError}
                type="error"
                description={serviceError}
                showIcon
            />
        )}
        { serviceCode && serviceCode !== 200 && (
            <Alert
                className="message"
                message={serviceError}
                type="error"
                showIcon
            />
        )}
      </Form.Item>
    </div>
  );
};

export default LoginRegister;
