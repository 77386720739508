import axios from "axios";

export const serviceEndPointBaseURL = process.env.REACT_APP_API_ENDPOINT;
 const headerConfig = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', };

const createAxiosInstance = (version) => {
  return axios.create({
    baseURL: `${serviceEndPointBaseURL}/api/${version}`,
    timeout: 60000,
    headers: headerConfig,
    //withCredentials: true,
    crossDomain: true,
    authRequired: false
    // rejectUnauthorized: false, //add when working with https sites
    // requestCert: false, //add when working with https sites
    // agent: false,
  });
};

const API = createAxiosInstance("v1");

export const APIv2 = createAxiosInstance("v2");

export default API;
