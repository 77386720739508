/* eslint-disable import/no-unresolved */
//  @flow
import * as React from "react";
import { Box, Container } from "@mui/material";
import { H4 } from 'components/CustomTypography';
import ShippingAddressList from "components/widgets/core/ShippingAddressList";

import "./styles.scss";

const ProfileAddresses = () => (
  <Container>
      <Box>
      <H4 sx={{
          m: 1,
      }}>Delivery Address</H4>
    <ShippingAddressList />
      </Box>
  </Container>
);

export default (ProfileAddresses);
