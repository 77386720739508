import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import {Avatar, Box, Card, Container, Paper, Typography} from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  rootActive: ({ accentColor = '#ddd' }) => ({
    backgroundColor: accentColor,
  }),
  icon: ({ accentColor = '#ddd' }) => ({
    minWidth: 40,
    opacity: 0.6,
    color: accentColor,
  }),
  primary: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily:
        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    fontWeight: 900,
    opacity: 0.6,
  },
  iconActive: () => ({
    opacity: 0.87,
  }),
  primaryActive: {
    opacity: 1,
  },
}));

const MockOrderDetail = ({
  active,
  accentColor,
  icon,
  primaryText,
  secondaryText,
}) => {
  const styles = useStyles({ accentColor });
  return (
      <div>
          <Container maxWidth="md">
              <Paper>
                  <Container className="header-color">
                      <Box p={2} width="100%" display="flex">
                          <Box width="33%">
                              <Typography className="styled-link" variant="subtitle2"></Typography>
                          </Box>
                          <Box width="33%">
                              <Typography className="styled-link" align="center" component="h5" variant="subtitle2"></Typography>
                          </Box>
                          <Box width="34%">
                              <Typography className="styled-link" align="right" display="block" variant="subtitle2"></Typography>
                          </Box>
                      </Box>
                  </Container>
              </Paper>
              <div className="orderlist-container">
              </div>
              <br />
              <Box display="flex">
                  <Box width="50%" className="mr-2">
                      <Card className="p-3">
                          <Typography gutterBottom variant="h6"></Typography>
                          <Typography component="caption" display="block" variant="subtitle2"></Typography>
                      </Card>
                  </Box>
                  <Box width="50%" className="ml-2">
                      <Card className="p-3">
                          <Typography gutterBottom variant="h6"></Typography>
                          <Box display="flex">
                              <Box width="50%">
                                  <Typography component="caption" display="block" variant="subtitle2"></Typography>
                              </Box>
                              <Box width="50%">
                                  <Typography component="caption" variant="subtitle2" display="block" align="right"></Typography>
                              </Box>
                          </Box>
                          <Box display="flex">
                              <Box width="50%">
                                  <Typography component="caption" display="block" variant="subtitle2"></Typography>
                              </Box>
                              <Box width="50%">
                                  <Typography component="caption" variant="subtitle2" display="block" align="right"></Typography>
                              </Box>
                          </Box>
                          <hr className="solid" />
                          <Box display="flex">
                              <Box width="50%">
                                  <Typography component="caption" display="block" variant="subtitle2"></Typography>
                              </Box>
                              <Box width="50%">
                                  <Typography component="caption" variant="subtitle2" display="block" align="right"></Typography>
                              </Box>
                          </Box>
                      </Card>
                  </Box>
              </Box>
              <br />
          </Container>
      </div>
  );
};

MockOrderDetail.propTypes = {
  active: PropTypes.bool,
  accentColor: PropTypes.string,
  icon: PropTypes.node,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
};
MockOrderDetail.defaultProps = {
  active: true,
  accentColor: undefined,
  icon: undefined,
  primaryText: undefined,
  secondaryText: undefined,
};

export default MockOrderDetail;
