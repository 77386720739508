/* eslint-disable react/prop-types */
import React from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const CustomRightArrow = ({ onClick: action }) => (
  <IconButton onClick={action} color="primary" className="view-icon-right" size="small">
    <KeyboardArrowRightIcon />
  </IconButton>
);
export const CustomLeftArrow = ({ onClick: action }) => (
  <IconButton onClick={action} color="primary" className="view-icon-left" size="small">
    <KeyboardArrowLeftIcon />
  </IconButton>
);
