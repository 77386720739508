import * as React from "react";

import OrdersMain     from "components/pages/seller/orders/OrdersMain";

import "./style.scss";

class OrdersReadyPickup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <OrdersMain {...this.props} orderstatus="pickupready" title="Ready Orders"></OrdersMain>
    );
  }
}

export default (OrdersReadyPickup)


