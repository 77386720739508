import * as React from "react";
import { Button } from "components/Atoms";

import { currencyFormatter } from "helpers/utils";

import "./style.scss";

type Props = {
  className?: ?string,
};

const AvailablePlan = (props: Props) => {
  const { data, addToCart, removeFromCart } = props;
  const { title, description, duration, pricing, addedToCart } =
    props.data || {};

  // const expiryFromNow = moment(expiryDate).fromNow(); //format("MMM/DD/YYYY :hh:mm:A");
  return (
    <div className="available-subscription">
      <h4>{title}</h4>
      <p className="description">{description}</p>
      <h5 className="price">{currencyFormatter(pricing)}</h5>
      <div className="footer">
        <div className="validity">
          <span className="value text large bold">{duration}</span>
          <label className="label">Days</label>
        </div>
        {addedToCart ? (
          <Button
            type="secondary"
            size="large"
            className=""
            onClick={removeFromCart}
          >
            Remove From Cart
          </Button>
        ) : (
          <Button type="primary" size="large" className="" onClick={addToCart}>
            Add to Cart
          </Button>
        )}
      </div>
    </div>
  );
};

export default AvailablePlan;
