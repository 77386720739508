// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const getStoreOrderCategories = (reqData) => ({
  type: ActionTypes.GET_STORE_ORDER_CATEGORIES_START,
  payload: reqData,
});

export const getStoreOrderCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_ORDER_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const getStoreOrderCategoriesFailure = (error) => ({
  type: ActionTypes.GET_STORE_ORDER_CATEGORIES_FAILURE,
  payload: error,
});

export const getCommonOrders = (reqData, category) => ({
  type: ActionTypes.GET_COMMON_ORDERS_START,
  payload: reqData,
  category: category
});

export const getCommonOrdersSuccess = (data) => {
  return {
    type: ActionTypes.GET_COMMON_ORDERS_SUCCESS,
    payload: data,
  };
};
export const getCommonOrdersFailure = (error) => ({
  type: ActionTypes.GET_COMMON_ORDERS_FAILURE,
  payload: error,
});

export const getStoreOrders = (reqData, ordertype) => ({
  type: ActionTypes.GET_STORE_ORDERS_START,
  orderstatus: reqData,
  ordertype: ordertype
});

export const getStoreOrdersSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_ORDERS_SUCCESS,
    payload: data,
  };
};
export const getStoreOrdersFailure = (error) => ({
  type: ActionTypes.GET_STORE_ORDERS_FAILURE,
  payload: error,
});

export const getSellerOrderDetail = (reqData) => ({
  type: ActionTypes.GET_ORDER_DETAIL_START,
  payload: reqData,
});

export const getSellerOrderDetailSuccess = (data) => {
  return {
    type: ActionTypes.GET_ORDER_DETAIL_SUCCESS,
    payload: data,
  };
};
export const getSellerOrderDetailFailure = (error) => ({
  type: ActionTypes.GET_ORDER_DETAIL_FAILURE,
  payload: error,
});

export const addOrder = (reqData) => ({
  type: ActionTypes.ADD_ORDER_START,
  payload: reqData,
});

export const addOrderSuccess = (data) => {
  return {
    type: ActionTypes.ADD_ORDER_SUCCESS,
    payload: data,
  };
};
export const addOrderFailure = (error) => ({
  type: ActionTypes.ADD_ORDER_FAILURE,
  payload: error,
});

export const updateOrder = (reqData) => ({
  type: ActionTypes.UPDATE_ORDER_START,
  payload: reqData,
});

export const updateOrderSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_ORDER_SUCCESS,
    payload: data,
  };
};
export const updateOrderFailure = (error) => ({
  type: ActionTypes.UPDATE_ORDER_FAILURE,
  payload: error,
});

export const removeOrder = (reqData) => ({
  type: ActionTypes.REMOVE_ORDER_START,
  payload: reqData,
});

export const removeOrderSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_ORDER_SUCCESS,
    payload: data,
  };
};
export const removeOrderFailure = (error) => ({
  type: ActionTypes.REMOVE_ORDER_FAILURE,
  payload: error,
});

export const addOrderUPC = (reqData) => ({
  type: ActionTypes.ADD_ORDER_UPC_START,
  payload: reqData,
});

export const addOrderUPCSuccess = (data) => {
  return {
    type: ActionTypes.ADD_ORDER_UPC_SUCCESS,
    payload: data,
  };
};

export const addOrderUPCFailure = (error) => ({
  type: ActionTypes.ADD_ORDER_UPC_FAILURE,
  payload: error,
});

export const searchOrders = (reqData, searchType) => ({
  type: ActionTypes.SEARCH_ORDERS_START,
  payload: reqData,
  searchType: searchType,
});

export const searchOrdersSuccess = (data) => {
  return {
    type: ActionTypes.SEARCH_ORDERS_SUCCESS,
    payload: data,
  };
};

export const searchOrdersFailure = (error) => ({
  type: ActionTypes.SEARCH_ORDERS_FAILURE,
  payload: error,
});

export const searchKeywords = (reqData) => ({
  type: ActionTypes.SEARCH_ORDERS_KEYWORDS_START,
  payload: reqData,
});

export const searchKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.SEARCH_ORDERS_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const searchKeywordsFailure = (error) => ({
  type: ActionTypes.SEARCH_ORDERS_KEYWORDS_FAILURE,
  payload: error,
});

export const checkOrders = (reqData) => ({
  type: ActionTypes.CHECK_ORDER_START,
  payload: reqData,
});

export const checkOrdersSuccess = (data) => {
  return {
    type: ActionTypes.CHECK_ORDER_SUCCESS,
    payload: data,
  };
};

export const checkOrdersFailure = (error) => ({
  type: ActionTypes.CHECK_ORDER_FAILURE,
  payload: error,
});
