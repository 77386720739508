import React, { Component } from "react";

import { Button } from "components/Atoms";
import { Box, CircularProgress, Container } from "@mui/material";
import Slider from "react-slick/lib";
import { Slider6 } from "services/script";
import { Link } from "react-router-dom";

const SellerCategoryMenuSlider = (props) => {
  const {
    categories, onCategorySelect, resetCategory, selectedCategory,
  } = props;

  const renderResults = () => (
    <Slider {...Slider6} className="product-4 product-m arrow">
      <span style={{ paddingRight: "5px" }}>
        {!selectedCategory
          ? <Button variant="contained" size="medium" color="primary" onClick={() => resetCategory()}>All</Button>
          : <Button variant="outlined" size="medium" color="secondary" onClick={() => resetCategory()}>All</Button>}
      </span>
      {categories.map((category, index) => (
        <span style={{ paddingRight: "5px" }}>
          {category.id === selectedCategory
            ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => onCategorySelect(category)}
              >
                {category.name}
              </Button>
            )
            : (
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={() => onCategorySelect(category)}
              >
                {category.name}
              </Button>
            )}
        </span>
      ))}
    </Slider>
  );

  const renderNoResults = () => (
    <Box> </Box>
  );

  return (
    <Box>
      {categories.length > 0
        ? renderResults()
        : renderNoResults()}
    </Box>

  );
};

export default SellerCategoryMenuSlider;
