//@flow
import moment from "moment";
import * as _ from "lodash";
// constants
import { DATE_FORMAT, TimeSlots } from "config/constants";
export const regEx = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[0-9]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,}).{8,}$/,
};

export const currencyFormatter = (
  value: number,
  format: string = "en-US",
  currency: string = "USD"
) => {
  const formatter = new Intl.NumberFormat(format, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });
  return _.isFinite(value) ? formatter.format(value) : "";
};

export const getLocalDate = (
  dateValue: string,
  dateFormat: string = DATE_FORMAT
) => {
  const formattedDate = moment
    .utc(dateValue)
    .local()
    .format(dateFormat);
  return formattedDate;
};

export const getTimeSlot = (
    timeslot: string,
) => {
  return (TimeSlots[timeslot] ? TimeSlots[timeslot] : "None" );
};

export const signedNumber = (value: any) => {
  let formattedValue = "NA";
  if (typeof value !== "number") {
    try {
      formattedValue = parseFloat(value);
    } catch (e) {
      return (formattedValue = "NA");
    }
  }

  if (Math.abs(value) === 0) return 0;
  formattedValue = `${value > 0 ? "+" : "-"}${Math.abs(value).toFixed(2)}`;
  return formattedValue;
};

export const validate = {
  email: (emailId) => regEx.email.test(emailId),
  password: (password) => regEx.password.test(password),
};

export const getFileExtension = (fileName) => {
  return fileName
    .slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
    .toLowerCase();
};
