import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class ShopItem extends Component {

    render() {
        const {shop, key} = this.props;

        return (
            <div className="row list-item no-gutters col-12" key={key}>
                <div className="col-3">
                <Link to={`${process.env.PUBLIC_URL}/user/shop/${shop.id}`}>
                    <img className="img-fluid-50width" src={`${shop.images ? shop.images[0] ? shop.images[0].cdn.url : "" : ""}`}  alt="" />
                </Link>
                </div>
                <div className="list-item-body col-9 align-self-center">
                    <Link to={`${process.env.PUBLIC_URL}/user/shop/${shop.id}`}>
                    <div className="col">
                        <h4>{shop.title}</h4>
                    </div>
                    <div className="col">
                        <h6>
                            <span className="money">{shop.subdomain}.{shop.domain}</span>
                        </h6>
                    </div>
                    <div className="col">
                        <h6 className="td-color">
                            {shop.subdomain}
                        </h6>
                    </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default ShopItem;
