import * as React from "react";

import { Button, ImageGallery } from "components/Atoms";
import { FileImageOutlined } from "@ant-design/icons";

import "./style.scss";

type Props = {
  className?: ?string,
  type: string,
  actions: React.Node,
};

const Product = (props: Props) => {
  const { data = {}, addactions, editactions, className = "" } = props;
  const { id, name, images, price, is_popular, is_price_visible, is_active, store_entry } = data;
  const galleryInput = images.map((image) => {
    const { id, cdn } = image;
    return {
      id,
      original: cdn.secure_url,
      thumbnail: cdn.secure_url,
    };
  });

  return (
    <div className={`product ${className}`}>
      <div className="photo-gallery">
        {galleryInput.length > 0 ? (
          <ImageGallery
            additionalClass="photos"
            items={galleryInput}
            showThumbnails={false}
            showPlayButton={false}
          />
        ) : (
          <div className="photos place-holder">
            <FileImageOutlined className="no-image" />
          </div>
        )}
      </div>
      <span className="name bold text medium">{name} {store_entry ? (store_entry.is_active ? "" : "(Inactive)") : ""}</span>
      <span className="footer">
          {store_entry ? (store_entry.is_price_visible ? <span className="pricevisible">{store_entry.price}</span> : <span className="pricenotvisible">{store_entry.price}</span>) : ""}
          {editactions && store_entry && <div className="actions">{editactions}</div>}
          {addactions && !store_entry && <div className="actions">{addactions}</div>}
      </span>
    </div>
  );
};

export default Product;
