import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import { Input, Button, Modal } from "components/Atoms";

import "./style.scss";

//import * as Actions   from "modules/products/actions";
import * as Actions     from "actions/seller/seller.products";

type Props = {
  storeId?: String,
};
class SearchModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    //this.filterCategories = _.debounce(this.filterCategories, 1000);
  }

  filterCategories = (value) => {
    this.setState({ searchText: value.toLowerCase() });
  };

  fetchSearchResults = ( updatedPageNo = '', query ) => {
    this.state.searchquery = query;
    console.log("search now : " + query);
    this.props.searchKeywords(query);
  };

  handleOnInputChange = ( event ) => {
    const query = event.target.value;
    console.log("Query : " + query);
    if ( ! query ) {
      this.setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
    } else {
      this.setState( { query, loading: true, message: '' }, () => {
        this.fetchSearchResults( 1, query );
      } );
    }
  };

  render() {
    const {
      isLoading,
      title,
      searchkeywords,
      storeId,
      visible,
      onOk,
      onCancel,
      onCategorySelect
    } = this.props;
    const { keywords } = searchkeywords;
    const { searchText } = this.state;
    return (
      <Modal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        type="full-page"
        className="dialog-category-browser"
      >
        <Input.Search
          placeholder="Search"
          onChange={this.handleOnInputChange}
        />
        <div className="category-list">
          {keywords.map((keyword, index) => {
            const { name, id } = keyword;
            if (searchText) {
              if (name.toLowerCase().indexOf(searchText) === -1) return null;
            }
            return (
              <span className="category-item" key={id}>
                <Button
                    onClick={() => this.props.onKeywordSelect(name)}
                >
                  {name}
                </Button>
              </span>
            );
          })}
        </div>
      </Modal>
    );
  }
}

//export default SearchModal;

const _SearchModal = connect(
    ({ products, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: products.reqStatus,
      isLoading: products.isLoading,
      serviceError: products.serviceError,
      searchkeywords: products.searchkeywords,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SearchModal);

export { _SearchModal as default };
