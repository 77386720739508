/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./shopwishlist.scss";

// import materialComponents
import {
  Card, Typography, IconButton, Container, Box,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import MockProductList from "components/elements/main/MockProductList";
import * as Actions from "actions";

const ShopWishList = (props) => {
  const [items, setItems] = useState([]);
  const {
    isLoading, shopwishlistItems, symbol, bootupInfo, getShopWishlistProducts,
    removeProductFromWishlist, addProductToCart,
  } = props;

  useEffect(() => {
    getShopWishlistProducts(bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite);
  }, []);
  useEffect(() => {
    if (!(JSON.stringify(shopwishlistItems) === JSON.stringify(items))) {
      setItems(shopwishlistItems);
    }
  }, [shopwishlistItems]);

  const notFound = () => (
    <section className="cart-section section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-12 empty-cart-cls text-center">
              <img src={`${process.env.PUBLIC_URL}/assets/images/empty-wishlist.png`} className="img-fluid mb-4" alt="" />
              <h3>
                <strong>WhishList is Empty</strong>
              </h3>
              <h4>Explore more shortlist some items.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const handleRemove = (item) => {
    removeProductFromWishlist(item);
    const newItems = items;
    newItems.splice(items.indexOf(item), 1);
    setItems(newItems);
  };

  const getPrice = (currencyFormat, product) => {
    const price = product.price
    - (product.discount ? (product.price * (parseInt(product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {currencyFormat}
        {price}
        {product.discount && (
        <del>
          <span className="money">
            {currencyFormat}
            {product.price}
          </span>
        </del>
        )}
      </Typography>
    );
  };

  if (isLoading) {
    return <MockProductList />;
  }

  if (!items.length) {
    return [notFound()];
  }

  return (
    <div>
      {/* SEO Support */}
      <Helmet>
        <title>{bootupInfo.shopinfo.title}</title>
        <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
      </Helmet>
      {/* SEO Support End */}
      <Container maxWidth="md">
        {items.map((item, index) => (
          <Card key={`${index + 1}`} className="m-3">
            <Box className={item.product.images.length ? "styled-card" : "no-img-product"} width="100%" display="flex" p={1}>
              <Box width="20%" order={1}>
                <img
                  alt=""
                  className="img-product"
                  src={item.product.images && item.product.images[0] ? item.product.images[0].cdn.url : ""}
                />
              </Box>
              <Box width="80%" className="ml-1" order={2} display="flex">
                <Box flexGrow={1}>
                  <Typography className="styled-link" gutterBottom variant="h6" component="p">
                    {item.product.name}
                  </Typography>
                  <Typography display="block" color="secondary" variant="caption">
                    In Stock
                  </Typography>
                  {getPrice(symbol, item.product)}
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => addProductToCart({ ...item.product, _id: item.product.id },
                      bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite)}
                  >
                    <AddShoppingCartIcon color="secondary" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleRemove(item)}>
                    <HighlightOffIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Card>
        ))}
      </Container>
      <br />
      <br />
    </div>
  );
};

ShopWishList.defaultProps = {
  symbol: "$",
};

ShopWishList.propTypes = {
  symbol: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  getShopWishlistProducts: PropTypes.func.isRequired,
  removeProductFromWishlist: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  shopwishlistItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const shopWishLists = connect(
  ({ cartList, bootupInfo }) => ({
    bootupInfo,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
    shopwishlistItems: cartList.shopwishlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopWishList);

export default shopWishLists;
