import React from "react";
import { Box } from '@mui/material';
import PropTypes from "prop-types";

const CustomFlexBox = ({ children, ...props }) => (
  <Box display="flex" {...props}>
    {children}
  </Box>
)

CustomFlexBox.defaultProps = {
  display: 'flex',
}

CustomFlexBox.propTypes = {
  display: PropTypes.string,
};

export default CustomFlexBox
