/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card, Typography, IconButton, Container, Box, Button,
} from "@mui/material";
import * as Actions from "actions";
import MockProductList from "components/elements/main/MockProductList";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const WishList = (props) => {
  const {
    isLoading, wishlistItems, symbol, getWishlistProducts,
  } = props;

  useEffect(() => {
    getWishlistProducts();
  }, []);

  const getPrice = (currencyFormat, product) => {
    const price = product.price
    - (product.discount ? (product.price * (parseInt(product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {currencyFormat}
        {price}
        {product.discount && (
        <del>
          <span className="money">
            {currencyFormat}
            {product.price}
          </span>
        </del>
        )}
      </Typography>
    );
  };

  if (isLoading) {
    return <MockProductList />;
  }

  return (
    <div>
      {wishlistItems.length > 0
        ? (
          <Container maxWidth="md">
            {wishlistItems.map((item, index) => (
              <Card key={`${index + 1}`} className="m-3">
                <Box className={item.product.images.length ? "styled-card" : "no-img-product"} width="100%" display="flex" p={1}>
                  <Box width="20%" order={1}>
                    <img
                      alt=""
                      className="img-product"
                      src={item.product.images && item.product.images[0] ? item.product.images[0].cdn.url : ""}
                    />
                  </Box>
                  <Box width="80%" className="ml-1" order={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography className="styled-link" gutterBottom variant="h6" component="p">
                        {item.product.name}
                      </Typography>
                      <Typography display="block" color="secondary" variant="caption">
                        In Stock
                      </Typography>
                      {getPrice(symbol, item.product)}
                    </Box>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => props.addProductToCart(item, 1)}
                      >
                        <AddShoppingCartIcon color="secondary" />
                      </IconButton>
                      <IconButton size="small" onClick={() => props.removeProductFromWishlist(item)}>
                        <HighlightOffIcon color="secondary" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Card>
            ))}
            <Container>
              <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                <Button to={() => props.history.push(`${process.env.PUBLIC_URL}/cart`)} className="btn m-1 btn-solid">View cart</Button>
                <Button to={() => props.history.push(`${process.env.PUBLIC_URL}/left-sidebar/collection`)} className="btn m-1 btn-solid">continue shopping</Button>
              </Box>
            </Container>
            <br />
            <br />
          </Container>
        )
        : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <h3>
                        <strong>No product added to favourite list</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
    </div>
  );
};

WishList.defaultProps = {
  isLoading: false,
  symbol: "$",
};

WishList.propTypes = {
  isLoading: PropTypes.bool,
  wishlistItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
  getWishlistProducts: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  removeProductFromWishlist: PropTypes.func.isRequired,
};

// Mapping Props and State.
const wishList = connect(
  ({ cartList, bootupInfo }) => ({
    bootupInfo,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
    wishlistItems: cartList.wishlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(WishList);

export default wishList;
