/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// @flow

// AdminApp routes

import { SearchLandingPage } from "components/pages/admin/pages";
import AdminShopList from "components/pages/admin/shops/AdminShopList";
import AdminProductList from "components/pages/admin/shops/AdminProductList";
import AdminProductAdd from "components/pages/admin/products/AdminProductAdd";
import AdminApprovePage from "components/pages/admin/pages/Approve";

export const AdminAppRoutes = [
  { path: "/shops", component: AdminShopList, exact: true },
  { path: "/search", component: SearchLandingPage, exact: true },
  { path: "/products/add", component: AdminProductAdd, exact: true },
  { path: "/products/shopId", component: AdminProductList, exact: true },
  { path: "/shop/approve", component: AdminApprovePage, exact: true },
];
