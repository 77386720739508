/* eslint-disable no-underscore-dangle */
import React from "react";
import {
  Card, Box, Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import "./category.scss";

const CategoryItem = ({ category, handleSelectCategory }) => (
  <Card onClick={() => handleSelectCategory(category.id)} className="product-card cursor-pointer">
    <div className="image-container">
      <Box className="front">
        {category.image && category.image.length ? (
          <img
            src={category.image[0].cdn.secure_url}
            className="product-image"
            alt=""
          />
        ) : ""}
      </Box>
    </div>
    <Box className="product-detail">
      <Typography align="center" variant="h5" className="text-truncate styled-link">{category.name}</Typography>
    </Box>
  </Card>
);

CategoryItem.propTypes = {
  category: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelectCategory: PropTypes.func.isRequired,
};

export default CategoryItem;
