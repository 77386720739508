import * as React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";

const ShippingAddressPreview = (props) => {
  const { shippingaddress, actions } = props;

  const {
    name, city, area, address, pincode, phone,
  } = shippingaddress;

  return (
    <Box display="flex">
      <Box width="25%" className="p-3">
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
      <Box overflow="hidden" width="40%" className="p-3">
        <Typography>
          {`${address || ""} ${area || ""} ${city || ""} ${pincode || ""}`}
        </Typography>
      </Box>
      <Box overflow="hidden" width="25%" className="p-3">
        <Typography>{phone}</Typography>
      </Box>
      <Box className="p-2" width="10%">
        <Typography align="right">
          {actions}
        </Typography>
      </Box>
    </Box>
  );
};

ShippingAddressPreview.defaultProps = {
  shippingaddress: {},
};

ShippingAddressPreview.propTypes = {
  shippingaddress: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.node.isRequired,
};

export default ShippingAddressPreview;
