/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Snackbar, Divider, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CustomSnackBar from "components/CustomSnackBar";
import * as ActionTypes from "constants/ActionTypes";

const LoginPostOTPForm = ({
  loginPostOTP, OTP_USER, serviceError, reqStatus,
}) => {
  const [otp, setOtp] = useState("");
  const [blur, setBlur] = useState(false);
  const { mobile } = OTP_USER;

  const onFinish = () => {
    loginPostOTP({ otp, mobile });
  };

  const onBlur = () => {
    setBlur(true);
  };

  return (
    <div className="form login">
        <Typography align="center" variant="h5">
            {process.env.REACT_APP_NAME}
        </Typography>
        <Typography align="center" variant="h6">Login</Typography>
        <br />
      <Typography>
        {`Login: ${mobile}`}
      </Typography>
      <TextFieldComponent
        fullWidth
        value={otp}
        label="OTP"
        required
        helperText={blur && !otp && "This field is required"}
        onChange={(event) => { setOtp(event.target.value); setBlur(true); }}
        onBlur={onBlur}
        error={blur && !otp}
      />
      <Typography align="center">
        <ButtonComponent
          variant="contained"
          type="primary"
          onClick={onFinish}
          name="Login"
          disabled={!blur || (blur && !otp)}
        />

        {reqStatus === ActionTypes.LOGIN_POST_OTP_FAILURE && (
            <CustomSnackBar
                status
                errorMessage={serviceError}
            />
        )}
      </Typography>
    </div>
  );
};

LoginPostOTPForm.propTypes = {
  loginPostOTP: PropTypes.func.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  OTP_USER: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LoginPostOTPForm;
