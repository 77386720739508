import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("Name is required field"),
  city: yup.string().required("City is required field"),
  area: yup.string().nullable().required("Area is required field"),
  address: yup.string().required("Address is required field"),
  pincode: yup.string().required("Pincode is required field"),
  phone: yup.string().required("Phone No. is required field"),
});

export const getTextfieldData = (name, city, address, area, phone, pincode) => [
  {
    label: "Name",
    value: name,
    onChangeField: "name",
  },
  {
    label: "City",
    value: city,
    onChangeField: "city",
  },
  {
    label: "Area",
    value: area,
    onChangeField: "area",
  },
  {
    label: "Address",
    value: address,
    onChangeField: "address",
  },
  {
    label: "Pincode",
    value: pincode,
    onChangeField: "pincode",
  },
  {
    label: "Phone No.",
    value: phone,
    onChangeField: "phone",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    type: "secondary",
    disabled: false,
  },
];
