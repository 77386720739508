//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
    Divider
} from "components/Atoms";


import * as Actions         from "actions/seller/seller.registerstore";

import ShopClaimList  from "components/widgets/seller/ShopClaimList";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class Home extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { appBase, serviceError, reqStatus } = this.props;
    if (reqStatus == prevProps.reqStatus) return;
    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  handleDomainEdit = (mode) => (e) => {
    this.setState({ domainMode: mode });
  };

  handleLogout = () => {
    localStorage.clear();
    this.props.logout();
  }

  render() {
    const { isLoading, reqStatus } = this.props;

    return (
      <Page className="subscriptions-home">
        <h4 className="page-title">Register business :</h4>
        <div className="panel--white">
          <ShopClaimList></ShopClaimList>
          <Divider />
          <Button
              type="secondary"
              size="large"
              htmlType="button"
              style={{ width: "100%" }}
              onClick={this.handleLogout}
          >
            Logout
          </Button>
        </div>
      </Page>
    );
  }
}
// Mapping Props and State.
const _Home = connect(
  ({ registerStore, appBase }) => ({
    appBase: appBase,
    reqStatus: registerStore.reqStatus,
    isLoading: registerStore.isLoading,
    serviceError: registerStore.serviceError,
    usersAddress: registerStore.usersAddress,
    placeSuggestions: registerStore.placeSuggestions,
    storeList: registerStore.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(Home);

export { _Home as default };
