import * as React from "react";

import OrdersMain     from "components/pages/seller/orders/OrdersMain";

import "./style.scss";

class OrdersNew extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <OrdersMain {...this.props} orderstatus="new" title="New Orders"></OrdersMain>
    );
  }
}

export default (OrdersNew)


