// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as Actions     from "actions/seller/seller.subscription";
import * as ActionTypes from 'constants/ActionTypes';

function* getUserSubscriptions(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/subscription/active-subscription-plans`,
      action.payload
    );
    yield put(
      Actions.getUserSubscriptionsSuccess(response)
    );
  } catch (error) {
    yield put(Actions.getUserSubscriptionsFailure(error));
  }
}

function* getAllSubscriptions(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/subscription/subscription-plans`,
      action.payload
    );

    yield put(Actions.getAllSubscriptionsSuccess(response));
  } catch (error) {
    yield put(Actions.getAllSubscriptionsFailure(error));
  }
}

function* addSubscriptionToCart(action: Object) {
  // TODO: Add to cart
  try {
    const plan = action.payload;
    // const response = yield API.get(
    //   `/sellers/subscription/subscription-plans`,
    //   action.payload
    // );

    yield put(Actions.addSubscriptionToCartSuccess(plan));
  } catch (error) {
    yield put(Actions.addSubscriptionToCartFailure(error));
  }
}

function* removeSubscriptionFromCart(action: Object) {
  // TODO: Add to cart
  try {
    const plan = action.payload;
    // const response = yield API.get(
    //   `/sellers/subscription/subscription-plans`,
    //   action.payload
    // );

    yield put(Actions.removeSubscriptionFromCartSuccess(plan));
  } catch (error) {
    yield put(Actions.removeSubscriptionFromCartFailure(error));
  }
}

function* checkoutSubscriptionPlan(action: Object) {
  try {
    //   const response = yield API.get(
    //     `/sellers/subscription/subscription-plans`,
    //     action.payload
    //   );

    yield put(Actions.checkoutSubscriptionPlanSuccess());
  } catch (error) {
    yield put(Actions.checkoutSubscriptionPlanFailure(error));
  }
}

export default function* SubscriptionsSaga(): Generator<*, *, *> {
  yield takeLatest(
    ActionTypes.GET_USER_SUBSCRIPTIONS_START,
    getUserSubscriptions
  );
  yield takeLatest(
    ActionTypes.GET_ALL_SUBSCRIPTIONS_START,
    getAllSubscriptions
  );
  yield takeLatest(
    ActionTypes.ADD_SUBSCRIPTION_TO_CART_START,
    addSubscriptionToCart
  );
  yield takeLatest(
    ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_START,
    removeSubscriptionFromCart
  );
  yield takeLatest(
    ActionTypes.CHECKOUT_SUBSCRIPTION_START,
    checkoutSubscriptionPlan
  );
}
