import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Resizer from "react-image-file-resizer";
import ButtonComponent from "components/Button";
import { Paper, Typography } from "@mui/material";

import * as Actions from "actions/core/core.actions";

import Images from "components/elements/main/Images";
import Buttons from "components/elements/main/Buttons";
// Component Imports

const resizeFile = (file) => new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    1200,
    1200,
    "JPEG",
    80,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64",
  );
});

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString = splitDataURI[0].indexOf("base64") >= 0
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

const ProfileImageEditor = (props) => {
  const {
    serviceError,
    reqStatus,
  } = props;

  React.useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
  }, [serviceError, reqStatus]);

  const sendNotification = (type, { title, description = "" }) => {
    console.log({
      message: title,
      description,
    });
  };

  const onChange = (e) => {
    const errs = [];
    const files = Array.from(e.target.files);

    if (files.length > 3) {
      const msg = "Only 3 images can be uploaded at a time";
      return sendNotification("error", { title: msg, description: msg });
    }

    const formData = new FormData();
    const types = ["image/png", "image/jpeg", "image/gif", "image/webp"];

    files.forEach(async (file, i) => {
      if (types.every((type) => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
      }

      if (file.size > 15000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`);
      }
      if (errs.length) {
        return errs.forEach((err) => sendNotification("error", { title: err, description: err }));
      }

      // formData.append(i, file)
      const image = await resizeFile(file);
      const newFile = dataURIToBlob(image);
      formData.append("image", newFile);
      props.updateUserPic(formData);
    });
  };

  // const filter = (id) => this.images.filter((image) => image.cdn.public_id !== id);

  const removeImage = (id) => {
    // this.setState({ images: this.filter(id) });
    props.storeImages.images =
      props.storeImages.images.filter((image) => image.cdn.public_id !== id);
    props.removeStoreImage(id);
  };

  // const onError = (id) => {
  //   this.setState({ images: filter(id) });
  // };

  const { isLoading, userDetails, cancelEdit } = props;
  const { image } = userDetails;

  const content = () => {
    switch (true) {
      case image.length > 0:
        return (
          <div>
            <Images
              images={image}
              // onError={onError}
            />
            <br />
            <Buttons onChange={onChange} />
          </div>
        );
      default:
        return <div><Buttons onChange={onChange} /></div>;
    }
  };

  return (
    <>
      <h2 className="page-title">Images</h2>
      <div className="content">
        <div className="product-list">
          <div className="buttons">
            {content()}
          </div>
          <br />
          <Typography align="right">
            <ButtonComponent onClick={cancelEdit} name="Done" />
          </Typography>
          <br />
        </div>
      </div>
    </>
  );
};

// Mapping Props and State.
const profileImageEditor = connect(
  ({ store, bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProfileImageEditor);

export { profileImageEditor as default };
