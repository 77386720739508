import * as React from "react";

import OrdersMain     from "components/pages/seller/orders/OrdersMain";

import "./style.scss";

class OrdersPicked extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <OrdersMain {...this.props} orderstatus="picked" title="Picked"></OrdersMain>
    );
  }
}

export default (OrdersPicked)


