//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
  Input,
  Notification,
  Roller,
  AntdModal,
} from "components/Atoms";

import * as _ from "lodash";

import * as Actions         from "actions/seller/seller.products";

import Product              from "components/elements/seller/Product";
import ProductEntryForm     from "components/elements/seller/ProductEntryForm";
import CategoryBrowser      from "components/elements/seller/CategoryBrowser";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class ProductByCategory extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const { storeId, categoryId } = props.match.params;
    this.storeId = storeId;
    this.categoryId = categoryId;
    this.state = {
      bShowProductEntryDialog: false,
      bShowCategoryBrowser: false,
      selectedProduct: null,
    };
    this.filterCategories = _.debounce(this.filterProducts, 1000);
    props.getStoreProducts();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  filterProducts = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };

  addAllProductsInCategory = () => {
    const storeId = this.storeId;
    const categoryId = this.categoryId;
  };

  removeAllProductsInCategory = () => {
    // TODO: remove all products from store by categoryId and Store ID
    const storeId = this.storeId;
    const categoryId = this.categoryId;
  };

  addProductToStore = (product) => () => {};
  removeProductFromStore = (product) => () => {};

  render() {
    const { isLoading, storeProducts, categoryList } = this.props;
    const {
      bShowProductEntryDialog,
      bShowCategoryBrowser,
      selectedProduct,
    } = this.state;
    let products = [
      ...storeProducts.records,
      ...storeProducts.records,
      ...storeProducts.records,
    ];
    return (
      <Page className="products-catalog">
        <h2 className="page-title">Products in Category: {this.categoryId}</h2>
        <div className="content">
          <div className="header">
            <Input.Search
              placeholder="Enter Product Name"
              onChange={(e) => this.filterProducts(e.target.value)}
              onSearch={this.filterProducts}
            />
            <div className="actions">
              {this.storeId ? (
                <Button
                  type="primary"
                  onClick={this.removeAllProductsInCategory}
                >
                  Remove All Products
                </Button>
              ) : (
                <Button type="primary" onClick={this.addAllProductsInCategory}>
                  Add All Products
                </Button>
              )}
            </div>
          </div>
          <div className="product-list">
            <Row gutter={[24, 24]}>
              {products.map((product) => {
                return (
                  <Col span={6} key={`pr-item-${product.id}`}>
                    <Product
                      className="product-item"
                      key={product.id}
                      data={product}
                      actions={
                        this.storeId ? (
                          <>
                            <Button
                              icon={<DeleteOutlined />}
                              size={32}
                              style={{ marginRight: "10px" }}
                              onClick={this.removeProductFromStore(product)}
                            />
                            <Button type="primary">Edit</Button>
                          </>
                        ) : (
                          <Button
                            type="primary"
                            onClick={this.addProductToStore(product)}
                          >
                            Add to Store
                          </Button>
                        )
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <ProductEntryForm
          visible={bShowProductEntryDialog}
          data={selectedProduct}
          onOk={this.saveProduct}
          onCancel={this.hideProductEntryDialog}
        />
        <CategoryBrowser
          visible={bShowCategoryBrowser}
          categoryList={categoryList}
          onOk={this.hideCategoryBrowser}
          onCancel={this.hideCategoryBrowser}
        />
      </Page>
    );
  }
}
// Mapping Props and State.
const _ProductByCategory = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    storeProducts: products.storeProducts,
    categoryList: products.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProductByCategory);

export { _ProductByCategory as default };
