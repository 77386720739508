import React, { useState } from "react";
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  InboxIcon,
  MailIcon,
} from "components/Atoms";

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: 300,
  },
}));

const drawerWidth = 300;

const Sidebar = (props) => {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = (event) => {
    if (
      event
        && event.type === "keydown"
        && (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(true);
  };

  const constructSidebarMenu = (navList) => {
    const { classes, onClose } = props;
    return (
      <div
        className="p-4"
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >

        <List>
          {navList.map((navItem, index) => (
            <ListItem className="p-2 mr-2" button component={Link} to={navItem.routelink} key={navItem.name}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={navItem.name} />
            </ListItem>

          ))}
        </List>
      </div>
    );
  };

  const {
    open, onOpen, onClose, menuItems,
  } = props;

  return (
    <SwipeableDrawer
      width={drawerWidth}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      {constructSidebarMenu(menuItems)}
    </SwipeableDrawer>
  );
};

export default Sidebar;
