//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Notification,
  Pace,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.store";
import * as ActionTypes from "constants/ActionTypes";

import ServicePreview from "components/elements/seller/ServicePreview";
import ServiceEditor from "components/elements/seller/ServiceEditor";

import "./styles.scss";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Card, Container } from "@mui/material";
import { H4 } from "components/CustomTypography";

const StoreProfileService = (props) => {
  const [state, setState] = useState({ serviceMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopService,
    getStoreService, updateStoreService,
  } = props;

  useEffect(() => {
    getStoreService();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_SERVICE_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { serviceMode, storeService = {} } = state;

  const handleServiceEdit = (service) => {
    setState({ serviceMode: "EDIT" });
    setState({
      storeService: service,
    });
  };

  const handleServiceEditCancel = (mode) => (e) => {
    setState({ serviceMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateServiceCall = (service) => {
    setState({ serviceMode: "PREVIEW" });
    updateStoreService(service);
  };

  return (
    <Container>
      <Box m={2}>
        <Card>
          <Box>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <H4 sx={{
              m: 2,
            }}
            >
              Service Charge
            </H4>
            {serviceMode === "PREVIEW" ? (
              <ServicePreview
                isLoading={isLoading}
                service={shopService}
                editService={() => handleServiceEdit(shopService)}
                cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
            ) : (
              <ServiceEditor
                setState={(value, field) => setState({
                  ...state,
                  storeService: {
                    ...storeService,
                    [field]: value,
                  },
                })}
                service={storeService}
                updateServiceCall={updateServiceCall}
                cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
            )}
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

StoreProfileService.propTypes = {
  history: PropTypes.object.isRequired,
  shopService: PropTypes.object.isRequired,
  updateStoreService: PropTypes.func.isRequired,
  getStoreService: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const _StoreProfileService = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopService: store.storeService,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileService);

export { _StoreProfileService as default };
