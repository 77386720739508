/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import { H6, SpanInline } from 'components/CustomTypography';
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import "./ShopProductItem.scss";


const ShopProductItem = (props) => {
  const [state, setState] = useState({
    open: false,
    stock: "InStock",
    quantity: 1,
    image: "",
  });

  const [share, setShare] = useState(false);

  const {
    product, onAddToCartClicked, onAddToWishlistClicked, symbol,
  } = props;

  // const onClickHandle = (img) => {
  //   setState({ image: img });
  // };

  const vegIconUrl = `${process.env.PUBLIC_URL}/assets/images/GreenIcon.png`;
  const nonVegIconUrl = `${process.env.PUBLIC_URL}/assets/images/RedIcon.png`;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

  const handleShare = () => {
    setShare(!share);
  };

  // const minusQty = () => {
  //   if (state.quantity > 1) {
  //     setState({ stock: "InStock" });
  //     setState({ quantity: state.quantity - 1 });
  //   }
  // };

  // const plusQty = () => {
  //   if (props.product.stock >= state.quantity) {
  //     setState({ quantity: state.quantity + 1 });
  //   } else {
  //     setState({ stock: "Out of Stock !" });
  //   }
  // };
  // const changeQty = (e) => {
  //   setState({ quantity: parseInt(e.target.value) });
  // };
  // const RatingStars = [];
  // for (let i = 0; i < product.rating; i++) {
  //   RatingStars.push(<i className="fa fa-star" key={i} />);
  // }
  return (
    <Card className="product-card">
      <div className="image-container">
        <Box>
          {product.new ? <span className="lable3">new</span> : ""}
          {product.sale ? <span className="lable4">on sale</span> : ""}

        </Box>
        <Box className="front">
          <Link
            to={`/product/${product.id}`}
          >
            {product.images && (
              <img
                src={`${product.images[0] ? product.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`}
                className="product-image"
                alt=""
              />
            )}
          </Link>
        </Box>
        <Box className="actions" position="absolute" top={0} right={0} display="none" flexDirection="column">
          <IconButton className="cart-button" color="primary" size="small" onClick={onAddToWishlistClicked}>
            <FavoriteBorderIcon />
          </IconButton>
        </Box>
      </div>
      <SpanInline fontWeight="600" fontSize="14px" title={product.name} ellipsis>
        <H6 className="text-truncate" fontWeight="600" width="70%" textAlign="left">
          {product.name}
        </H6>
        <IconButton className="setting-button" color="primary" size="small" onClick={onAddToCartClicked}>
          <AddBoxSharpIcon />
        </IconButton>
      </SpanInline>
      <Modal open={state.open} onClose={onCloseModal} center>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6  col-xs-12">
                  <div className="quick-view-img">
                    <img src={`${product.images && product.images[0] ? product.images[0].cdn.url : "/assets/images/fashion/product/1.jpg"}`} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <Typography variant="h2">
                      {product.name}
                    </Typography>
                    <div className="border-product">
                      <Typography variant="h6">Product Details</Typography>
                      <Typography>{product.shortDetails}</Typography>
                    </div>
                    <Typography align="right">
                      <IconButton onClick={handleShare} size="large">
                        <ShareIcon />
                      </IconButton>
                    </Typography>
                    {
                      share && (
                        <>
                          <EmailShareButton url={window.location.href}>
                            <IconButton size="small">
                              <MailIcon />
                            </IconButton>
                          </EmailShareButton>
                          <FacebookShareButton url={window.location.href}>
                            <IconButton size="small">
                              <FacebookIcon />
                            </IconButton>
                          </FacebookShareButton>
                          <TwitterShareButton url={window.location.href}>
                            <IconButton size="small">
                              <TwitterIcon />
                            </IconButton>
                          </TwitterShareButton>
                          <WhatsappShareButton url={window.location.href}>
                            <IconButton size="small">
                              <WhatsAppIcon />
                            </IconButton>
                          </WhatsappShareButton>
                        </>
                      )
                    }
                    <Box className="product-buttons">
                      <button className="btn m-2 btn-solid" type="button" onClick={() => onAddToCartClicked(product, state.quantity)}>add to cart</button>
                      <button
                        className="btn m-2 btn-solid"
                        type="button"
                        onClick={onCloseModal}
                      >
                        close
                      </button>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

ShopProductItem.defaultProps = {
  symbol: "$",
};

ShopProductItem.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  onAddToCartClicked: PropTypes.func.isRequired,
  onAddToWishlistClicked: PropTypes.func.isRequired,
  symbol: PropTypes.string,
};

const ShopProductItemComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    productprices: userproducts.productprices,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductItem);

export default ShopProductItemComponent;
