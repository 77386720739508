import * as React from "react";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import { scanBarCode } from "utils/cordova";

import "./style.scss";

import * as Actions from "actions";
import {startCamera, stopCamera, stopScanning} from "utils/cordova";

import {BreakPoints} from "config/constants";

class UserBarCodeScanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScanning: false,
      scanError: false,
      image: "",
        breakPoint: "lg",
        isMobile : false,
      scanPadding: 0
    };
  }

    componentDidMount() {
        this.startScan();
    }

    componentWillMount(){
        this.manageLayoutChange();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            appBase,
            getStoresByLocation,
            serviceError,
            reqStatus,
        } = this.props;
    }

    componentWillUnmount() {
        stopScanning();
    }

    resolve (value) {
        this.props.usergetProductPrices(value, false);
    };

  reject (value) {};

    manageLayoutChange() {
        const docElement = document.documentElement;
        const currentWindowWidth =
            window.innerWidth || (docElement && docElement.offsetWidth);
        if (currentWindowWidth < BreakPoints.md) {
            this.state.isMobile = true;
            this.state.scanPadding = window.screen.height * 0.25;
        } else {
            this.state.isMobile = false;
        }
    }

  startCamera () {
    this.setState(
      {
        isScanning: true,
        scanError: false,
      },
      () => {
        startCamera();
      }
    );
  };

  stopCamera () {
    this.setState(
        {
          isScanning: false,
          scanError: false,
        },
        () => {
          stopCamera();
        }
    );
  };

  startScan () {
        this.setState(
            {
                isScanning: true,
                scanError: false,
            },
            () => {
                scanBarCode(this.resolve, this.reject);
            }
        );
    };

    stopScan () {
        this.setState(
            {
                isScanning: false,
                scanError: false,
            },
            () => {
                stopScanning();
            }
        );
    };

    addProductToStore = (product) => () => {};
    removeProductFromStore = (product) => () => {};

    render() {
        const { isLoading, productprices } = this.props;
        const {
            isMobile,
            breakPoint,
            scanPadding
        } = this.state;

        // 40 px added to accomodate header - change this if header changed
        var divStyle = {
            paddingTop: this.state.scanPadding - 40
        };

    return (
        <div className="product-list">
            <div style={divStyle}>
            </div>
            <div className="row wishlist-buttons">
                <div className="col-12">
                    <Link to={`${process.env.PUBLIC_URL}/products`} className="btn btn-solid">Home</Link>
                </div>
            </div>
            {productprices.length>0 ?
                <section className="wishlist-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col">Shop</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">price</th>
                                        <th scope="col">availability</th>
                                        <th scope="col">action</th>
                                    </tr>
                                    </thead>
                                    {productprices.map((item, index) => {
                                        return (
                                            <tbody key={index}>
                                            <tr>
                                                <td>
                                                    {item.title}
                                                </td>
                                                <td>{item.created_at}
                                                    <div className="mobile-cart-content row">
                                                        <div className="col-xs-3">
                                                            <p>in stock</p>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">{item.seller_price}</h2>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">
                                                                View
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><h2>{item.seller_price}</h2></td>
                                                <td >
                                                    <p>in stock</p>
                                                </td>
                                                <td>
                                                    View
                                                </td>
                                            </tr>
                                            </tbody> )
                                    })}
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty-wishlist.png`} className="img-fluid mb-4" alt="" />
                                        <h3>
                                            <strong>WhishList is Empty</strong>
                                        </h3>
                                        <h4>Explore more shortlist some items.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }


        </div>
    );
  }
}

// Mapping Props and State.
const _UserBarCodeScanner = connect(
    ({ userproducts, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: userproducts.reqStatus,
        isLoading: userproducts.isLoading,
        serviceError: userproducts.serviceError,
        productprices: userproducts.productprices
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(UserBarCodeScanner);

export { _UserBarCodeScanner as default };


/*
const mapStateToProps = (state) => ({
    productprices: state.userproducts.productprices,
})

export default connect(
    mapStateToProps,
    {usergetProductPrices}
)(UserBarCodeScanner)

*/

