import * as React from "react";

import OrdersMain     from "components/pages/seller/orders/OrdersMain";

import "./style.scss";

class OrdersAccepted extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <OrdersMain {...this.props} orderstatus="accepted" title="Accepted"></OrdersMain>
    );
  }
}

export default (OrdersAccepted)


