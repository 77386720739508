// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.shops";

const querystring = require('querystring');

function* adminGetLocationShopList(action: Object) {
  try {

    let query = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      query = {
        lat: latitude,
        lng: longitude,
      };
    }

    const shops = yield API.get(`/admin/shop/get-shops`, { params: query });
    yield put(Actions.adminGetLocationShopListSuccess(shops.response));
  } catch (error) {
    yield put(Actions.adminGetShopLocationListFailure(error));
  }
}

function* adminSearchShop(action: Object) {
  try {
    const searchQuery = querystring.stringify({ name: action.name });
    const query = (searchQuery) ? "?"+searchQuery : "" ;
    const response = yield API.get(
        `/admin/shop/get-all-shops` + query
    );
    yield put(Actions.adminSearchShopSuccess(response.response));
  } catch (error) {
    yield put(Actions.adminSearchShopFailure(error));
  }
}

function* adminSearchShopKeywords(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/get-shop-keywords`,
        action.payload
    );
    yield put(Actions.adminSearchShopSuccess(response.response));
  } catch (error) {
    yield put(Actions.adminSearchShopFailure(error));
  }
}

function* adminGetShopInfoById(action: Object) {
  try {
    //const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const searchQuery = querystring.stringify({ id: action.id });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/shops/get-shopinfo?id=`+ action.payload
    );
    yield put(Actions.adminGetShopInfoByIdSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetShopInfoByIdFailure(error));
  }
}

function* adminUpdateShopStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-status`,
        action.payload
    );
    yield put(Actions.adminUpdateShopStatusSuccess(response.response));
  } catch (error) {
    yield put(Actions.adminUpdateShopStatusFailure(error));
  }
}

export default function* RegisterStoreSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_START,        adminGetLocationShopList);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SHOPS_START,          adminSearchShop);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_START,  adminSearchShopKeywords);
  yield takeLatest(ActionTypes.ADMIN_GET_SHOP_INFO_BYID_START,    adminGetShopInfoById);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_STATUS_START,   adminUpdateShopStatus);

}
