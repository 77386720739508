import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';

class TopBarUser extends Component {

    render() {
        const {translate} = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li className="mobile-tab1"><Link to={`${process.env.PUBLIC_URL}/user`}> <i className="fa fa-home"></i>{translate('home')}</Link></li>
                                <li className="mobile-tab2"><Link to={`${process.env.PUBLIC_URL}/user/wishlist`}><i className="fa fa-heart" aria-hidden="true"></i>{translate('wishlist')}</Link></li>
                                <li className="mobile-tab3"><Link to={`${process.env.PUBLIC_URL}/me`}> <i className="fa fa-user" aria-hidden="true"></i> {translate('my_account')}</Link></li>
                                <li className="mobile-tab4"><Link to={`${process.env.PUBLIC_URL}/user/orderlist`}><i className="fa fa-search" aria-hidden="true"></i>{translate('orders')}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBarUser);
