/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React from "react";
import ErrorBoundaryComponent from "components/ErrorBoundary";
// Fallback UI when something goes wrong in UI
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorInfo: error };
  }

  componentDidCatch() {
    // log the error to error reporting service
  }

  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return <ErrorBoundaryComponent errorInfo={errorInfo.toString()} />;
    }
    return this.props.children;
  }
}
