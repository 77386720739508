// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.orders";

const querystring = require('querystring');

function* adminGetCommonOrders(action: Object) {
  try {
    const response = yield API.get(
      `/admin/orders/search` + (action.category ? `?category=` + action.category.id : ""),
      action.payload
    );
    yield put(Actions.adminGetCommonOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonOrdersFailure(error));
  }
}


function* adminGetStoreOrders(action: Object) {
  try {

    const searchQuery = querystring.stringify({ id: action.shopId, status: action.orderstatus ?  action.orderstatus : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/admin/orders/search`+ query
    );
    yield put(Actions.adminGetStoreOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetStoreOrdersFailure(error));
  }
}

function* adminSearchOrders(action: Object) {
  try {
    const searchQuery = action.searchType + "=" + action.payload ;
    const response = yield API.get(
      `/admin/orders/search?` + searchQuery,
      action.payload
    );
    yield put(Actions.adminSearchOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchOrdersFailure(error));
  }
}
function* adminSearchOrderKeywords(action: Object) {
  try {
    //const searchQuery = ((action.isUPCBased) ? (`upc=` + action.upc) : (`name=` + action.payload +  (action.category ? `&category=` + action.category.id : "")));
    const response = yield API.get(
        `/admin/orders/orders-keywords?name=` + action.payload,
        action.payload
    );
    yield put(Actions.adminSearchOrderKeywordsSuccess(response.response));
  } catch (error) {
    yield put(Actions.adminSearchOrderKeywordsFailure(error));
  }
}

export default function* OrdersSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_ORDERS_START, adminGetCommonOrders);
  yield takeLatest(ActionTypes.ADMIN_GET_STORE_ORDERS_START, adminGetStoreOrders);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_ORDERS_START, adminSearchOrders);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_START, adminSearchOrderKeywords);
}
