/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import {
  Button, Divider,
} from "components/Atoms";
import * as ActionTypes from "constants/ActionTypes";
import { Snackbar, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CustomSnackBar from "components/CustomSnackBar";

const LoginForm = ({
  login, googleSignIn, showResetPassword, showRegister, serviceError, reqStatus, showOTPLogin,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [blur, setBlur] = useState(false);

  const onFinish = () => {
    login({ email, password });
  };

  const onBlur = () => {
    setBlur(true);
  };

  { /*
    const cordova = window.cordova;
    */ }

  return (
    <div className="form login">
      <Typography align="center" variant="h5">
        {process.env.REACT_APP_NAME}
      </Typography>
      <Typography align="center" variant="h6">Login</Typography>
      <br />
      {/*

        {!cordova && (
            <center>
                <a
                    type="link"
                    style={{ float: "center" }}
                    onClick={googleSignIn}
                >
                    <img className="ant-menu-item" src="assets/images/google.png"/>
                </a>
                <Divider>OR</Divider>
            </center>
        )}

*/}

      <TextFieldComponent
        fullWidth
        value={email}
        label="Email"
        required
        helperText={blur && !email && "This field is required"}
        onChange={(event) => { setEmail(event.target.value); setBlur(true); }}
        onBlur={onBlur}
        error={blur && !email}
      />
      <TextFieldComponent
        fullWidth
        value={password}
        label="Password"
        type="password"
        required
        helperText={blur && !password && "This field is required"}
        onChange={(event) => { setPassword(event.target.value); setBlur(true); }}
        onBlur={onBlur}
        error={blur && !password}
      />
      <Typography align="center">
        <br />
        <ButtonComponent
          variant="contained"
          type="primary"
          onClick={onFinish}
          name="Login"
          disabled={!blur || (blur && !email && !password)}
        />

        {reqStatus === ActionTypes.LOGIN_POST_OTP_FAILURE && (
        <CustomSnackBar
          status
          errorMessage={serviceError}
        />
        )}
      </Typography>

      {/*
          <a
              type="link"
              style={{ float: "right" }}
              onClick={showResetPassword}
          >
              Forgot Password?
          </a>
          */}

      {reqStatus === ActionTypes.LOGIN_FAILURE && (
      <CustomSnackBar
        status
        errorMessage={serviceError}
      />
      )}
      <br />
      <Divider />
      <br />
      <Typography align="right" variant="h6">
        <Button
          variant="text"
          onClick={showOTPLogin}
        >
          OTP Login
          {" "}
        </Button>
      </Typography>
      {/*
            <Button

                size="large"
                htmlType="submit"
                style={{ width: "100%", float: "center" }}
                onClick={showRegister}
            >
                SignUp (Free website for your business)
            </Button>
    */}
    </div>
  );
};

export default LoginForm;
