import * as React from "react";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";

import { Button, ImageGallery, Spin } from "components/Atoms";
import { Link } from "react-router-dom";


import { BarcodeOutlined,
    DeleteOutlined
} from "@ant-design/icons";

import { scanBarCode } from "utils/cordova";
import "./style.scss";

import * as Actions         from "actions/seller/seller.products";

import Product              from "components/elements/seller/Product";

import {startCamera, stopCamera, stopScanning} from "utils/cordova";
import {BreakPoints} from "config/constants";

type Props = {
  className?: ?string,
  onScan: Function,
};
class BarCodeScannerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScanning: false,
      scanError: false,
      image: "",
        breakPoint: "lg",
        isMobile : false,
        scanPadding: 0
    };
  }

    componentWillMount(){
        this.manageLayoutChange();
    }

    componentDidMount() {
      this.startScan();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            appBase,
            getStoresByLocation,
            serviceError,
            reqStatus,
        } = this.props;

    }

    componentWillUnmount() {
        stopScanning();
    }

    manageLayoutChange() {
        const docElement = document.documentElement;
        const currentWindowWidth =
            window.innerWidth || (docElement && docElement.offsetWidth);
        if (currentWindowWidth < BreakPoints.md) {
            this.state.isMobile = true;
            this.state.scanPadding = window.screen.height * 0.25;
        } else {
            this.state.isMobile = false;
        }
    }

    resolve = (value) => {
    this.props.addProductUPC(value);
  };

  reject = (value) => {};

  startCamera = () => {
    this.setState(
      {
        isScanning: true,
        scanError: false,
      },
      () => {
        startCamera();
      }
    );
  };

  stopCamera = () => {
    this.setState(
        {
          isScanning: false,
          scanError: false,
        },
        () => {
          stopCamera();
        }
    );
  };

  startScan = () => {
        this.setState(
            {
                isScanning: true,
                scanError: false,
            },
            () => {
                scanBarCode(this.resolve, this.reject);
            }
        );
    };

    stopScan = () => {
        this.setState(
            {
                isScanning: false,
                scanError: false,
            },
            () => {
                stopScanning();
            }
        );
    };

    addProductToStore = (product) => () => {};
    removeProductFromStore = (product) => () => {};

    render() {
        const { isLoading, productinfo } = this.props;
        const {
            isMobile,
            breakPoint,
            scanPadding
        } = this.state;

        // 40 px added to accomodate header - change this if header changed
        var divStyle = {
            paddingTop: this.state.scanPadding - 40
        };

    return (
        <div className="product-list">
            <div style={divStyle}>
            </div>
            <div className="row wishlist-buttons">
                <div className="col-12">
                    <Link to={`${process.env.PUBLIC_URL}/products`} className="btn btn-solid">Home</Link>
                </div>
            </div>
            {isLoading && <Spin />}
        <Product
            className="product-item"
            key={productinfo._id}
            data={productinfo}
            actions={
                this.storeId ? (
                    <>
                        <Button
                            icon={<DeleteOutlined />}
                            size={32}
                            style={{ marginRight: "10px" }}
                            onClick={this.removeProductFromStore(productinfo)}
                        />
                        <Button type="primary">Edit</Button>
                    </>
                ) : (
                    <Button
                        type="primary"
                        onClick={this.addProductToStore(productinfo)}
                    >
                        Add to Store
                    </Button>
                )
            }
        />
        </div>
    );
  }
}

// Mapping Props and State.
const _BarCodeScannerList = connect(
    ({ products, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: products.reqStatus,
        isLoading: products.isLoading,
        serviceError: products.serviceError,
        productinfo: products.productinfo
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(BarCodeScannerList);

export { _BarCodeScannerList as default };

/*
const mapStateToProps = (state) => ({
    productinfo: state.userstoreList.productinfo,
})

export default connect(
    mapStateToProps,
    {selleraddProductUPC}
)(BarCodeScannerList)

 */

