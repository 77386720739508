// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const login = (req) => ({
  type: ActionTypes.LOGIN_START,
  payload: req,
});

export const loginSuccess = (req) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: req,
  };
};
export const loginFailure = (error) => ({
  type: ActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const loginPreOTP = (req) => ({
  type: ActionTypes.LOGIN_PRE_OTP_START,
  payload: req,
});

export const loginPreOTPSuccess = (req) => {
  return {
    type: ActionTypes.LOGIN_PRE_OTP_SUCCESS,
    payload: req,
  };
};
export const loginPreOTPFailure = (error) => ({
  type: ActionTypes.LOGIN_PRE_OTP_FAILURE,
  payload: error,
});

export const loginPostOTP = (req) => ({
  type: ActionTypes.LOGIN_POST_OTP_START,
  payload: req,
});

export const loginPostOTPSuccess = (req) => {
  return {
    type: ActionTypes.LOGIN_POST_OTP_SUCCESS,
    payload: req,
  };
};
export const loginPostOTPFailure = (error) => ({
  type: ActionTypes.LOGIN_POST_OTP_FAILURE,
  payload: error,
});

export const Sociallogin = (req) => ({
  type: ActionTypes.SOCIAL_LOGIN_START,
  payload: req,
});

export const SocialloginSuccess = (req) => {
  return {
    type: ActionTypes.SOCIAL_LOGIN_SUCCESS,
    payload: req,
  };
};
export const SocialloginFailure = (error) => ({
  type: ActionTypes.SOCIAL_LOGIN_FAILURE,
  payload: error,
});


export const logout = (req) => ({
  type: ActionTypes.LOGOUT_START,
  payload: req,
});

export const logoutSuccess = (req) => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    payload: req,
  };
};
export const logoutFailure = (error) => ({
  type: ActionTypes.LOGOUT_FAILURE,
  payload: error,
});

export const register = (req) => ({
  type: ActionTypes.REGISTER_START,
  payload: req,
});

export const registerSuccess = (data) => {
  return {
    type: ActionTypes.REGISTER_SUCCESS,
    payload: data,
  };
};
export const registerFailure = (error) => ({
  type: ActionTypes.REGISTER_FAILURE,
  payload: error,
});

export const resetPassword = (req) => ({
  type: ActionTypes.RESET_PASSWORD_START,
  payload: req,
});

export const resetPasswordSuccess = (data) => {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};
export const resetPasswordFailure = (error) => ({
  type: ActionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});

export const getUserProfile = (req) => ({
  type: ActionTypes.GET_USER_PROFILE_START,
  payload: req,
});

export const getUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getUserProfileFailure = (error) => ({
  type: ActionTypes.GET_USER_PROFILE_FAILURE,
  payload: error,
});

export const updateUserUUID = (req) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_UUID_START,
  payload: req,
});

export const updateUserUUIDSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_UUID_SUCCESS,
    payload: data,
  };
};
export const updateUserUUIDFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_UUID_FAILURE,
  payload: error,
});

export const getShopUserProfile = (req) => ({
  type: ActionTypes.GET_SHOP_USER_PROFILE_START,
  payload: req,
});

export const getShopUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHOP_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getShopUserProfileFailure = (error) => ({
  type: ActionTypes.GET_SHOP_USER_PROFILE_FAILURE,
  payload: error,
});

export const getShopAndUserProfile = (req, isShopDomain) => ({
  type: ActionTypes.GET_SHOP_AND_USER_PROFILE_START,
  payload: req,
  isShopDomain: isShopDomain
});

export const getShopAndUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHOP_AND_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getShopAndUserProfileFailure = (error) => ({
  type: ActionTypes.GET_SHOP_AND_USER_PROFILE_FAILURE,
  payload: error,
});

export const getShopProfile = (req, isShopDomain) => ({
  type: ActionTypes.GET_SHOP_PROFILE_START,
  payload: req,
  isShopDomain: isShopDomain
});

export const getShopProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHOP_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getShopProfileFailure = (error) => ({
  type: ActionTypes.GET_SHOP_PROFILE_FAILURE,
  payload: error,
});

export const updateUserProfile = (req) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_START,
  payload: req,
});

export const updateUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const updateUserProfileFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_FAILURE,
  payload: error,
});

export const getBillingAddress = (req) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_START,
  payload: req,
});

export const getBillingAddressSuccess = (data) => {
  return {
    type: ActionTypes.GET_BILLING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const getBillingAddressFailure = (error) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_FAILURE,
  payload: error,
});

export const updateBillingAddress = (req) => ({
  type: ActionTypes.UPDATE_BILLING_ADDRESS_START,
  payload: req,
});

export const updateBillingAddressSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_BILLING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const updateBillingAddressFailure = (error) => ({
  type: ActionTypes.UPDATE_BILLING_ADDRESS_FAILURE,
  payload: error,
});


export const addShippingAddress = (req) => ({
  type: ActionTypes.ADD_SHIPPING_ADDRESS_START,
  payload: req,
});

export const addShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.ADD_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const addShippingAddressFailure = (error) => ({
  type: ActionTypes.ADD_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const getShippingAddress = (req) => ({
  type: ActionTypes.GET_SHIPPING_ADDRESS_START,
  payload: req,
});

export const getShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const getShippingAddressFailure = (error) => ({
  type: ActionTypes.GET_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const updateShippingAddress = (req) => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_START,
  payload: req,
});

export const updateShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const updateShippingAddressFailure = (error) => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const deleteShippingAddress = (req) => ({
  type: ActionTypes.DELETE_SHIPPING_ADDRESS_START,
  payload: req,
});

export const deleteShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const deleteShippingAddressFailure = (error) => ({
  type: ActionTypes.DELETE_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const updateUserPic = (req) => ({
  type: ActionTypes.UPDATE_USER_PIC_START,
  payload: req,
});

export const updateUserPicSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PIC_SUCCESS,
    payload: data,
  };
};
export const updateUserPicFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PIC_FAILURE,
  payload: error,
});
