import * as ActionTypes from '../../constants/ActionTypes'
import store from "../../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const usergetStoreList= (reqData) => ({
    type: ActionTypes.USER_GET_STORE_LIST_START,
    payload: reqData,
});

export const usergetStoreListSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_STORE_LIST_SUCCESS,
        payload: data,
    };
};
export const usergetStoreListFailure = (error) => ({
    type: ActionTypes.USER_GET_STORE_LIST_FAILURE,
    payload: error,
});

export const usersearchShops = (reqData) => ({
    type: ActionTypes.USER_SEARCH_SHOPS_START,
    payload: reqData,
});

export const usersearchShopsSuccess = (data) => {
    return {
        type: ActionTypes.USER_SEARCH_SHOPS_SUCCESS,
        payload: data,
    };
};

export const usersearchShopsFailure = (error) => ({
    type: ActionTypes.USER_SEARCH_SHOPS_FAILURE,
    payload: error,
});

export const getStoreFavlist = (reqData) => ({
    type: ActionTypes.USER_GET_STORE_FAVLIST_START,
    payload: reqData,
});

export const getStoreFavlistSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_STORE_FAVLIST_SUCCESS,
        payload: data,
    };
};
export const getStoreFavlistFailure = (error) => ({
    type: ActionTypes.USER_GET_STORE_FAVLIST_FAILURE,
    payload: error,
});

export const addStoreToFavlist = (reqData) => ({
    type: ActionTypes.USER_ADD_STORE_TO_FAVLIST_START,
    payload: reqData,
});

export const addStoreToFavlistSuccess = (data, storeId) => {
    toast.success("Added as favourite", {autoClose: 2000, hideProgressBar: true});
    return {
        type: ActionTypes.USER_ADD_STORE_TO_FAVLIST_SUCCESS,
        payload: data,
        storeId: storeId
    };
};
export const addStoreToFavlistFailure = (error) => ({
    type: ActionTypes.USER_ADD_STORE_TO_FAVLIST_FAILURE,
    payload: error,
});

export const removeStoreFromFavlist = (reqData) => ({
    type: ActionTypes.USER_REMOVE_STORE_FROM_FAVLIST_START,
    payload: reqData,
});

export const removeStoreFromFavlistSuccess = (data, storeId) => {
    return {
        type: ActionTypes.USER_REMOVE_STORE_FROM_FAVLIST_SUCCESS,
        payload: data,
        storeId: storeId
    };
};
export const removeStoreFromFavlistFailure = (error) => ({
    type: ActionTypes.USER_REMOVE_STORE_FROM_FAVLIST_FAILURE,
    payload: error,
});


export const usergetShopInfoById = (reqData, isShopDomain) => ({
    type: ActionTypes.USER_GET_SHOP_INFO_BYID_START,
    payload: reqData,
    isShopDomain: isShopDomain
});

export const usergetShopInfoByIdSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_SHOP_INFO_BYID_SUCCESS,
        payload: data,
    };
};
export const usergetShopInfoByIdFailure = (error) => ({
    type: ActionTypes.USER_GET_SHOP_INFO_BYID_FAILURE,
    payload: error,
});

