import * as ActionTypes from '../../constants/ActionTypes'
import store from "../../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const userlogin = (req) => ({
    type: ActionTypes.USER_LOGIN_START,
    payload: req,
});

export const userloginSuccess = (req) => {
    return {
        type: ActionTypes.USER_LOGIN_SUCCESS,
        payload: req,
    };
};
export const userloginFailure = (error) => ({
    type: ActionTypes.USER_LOGIN_FAILURE,
    payload: error,
});

export const userSocaillogin = (req) => ({
    type: ActionTypes.USER_SOCIAL_LOGIN_START,
    payload: req,
});

export const userSocialloginSuccess = (req) => {
    return {
        type: ActionTypes.USER_SOCIAL_LOGIN_SUCCESS,
        payload: req,
    };
};
export const userSocialloginFailure = (error) => ({
    type: ActionTypes.USER_SOCIAL_LOGIN_FAILURE,
    payload: error,
});

export const userlogout = (req) => ({
    type: ActionTypes.USER_LOGOUT_START,
    payload: req,
});

export const userlogoutSuccess = (req) => {
    return {
        type: ActionTypes.USER_LOGOUT_SUCCESS,
        payload: req,
    };
};
export const userlogoutFailure = (error) => ({
    type: ActionTypes.USER_LOGOUT_FAILURE,
    payload: error,
});

export const userregister = (req) => ({
    type: ActionTypes.USER_REGISTER_START,
    payload: req,
});

export const userregisterSuccess = (data) => {
    return {
        type: ActionTypes.USER_REGISTER_SUCCESS,
        payload: data,
    };
};
export const userregisterFailure = (error) => ({
    type: ActionTypes.USER_REGISTER_FAILURE,
    payload: error,
});

export const userresetPassword = (req) => ({
    type: ActionTypes.USER_RESET_PASSWORD_START,
    payload: req,
});

export const userresetPasswordSuccess = (data) => {
    return {
        type: ActionTypes.USER_RESET_PASSWORD_SUCCESS,
        payload: data,
    };
};
export const userresetPasswordFailure = (error) => ({
    type: ActionTypes.USER_RESET_PASSWORD_FAILURE,
    payload: error,
});

export const usergetUserProfile = (req) => ({
    type: ActionTypes.USER_GET_USER_PROFILE_START,
    payload: req,
});

export const usergetUserProfileSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_USER_PROFILE_SUCCESS,
        payload: data,
    };
};
export const usergetUserProfileFailure = (error) => ({
    type: ActionTypes.USER_GET_USER_PROFILE_FAILURE,
    payload: error,
});

export const userupdateUserProfile = (req) => ({
    type: ActionTypes.USER_UPDATE_USER_PROFILE_START,
    payload: req,
});

export const userupdateUserProfileSuccess = (data) => {
    return {
        type: ActionTypes.USER_UPDATE_USER_PROFILE_SUCCESS,
        payload: data,
    };
};
export const userupdateUserProfileFailure = (error) => ({
    type: ActionTypes.USER_UPDATE_USER_PROFILE_FAILURE,
    payload: error,
});

export const usergetBillingAddress = (req) => ({
    type: ActionTypes.USER_GET_BILLING_ADDRESS_START,
    payload: req,
});

export const usergetBillingAddressSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_BILLING_ADDRESS_SUCCESS,
        payload: data,
    };
};
export const usergetBillingAddressFailure = (error) => ({
    type: ActionTypes.USER_GET_BILLING_ADDRESS_FAILURE,
    payload: error,
});

export const userupdateBillingAddress = (req) => ({
    type: ActionTypes.USER_UPDATE_BILLING_ADDRESS_START,
    payload: req,
});

export const userupdateBillingAddressSuccess = (data) => {
    return {
        type: ActionTypes.USER_UPDATE_BILLING_ADDRESS_SUCCESS,
        payload: data,
    };
};
export const userupdateBillingAddressFailure = (error) => ({
    type: ActionTypes.USER_UPDATE_BILLING_ADDRESS_FAILURE,
    payload: error,
});

export const usergetUserLocationAddress = (req) => ({
    type: ActionTypes.USER_GET_USER_LOCATION_START,
    payload: req,
});

export const usergetUserLocationAddressSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_USER_LOCATION_SUCCESS,
        payload: data,
    };
};
export const usergetUserLocationAddressFailure = (error) => ({
    type: ActionTypes.USER_GET_USER_LOCATION_FAILURE,
    payload: error,
});
