import * as yup from "yup";

export const schema = yup.object().shape({
  shippingoption: yup.string().nullable().required(),
  orderminimum: yup.string().nullable().when("shippingoption", { is: "shippingcost", then: yup.string().required().matches(/^[0-9.]+$/, "Must be number") }),
  shipping: yup.string().nullable().when("shippingoption", { is: "shippingcost", then: yup.string().required().matches(/^[0-9.]+$/, "Must be number") }),
});

export const getTextfieldData = (orderminimum, shipping) => [
  {
    label: "Order Minimum",
    value: orderminimum,
    onChangeField: "orderminimum",
  },
  {
    label: "Shipping Charge",
    value: shipping,
    onChangeField: "shipping",
  },
];

export const getRadioButtonData = (shippingoption) => [
  {
    label: "Shipping",
    value: shippingoption,
    onChangeField: "shippingoption",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    vairant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    vairant: "outlined",
    type: "primary",
    disabled: false,
  },
];
