//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  Notification,
} from "components/Atoms";
import * as _ from "lodash";

import * as Actions         from "actions/core/core.actions";
import * as ActionTypes     from 'constants/ActionTypes';

import StoreProfileWidget             from "components/pages/seller/store/StoreProfileWidget";

import OrdersMain                     from "components/pages/seller/orders/OrdersMain";
import { H5 } from 'components/CustomTypography';

import "./styles.scss";
import {Box, Card, Container} from "@mui/material";
// Component Imports

const quickAccessMenu1 = [
  {
    id: "neworders",
    name: "New Orders",
    icon: "icon-orders",
    route: "/orders/new",
  },
  {
    id: "inprogressorders",
    name: "Open Orders",
    icon: "icon-orders",
    route: "/orders/open",
  },
  {
    id: "allorders",
    name: "All orders",
    icon: "icon-orders",
    route: "/orders/all",
  },
  {
    id: "searchorders",
    name: "Search orders",
    icon: "icon-orders",
    route: "/orders/search",
  }
];
const quickAccessMenu2 = [
  {
    id: "shoproducts",
    name: "All Products in my shop",
    icon: "icon-product",
    route: "/products",
  },
  {
    id: "scanproduct",
    name: "Scan to add common product",
    icon: "icon-barcode",
    route: "/products/scan",
  },
  {
    id: "searchproduct",
    name: "Search common products",
    icon: "icon-info",
    route: "/products/search",
  },
  {
    id: "privateproduct",
    name: "Manage Private Products",
    icon: "icon-info",
    route: "/products/private",
  }
];
const quickAccessMenu3 = [
  {
    id: "shopprofile",
    name: "Shop profile",
    icon: "icon-info",
    route: "/shop",
  },
  {
    id: "shopaddress",
    name: "Shop address",
    icon: "icon-home",
    route: "/shop/address",
  },
  {
    id: "shopdomain",
    name: "Shop domain",
    icon: "icon-home",
    route: "/shop/domain",
  },
];
const quickAccessMenu4 = [
  {
    id: "manage_subscriptions",
    name: "Manage  Subscriptions",
    icon: "icon-subscriptions",
    route: "/subscriptions",
  },
  {
    id: "upcoming_plans",
    name: "Upcoming Plans",
    icon: "icon-new-plans",
    route: "/subscriptions/store",
  },
];

type Props = {};

type State = {};

class SellerHome extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      bootupInfo,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (reqStatus === prevProps.reqStatus) return;
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
        break;
      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  render() {
    const { isLoading, userDetails } = this.props;
    return (
        <Container>
            <Box mb={2}>
              <Card>
                <H5>Shop</H5>
                <StoreProfileWidget {...this.props} />
              </Card>
            </Box>
            <Box mb={2}>
              <Card>
                <H5>Recent Orders</H5>
                <OrdersMain {...this.props} orderstatus="new" includestatus="false" title="New Orders"></OrdersMain>

              </Card>
            </Box>
        </Container>
    );
  }
}
// Mapping Props and State.
const _Home = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SellerHome);

export { _Home as default };
