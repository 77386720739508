/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container, Divider, Typography,
} from "@mui/material";
import Slider from "react-multi-carousel";
import { CustomLeftArrow, CustomRightArrow } from "components/Button/SliderButtons";
import { connect } from "react-redux";
import * as Actions from "actions";
import { bindActionCreators } from "redux";
import CategoryItem from "./CategoryItems";
import responsive from "./responsive";

const CategoryMenuSlider = (props) => {
  const { usergetProductCategories, bootupInfo, categoryList } = props;
  useEffect(() => {
    usergetProductCategories(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite);
  }, []);
  const handleSelectCategory = (id) => {
    props.history.push(`/product/category/${id}`);
  };

  if (!categoryList || !categoryList.length) {
    return null;
  }

  return (
    <Container className="theme-card">
      <div className="position-relative">
        <Typography variant="h6">Top Categories</Typography>
        <Divider />
        <Slider
          containerClass="overflow-clear"
          renderButtonGroupOutside
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          responsive={responsive}
        >
          {categoryList.map((category, index) => (
            <CategoryItem key={`${index + 1}`} category={category} handleSelectCategory={handleSelectCategory} />
          ))}
        </Slider>
      </div>
    </Container>
  );
};

CategoryMenuSlider.propTypes = {
  usergetProductCategories: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  categoryList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const CategoryMenuSliderComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    categoryList: userproducts.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(CategoryMenuSlider);

export default CategoryMenuSliderComponent;
