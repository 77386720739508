/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";

import {
  Pace,
} from "components/Atoms";

import Loginform from "components/elements/core/LoginForm";

import LoginRegister from "components/elements/core/LoginRegister";
import LoginRegisterSuccess from "components/elements/core/LoginRegisterSuccess";
import PasswordResetForm from "components/elements/core/PasswordResetForm";
import PasswordResetSuccess from "components/elements/core/PasswordResetSuccess";
import LoginPreOTPForm from "../../../elements/core/LoginPreOTPForm";
import LoginPostOTPForm from "../../../elements/core/LoginPostOTPForm";

import "./styles.scss";
import {Link} from "react-router-dom";
import {Box, Container} from "@mui/material";

const Login = (props) => {
  const [state, setState] = useState({
    viewMode: "LOGIN",
    loginMode: "OTP",
    errorType: "",
    mobile: "",
  });
  const {
    login: loginApi,
    serviceCode,
    serviceError,
    reqStatus,
    isLoading,
  } = props;

  useEffect(() => {
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
        break;
      case ActionTypes.LOGIN_POST_OTP_SUCCESS:
        break;
      default:
        break;
    }
  }, [reqStatus]);

  // const sendNotification = (type, { title, description = "" }) => {
  //   Notification[type]({
  //     message: title,
  //     description,
  //   });
  // };
  const setViewMode = (viewMode) => () => {
    setState({ ...state, viewMode });
  };

  const setLoginMode = (loginMode) => () => {
    setState({ ...state, loginMode });
  };

  const login = (loginDetails) => {
    loginApi(loginDetails);
  };

  const loginPreOTP = (loginDetails) => {
    props.loginPreOTP(loginDetails);
    setState({ ...state, mobile: loginDetails.mobile });
  };

  const loginPostOTP = (loginDetails) => {
    props.loginPostOTP(loginDetails);
  };

  const register = (registerDetails) => {
    props.register(registerDetails);
  };

  const resetPassword = (email) => {
    props.resetPassword(email);
  };

  const handleSignInClick = () => {
    window.open(`${process.env.REACT_APP_GOOGLE_LOGIN_ENDPOINT}`, "_self");
  };

  const { viewMode, loginMode, mobile } = state;
  return (
      <div>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <section className="page-login">
      <div className="action-box">
        {viewMode === "LOGIN" ? (
          loginMode === "OTP" ? (
            (serviceCode === 200 && reqStatus === ActionTypes.LOGIN_PRE_OTP_SUCCESS)
              ? (
                <LoginPostOTPForm
                  serviceError={serviceError}
                  serviceCode={serviceCode}
                  reqStatus={reqStatus}
                  OTP_USER={{ mobile }}
                  showResetPassword={setViewMode("RESET_PASSWORD")}
                  showRegister={setViewMode("REGISTER")}
                  showPasswordLogin={setLoginMode("PASSWORD_LOGIN")}
                  loginPostOTP={loginPostOTP}
                />
              )
              : (
                <LoginPreOTPForm
                  serviceError={serviceError}
                  serviceCode={serviceCode}
                  reqStatus={reqStatus}
                  showResetPassword={setViewMode("RESET_PASSWORD")}
                  showRegister={setViewMode("REGISTER")}
                  showPasswordLogin={setLoginMode("PASSWORD_LOGIN")}
                  loginPreOTP={loginPreOTP}
                />
              )
          ) : (
            <Loginform
              serviceError={serviceError}
              serviceCode={serviceCode}
              reqStatus={reqStatus}
              showResetPassword={setViewMode("RESET_PASSWORD")}
              showRegister={setViewMode("REGISTER")}
              showOTPLogin={setLoginMode("OTP")}
              login={login}
              googleSignIn={handleSignInClick}
            />
          )
        ) : viewMode === "REGISTER" ? (
          (serviceCode === 200 && reqStatus === ActionTypes.REGISTER_SUCCESS)
            ? (
              <LoginRegisterSuccess
                serviceError={serviceError}
                serviceCode={serviceCode}
                reqStatus={reqStatus}
                showLogin={setViewMode("LOGIN")}
                showResetPassword={setViewMode("RESET_PASSWORD")}
                register={register}
              />
            )
            : (
              <LoginRegister
                serviceError={serviceError}
                serviceCode={serviceCode}
                reqStatus={reqStatus}
                showLogin={setViewMode("LOGIN")}
                showResetPassword={setViewMode("RESET_PASSWORD")}
                register={register}
              />
            )

        ) : (
          (serviceCode === 200 && reqStatus === ActionTypes.RESET_PASSWORD_SUCCESS)
            ? (
              <PasswordResetSuccess
                serviceError={serviceError}
                serviceCode={serviceCode}
                reqStatus={reqStatus}
                showLogin={setViewMode("LOGIN")}
                showRegister={setViewMode("REGISTER")}
                resetPassword={resetPassword}
              />
            )
            : (
              <PasswordResetForm
                serviceError={serviceError}
                serviceCode={serviceCode}
                reqStatus={reqStatus}
                showLogin={setViewMode("LOGIN")}
                showRegister={setViewMode("REGISTER")}
                resetPassword={resetPassword}
              />
            )
        )}

      </div>
    </section>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Link to={`${process.env.PUBLIC_URL}`} className="m-2 btn btn-solid">Home</Link>
        </Box>
  </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loginPreOTP: PropTypes.func.isRequired,
  loginPostOTP: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  serviceCode: PropTypes.number.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const LoginComponent = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    serviceCode: bootupInfo.serviceCode,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(Login);

export { LoginComponent as default };
