/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const TextFieldComponent = ({
  required = false, label, type, value, error, helperText, onBlur, onChange, ...rest
}) => (
  <TextField
    required={required}
    label={label}
    type={type}
    autoComplete
    variant="standard"
    value={value}
    error={error}
    helperText={helperText}
    onBlur={onBlur}
    onChange={onChange}
    {...rest}
  />
);

TextFieldComponent.defaultProps = {
  type: "text",
};

TextFieldComponent.propTypes = {
  type: PropTypes.string,
};

export default TextFieldComponent;
