import * as React from "react";
import ButtonComponent from "components/Button";
import { Box, Typography } from "@mui/material";
import { H6 } from "components/CustomTypography";

const ServicePreview = ({
  isLoading, service, editService, cancelEdit,
}) => {
  const { shippingoption, orderminimum, shipping } = service;

  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
        {shippingoption === "freeshipping" ? (
          <Typography>
            Free Shipping
          </Typography>
        ) : (
          <Typography>
            <H6> Minimum order for free Shipping:</H6>
            {`${orderminimum || ""}`}
            <br/>
            <H6>Shipping cost:</H6>
            {`${shipping || ""}`}
          </Typography>
        )}
      </Box>
      <Box>
        {!isLoading
              && (
              <ButtonComponent
                sx={{
                  m: 2,
                }}
                variant="contained"
                type="primary"
                name="Edit"
                size="small"
                onClick={editService}
              />
              )}
      </Box>
    </Box>
  );
};

export default ServicePreview;
