//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Button,
  Notification,
    Divider
} from "components/Atoms";

import { H6 } from 'components/CustomTypography';

import * as Actions       from "actions/seller/seller.store";

import "./styles.scss";

class StoreProfileWidget extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    props.getStoreProfile();
      props.getDomain();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;
    if (reqStatus == prevProps.reqStatus) return;
    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  handlePage = (page) => (e) => {
    this.props.history.push({
      pathname: page
    });
  }

  handleExternalPage = (url) => (e) => {
    if (window.cordova)
      window.open = window.cordova.InAppBrowser.open;
    window.open( url, '_system'); return false;

  }

  render() {
    const {
      isLoading,
      reqStatus,
      storeProfile,
        storeDomain
    } = this.props;

      const url = (storeDomain.value.domain && storeDomain.value.subdomain) ? "http://" + storeDomain.value.subdomain + "." + storeDomain.value.domain : "";

      return (
          <section className="order-page">
            <div className="action-box">
              <Page className="subscriptions-home">
                <H6>{storeProfile.title}</H6>
                <H6><a href="#" onClick={this.handleExternalPage(url)}>{url}</a> <Button type="secondary" size="small" htmlType="button" onClick={this.handlePage("/shop")}>Edit</Button> </H6>
              </Page>
            </div>
          </section>
    );
  }
}
// Mapping Props and State.
const _StoreProfileWidget = connect(
  ({ store, appBase }) => ({
    appBase: appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
    storeDomain: store.storeDomain,
    storeAddress: store.storeAddress,
    storeImages: store.storeImages,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StoreProfileWidget);

export { _StoreProfileWidget as default };
