import * as yup from "yup";

export const schema = yup.object().shape({
  is_popular: yup.boolean().default(true),
  is_active: yup.boolean().default(true),
});


export const getCheckboxData = (is_popular, is_active) => [
  {
    label: "Popular Category",
    value: is_popular,
    onChangeField: "is_popular",
  },
  {
    label: "Enabled",
    value: is_active,
    onChangeField: "is_active",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    variant: "contained",
    onClick: onFinish,
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    variant: "outlined",
    onClick: cancelEdit,
    type: "primary",
    disabled: false,
  },
];
