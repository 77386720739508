// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  categoryList: {
    count: 0,
    categories: [],
  },
  orderinfo: {
    _id: "",
    name: "",
    images: []
  },
  orderdetail: {
    products: []
  },
  orderList: {
    meta: {
      count: 0,
    },
    records: [],
  },
  ordersByCategory: {
    meta: {
      count: 0,
    },
    records: [],
  },

  storeOrderCategoryList: {
    count: 0,
    categories: [],
  },
  storeOrders: {
    records: [],
  },
  searchorderList: {
    count: 0,
    records: [],
  },
  searchkeywords: {
    keywords: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.GET_ACTIVE_CATEGORIES_START:
    case ActionTypes.GET_COMMON_ORDERS_START:
    case ActionTypes.GET_STORE_ORDER_CATEGORIES_START:
    case ActionTypes.GET_STORE_ORDERS_START:
    case ActionTypes.GET_ORDER_DETAIL_START:
    case ActionTypes.ADD_ORDER_START:
    case ActionTypes.UPDATE_ORDER_START:
    case ActionTypes.REMOVE_ORDER_START:
    case ActionTypes.ADD_ORDER_UPC_START:
    case ActionTypes.SEARCH_ORDERS_START:
    case ActionTypes.SEARCH_ORDERS_KEYWORDS_START:
    case ActionTypes.CHECK_ORDER_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.GET_ACTIVE_CATEGORIES_FAILURE:
    case ActionTypes.GET_COMMON_ORDERS_FAILURE:
    case ActionTypes.GET_STORE_ORDER_CATEGORIES_FAILURE:
    case ActionTypes.GET_STORE_ORDERS_FAILURE:
    case ActionTypes.GET_ORDER_DETAIL_FAILURE:
    case ActionTypes.ADD_ORDER_FAILURE:
    case ActionTypes.UPDATE_ORDER_FAILURE:
    case ActionTypes.REMOVE_ORDER_FAILURE:
    case ActionTypes.ADD_ORDER_UPC_FAILURE:
    case ActionTypes.SEARCH_ORDERS_FAILURE:
    case ActionTypes.SEARCH_ORDERS_KEYWORDS_FAILURE:
    case ActionTypes.CHECK_ORDER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

          // keep loading to true as fetch will trigger post update
    case ActionTypes.UPDATE_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
  }

    case ActionTypes.REMOVE_ORDER_SUCCESS:
    case ActionTypes.ADD_ORDER_SUCCESS:
    case ActionTypes.CHECK_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    case ActionTypes.ADD_ORDER_UPC_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          orderinfo: action.payload.response[0],
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          orderinfo: {
            _id: "",
            name: "",
            images: []
          },
        };
      }
    }

    case ActionTypes.SEARCH_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {orders} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchorderList: {
            records: orders,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchorderList: {
            records: [],
          },
        };
      }
    }

    case ActionTypes.SEARCH_ORDERS_KEYWORDS_SUCCESS: {
      if (action.payload.statusCode === 200) {

        const {keywords} = action.payload;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: keywords,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: [],
          },
        };
      }
    }
    case ActionTypes.GET_ACTIVE_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          categoryList: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          categoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.GET_COMMON_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {order_count, orders} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          ordersByCategory: {
            meta: {
              count: order_count,
            },
            records: orders,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          ordersByCategory: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.GET_STORE_ORDER_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {categories, count} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeOrderCategoryList: {
            count: count,
            categories: categories
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeOrderCategoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.GET_STORE_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {orders} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeOrders: {
            records: orders,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeOrders: {
            records: [],
          },
        };
      }
    }

    case ActionTypes.GET_ORDER_DETAIL_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {order} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          orderdetail: order
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          orderdetail: {
            products: []
          }
        };
      }
    }

    /*
    case ActionTypes.SEARCH_ORDERS_SUCCESS: {
      const { order_count, orders } = action.payload;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        orderList: {
          meta: {
            count: order_count,
          },
          records: orders,
        },
      };
    }
    */

    default:
      return state;
  }
}
