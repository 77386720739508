import * as yup from "yup";

export const schema = yup.object().shape({
  subdomain: yup.string().required("Subdomain is required field")
    .matches(/^[a-z0-9]+$/, "Must be lower case char or number")
    .min(8, "Must be 8 chars or more")
    .max(15, "Must be less than 15 chars"),
});

export const getTextfieldData = (subdomain) => [
  {
    label: "Subdomain",
    value: subdomain,
    onChangeField: "subdomain",
  },
];

export const getButtonData1 = (saveDomain, cancelEdit) => [
  {
    name: "Save",
    onClick: saveDomain,
    variant: "contained",
    type: "primary",
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];

export const getButtonData2 = (checkDomain, cancelEdit, hasError) => [
  {
    name: "Check Availability",
    onClick: checkDomain,
    variant: "contained",
    type: "primary",
    disabled: hasError(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];
