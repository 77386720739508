import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as Actions from "actions";
import { Notification, Spin } from "components/Atoms";
import { getLocalDate } from "helpers/utils";

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    const { orderId } = props.match.params;
    this.orderId = orderId;
    this.props.getOrderDetail(this.orderId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
  }

  render() {
    const { isLoading, orderdetail, symbol } = this.props;
    const {
      products, shop, created_at, _id, total_price,
    } = orderdetail;

    return (
      <div>
        {isLoading && <Spin />}
        {!isLoading ? (products.length > 0
          ? (
            <section className="cart-section section-b-space">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <div className="col-sm-12 empty-cart-cls text-left">
                        <h4>{shop[0].title}</h4>
                        {shop[0].addressline}
                        {" "}
                        {shop[0].cityname}
                        Order:
                        {" "}
                        {_id}
                        , Date:
                        {" "}
                        {getLocalDate(created_at)}
                      </div>
                    </div>
                    <table className="table cart-table table-responsive-xs">
                      <thead>
                        <tr className="table-head">
                          <th scope="col">image</th>
                          <th scope="col">product name</th>
                          <th scope="col">price</th>
                          <th scope="col">quantity</th>
                          <th scope="col">action</th>
                          <th scope="col">total</th>
                        </tr>
                      </thead>
                      {products.map((cartItem, index) => (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${cartItem.product.id}`}>
                                <img src={`${cartItem.product.images ? cartItem.product.images[0] ? cartItem.product.images[0].cdn.url : "" : ""}`} alt="" />
                              </Link>
                            </td>
                            <td>
                              <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${cartItem.product.id}`}>{cartItem.product.name}</Link>
                              <div className="mobile-cart-content row">
                                <div className="col-xs-3">
                                  <div className="qty-box">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementProductInCart(cartItem, this.shopId)} data-type="minus" data-field="">
                                          <i className="fa fa-angle-left" />
                                        </button>
                                      </span>
                                      <input type="text" name="quantity" value={cartItem.product_quantity} readOnly className="form-control input-number" />

                                      <span className="input-group-prepend">
                                        <button className="btn quantity-right-plus" onClick={() => this.props.incrementProductInCart(cartItem, this.shopId)} data-type="plus">
                                          <i className="fa fa-angle-right" />
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    {symbol}
                                    {cartItem.seller_price}
                                  </h2>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    <a href={null} className="icon" onClick={() => this.props.removeProductFromCart(cartItem, this.shopId)}>
                                      <i className="icon-close" />
                                    </a>
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td>
                              <h2>
                                {symbol}
                                {cartItem.seller_price}
                              </h2>
                            </td>
                            <td>
                              <div className="qty-box">
                                {cartItem.product_quantity}
                              </div>
                            </td>
                            <td>
                              <a href={null} className="icon" onClick={() => this.props.removeProductFromCart(cartItem, this.shopId)}>
                                <i className="fa fa-times" />
                              </a>
                            </td>
                            <td>
                              <h2 className="td-color">
                                {symbol}
                                {cartItem.product_quantity * cartItem.product.price}
                              </h2>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <table className="table cart-table table-responsive-md">
                      <tfoot>
                        <tr>
                          <td>total price :</td>
                          <td>
                            <h2>
                              {symbol}
                              {" "}
                              {total_price}
                              {" "}
                            </h2>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="row cart-buttons">
                  <div className="col-6">
                    <Link to={`${process.env.PUBLIC_URL}/user`} className="btn btn-solid">Shopana</Link>
                  </div>
                </div>
              </div>
            </section>
          )
          : (
            <section className="cart-section section-b-space">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <div className="col-sm-12 empty-cart-cls text-center">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                        <h3>
                          <strong>Order is Empty</strong>
                        </h3>
                        <h4>Explore more shortlist some items.</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        ) : <div> </div> }
      </div>
    );
  }
}

// Mapping Props and State.
const _OrderDetail = connect(
  ({ userorders, userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userorders.reqStatus,
    isLoading: userorders.isLoading,
    serviceError: userorders.serviceError,
    orderdetail: userorders.orderdetail,
    symbol: userproducts.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(OrderDetail);

export { _OrderDetail as default };
