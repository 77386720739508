import * as React from "react";
import { currencyFormatter } from "helpers/utils";

import "./style.scss";

type Props = {
  className?: ?string,
};


const AvailablePlanCheckout = ({ plan }) => {

  const { title, description, duration, pricing  } = plan || {};

  return (
    <div className="available-subscription-checkout">
      <h4>{title}</h4>
      <p className="description">{description}</p>
      <h5 className="price">{currencyFormatter(pricing)}</h5>
      <div className="footer">
        <div className="validity">
          <span className="value text large bold">{duration}</span>
          <label className="label">Days</label>
        </div>
      </div>
    </div>
  );
};

export default AvailablePlanCheckout;
