// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/seller/seller.registerstore";


function* getUserLocation(action: Object) {
  try {
    let query = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      query = {
        lat: latitude,
        lng: longitude,
      };
    }
    const shops = yield API.get(`/sellers/store/get-shops`, { params: query });

    yield put(Actions.getUserLocationSuccess(shops.shops));
  } catch (error) {
    yield put(Actions.getUserLocationFailure(error));
  }
}
function* getPlaceSuggestions(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/store/is-available`,
      action.payload
    );
    yield put(Actions.getPlaceSuggestionsSuccess(response));
  } catch (error) {
    yield put(Actions.getPlaceSuggestionsFailure(error));
  }
}
function* getStoresForLocation(action: Object) {
  try {

    let query = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      query = {
        lat: latitude,
        lng: longitude,
      };
    }

    const shops = yield API.get(`/sellers/store/get-shops`, { params: query });
    yield put(Actions.getStoresForLocationSuccess(shops.response));
  } catch (error) {
    yield put(Actions.getStoresForLocationFailure(error));
  }
}

function* addShopToAccount(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/account/add-shop`,
        action.payload
    );
    yield put(Actions.addShopToAccountSuccess(response.response));
  } catch (error) {
    yield put(Actions.addShopToAccountFailure(error));
  }
}

export default function* RegisterStoreSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.GET_USER_LOCATION_START,       getUserLocation);
  yield takeLatest(ActionTypes.GET_PLACE_SUGGESTIONS_START,   getPlaceSuggestions);
  yield takeLatest(ActionTypes.GET_STORES_FOR_LOCATION_START, getStoresForLocation);
  yield takeLatest(ActionTypes.ADD_SHOP_TO_ACCOUNT_START,     addShopToAccount);

}
