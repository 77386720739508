/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "components/elements/main/index.scss";

import * as Actions from "actions";

import ProductSearch from "components/widgets/user/ShopProductSearch";

const UserSearch = (props) => {
  const [state] = useState({
    layoutColumns: 3,
  });

  useEffect(() => {
    props.userResetSearchProducts();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

  return (
    <div>
      <ProductSearch {...props} colSize={state.layoutColumns} />
    </div>
  );
};

UserSearch.propTypes = {
  userResetSearchProducts: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userSearch = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserSearch);

export default userSearch;
