import * as React from "react";

import OrdersMain     from "components/pages/seller/orders/OrdersMain";

import "./style.scss";

class OrdersOpen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <OrdersMain {...this.props} orderstatus="inprogress" title="Open Orders"></OrdersMain>
    );
  }
}

export default (OrdersOpen)


