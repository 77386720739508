import * as React from "react";

import { Box, Container, Typography } from "@mui/material";
import { H5 } from "components/CustomTypography";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CheckboxComponent from "components/Checkbox";
import PropTypes from "prop-types";
import {
  schema, getTextfieldData, getButtonData, getCheckboxData,
} from "./helper";

const SellerProductEntryForm = ({
  setState, product, updateProductSettingClicked, cancelEdit,
}) => {
  const {
    name, id, price, is_popular, is_price_visible, is_active,
  } = product || {};

  const [blur, setblur] = React.useState({
    price: false,
    is_popular: false,
    is_price_visible: false,
    is_active: false,
  });

  const onFinish = () => {
    updateProductSettingClicked({
      price, is_popular, is_price_visible, is_active,
    }, id);
  };

  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleCheckBoxChange = (event, field) => {
    setState(event.target.checked, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(product);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.price || blur.is_popular || blur.is_price_visible || blur.is_active);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, product);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        <H5>{name}</H5>
        {getTextfieldData(price).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
            />
          </Box>
        ))}

        {getCheckboxData(is_popular, is_price_visible, is_active).map((element) => (
          <Box className="textfield" m={2}>
            <CheckboxComponent
              required
              label={element.label}
              value={element.value}
              onChange={(event) => handleCheckBoxChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            type={element.type}
            variant={element.variant}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

SellerProductEntryForm.propTypes = {
  setState: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  updateProductSettingClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default SellerProductEntryForm;
