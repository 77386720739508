//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
  Input,
  Notification,
  Roller,
  AntdModal,
    Pace
} from "components/Atoms";

import * as _ from "lodash";

import * as Actions         from "actions/seller/seller.products";
import * as ActionTypes     from 'constants/ActionTypes';

import SellerProduct          from "components/elements/seller/SellerProduct";
import SellerProductEntryForm from "components/elements/seller/SellerProductEntryForm";
import CategorySelector       from "components/elements/seller/CategorySelector";

import "./styles.scss";
// Component Imports

type Props = {};

type State = {};

class ProductsMain extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      bShowCategoryBrowser: false,
    };
    this.filterCategories = _.debounce(this.filterProducts, 1000);
    props.getStoreProductCategories();
    props.getStoreProducts(null, false);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (reqStatus === prevProps.reqStatus) return;

    switch (reqStatus) {
      case ActionTypes.UPDATE_PRODUCT_SUCCESS:
          // optimize later - updated product only
          this.props.getStoreProducts();
        break;
      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }

        break;
    }
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  filterProducts = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };
  addProduct = () => {
    this.props.history.push("/products/new");
  };

  editProduct = (product, prodId) => {
    product.id = prodId;
    this.props.updateProduct(product);
    this.hideProductEntryDialog();
  };


  /* Header actions*/
  showProductEntryDialog = (product) => () => {
    this.setState({ selectedProduct: product, bShowProductEntryDialog: true });
  };
  hideProductEntryDialog = () => {
    this.setState({ selectedProduct: null, bShowProductEntryDialog: false });
  };

  showCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: true });
  };
  hideCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: false });
  };

  resetCategory = () => {
    this.state.selectedCategory = null;
    this.props.getStoreProducts(this.state.selectedCategory);
  };

  setCategory = (category) => {
    this.state.selectedCategory = category;
    this.hideCategoryBrowser();
    this.props.getStoreProducts(this.state.selectedCategory);
  };

  handlePage = (page) => (e) => {
    this.props.history.push({
      pathname: page
    });
  }

  render() {
    const { isLoading, storeProducts, categoryList } = this.props;
    const { bShowProductEntryDialog, bShowCategoryBrowser, selectedProduct, selectedCategory } = this.state;

    const { count, records } = storeProducts;

    return (
      <Page className="products-home">
        <div className="content">
          {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px"/>}
          <div className="product-list">
            <CategorySelector
                title="All Product Categories"
                visible={bShowCategoryBrowser}
                categoryList={categoryList}
                handlePage={this.handlePage}
                resetCategory={this.resetCategory}
                onCategorySelect={this.setCategory}
            />
            <Row gutter={[16, 16]}>
              {records.map((product) => {
                return (
                  <Col span={6} key={`pr-item-${product.id}`}>
                    <SellerProduct
                      className="product-item"
                      key={product.id}
                      data={product}
                      actions={
                        <>
                          <Button
                              type="primary"
                              onClick={this.showProductEntryDialog(product)}
                          >
                            Edit
                          </Button>
                        </>
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <SellerProductEntryForm
            visible={bShowProductEntryDialog}
            data={selectedProduct}
            onOk={this.editProduct}
            onCancel={this.hideProductEntryDialog}
        />
      </Page>
    );
  }
}
// Mapping Props and State.
const _ProductsMain = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    storeProducts: products.storeProducts,
    categoryList: products.storeProductCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProductsMain);

export { _ProductsMain as default };
