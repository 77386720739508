import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { bindActionCreators } from "redux";

import { Notification, Pace } from "components/Atoms";

import * as Actions     from "actions";
import MockProductList  from "components/elements/main/MockProductList";

import { getLocalDate } from "helpers/utils";

class orderList extends Component {

    constructor (props) {
        super (props);
        props.getOrderlistProducts(this.props.bootupInfo.appState.subdomain_shopId);
    }


    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    render (){

        const {isLoading, orderlistItems, symbol} = this.props;

        return (
            <div>
                {isLoading ? <MockProductList /> :
                orderlistItems.length>0 ?
                <section className="wishlist-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col">order no</th>
                                        <th scope="col">shop</th>
                                        <th scope="col">date</th>
                                        <th scope="col">total</th>
                                    </tr>
                                    </thead>
                                    {orderlistItems.map((item, index) => {
                                        return (
                                            <tbody key={index}>
                                            <tr>
                                                <td>
                                                    <Link to={`${process.env.PUBLIC_URL}/user/order/${item._id}`}>${item.order_id}
                                                    </Link>
                                                </td>
                                                <td><Link to={`${process.env.PUBLIC_URL}/user/shop/${item.shop._id}`}>{item.shop.title}</Link>
                                                    <div className="mobile-cart-content row">
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color"><del><span className="money">{symbol}{item.total_price}</span></del></h2>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {getLocalDate(item.created_at)}
                                                </td>
                                                <td><h2><del><span className="money">{symbol}{item.total_price}</span></del></h2>
                                                </td>
                                            </tr>
                                            </tbody> )
                                    })}
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty-wishlist.png`} className="img-fluid mb-4" alt="" />
                                        <h3>
                                            <strong>WhishList is Empty</strong>
                                        </h3>
                                        <h4>Explore more shortlist some items.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
            </div>
        )
    }
}

// Mapping Props and State.
const _orderList = connect(
    ({ userorders, userproducts, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: userorders.reqStatus,
        isLoading: userorders.isLoading,
        serviceError: userorders.serviceError,
        orderlistItems: userorders.orderlist,
        symbol: userproducts.symbol
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(orderList);

export { _orderList as default };
