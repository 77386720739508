/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";

import * as Actions from "actions";
import {
  TextField, InputAdornment, IconButton, Container, Typography, Chip, Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextFieldComponent from "components/TextField";
import ShopProductItem from "components/elements/user/ShopProductItem";
import MockProductList from "components/elements/main/MockProductList";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ShopProductSearch = (props) => {
  const [state, setState] = useState({
    limit: 5,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
    searchvalue: "Search products",
  });
  const [optionValues, setOptionValues] = useState([]);

  const {
    isLoading, products, symbol, addProductToCart, addProductToWishlist,
    addToCompare, searchkeywords, match, history,
  } = props;
  // const {count, products} = productList;
  const { keywords } = searchkeywords;
  const {
    resetvalue,
  } = state;
  const { query } = match.params;

  useEffect(() => {
    if (query) {
      props.usersearchProducts(query,
        props.bootupInfo.appState.subdomain_shopId, true, props.bootupInfo.appState.isWebsite);
    }
  }, []);

  const runSearch = (keyword) => {
    props.userResetsearchKeywords();
    setState({ searchquery: keyword });
    setState({ resetvalue: "" });
    setState({ isLoading: true });
    if (props.bootupInfo.appState.isWebsite) {
      props.history.push(`/search/${keyword}`);
    } else {
      history.push(`/user/search/${keyword}`);
    }

    // props.usersearchProducts(keyword,
    //   props.bootupInfo.appState.subdomain_shopId, true, props.bootupInfo.appState.isWebsite);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      //runSearch(resetvalue);
      runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      props.usersearchKeywords(value,
        props.bootupInfo.appState.subdomain_shopId, true, props.bootupInfo.appState.isWebsite);
    }
  };

  const handleDelete = () => {
    history.push("");
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/search" || match.path !== "/user/search")) {
      props.history.push(process.env.REACT_APP_TYPE === "MAINSITE" ? "/user/search" : "/search");
    }
  };

  let isModalSearch = false;

  return (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box>
        {
            window.innerWidth <= 500
            && (match.path === "/user/search" || match.path === "/search") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={handleKeyPress}
          onSelect={(input) => runKeywordSearch(input.target.value)}
          options={window.innerWidth <= 500 ? [] : optionValues.map((element) => element.label)}
          renderInput={(params) => (
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="large" onClick={() => props.history.push("/")}>
                      <ArrowBackIosIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...params}
              type="search"
              size="large"
              autoFocus={isModalSearch}
              label={`Search ${process.env.REACT_APP_PRODUCT_TITLE}`}
              placeholder={`Search ${process.env.REACT_APP_PRODUCT_TITLE}`}
              margin="normal"
            />
          )}
        />
        </Box>
      </Container>
      {
            window.innerWidth <= 500
              && (match.path === "/user/search" || match.path === "/search")
              && optionValues.map((element) => (
                <Container>
                  <Typography onClick={() => history.push(`search/${element.label}`)}>
                    <IconButton disabled size="large">
                      <SearchIcon />
                    </IconButton>
                    {element.label}
                  </Typography>
                </Container>
              ))
          }

      <Container>
        {query && (
        <Chip
          label={query}
          onDelete={handleDelete}
        />
        )}
      </Container>
      { match.path !== "/product"
      && match.path !== "/"
      && (match.path === "/user/search/:query" || match.path === "/search/:query") && (
      <div className="product-wrapper-grid">
        <div className="container-fluid">
          {isLoading ? <MockProductList />
            : products.length > 0
              ? (
                <div className="row">
                  { products.slice(0, state.limit).map((product, index) => (
                    <div className="product-card-container align-left-Last" key={`${index + 1}`}>
                      <ShopProductItem
                        product={product}
                        symbol={symbol}
                        onAddToCompareClicked={() => addToCompare(product)}
                        onAddToWishlistClicked={() => addProductToWishlist(product)}
                        onAddToCartClicked={() => addProductToCart(product,
                          props.bootupInfo.appState.subdomain_shopId,
                          props.bootupInfo.appState.isWebsite)}
                        key={`${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              )
              : (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="" />
                    <h3>Sorry! Couldn`t find the product you were looking For!!!    </h3>
                    <p>
                      Please check if you have misspelt something or try searching with other words.
                    </p>
                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                  </div>
                </div>
              )}
        </div>
      </div>
      )}
    </>
  );
};

ShopProductSearch.defaultProps = {
  isLoading: false,
  symbol: "$",
};

ShopProductSearch.propTypes = {
  isLoading: PropTypes.bool,
  addProductToCart: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usersearchProducts: PropTypes.func.isRequired,
  userResetsearchKeywords: PropTypes.func.isRequired,
  //usersearchKeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  //reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  //serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ShopProductSearchComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.searchproducts,
    searchkeywords: userproducts.searchkeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductSearch);

export { ShopProductSearchComponent as default };
