// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const getDomain = (req) => ({
  type: ActionTypes.GET_DOMAIN_START,
  payload: req,
});

export const getDomainSuccess = (data) => {
  return {
    type: ActionTypes.GET_DOMAIN_SUCCESS,
    payload: data,
  };
};
export const getDomainFailure = (error) => ({
  type: ActionTypes.GET_DOMAIN_FAILURE,
  payload: error,
});

export const checkDomainAvailability = (req) => ({
  type: ActionTypes.CHECK_DOMAIN_AVAILABILITY_START,
  payload: req,
});

export const checkDomainAvailabilitySuccess = (data) => {
  return {
    type: ActionTypes.CHECK_DOMAIN_AVAILABILITY_SUCCESS,
    payload: data,
  };
};
export const checkDomainAvailabilityFailure = (error) => ({
  type: ActionTypes.CHECK_DOMAIN_AVAILABILITY_FAILURE,
  payload: error,
});

export const updateDomain = (req) => ({
  type: ActionTypes.UPDATE_DOMAIN_START,
  payload: req,
});

export const updateDomainSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_DOMAIN_SUCCESS,
    payload: data,
  };
};
export const updateDomainFailure = (error) => ({
  type: ActionTypes.UPDATE_DOMAIN_FAILURE,
  payload: error,
});

export const getStoreProfile = (req) => ({
  type: ActionTypes.GET_STORE_PROFILE_START,
  payload: req,
});

export const getStoreProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getStoreProfileFailure = (error) => ({
  type: ActionTypes.GET_STORE_PROFILE_FAILURE,
  payload: error,
});

export const updateStoreProfile = (req) => ({
  type: ActionTypes.UPDATE_STORE_PROFILE_START,
  payload: req,
});

export const updateStoreProfileSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};
export const updateStoreProfileFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_PROFILE_FAILURE,
  payload: error,
});

export const getStoreService = (req) => ({
  type: ActionTypes.GET_STORE_SERVICE_START,
  payload: req,
});

export const getStoreServiceSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_SERVICE_SUCCESS,
    payload: data,
  };
};
export const getStoreServiceFailure = (error) => ({
  type: ActionTypes.GET_STORE_SERVICE_FAILURE,
  payload: error,
});

export const updateStoreService = (req) => ({
  type: ActionTypes.UPDATE_STORE_SERVICE_START,
  payload: req,
});

export const updateStoreServiceSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_SERVICE_SUCCESS,
    payload: data,
  };
};
export const updateStoreServiceFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_SERVICE_FAILURE,
  payload: error,
});

export const getStoreAddress = (req) => ({
  type: ActionTypes.GET_STORE_ADDRESS_START,
  payload: req,
});

export const getStoreAddressSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const getStoreAddressFailure = (error) => ({
  type: ActionTypes.GET_STORE_ADDRESS_FAILURE,
  payload: error,
});

export const updateStoreAddress = (req) => ({
  type: ActionTypes.UPDATE_STORE_ADDRESS_START,
  payload: req,
});

export const updateStoreAddressSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const updateStoreAddressFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_ADDRESS_FAILURE,
  payload: error,
});

export const getStoreImages = (req) => ({
  type: ActionTypes.GET_STORE_IMAGES_START,
  payload: req,
});

export const getStoreImagesSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_IMAGES_SUCCESS,
    payload: data,
  };
};
export const getStoreImagesFailure = (error) => ({
  type: ActionTypes.GET_STORE_IMAGES_FAILURE,
  payload: error,
});

export const addStoreImage = (reqData) => ({
  type: ActionTypes.ADD_STORE_IMAGE_START,
  formData: reqData
});

export const addStoreImageSuccess = (data) => {
  return {
    type: ActionTypes.ADD_STORE_IMAGE_SUCCESS,
    payload: data,
  };
};
export const addStoreImageFailure = (error) => ({
  type: ActionTypes.ADD_STORE_IMAGE_FAILURE,
  payload: error,
});

export const removeStoreImage = (reqData) => ({
  type: ActionTypes.REMOVE_STORE_IMAGE_START,
  payload: {image_id: reqData},
});

export const removeStoreImageSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_STORE_IMAGE_SUCCESS,
    payload: data,
  };
};
export const removeStoreImageFailure = (error) => ({
  type: ActionTypes.REMOVE_STORE_IMAGE_FAILURE,
  payload: error,
});

export const updateStoreLogo = (req) => ({
  type: ActionTypes.UPDATE_STORE_LOGO_START,
  payload: req,
});

export const updateStoreLogoSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_LOGO_SUCCESS,
    payload: data,
  };
};
export const updateStoreLogoFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_LOGO_FAILURE,
  payload: error,
});
