import * as yup from "yup";

export const schema = yup.object().shape({
  title: yup.string().required("Name is required field"),
  cityname: yup.string().required("City is required field"),
  areaname: yup.string().required("Area is required field"),
  addressline: yup.string().required("Address is required field"),
  pincode: yup.string().required("Pin/Zip code is required field"),
  phone: yup.string().required("Phone is required field"),
});

export const getTextfieldData = (title, cityname, areaname, addressline, pincode, phone) => [
  {
    label: "Shop Name",
    value: title,
    onChangeField: "title",
  },
  {
    label: "City",
    value: cityname,
    onChangeField: "cityname",
  },
  {
    label: "Area",
    value: areaname,
    onChangeField: "areaname",
  },
  {
    label: "Address",
    value: addressline,
    onChangeField: "addressline",
  },
  {
    label: "Pin/Zip code",
    value: pincode,
    onChangeField: "pincode",
  },
  {
    label: "Phone",
    value: phone,
    onChangeField: "phone",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];
