/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Tab, Tabs, TabList, TabPanel,
} from "react-tabs";
import { Box, Container, Typography } from "@mui/material";
import { getBestSeller, getMensWear, getWomensWear } from "services";
import {
  addProductToCart, addToWishlist, addToCompare, usergetPopularProducts,
} from "actions";

import ProductItem from "components/elements/user/ProductItem";

const SpecialProducts = (props) => {
  const {
    popularproducts, mensWear, womensWear, symbol, addProductToCart: addProductToCartMethod,
    addToWishlist: addToWishlistMethod, addToCompare: addToCompareMethod,
    usergetPopularProducts: usergetPopularProduct,
  } = props;
  useEffect(() => {
    usergetPopularProduct();
  }, []);

  return (
    <div>
      <br />
      <Box display="flex" justifyContent="center">
        <Typography gutterBottom className="highlight-tab" variant="h4">special products</Typography>
      </Box>
      <br />
      <Container>
        <Tabs className="theme-tab">
          <TabList className="tabs tab-title">
            <Tab className="cursor-pointer">New Products</Tab>
            <Tab className="cursor-pointer">Mens Wear</Tab>
            <Tab className="cursor-pointer">Womens Wear</Tab>
          </TabList>

          <TabPanel>
            <div className="no-slider row">
              { popularproducts.map((product, index) => (
                <div className="product-card-container" key={`${index + 1}`}>
                  <ProductItem
                    product={product}
                    symbol={symbol}
                    onAddToCompareClicked={() => addToCompareMethod(product)}
                    onAddToWishlistClicked={() => addToWishlistMethod(product)}
                    onAddToCartClicked={() => addProductToCartMethod(product, 1)}
                  />
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="no-slider row">
              { mensWear.map((product, index) => (
                <div className="product-card-container" key={`${index + 1}`}>
                  <ProductItem
                    product={product}
                    symbol={symbol}
                    onAddToCompareClicked={() => addToCompareMethod(product)}
                    onAddToWishlistClicked={() => addToWishlistMethod(product)}
                    onAddToCartClicked={() => addProductToCartMethod(product, 1)}
                  />
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className=" no-slider row">
              { womensWear.map((product, index) => (
                <div className="product-card-container" key={`${index + 1}`}>
                  <ProductItem
                    product={product}
                    symbol={symbol}
                    onAddToCompareClicked={() => addToCompareMethod(product)}
                    onAddToWishlistClicked={() => addToWishlistMethod(product)}
                    onAddToCartClicked={() => addProductToCartMethod(product, 1)}
                  />
                </div>
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

SpecialProducts.defaultProps = {
  symbol: "$",
};

SpecialProducts.propTypes = {
  popularproducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  mensWear: PropTypes.arrayOf(PropTypes.object).isRequired,
  womensWear: PropTypes.arrayOf(PropTypes.object).isRequired,
  symbol: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usergetPopularProducts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  popularproducts: getBestSeller(state.userproducts.popularproducts),

  bestSeller: getBestSeller(state.userproducts.products),
  mensWear: getMensWear(state.userproducts.products),
  womensWear: getWomensWear(state.userproducts.products),
  symbol: state.userproducts.symbol,
});

export default connect(mapStateToProps, {
  addProductToCart, addToWishlist, addToCompare, usergetPopularProducts,
})(SpecialProducts);
