// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminGetCommonProducts = (reqData, category) => ({
  type: ActionTypes.ADMIN_GET_COMMON_PRODUCTS_START,
  payload: reqData,
  category: category
});

export const adminGetCommonProductsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonProductsFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_PRODUCTS_FAILURE,
  payload: error,
});

export const adminGetStorePrivateProducts = (reqData, isPrivate) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_START,
  shopId: reqData,
  private: isPrivate
});

export const adminGetStorePrivateProductsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const adminGetStorePrivateProductsFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE,
  payload: error,
});

export const adminGetStorePrivateCategories = (reqData, isPrivate) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_START,
  shopId: reqData,
  private: isPrivate
});

export const adminGetStorePrivateCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const adminGetStorePrivateCategoriesFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE,
  payload: error,
});

export const adminGetCommonProductCategories = (reqData) => ({
  type: ActionTypes.ADMIN_GET_COMMON_CATEGORIES_START,
  payload: reqData,
});

export const adminGetCommonProductCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonProductCategoriesFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_CATEGORIES_FAILURE,
  payload: error,
});

export const adminUpdateStatusPrivateProduct = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START,
  payload: reqData,
});

export const adminUpdateStatusPrivateProductSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const adminUpdateStatusPrivateProductFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE,
  payload: error,
});

export const adminSearchProducts = (reqData, category, upc, isUPCBased) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_START,
  payload: reqData,
  category: category,
  upc: upc,
  isUPCBased: isUPCBased
});

export const adminSearchProductsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const adminSearchProductsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_FAILURE,
  payload: error,
});

export const adminSearchProductKeywords = (reqData) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_START,
  payload: reqData,
});

export const adminSearchProductKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const adminSearchProductKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE,
  payload: error,
});

export const adminAddCommonProduct = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_START,
  payload: reqData,
});

export const adminAddCommonProductSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonProductFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_FAILURE,
  payload: error,
});

export const adminUpdateCommonProduct = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_START,
  payload: reqData,
});

export const adminUpdateCommonProductSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const adminUpdateCommonProductFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_FAILURE,
  payload: error,
});


export const adminAddCommonProductImage = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const adminAddCommonProductImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonProductImageFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE,
  payload: error,
});

export const adminRemoveCommonProductImage = (reqData, image_id) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START,
  payload: {prod_id: reqData, image_id: image_id},
});

export const adminRemoveCommonProductImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminRemoveCommonProductImageFailure = (error) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE,
  payload: error,
});

export const adminAddCommonCategory = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_START,
  payload: reqData,
});

export const adminAddCommonCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonCategoryFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_FAILURE,
  payload: error,
});

export const adminUpdateCommonCategory = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START,
  payload: reqData,
});

export const adminUpdateCommonCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const adminUpdateCommonCategoryFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_FAILURE,
  payload: error,
});

export const adminAddCommonCategoryImage = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const adminAddCommonCategoryImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonCategoryImageFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE,
  payload: error,
});

export const adminRemoveCommonCategoryImage = (reqData, image_id) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START,
  payload: {categ_id: reqData, image_id: image_id},
});

export const adminRemoveCommonCategoryImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminRemoveCommonCategoryImageFailure = (error) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE,
  payload: error,
});
