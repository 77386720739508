import { AppBaseRoutes, UserAppHomeRoutes, SellerAppHomeRoutes, AdminAppHomeRoutes } from "../routes-common/core-routes";
import { SellerSiteRoutes }  from "../routes-common/user-routes";
import { MainSiteRoutes }     from "../routes-common/main-routes";
import { SellerAppRoutes }   from "../routes-common/seller-routes";
import { AdminAppRoutes }   from "../routes-common/admin-routes";

// ifdef REACT_APP_TYPE = SELLERAPP
export const SellerMgmtRoutes = [
  ...AppBaseRoutes,
  ...SellerAppRoutes,
  ...SellerAppHomeRoutes
];
// endif

// ifdef REACT_APP_TYPE = ADMINAPP
export const AdminMgmtRoutes = [
  ...AppBaseRoutes,
  ...AdminAppRoutes,
  ...AdminAppHomeRoutes
];
// endif

// ifdef REACT_APP_TYPE = USERSITE
export const SellerWebsiteRoutes = [
  ...AppBaseRoutes,
  ...SellerSiteRoutes,
  ...UserAppHomeRoutes,
];
// endif

// ifdef REACT_APP_TYPE = MAINSITE
export const UserWebsiteRoutes = [
  ...AppBaseRoutes,
  ...MainSiteRoutes,
  ...UserAppHomeRoutes,
];
// endif
