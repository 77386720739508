/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
// Shop site
import ShopMain from "../components/pages/user/home/ShopMain";
import ShopSearch from "../components/pages/user/home/ShopSearch";
// Products
import ShopCategoryProduct from "../components/pages/user/products/ShopCategoryProduct";
import ShopProduct from "../components/pages/user/products/shopProduct";
import ProductDetail from "../components/pages/user/products/ProductDetail";
// Orders
import shopOrderList from "../components/pages/user/orders/shoporderlist";
import ShopOrderDetail from "../components/pages/user/orders/shoporderdetail";
// Favourites
import shopWishList from "../components/pages/user/favourites/shopwishlist";
// Checkout
import shopCart from "../components/pages/user/checkout/shopCart";
import shopCheckOut from "../components/pages/user/checkout/shopCheckout";
import shopPaymentCheckout from "../components/pages/user/checkout/shopCheckout/payment-page";
import shopOrderSuccess from "../components/pages/user/checkout/shopCheckout/success-page";

export const SellerSiteRoutes = [
  // User shop pages on seller site
	// Commmon with shop/seller site on main site
  { path: "/", component: ShopMain, exact: true },
  { path: "/search/:query", component: ShopSearch, exact: true },
  { path: "/search", component: ShopSearch, exact: true },
	// Products - common
  { path: "/product/category/:category", component: ShopCategoryProduct, exact: true },
  { path: "/product", component: ShopProduct, exact: true },
  { path: "/product/:id", component: ProductDetail, exact: true },
	// Orders - common
  { path: "/orders", component: shopOrderList, exact: true },
  { path: "/order/:orderId", component: ShopOrderDetail, exact: true },
	// Favourites - products in shop
  { path: "/wishlist", component: shopWishList, exact: true },
	// Checkout - common
  { path: "/cart", component: shopCart, exact: true },
  { path: "/cart/checkout", component: shopCheckOut },
  { path: "/cart/payment-page", component: shopPaymentCheckout },
  { path: "/cart/order-success", component: shopOrderSuccess },
];
