// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as Actions     from "actions/admin/admin.subscription";
import * as ActionTypes from 'constants/ActionTypes';

function* adminGetAllSubscriptions(action: Object) {
  try {
    const response = yield API.get(
      `/admin/subscription/subscription-plans`,
      action.payload
    );
    yield put(
      Actions.adminGetAllSubscriptionsSuccess(response)
    );
  } catch (error) {
    yield put(Actions.adminGetAllSubscriptionsFailure(error));
  }
}

function* adminAddSubscriptionPlan(action: Object) {
  try {
    const response = yield API.post(
        `/admin/subscription/admin-subscription-plan`,
        action.payload
    );

    yield put(Actions.adminAddSubscriptionPlanSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddSubscriptionPlanFailure(error));
  }
}

function* adminUpdateStatusSubscription(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/subscription/subscription-plans`,
        action.payload
    );

    yield put(Actions.adminUpdateStatusSubscriptionSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateStatusSubscriptionFailure(error));
  }
}

export default function* SubscriptionsSaga(): Generator<*, *, *> {
  yield takeLatest(
    ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_START,
      adminGetAllSubscriptions
  );
  yield takeLatest(
    ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_START,
      adminAddSubscriptionPlan
  );
  yield takeLatest(
    ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START,
      adminUpdateStatusSubscription
  );
}
