import * as React from "react";
import { Layout } from "components/Atoms";
import "./style.scss";

const PageContent = (props) => {
  return (
    <Layout.Content className={`page-content ${props.className || ""}`}>
      {props.children}
    </Layout.Content>
  );
};

export default PageContent;
