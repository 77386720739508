import * as React from "react";
import { Form, Input, Modal, Checkbox } from "components/Atoms";

const ProductEntryForm = ({ data, visible, onOk, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (data) => {
    form.validateFields().then((values) => {
      onOk(values, id, store_entry);
    });
  };

  const { name, id, store_entry } = data || {};
  const { price, is_popular, is_price_visible, is_active } = store_entry || {};
  return (
    <Modal
      className="dialog-product-entry-form"
      title="Enter Product Details"
      visible={visible}
      onOk={onFinish}
      destroyOnClose={true}
      afterClose={() => form.resetFields()}
      onCancel={onCancel}
      type="full-page"
    >
      <Form
        name="product-entry-form"
        form={form}
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          rules={[{ required: true, message: "Please product name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
            name="is_popular"
            initialValue={is_popular}
            valuePropName="checked">
          <Checkbox>Is popular?</Checkbox>
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          initialValue={price}
          rules={[{ required: true, message: "Please add price" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
            name="is_price_visible"
            initialValue={is_price_visible}
            valuePropName="checked">
          <Checkbox>Price is visible to user?</Checkbox>
        </Form.Item>

        <Form.Item
            name="is_active"
            initialValue={is_active}
            valuePropName="checked">
          <Checkbox>Enabled</Checkbox>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default ProductEntryForm;
