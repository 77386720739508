// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminGetCommonOrders = (reqData, category) => ({
  type: ActionTypes.ADMIN_GET_COMMON_ORDERS_START,
  payload: reqData,
  category: category
});

export const adminGetCommonOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_ORDERS_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_ORDERS_FAILURE,
  payload: error,
});

export const adminGetStoreOrders = (reqData, orderstatus) => ({
  type: ActionTypes.ADMIN_GET_STORE_ORDERS_START,
  shopId: reqData,
  orderstatus: orderstatus,
});

export const adminGetStoreOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_STORE_ORDERS_SUCCESS,
    payload: data,
  };
};
export const adminGetStoreOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_STORE_ORDERS_FAILURE,
  payload: error,
});

export const adminSearchOrders = (reqData, searchType) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_START,
  payload: reqData,
  searchType: searchType,
});

export const adminSearchOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_ORDERS_SUCCESS,
    payload: data,
  };
};

export const adminSearchOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_FAILURE,
  payload: error,
});

export const adminSearchOrderKeywords = (reqData) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_START,
  payload: reqData,
});

export const adminSearchOrderKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const adminSearchOrderKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE,
  payload: error,
});

