// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  availablePlans: [],
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_START:
    case ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_START:
    case ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_FAILURE:
    case ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          availablePlans: action.payload.response.plans,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          availablePlans: [],
        };
      }
    }

    case ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    default:
      return state;
  }
}
