/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Tab, Tabs, TabList, TabPanel,
} from "react-tabs";
import { Container, Typography, Box } from "@mui/material";
import ShopProductItem from "components/elements/user/ShopProductItem";
import * as Actions from "actions";

const ShopSpecialProducts = (props) => {
  const {
    newproducts, recentlySold, symbol, addProductToCart,
    addToWishlist, addToCompare, bootupInfo, usergetPopularProducts, usergetCommonProducts,
  } = props;
  useEffect(() => {
    // usergetPopularProducts(bootupInfo.appState.subdomain_shopId,
    // true, bootupInfo.appState.isWebsite);
    usergetCommonProducts(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite, "614ad6a1a405d68a1f979226");
  }, []);
  return (
    <div>
      <Box display="flex" justifyContent="center">
        <Typography gutterBottom className="highlight-tab" variant="h6">special products</Typography>
      </Box>
      <br />
      <Container>
        <Tabs className="theme-tab">
          <TabList className="tabs tab-title">
            <Tab className="tab-cursor">New</Tab>
            <Tab className="tab-cursor">Recently Sold</Tab>
          </TabList>

          <TabPanel>
            <Box display="flex" flexWrap="wrap">
              { newproducts.map((product, index) => (
                <div className="product-card-container" key={`${index + 1}`}>
                  <ShopProductItem
                    product={product}
                    symbol={symbol}
                    onAddToCompareClicked={() => addToCompare(product)}
                    onAddToWishlistClicked={() => addToWishlist(product)}
                    onAddToCartClicked={() => addProductToCart(product,
                      bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite)}
                    key={`${index + 1}`}
                  />
                </div>
              ))}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box display="flex" flexWrap="wrap">
              { recentlySold.map((product, index) => (
                <div className="product-card-container" key={`${index + 1}`}>
                  <ShopProductItem
                    product={product}
                    symbol={symbol}
                    onAddToCompareClicked={() => addToCompare(product)}
                    onAddToWishlistClicked={() => addToWishlist(product)}
                    onAddToCartClicked={() => addProductToCart(product, 1)}
                    key={`${index + 1}`}
                  />
                </div>
              ))}
            </Box>
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

ShopSpecialProducts.defaultProps = {
  symbol: "$",
};

ShopSpecialProducts.propTypes = {
  symbol: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  addToWishlist: PropTypes.func.isRequired,
  usergetPopularProducts: PropTypes.func.isRequired,
  usergetCommonProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  newproducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  recentlySold: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Mapping Props and State.
const ShopSpecialProductsComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    newproducts: userproducts.categoryproducts,
    recentlySold: userproducts.popularproducts,
    symbol: userproducts.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopSpecialProducts);

export { ShopSpecialProductsComponent as default };
