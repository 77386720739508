// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  activePlans: [],
  availablePlans: [],
  subscriptionCheckout: {
    cart: [],
    total_original_price: "",
    total_after_discount: "",
    total_discount: "",
    total_shipping: "",
    promo_discount: "",
  },
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.GET_USER_SUBSCRIPTIONS_START:
    case ActionTypes.GET_ALL_SUBSCRIPTIONS_START:
    case ActionTypes.ADD_SUBSCRIPTION_TO_CART_START:
    case ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_START:
    case ActionTypes.CHECKOUT_SUBSCRIPTION_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.GET_USER_SUBSCRIPTIONS_FAILURE:
    case ActionTypes.GET_ALL_SUBSCRIPTIONS_FAILURE:
    case ActionTypes.ADD_SUBSCRIPTION_TO_CART_FAILURE:
    case ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_FAILURE:
    case ActionTypes.CHECKOUT_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.GET_ALL_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          availablePlans: action.payload.response.plans,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          availablePlans: [],
        };
      }
    }

    case ActionTypes.GET_USER_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          activePlans: action.payload.response.activeplans,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          activePlans: [],
        };
      }
    }

    case ActionTypes.ADD_SUBSCRIPTION_TO_CART_SUCCESS: {
      const planToAdd = action.payload;
      const plans = [...state.availablePlans];
      const planIndex = plans.findIndex((plan) => plan._id === planToAdd._id);
      if (planIndex !== -1) plans[planIndex].addedToCart = true;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        availablePlans: plans,
      };
    }

    case ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS: {
      const planToAdd = action.payload;
      const plans = [...state.availablePlans];
      const planIndex = plans.findIndex((plan) => plan._id === planToAdd._id);

      if (planIndex !== -1) plans[planIndex].addedToCart = false;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        availablePlans: plans,
      };
    }

    case ActionTypes.CHECKOUT_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        // subscriptionCheckout: action.payload,
      };
    }

    default:
      return state;
  }
}
