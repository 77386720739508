//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
  Spin,
  Divider,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.store";

import "./styles.scss";
import {
  Avatar, Box, Card, Container, IconButton, Typography,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ViewListIcon from "@mui/icons-material/ViewList";
import LanguageIcon from '@mui/icons-material/Language';

// Component Imports

const StoreProfileHome = (props) => {
  const {
    getStoreProfile, serviceError, reqStatus, storeProfile, history,
  } = props;

  React.useEffect(() => {
    getStoreProfile();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  React.useEffect(() => {
    switch (reqStatus) {
      default:
        if (serviceError) {
          const feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          // sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);

  const handlePage = (page) => () => {
    history.push({
      pathname: page,
    });
  };

  const { logo } = storeProfile;

  return (

    <Container>
      <Box m={2} display="flex" className="position-relative">
        <Box>
          <Avatar onClick={handlePage("/shop/logo")} src={logo && logo.length > 0 && logo[0].cdn.url} className="profile-img" />
          <div className="camera-icon">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handlePage("/shop/logo")}
              size="large"
            >
              <PhotoCamera />
            </IconButton>
          </div>
        </Box>
        <Box className="detail-text">
          <Typography variant="h5">{storeProfile.title}</Typography>
          <Typography className="styled-link">
            {storeProfile.email}
          </Typography>
        </Box>
      </Box>
      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handlePage("/shop/profile")}>
                <IconButton size="large">
                  <PersonRoundedIcon />
                </IconButton>
                Profile
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handlePage("/shop/photos")}>
                <IconButton size="large">
                  <PersonRoundedIcon />
                </IconButton>
                Photos
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handlePage("/shop/address")}>
                <IconButton size="large">
                  <LocationOnRoundedIcon />
                </IconButton>
                Address
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handlePage("/shop/domain")}>
                <IconButton size="large">
                  <LanguageIcon />
                </IconButton>
                Website/Domain
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handlePage("/shop/service")}>
                <IconButton size="large">
                  <ViewListIcon />
                </IconButton>
                Service Charge
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <br />
      <br />
      <br />
    </Container>

  );
};
// Mapping Props and State.
const _StoreProfileHome = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
    storeDomain: store.storeDomain,
    storeAddress: store.storeAddress,
    storeImages: store.storeImages,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileHome);

export { _StoreProfileHome as default };
