import React from 'react';
import { Icon } from 'components/Atoms';
import './style.scss';

class Roller extends React.PureComponent {
  scrollLeft;
  scrollRight;
  scrollToLeftEnd;
  scrollToRightEnd;
  stopScrolling;
  updateScrollers;
  scrollTimer;
  state = {};

  constructor(props) {
    super(props);
    this.rollerContentWrap = React.createRef();
    this.rollerControlLeft = React.createRef();
    this.rollerControlRight = React.createRef();
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.scrollToRightEnd = this.scrollToRightEnd.bind(this);
    this.scrollToLeftEnd = this.scrollToLeftEnd.bind(this);
    this.stopScrolling = this.stopScrolling.bind(this);
    this.updateScrollers = this.updateScrollers.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateScrollers);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScrollers);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateScrollers();
  }

  updateScrollers() {
    const rollerContentWrap = this.rollerContentWrap.current;
    if (rollerContentWrap === null) {
      return;
    }
    const contentWidth = rollerContentWrap.scrollWidth;
    const rollerWidth = rollerContentWrap.offsetWidth;

    this.rollerControlRight.current.style.display = 'none';
    this.rollerControlLeft.current.style.display = 'none';
    if (contentWidth > rollerWidth) {
      this.rollerControlRight.current.style.display = 'flex';
    }
  }
  scrollToLeftEnd() {
    const rollerContentWrap = this.rollerContentWrap.current;
    const contentWidth = rollerContentWrap.scrollWidth;
    const rollerWidth = rollerContentWrap.offsetWidth;
    rollerContentWrap.scrollLeft = contentWidth - rollerWidth;
  }
  scrollToRightEnd() {
    const rollerContentWrap = this.rollerContentWrap.current;
    rollerContentWrap.scrollLeft = 0;
  }

  scrollLeft() {
    const rollerContentWrap = this.rollerContentWrap.current;
    const contentWidth = rollerContentWrap.scrollWidth;
    const rollerWidth = rollerContentWrap.offsetWidth;

    window.clearInterval(this.scrollTimer);
    // start scrolling
    this.scrollTimer = window.setInterval(() => {
      const leftScrolledOffset = rollerContentWrap.scrollLeft;
      if (Math.abs(leftScrolledOffset + rollerWidth - contentWidth) <= 3) {
        this.stopScrolling(null, 'left');
        return;
      }
      rollerContentWrap.scrollLeft = leftScrolledOffset + 3;
    }, 10);
  }

  scrollRight() {
    const rollerContentWrap = this.rollerContentWrap.current;

    window.clearInterval(this.scrollTimer);
    // start scrolling
    this.scrollTimer = window.setInterval(() => {
      const leftScrolledOffset = rollerContentWrap.scrollLeft;
      if (leftScrolledOffset <= 0) {
        this.stopScrolling(null, 'right');
        return;
      }
      rollerContentWrap.scrollLeft = leftScrolledOffset - 3;
    }, 10);
  }

  stopScrolling(event, direction) {
    if (event === null) {
      if (direction === 'left') {
        this.rollerControlRight.current.style.display = 'none';
        this.rollerControlLeft.current.style.display = 'flex';
      } else {
        this.rollerControlRight.current.style.display = 'flex';
        this.rollerControlLeft.current.style.display = 'none';
      }
    }

    window.clearInterval(this.scrollTimer);
  }

  render() {
    const { children, className } = this.props;
    return (
      <div className={`roller ${className || ''}`} data-test="roller">
        <div
          data-test="roller_control--left"
          className="roller_control roller_control--left"
          ref={this.rollerControlLeft}
          onMouseOver={this.scrollRight}
          onClick={this.scrollToRightEnd}
          onMouseLeave={this.stopScrolling}>
          <Icon type="left" />
        </div>
        <div
          data-test="roller_control--right"
          className="roller_control roller_control--right"
          ref={this.rollerControlRight}
          onMouseOver={this.scrollLeft}
          onClick={this.scrollToLeftEnd}
          onMouseLeave={this.stopScrolling}>
          <Icon type="right" />
        </div>
        <div className="roller_content" ref={this.rollerContentWrap} data-test="roller_content">
          {children}
        </div>
      </div>
    );
  }
}

export default Roller;
