import React from 'react'
import { Image } from "components/Atoms";
import {IconButton, Typography} from "@mui/material";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';

export default props =>
    props.images.map((image, i) =>
        <div key={i}>
            <Typography align="right">
                <IconButton size="large" color="primary" onClick={() => props.removeImage(image.cdn.public_id)}>
                    <ClearSharpIcon />
                </IconButton>
            </Typography>
            <Image
                preview={{ visible: false }}
                width={200}
                src={image.cdn.secure_url}
                alt=''
                onError={() => props.onError(image.cdn.public_id)}
            />
        </div>
    )
