// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const getUserSubscriptions = (req) => ({
  type: ActionTypes.GET_USER_SUBSCRIPTIONS_START,
  payload: req,
});

export const getUserSubscriptionsSuccess = (data) => {
  return {
    type: ActionTypes.GET_USER_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const getUserSubscriptionsFailure = (error) => ({
  type: ActionTypes.GET_USER_SUBSCRIPTIONS_FAILURE,
  payload: error,
});

export const getAllSubscriptions = (req) => ({
  type: ActionTypes.GET_ALL_SUBSCRIPTIONS_START,
  payload: req,
});

export const getAllSubscriptionsSuccess = (data) => {
  return {
    type: ActionTypes.GET_ALL_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const getAllSubscriptionsFailure = (error) => ({
  type: ActionTypes.GET_ALL_SUBSCRIPTIONS_FAILURE,
  payload: error,
});

export const addSubscriptionToCart = (reqData) => ({
  type: ActionTypes.ADD_SUBSCRIPTION_TO_CART_START,
  payload: reqData,
});

export const addSubscriptionToCartSuccess = (data) => {
  return {
    type: ActionTypes.ADD_SUBSCRIPTION_TO_CART_SUCCESS,
    payload: data,
  };
};

export const addSubscriptionToCartFailure = (error) => ({
  type: ActionTypes.ADD_SUBSCRIPTION_TO_CART_FAILURE,
  payload: error,
});

export const removeSubscriptionFromCart = (reqData) => ({
  type: ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_START,
  payload: reqData,
});

export const removeSubscriptionFromCartSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS,
    payload: data,
  };
};

export const removeSubscriptionFromCartFailure = (error) => ({
  type: ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_FAILURE,
  payload: error,
});

export const checkoutSubscriptionPlan = (reqData) => ({
  type: ActionTypes.CHECKOUT_SUBSCRIPTION_START,
  payload: reqData,
});

export const checkoutSubscriptionPlanSuccess = (data) => {
  return {
    type: ActionTypes.CHECKOUT_SUBSCRIPTION_SUCCESS,
    payload: data,
  };
};

export const checkoutSubscriptionPlanFailure = (error) => ({
  type: ActionTypes.CHECKOUT_SUBSCRIPTION_FAILURE,
  payload: error,
});
