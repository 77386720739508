/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// @flow
import * as React from "react";
import { Route, Redirect } from "react-router-dom";

const isAuthenticated = (store, props, excluded) => {
// Use the required properties  to validate the routing
  const currentPath = props.match.path;

  if (excluded.indexOf(currentPath) > -1) {
    if (localStorage.getItem("isAuthorized") === "true") {
      return true;
    }
    return false;
  }
  return true;
};

const AuthenticatedRoute = ({ component: Component, store, excluded, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated(store, props, excluded) ? (
      <Component {...props} />
    ) : (
      <Redirect to="/login" />
    ))}
  />
);

export default AuthenticatedRoute;
