// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const getUserLocation = (req) => ({
  type: ActionTypes.GET_USER_LOCATION_START,
  payload: req,
});

export const getUserLocationSuccess = (data) => {
  return {
    type: ActionTypes.GET_USER_LOCATION_SUCCESS,
    payload: data,
  };
};
export const getUserLocationFailure = (error) => ({
  type: ActionTypes.GET_USER_LOCATION_FAILURE,
  payload: error,
});

export const getPlaceSuggestions = (req) => ({
  type: ActionTypes.GET_PLACE_SUGGESTIONS_START,
  payload: req,
});

export const getPlaceSuggestionsSuccess = (data) => {
  return {
    type: ActionTypes.GET_PLACE_SUGGESTIONS_SUCCESS,
    payload: data,
  };
};
export const getPlaceSuggestionsFailure = (error) => ({
  type: ActionTypes.GET_PLACE_SUGGESTIONS_FAILURE,
  payload: error,
});

export const getStoresForLocation = (req) => ({
  type: ActionTypes.GET_STORES_FOR_LOCATION_START,
  payload: req,
});

export const getStoresForLocationSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORES_FOR_LOCATION_SUCCESS,
    payload: data,
  };
};
export const getStoresForLocationFailure = (error) => ({
  type: ActionTypes.GET_STORES_FOR_LOCATION_FAILURE,
  payload: error,
});

export const addShopToAccount = (req) => ({
  type: ActionTypes.ADD_SHOP_TO_ACCOUNT_START,
  payload: req,
});

export const addShopToAccountSuccess = (data) => {
  return {
    type: ActionTypes.ADD_SHOP_TO_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const addShopToAccountFailure = (error) => ({
  type: ActionTypes.ADD_SHOP_TO_ACCOUNT_FAILURE,
  payload: error,
});
