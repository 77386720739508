// @flow
import * as React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

type Props = {};

type State = {};

class PageNotFound extends React.PureComponent<Props, State> {
  componentWillMount() {}

  render() {
    return (
      <div className="page-not-found" data-test="page-not-found">
        <p className="title">This page could not be found </p>
        <Link className="link-home text-large" to="/">
          Back to the home page
        </Link>
      </div>
    );
  }
}

export { PageNotFound as default };
