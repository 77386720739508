import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Resizer from "react-image-file-resizer";

import * as Actions     from "actions/seller/seller.store";

import {
  Form,
  Page,
  Row,
  Col,
  Button,
  Input,
  Notification,
  Roller,
  AntdModal,
} from "components/Atoms";

import Images   from "components/elements/seller/Images";
import Buttons  from "components/elements/seller/Buttons";
// Component Imports

type Props = {};

type State = {};


const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
          file,
          1200,
          1200,
          "JPEG",
          80,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
      );
    });

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
      splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

class ShopImageEditor extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      appBase,
      getStoresByLocation,
      serviceError,
      reqStatus,
    } = this.props;

    if (serviceError && reqStatus !== prevProps.reqStatus) {
      let feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      this.sendNotification("error", feedback);
    }
    this.props.storeImages.images = this.props.storeImages.images;

 //       this.setState({ images: this.props.productImageList })
  }

  sendNotification(type, { title, description = "" }) {
    Notification[type]({
      message: title,
      description: description,
    });
  }

  onChange = e => {
    const errs = []
    const files = Array.from(e.target.files)

    if (files.length > 3) {
      const msg = 'Only 3 images can be uploaded at a time'
      return this.sendNotification("error", {title: msg, description: msg});
    }

    const formData = new FormData()
    const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp']

    files.forEach(async (file, i) => {

      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`)
      }

      if (file.size > 15000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`)
      }

      if (errs.length) {
        return errs.forEach(err => this.sendNotification("error", {title: err, description: err}))
      }

      //formData.append(i, file)
      const image = await resizeFile(file);
      const newFile = dataURIToBlob(image);
      formData.append("image", newFile);
      //this.setState({ uploading: true })
      this.props.addStoreImage(formData);
    })


  }

  filter = id => {
    return this.images.filter(image => image.cdn.public_id !== id)
  }

  removeImage = id => {
    //this.setState({ images: this.filter(id) });
    this.props.storeImages.images = this.props.storeImages.images.filter(image => image.cdn.public_id !== id);
    this.props.removeStoreImage(id);
  }

  onError = id => {
    this.setState({ images: this.filter(id) })
  }

  render() {

    const {isLoading, storeImages, cancelEdit} = this.props;
    const {images} = storeImages;

    const content = () => {
      switch(true) {
        case images.length > 0:
          return <div>
            <Buttons onChange={this.onChange} />
          <Images
              images={images}
              removeImage={this.removeImage}
              onError={this.onError}
          />
              </div>
        default:
          return <div><Buttons onChange={this.onChange} /></div>
      }
    }

    return (
        <Page className="products-catalog">
          <h2 className="page-title">Images</h2>
          <div className="content">
            <div className="product-list">
              <div className='container'>
                <div className='buttons'>
                  {content()}
                </div>
              </div>
              <Button
                  type="secondary"
                  htmlType="button"
                  style={{width: "50%"}}
                  onClick={cancelEdit}
              >
                Done
              </Button>
            </div>
          </div>
        </Page>
    );



  }
};

// Mapping Props and State.
const _ShopImageEditor = connect(
    ({ store, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: store.reqStatus,
      isLoading: store.isLoading,
      serviceError: store.serviceError,
      storeImages: store.storeImages,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ShopImageEditor);

export { _ShopImageEditor as default };
