// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import API from "api";

import * as ActionTypes     from 'constants/ActionTypes';
import { getUserProfile, updateUserUUID, getShopAndUserProfile, getShopUserProfile, getShopProfile } from "actions/core/core.actions";
import { AppContext } from "app-context";


import {Grid}           from "components/Atoms";
import {
  PageHeader,
  PageContent,
} from "components/elements/main";

import BottomNavSeller          from "components/elements/main/BottomNavSeller";
import BottomNavUser            from "components/elements/main/BottomNavUser";
import BottomNavMain            from "components/elements/main/BottomNavMain";
import FooterOne            from "components/elements/main/footers/footer-one";


import {changeCurrency, getCartProducts, usergetShopInfoById} from "../../actions";

type Props = {
  children: React.Node,
  location: Location,
  history: Object,
};

type State = {
  bootUpCompleted: boolean,
  collapsed: boolean,
  pageHeader: {
    title: string | React.Node,
    breadCrumb: string | React.Node,
  },
};
const UNAUTHORIZED_ROUTES = [
  "/login",
  "/password/reset",
  "/password/create",
  "/unsubscribe",
];

const SHOP_ROUTES = [
  "/test"
];
class Bootstrap extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bootUpCompleted: true,
      collapsed: false,
      isLoading: true,
      subdomainshopId: "",
      pageHeader: {
        title: "",
        breadCrumb: "",
      },
    };
    this.attachInterceptor();

    const cordova = window.cordova;

    if (process.env.REACT_APP_TYPE === "SELLERAPP") {
      // Seller mgmt site : e.g. biz.shopana.com
      if (localStorage.getItem("isAuthorized") === "true") {
        // user and associated shop information
        props.getShopUserProfile();
        if (cordova) {
          props.updateUserUUID();
        }
      } else {
        this.props.history.push("/login");
      }
    } else if (process.env.REACT_APP_TYPE === "USERSITE") {
      // Seller web site : e.g. xyzseller.shopana.com
      let subdomainKey = window.location.host.split('.').length == 1 ? process.env.DEFAULT_BRAND : window.location.host.split('.')[0];
      this.props.bootupInfo.appState.subdomain_shopId = subdomainKey;
      this.state.routeType = "SELLERSITE";
      this.state.subdomainshopId = this.props.bootupInfo.appState.subdomain_shopId;
      if (localStorage.getItem("isAuthorized") === "true") {
        // user information and shop information
        props.getShopAndUserProfile(this.props.bootupInfo.appState.subdomain_shopId, true);
        if (cordova) {
          props.updateUserUUID();
        }
        props.getCartProducts(this.props.bootupInfo.appState.subdomain_shopId, true);
      } else {
        props.getShopProfile(this.props.bootupInfo.appState.subdomain_shopId, true);
      }
    } else if (process.env.REACT_APP_TYPE === "ADMINAPP") {
      // Admin mgmt site : e.g. admin.shopana.com
      if (localStorage.getItem("isAuthorized") === "true") {
        // user and associated shop information
        props.getUserProfile();
        if (cordova) {
          props.updateUserUUID();
        }
      } else {
        this.props.history.push("/login");
      }
    } else{
      // Main web site : shopana.com or www.shopana.com
      if (localStorage.getItem("isAuthorized") === "true") {
        // user information
        props.getUserProfile();
      }
    }
  }

  attachInterceptor() {
    API.interceptors.request.use((request) => {
      const { bootupInfo } = this.props;
      const isLoginRequest = (request.url.indexOf("/login") !== -1) ||
                              request.url.indexOf("/postotp_login") !== -1 ||
                              (request.url.indexOf("/login/success") !== -1);

      console.log("React website: " + process.env.REACT_APP_TYPE);

      if (localStorage.getItem("isAuthorized") === "true") {
        bootupInfo.bootupCompleted = true;
      }

      if (request.withCredentials) {
        if (localStorage.getItem("isAuthorized") === "true") {
          request.headers.Authorization = localStorage.getItem("token");
        } else {
          this.props.history.push("/login");
        }
      } else if (!isLoginRequest && bootupInfo.bootupCompleted) {
        request.headers.Authorization = localStorage.getItem("token");
      }

      //request.headers.Authorization = "Bearer Token: eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfaWQiOiI1ZmQ2ZGJkYzM1NDJiZjI4YzYyZGE1OTUiLCJuYW1lIjoiU2hvcGFuYSBUZXN0IFNlbGxlciJ9LCJpYXQiOjE2MjUwMzQxNzcyNjAsImV4cCI6MTYyNTAzNDI2MzY2MH0.oCDk8rMdUUgFVrQOgZhscDnr78KBRoZtNsSfw4Nx8lngax8avjx7VWktXAi7QtxHHctURnd3Qd61v0-zZA5OXwK-NvpkpLUxr4UuSU4uPVIC1BlHnV21sCqmZj4mdJDE7FCdjoSA9le5nc3duXlXkSqwsg-fmMIuqKMs3GQVkWw-qxHjuUnBjqZh-ZHPsZf6I0bwWkT-0oCnO0banYVFFZENlG0Oo7H9UHHLcY0ZNRVRT5tGw3n7qbtmjG-GVQomX1s2NWQg3C2EmEr_PFrWB2ATPSq1k6BRa5G4FrfMAesjZ_vvHJ6S3VGrcvpMaSbbNF_uCNVGJ78nF_WhdjvcDzO6YE2WkUTFzq0iC7eXvacA1ThJOugzxKzdGnNjLRQZk-f1h3-vUtOfMj7tkd002QEVROOWErDHcWYSdu1qfFcsOdwqdM4pgYup8XeNjGvCUD4vhE3htEyFvic4IV3wAK7pxBbutE85SKk_mHKqqBl_V3YpGt61QBkaBFOp3TlsjJKpCC2EQwf-oT7Iel4-wm2R8bYoBOedQVtSgb3KaTZaK8mH35-7HaJ3J9n50LqpENrXvQmGqIqLq_fsrfuQkLbT2ks47WE-DMojxnq8rXRd1JH8UlBwi54uPugJYG45ZmnStXpzI45CIHv7W69niXp7ofBg-lRBbuESNI5zlig";
      return request;
    });
    API.interceptors.response.use(
      (response) => {
        if (
          response.config.url.indexOf("/logout") >= 0 &&
          response.status === 200
        ) {
          this.props.history.push("/login");
        }
        // if website and no shop info then redirect to unknown website 404
        if ((process.env.REACT_APP_TYPE === "USERSITE") || (this.state.routeType === "SELLERWEB")) {
          if (response.data.statusCode === 404) {
            // Fix it. Redirect to shopana.com
            this.props.history.push("/404");
          }
        }

        if ((process.env.REACT_APP_TYPE === "SELLERAPP") || (this.state.routeType === "SELLER")) {
          if (response.data.statusCode === 403) {
            this.props.history.push("/register-store");
          }
        }

        if ((process.env.REACT_APP_TYPE === "ADMINAPP") || (this.state.routeType === "ADMIN")) {
          if (response.data.statusCode === 403) {
            this.props.history.push("/login");
          }
        }

        return response.data;
      },
      (error) => {
        const errorStatusCode = error.response && error.response.status;
        switch (errorStatusCode) {
          case 404: {
            this.props.history.push("/404");
            return;
          }
        }
        return Promise.reject(error.response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { bootupInfo, history } = this.props;
    const { serviceError, reqStatus } = bootupInfo;
    const {
      serviceError: prevServiceError,
      reqStatus: prevReqStatus,
    } = prevProps.bootupInfo;

    const pageRoute = this.props.location.pathname;


    if ( process.env.REACT_APP_TYPE === "USERSITE") {
      let subdomainKey = window.location.host.split('.').length == 1 ? process.env.DEFAULT_BRAND : window.location.host.split('.')[0];
      this.props.bootupInfo.appState.subdomain_shopId = subdomainKey;
      this.state.routeType = "SELLERSITE";
      if (prevState.subdomainshopId !== this.props.bootupInfo.appState.subdomain_shopId) {
        this.props.getCartProducts(this.props.bootupInfo.appState.subdomain_shopId, true);
      }
    } else if (process.env.REACT_APP_TYPE === "SELLERAPP") {
      this.state.routeType = "SELLER";
      this.props.bootupInfo.appState.isSeller = true;
    } else if (process.env.REACT_APP_TYPE === "ADMINAPP") {
      this.state.routeType = "ADMIN";
      this.props.bootupInfo.appState.isSeller = true;
    } else {
      if (pageRoute.indexOf("/user/shop") !== -1) {
        this.props.bootupInfo.appState.subdomain_shopId = window.location.hash.split('/')[3];
        this.state.routeType = "SELLERWEB";
        if (prevState.subdomainshopId !== this.props.bootupInfo.appState.subdomain_shopId) {
          this.props.getShopProfile(this.props.bootupInfo.appState.subdomain_shopId, false);
          this.props.getCartProducts(this.props.bootupInfo.appState.subdomain_shopId, false);
          this.state.subdomainshopId = this.props.bootupInfo.appState.subdomain_shopId;
        }
      } else if (pageRoute.indexOf("/user") !== -1) {
        this.state.routeType = "USER";
      } else {
        this.state.routeType = "USER";
      }
    }


    if (reqStatus === prevReqStatus) return;

    const isLoginRoute = (pageRoute.indexOf("/login") !== -1) || (pageRoute.indexOf("/login/success") !== -1);
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
      case ActionTypes.LOGIN_POST_OTP_SUCCESS:
        const cordova = window.cordova;
        console.log("log in success - update UUID");
        if (cordova) {
          this.props.updateUserUUID();
        }
      case ActionTypes.GET_USER_PROFILE_SUCCESS:
      case ActionTypes.SOCIAL_LOGIN_SUCCESS:
        if (isLoginRoute) history.push("/");

        if (this.state.routeType === "SELLER") {
          if (bootupInfo.shop.length < 1) history.push("/register-store");
        }
        break;
      case ActionTypes.GET_USER_PROFILE_FAILURE:
      case ActionTypes.LOGOUT_SUCCESS:
      case ActionTypes.SOCIAL_LOGIN_FAILURE:
        history.push("/login");
        break;

      case ActionTypes.GET_SHOP_USER_PROFILE_SUCCESS:
        // redirected in intercept before render happens
        //history.push("/404");
        break;

      default:
        break;
    }
  }

  setPageHeader = (headerContent) => {
    const state = { pageHeader: { ...headerContent } };
    this.setState(state);
  };

  onSiderCollapse = (collapsed) => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  render() {

    const { logout, bootupInfo, history, isLoading } = this.props;
    const { userDetails = {}, shopinfo, shop, bootupCompleted } = bootupInfo;
    const { pageHeader, collapsed, subdomainshopId }  = this.state;


    // if (isLoading && !bootupCompleted) return <AppSkeleton />;
    const pageRoute = this.props.location.pathname;
    let bottomNav;

    if (process.env.REACT_APP_TYPE === "USERSITE") {
      this.state.routeType = "SELLERSITE";
      this.props.bootupInfo.appState.isWebsite = true;
      bottomNav = <BottomNavUser {...this.props} />
    } else if (process.env.REACT_APP_TYPE === "SELLERAPP") {
      this.state.routeType = "SELLER";
       bottomNav = <BottomNavSeller {...this.props} />
      this.props.bootupInfo.appState.isSeller = true;
    } else if (process.env.REACT_APP_TYPE === "ADMINAPP") {
      this.state.routeType = "ADMIN";
      bottomNav = <BottomNavSeller {...this.props} />
    } else {
      if (pageRoute.indexOf("/user/shop") !== -1) {
        this.state.routeType = "SELLERWEB";
          bottomNav = <BottomNavMain {...this.props} />
      } else if (pageRoute.indexOf("/user") !== -1 || process.env.REACT_APP_TYPE === "MAINSITE") {
        this.state.routeType = "USER";
          bottomNav = <BottomNavMain {...this.props} />
      } else {
        this.state.routeType = "USER";
          bottomNav = <BottomNavUser {...this.props} />
      }
    }

    if (UNAUTHORIZED_ROUTES.indexOf(pageRoute) !== -1) {
      return (
          <Grid container>
            <Grid item lg={2} md={2} >
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <PageContent style={{ zIndex: -1}}>{this.props.children}</PageContent>
            </Grid>
            <Grid item lg={3} md={2} >
            </Grid>
          </Grid>
      );
    }


    // redirect all seller routes to claim url if store is not created yet
    if (SHOP_ROUTES.indexOf(pageRoute) !== -1) {
      return (
          <div>
            <Grid container>
              <Grid item lg={2} md={2} >
              </Grid>
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <PageContent style={{ zIndex: -1}}>{this.props.children}</PageContent>
              </Grid>
              <Grid item lg={3} md={2} >
              </Grid>
            </Grid>
          </div>
      );
    }


    return (
        <AppContext.Provider
            value={{
              pageHeader: pageHeader,
              setPageHeader: this.setPageHeader,
            }}
        >
          <AppContext.Consumer>
            {({ pageHeader, setPageHeader }) => (
                <PageHeader
                    routeType={this.state.routeType}
                    subdomain_shopId={this.state.subdomainshopId}
                    shopinfo={shopinfo}
                    logout={logout}
                    pageRoute={pageRoute}
                    titleContent={pageHeader}
                    userName={this.props.bootupInfo.userDetails.name || "Login"}
                    collapseSider={this.onSiderCollapse}
                    userNotifications={[]}
                />
            )}
          </AppContext.Consumer>

          <Grid container>
            <Grid item lg={2} md={2} >
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <PageContent style={{ zIndex: -1}}>{this.props.children}</PageContent>
            </Grid>
            <Grid item lg={3} md={2} >
            </Grid>
          </Grid>
          <FooterOne height={50} />

            {bottomNav}

        </AppContext.Provider>
    );
  }
}


Bootstrap.contextTypes = {
  router: PropTypes.object,
};

export default withRouter(
  connect(
    (state) => ({ bootupInfo: state.bootupInfo,}),
    (dispatch) => bindActionCreators({ getUserProfile, updateUserUUID, getShopUserProfile, getShopAndUserProfile, getShopProfile, getCartProducts, usergetShopInfoById }, dispatch)
  )(Bootstrap)
);
