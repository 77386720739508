import * as ActionTypes from '../../constants/ActionTypes'
import store from "../../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const usergetCommonProducts = (shopId, isShop, isShopDomain, category) => ({
    type: ActionTypes.USER_GET_COMMON_PRODUCTS_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    category: category,
});

export const usergetCommonProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_COMMON_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const usergetCommonProductsFailure = (error) => ({
    type: ActionTypes.USER_GET_COMMON_PRODUCTS_FAILURE,
    payload: error,
});

export const usergetPopularProducts = (shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_GET_POPULAR_PRODUCTS_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const usergetPopularProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_POPULAR_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const usergetPopularProductsFailure = (error) => ({
    type: ActionTypes.USER_GET_POPULAR_PRODUCTS_FAILURE,
    payload: error,
});

export const usergetProductCategories = (shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_GET_ACTIVE_CATEGORIES_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const usergetProductCategoriesSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_ACTIVE_CATEGORIES_SUCCESS,
        payload: data,
    };
};
export const usergetProductCategoriesFailure = (error) => ({
    type: ActionTypes.USER_GET_ACTIVE_CATEGORIES_FAILURE,
    payload: error,
});

export const usergetProductPopularCategories = (shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const usergetProductPopularCategoriesSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS,
        payload: data,
    };
};
export const usergetProductPopularCategoriesFailure = (error) => ({
    type: ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE,
    payload: error,
});

export const usersearchProducts = (keyword, shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_SEARCH_PRODUCTS_START,
    keyword: keyword,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const usersearchProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_SEARCH_PRODUCTS_SUCCESS,
        payload: data,
    };
};

export const usersearchProductsFailure = (error) => ({
    type: ActionTypes.USER_SEARCH_PRODUCTS_FAILURE,
    payload: error,
});

export const userResetSearchProducts = () => ({
    type: ActionTypes.USER_SEARCH_PRODUCTS_RESET
});

export const usersearchKeywords = (keyword, shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_SEARCH_KEYWORDS_START,
    keyword: keyword,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const usersearchKeywordsSuccess = (data) => {
    return {
        type: ActionTypes.USER_SEARCH_KEYWORDS_SUCCESS,
        payload: data,
    };
};

export const usersearchKeywordsFailure = (error) => ({
    type: ActionTypes.USER_SEARCH_KEYWORDS_FAILURE,
    payload: error,
});

export const userResetsearchKeywords = () => ({
    type: ActionTypes.USER_SEARCH_KEYWORDS_RESET
});


export const usergetProductPrices = (reqData, isProd) => ({
    type: ActionTypes.USER_GET_PRODUCT_PRICES_UPC_START,
    isProd: isProd,
    payload: reqData,
});

export const usergetProductPricesSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_PRODUCT_PRICES_UPC_SUCCESS,
        payload: data,
    };
};
export const usergetProductPricesFailure = (error) => ({
    type: ActionTypes.USER_GET_PRODUCT_PRICES_UPC_FAILURE,
    payload: error,
});

export const getCartProducts = (reqData, isShopDomain) => ({
    type: ActionTypes.USER_GET_CART_PRODUCTS_START,
    payload: reqData,
    isShopDomain: isShopDomain
});

export const getCartProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_CART_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const getCartProductsFailure = (error) => ({
    type: ActionTypes.USER_GET_CART_PRODUCTS_FAILURE,
    payload: error,
});

export const addProductToCart = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_ADD_PRODUCT_TO_CART_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain
});

export const addProductToCartSuccess = (data, productId) => {
    toast.success("Item Added to Cart", {autoClose: 2000, hideProgressBar: true});
    return {
        type: ActionTypes.USER_ADD_PRODUCT_TO_CART_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const addProductToCartFailure = (error) => ({
    type: ActionTypes.USER_ADD_PRODUCT_TO_CART_FAILURE,
    payload: error,
});

export const removeProductFromCart = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain
});

export const removeProductFromCartSuccess = (data, productId) => {
    return {
        type: ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const removeProductFromCartFailure = (error) => ({
    type: ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_FAILURE,
    payload: error,
});


export const incrementProductInCart = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain
});

export const incrementProductInCartSuccess = (data, productId) => {
    return {
        type: ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const incrementProductInCartFailure = (error) => ({
    type: ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_FAILURE,
    payload: error,
});

export const decrementProductInCart = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain
});

export const decrementProductInCartSuccess = (data, productId) => {
    return {
        type: ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const decrementProductInCartFailure = (error) => ({
    type: ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_FAILURE,
    payload: error,
});

export const checkoutProductOrder = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain,
});

export const checkoutProductOrderSuccess = (data) => {
    return {
        type: ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_SUCCESS,
        payload: data
    };
};
export const checkoutProductOrderFailure = (error) => ({
    type: ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_FAILURE,
    payload: error,
});

export const generateProductOrder = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_GENERATE_PRODUCT_ORDER_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain,
});

export const generateProductOrderSuccess = (data) => {
    return {
        type: ActionTypes.USER_GENERATE_PRODUCT_ORDER_SUCCESS,
        payload: data
    };
};
export const generateProductOrderFailure = (error) => ({
    type: ActionTypes.USER_GENERATE_PRODUCT_ORDER_FAILURE,
    payload: error,
});

export const getWishlistProducts = (reqData) => ({
    type: ActionTypes.USER_GET_WISHLIST_PRODUCTS_START,
    payload: reqData,
});

export const getWishlistProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_WISHLIST_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const getWishlistProductsFailure = (error) => ({
    type: ActionTypes.USER_GET_WISHLIST_PRODUCTS_FAILURE,
    payload: error,
});


export const getShopWishlistProducts = (reqData, isShopDomain) => ({
    type: ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_START,
    payload: reqData,
    isShopDomain: isShopDomain
});

export const getShopWishlistProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const getShopWishlistProductsFailure = (error) => ({
    type: ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_FAILURE,
    payload: error,
});

export const addProductToWishlist = (reqData) => ({
    type: ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_START,
    payload: reqData,
});

export const addProductToWishlistSuccess = (data, productId) => {
    toast.success("Added to wish list", {autoClose: 2000, hideProgressBar: true});
    return {
        type: ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const addProductToWishlistFailure = (error) => ({
    type: ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_FAILURE,
    payload: error,
});

export const removeProductFromWishlist = (reqData) => ({
    type: ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_START,
    payload: reqData,
});

export const removeProductFromWishlistSuccess = (data, productId) => {
    return {
        type: ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const removeProductFromWishlistFailure = (error) => ({
    type: ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE,
    payload: error,
});

export const fetchSingleProduct = productId => ({
    type: ActionTypes.FETCH_SINGLE_PRODUCT,
    productId
})

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product,qty) => (dispatch) => {
    toast.success("Added to Cart");
        dispatch(addToCartUnsafe(product, qty))

}
export const addToCartAndRemoveWishlist = (product,qty) => (dispatch) => {
    toast.success("Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
}
export const addToCartUnsafe = (product, qty) => ({
    type: ActionTypes.ADD_TO_CART,
    product,
    qty
});
export const removeFromCart = product_id => (dispatch) => {
    toast.error("Removed from Cart");
    dispatch({
        type: ActionTypes.REMOVE_FROM_CART,
        product_id
    })
};
export const incrementQty = (product,qty) => (dispatch) => {
    toast.success("Added to Cart");
    dispatch(addToCartUnsafe(product, qty))

}
export const decrementQty = productId => (dispatch) => {
    toast.warn("Decrement Qty to Cart");

    dispatch({
    type: ActionTypes.DECREMENT_QTY,
    productId})
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: ActionTypes.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: ActionTypes.REMOVE_FROM_WISHLIST,
        product_id
    })
};


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe= (product) => ({
    type: ActionTypes.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: ActionTypes.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: ActionTypes.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: ActionTypes.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: ActionTypes.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: ActionTypes.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: ActionTypes.CHANGE_CURRENCY,
    symbol
});

