/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Divider, Typography } from "@mui/material";
import Slider from "react-multi-carousel";
import { CustomLeftArrow, CustomRightArrow } from "components/Button/SliderButtons";
import * as Actions from "actions";

// import Custom Components
import ProductItem from "components/elements/user/ProductItem";
import responsive from "./SliderProps";

const UserProductSlider = (props) => {
  const {
    products, symbol, addProductToCart, addProductToWishlist,
    addToCompare, usergetPopularProducts,
  } = props;

  useEffect(() => {
    usergetPopularProducts();
  }, []);

  return (
    <Container>
      <div className="theme-card position-relative">
        <Typography variant="h6">Popular products</Typography>
        <Divider />
        <Slider
          containerClass="overflow-clear"
          renderButtonGroupOutside
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          responsive={responsive}
        >
          { products.map((product, index) => (
            <div key={`${index + 1}`}>
              <ProductItem
                product={product}
                symbol={symbol}
                onAddToCompareClicked={() => addToCompare(product)}
                onAddToWishlistClicked={() => addProductToWishlist(product)}
                onAddToCartClicked={() => addProductToCart(product, "")}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Container>

  );
};

UserProductSlider.defaultProps = {
  symbol: "$",
};

UserProductSlider.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  symbol: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usergetPopularProducts: PropTypes.func.isRequired,
};

// Mapping Props and State.
const UserProductSliderComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.popularproducts,
    symbol: userproducts.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductSlider);

export default UserProductSliderComponent;
