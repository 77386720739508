import * as ActionTypes from "constants/ActionTypes";
import {searchKeywords} from "actions/seller/seller.products";
import { CURRENCY } from "config/constants";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    products: [],
    popularproducts: [],
    searchproducts: [],
    categoryproducts: [],
    popularcategories: [],
    productprices: [],
    categoryList: {
        count: 0,
        categories: [],
    },
    productList: {
        count: 0,
        products: [],
    },
    searchproductList: {
        count: 0,
        products: [],
    },
    productsByCategory: {
        meta: {
            count: 0,
        },
        records: [],
    },
    storeProductCategoryList: {
        count: 0,
        categories: [],
    },
    storeProducts: {
        meta: {
            count: 0,
        },
        products: [],
    },
    productwishList: {
        count: 0,
        products: [],
    },
    symbol: CURRENCY,
    product_details: [],
    searchkeywords: {
        keywords: [],
    },
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        /* Common Triggers */
        case ActionTypes.USER_SEARCH_PRODUCTS_START:
        case ActionTypes.USER_SEARCH_KEYWORDS_START:
        case ActionTypes.USER_GET_PRODUCT_PRICES_UPC_START:
        case ActionTypes.USER_GET_COMMON_PRODUCTS_START:
        case ActionTypes.USER_GET_POPULAR_PRODUCTS_START:
        case ActionTypes.USER_GET_ACTIVE_CATEGORIES_START:
        case ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        case ActionTypes.USER_SEARCH_PRODUCTS_FAILURE:
        case ActionTypes.USER_SEARCH_KEYWORDS_FAILURE:
        case ActionTypes.USER_GET_PRODUCT_PRICES_UPC_FAILURE:
        case ActionTypes.USER_GET_COMMON_PRODUCTS_FAILURE:
        case ActionTypes.USER_GET_POPULAR_PRODUCTS_FAILURE:
        case ActionTypes.USER_GET_ACTIVE_CATEGORIES_FAILURE:
        case ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case ActionTypes.RECEIVE_PRODUCTS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                products: action.products };

        case ActionTypes.USER_SEARCH_PRODUCTS_SUCCESS:

            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    searchproducts: action.payload.response.products
                };
            } else {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    searchproducts: []
                };
            }

        case ActionTypes.USER_SEARCH_KEYWORDS_SUCCESS:
            const { keywords } = action.payload;

            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                searchkeywords: {
                    keywords : keywords,
                },
            };

        case ActionTypes.USER_SEARCH_PRODUCTS_RESET:

            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                searchproducts: [],
            };
        case ActionTypes.USER_SEARCH_KEYWORDS_RESET:

            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                searchkeywords: {
                    keywords : [],
                },
            };

        case ActionTypes.USER_GET_PRODUCT_PRICES_UPC_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                productprices: action.payload.prices };

        case ActionTypes.USER_GET_COMMON_PRODUCTS_SUCCESS:

            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    categoryproducts: action.payload.response.products
                };
            } else {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    categoryproducts: []
                };
            }

        case ActionTypes.USER_GET_POPULAR_PRODUCTS_SUCCESS:

            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    popularproducts: action.payload.response.products
                };
            } else {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    popularproducts: []
                };
            }

        case ActionTypes.USER_GET_ACTIVE_CATEGORIES_SUCCESS:
            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    categoryList: action.payload.response.categories,
                };
            } else {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    categoryList: [],
                };
            }

        case ActionTypes.USER_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS:
            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    popularcategories: action.payload.response.categories,
                };
            } else {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    popularcategories: [],
                };
            }

        case ActionTypes.FETCH_SINGLE_PRODUCT:
            if (state.products.findIndex(product => product.id === action.productId) !== -1) {
                const singleItem = state.products.reduce((itemAcc, product) => {
                    return product
                }, [])
                return { ...state,
                    product_details: singleItem };
            }

        case ActionTypes.CHANGE_CURRENCY:
            return { ...state,
                symbol: action.symbol };
        default:
            return state;
    }
};
export default productReducer;
