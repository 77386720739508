import React from "react";
// import { Image } from "components/Atoms";
// import ButtonComponent from "components/Button";
import { Avatar } from "@mui/material";

export default (props) => props.images.map((image, i) => (
  <div>
    {/* <ButtonComponent
      onClick={() => props.removeImage(image.cdn.public_id)}
      name="Delete"
    /> */}
    <Avatar className="image_large" alt="Shop" src={image.cdn.secure_url} />
  </div>
));
